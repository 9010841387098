import React, { useEffect, useState } from "react";
import "./SearchData.css";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Link, useHistory } from "react-router-dom";

const SearchData = (props) => {
  const history = useHistory();
  const [closingSoon, setClosingSoon] = useState(false);
  const closingSoonDays = 5;
  const date = new Date();
  date.setDate(date.getDate() + closingSoonDays);
  const endDate = new Date(props.endDate);

  useEffect(() => {
    console.log(+date >= +endDate);
    setClosingSoon(+date >= +endDate);
  }, []);

  // const handle_click = () => {
  //   // history.push(`/dashboard/order-entry?symbol=${props?.symbol}`);
  //   history.push("/companies/" + props.symbol);
  //   props.close();
  // };

  const liveNow = {
    color: "#2eb139",
    fontWeight: "500",
    background: "#e4ffe5",
  };
  const gaugingInterest = {
    color: "rgb(226, 175, 22)",
    background: "rgba(244, 186, 12,0.5)",
  };
  const two_way = {
    color: "rgb(20, 158, 209)",
    background: "rgba(20, 158, 209,0.5)",
  };
  const express_deal = {
    // borderTopLeftRadius: "0px",
    // borderTopRightRadius: "0px",color: "#fcc777",
    background: "rgba(255, 193, 7, 0.288) ",
    color: "#fcc777",
  };
  return (
    <Link
      to={props.listingStage == "EXPRESS_DEAL" ? `/dashboard/order-entry?symbol=${props?.symbol}` : `/companies/${props?.symbol}`}
      className="search__data"
      onClick={() => props.close()}
    >
      <div className="search__data_left">
        <span className="search__data_title">{props.legalName}</span>
        <span className="search__data_subtitle">
          <span className="sds__sector">Sector: {props.sector}&nbsp;</span>|
          <span className="sds__symbol">&nbsp;Symbol: {props.symbol}</span>
        </span>
        <span className="search__data_closingsoon">
          {closingSoon && "Closing Soon"}{" "}
        </span>
      </div>
      <div className="search__data_right">
        <div
          className="search__data_badge"
          style={
            props.listingStage == "LIVE_NOW"
              ? liveNow
              : props.listingStage == "GAUGING"
              ? gaugingInterest
              : props.listingStage == "EXPRESS_DEAL"
              ? express_deal
              : null
          }
        >
          {props.listingStage == "LIVE_NOW"
            ? "Live Now"
            : props.listingStage == "GAUGING"
            ? "Gauging Interest"
            : props.listingStage == "EXPRESS_DEAL"
            ? "Express Deal"
            : props.listingStage}
        </div>
        <div
          className="search__data_badge"
          style={props.type == "TWO_WAY" ? two_way : null}
        >
          <div>{props.type == "TWO_WAY" ? "2 Way Quote" : "Fresh Round"}</div>
          <ErrorOutlineIcon className="sd__freshround_icon" />
        </div>
      </div>
    </Link>
  );
};

export default SearchData;
