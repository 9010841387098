import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { whoami } from "../../../../api/auth";
import { toast } from "react-toastify";

const EvarRedirect = () => {
  const [msg, setMsg] = useState("");
  const location = useLocation();
  const history = useHistory();
  const status = new URLSearchParams(location.search).get("status");
  const userName = new URLSearchParams(location.search).get("userName");
  const userType = new URLSearchParams(location.search).get("userType");
  const token = new URLSearchParams(location.search).get("token");

  const get_details = async () => {
    const my_details = await whoami(token);
    if (my_details.error) {
      toast.error(my_details.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log(my_details.data);
    }
  };

  useEffect(() => {
    get_details();
    console.log(status);
    if (status === "SUCCESS") {
      localStorage.setItem("token", token);
      history.push({
        pathname: "/mobile-otp",
        state: { userName, userType, token },
      });
    }
    if (status === "FAILURE") {
      setMsg("verification failed......");
    }
    if (status === "EXPIRED") {
      setMsg("verification email expired");
    }
  }, []);
  return <div>{msg}</div>;
};

export default EvarRedirect;
