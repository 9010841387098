import { useState } from "react";
import Slide from "../../dashboard/components/slide/Slide";
import ExpressDeal from "../../OrderCreation/Components/ExpressDeal/ExpressDeal";
import BackIcon from "../../OrderModification/Components/BackIcon/BackIcon";
import BuyerInfo from "../../OrderModification/Components/BuyerInfo/BuyerInfo";
import Express from "../ExpressPopup/Express";

import "./AddFunds.css";

const AddFunds = () => {
  // !why state?
  const [funds, setFunds] = useState(true);

  return (
    <>
      <div className="af__container">
        <div className="af__upper">
          <div className="af__buyerInfo__component">
            <BuyerInfo />
          </div>
          <div className="af__slide__component">
            <Slide
              items={[
                "funding",
                "resources",
                "invest/divest",
                "modify",
                "order",
                "create",
              ]}
            />
          </div>
          <div className="af__backIcon__component">
            <BackIcon />
          </div>
          <div className="af__heading">
            Add <span>Funds</span>
            <p className="af__heading__para">
              Send payment link to your client to complete this order
            </p>
          </div>

          <p className="af__heading__para2">
            Rs <span style={{ fontWeight: "bolder" }}> 40,235</span> are
            required as margins to complete this order. These margins are 100%
            refundable if transaction is not commplete
          </p>

          {funds === true ? (
            <div className="af__expressdeal__component">
              <ExpressDeal />
            </div>
          ) : (
            <p className="af__watch1">
              If the price at which the order is placed is too low & doesn't get
              qualified for an allocation, or in an unlikely event where we do
              nbot receive enough demand for an opened investment round, margin
              funds shall be refunded and there shall be no charges whatsoever.
            </p>
          )}

          <p className="af__watch">
            Need clarity on the transaction process?{" "}
            <span>
              <a
                href="https://youtu.be/GQl3f-e_goI"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Watch video.
              </a>
            </span>
          </p>

          <p className="af__footer__para">
            Send a link to your clents to transfer funds via
            UPI/Netbanking/NEFT/IMPS/RTGS
          </p>
        </div>

        <div className="addfunds__lower">
          <Express />
        </div>
      </div>
    </>
  );
};

export default AddFunds;
