import React, { useState } from "react";
import "./Bankdata.css";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Button from "../../../components/button/Button";

const Bankdata = ({ data_for_step2, setOtp, setCheckDisabled, profileData, incorrectOtp }) => {
  const [confirmCheck, setConfirmCheck] = useState(true);
  return (
    <>
      <div className="bankinfo__container">
        {console.log(data_for_step2)}
        <div className="userInfo__container">
          <div className="first__info">
            <div className="info__key">Name:</div>
            <p className="info__value">
              &nbsp;{data_for_step2?.accountHolderName}
            </p>
          </div>
          <div className="first__info">
            <div className="info__key">Bank:</div>
            <p className="info__value">&nbsp;{data_for_step2?.bankName}</p>
          </div>
          <div className="first__info">
            <div className="info__key">Account:</div>
            <p className="info__value">
              {/* &nbsp;************
              {data_for_step2?.ac?.substr(data_for_step2?.ac?.length - 4, 4)} */}
              &nbsp;{data_for_step2?.ac}
            </p>
          </div>
          <div className="first__info">
            <div className="info__key">Branch:</div>
            <p className="info__value">&nbsp;{data_for_step2?.bankBranch}</p>
          </div>
          <div className="bankdata__confirm">
            <div className="bankdata__checkbox">
              <Checkbox
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                checked={confirmCheck}
                onChange={(e) => {
                  setConfirmCheck(e.target.checked);
                  setCheckDisabled(confirmCheck);
                }}
              />
            </div>
            <div className="bankdata__confirmline">
              I confirm that the above bank account belongs to me.
            </div>
          </div>

          <div className="bankdata__otp">
            <p className="bankdata__otpmsg">
              Enter OTP sent to you via SMS on {profileData?.mobileNumber} & via email on {profileData?.email}
            </p>

            <div style={{ margin: "40px 0" }} className="bankdata__otpinput">
              <TextField
                style={{ width: "100%" }}
                inputProps={{
                  className: incorrectOtp && "incorrect__otp_input"
                }}
                id="outlined-basic"
                placeholder="Enter OTP"
                variant="standard"
                onChange={(e) => setOtp(e.target.value)}
              />
              {incorrectOtp && <p className="bankdata__wrongotpmsg" >
                Incorrect OTP. Please try again.
              </p>}
            </div>
          </div>
        </div>
      </div>
      {/* <Button text="ADD BANK ACCOUNT" /> */}
    </>
  );
};

export default Bankdata;
