import React from "react";
import "./ActionAlert.css";
import CloseIcon from "@material-ui/icons/Close";

const ActionAlert = ({ text, btnText, action, onOpen, onClose }) => {
  return (
    <div
      className="action__alert_wrapper "
      onClick={(e) => e.stopPropagation()}
    >
      <div className="action__alert">
        <CloseIcon className="action__alert_close" onClick={() => onClose()} />
        <p className="action__alert_text">{text}</p>
        <span className="action__alert_btn" onClick={() => action()}>
          {btnText}
        </span>
      </div>
    </div>
  );
};

export default ActionAlert;
