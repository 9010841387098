import React, { useEffect, useState } from "react";
import "./Table.css";

const Table = ({ headings, data, colWidth }) => {
  const [keys, setKeys] = useState();
  console.log(data);
  useEffect(() => {
    if (data && data !== null && data[0] && data[0] !== null) {
      setKeys(Object.keys(data[0]));
    } else {
      setKeys([]);
    }
  }, [data]);

  return (
    <div className="table__wrapper">
      <div className="table__body" style={colWidth}>
        {headings?.map((heading) => (
          <span className="table__heading">{heading ? heading : null}</span>
        ))}
        {data?.map((item) => (
          <>
            {keys?.map((key) => {
              return <span className="table__data">{item[key]}</span>;
            })}
          </>
        ))}
      </div>
    </div>
  );
};

export default Table;
