import { useState, useEffect } from "react";
import Button from "../../../components/button/Button";
import "./Motp.scss";
import { useHistory } from "react-router-dom";
import phone from "../../../Assets/imgs/phone.svg";
import { resend_otp } from "../../../api/auth";
import { toast } from "react-toastify";
import { mobile_verification, verify_otp } from "../../../api/profile";
import Alert from "../../../components/Alert/Alert";
import { useDispatch, useSelector } from "react-redux";
import { profile_details } from "../../../api/profile";
import { set_profile } from "../../../store/User";

const Motp = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [resend, setResend] = useState(false);
  const [secs, setSecs] = useState(15);
  const [partnerData, setPartnerData] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const stateToken = useSelector((state) => state.user.token);
  const [resendOpt, setResendOpt] = useState(false);

  const token = localStorage.getItem("token");

  const gen_otp = async () => {
    console.log("sending otp via sms");
    const sent = await mobile_verification(stateToken);
    if (sent.error) {
      toast.error(sent.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log("otp sent....");
    }
  };

  const otp_login = async () => {
    setLoading(true);
    console.log(otp);
    const responseToken = await verify_otp(otp, token);
    if (responseToken.error) {
      setLoading(false);
      toast.error(responseToken.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log(responseToken.data);
      let profile = await profile_details(token);
      if (profile.error != null) {
        toast.error(profile, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log(profile);
        dispatch(set_profile(profile.data.data));
        localStorage.setItem("profile", JSON.stringify(profile.data.data));
        //setProfile(profile.data.data);
        // dispatch(signup_user(responseToken.data.data));
        // localStorage.setItem("token", responseToken.data.data);
        history.push("/getstarted");
        setLoading(false);
      }
    }
  };

  const handle_resend = async (type) => {
    setResendOpt(false);
    setResend(false);
    setSecs(30);
    resendTimer();

    let res = await resend_otp(stateToken, type);
    if (res.error) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log("otp resent successfully");
    }
  };

  const resendTimer = () => {
    if (!resend) {
      setInterval(
        () =>
          setSecs((secs) => {
            if (secs > 0) {
              return secs - 1;
            } else {
              setResend(true);
              return 0;
            }
          }),
        1000
      );
    }
  };

  useEffect(() => {
    if (!resend) {
      setInterval(
        () =>
          setSecs((secs) => {
            if (secs > 0) {
              return secs - 1;
            } else {
              setResend(true);
              return 0;
            }
          }),
        1000
      );
    }
  }, []);

  useEffect(() => {
    setPartnerData(JSON.parse(localStorage.getItem("partner_data")));
    gen_otp();
  }, []);

  return (
    <div className="otp__screen">
      <Alert text="You emailID was verified successfully" />
      <div className="opt__body">
        <div className="Motp__headerimg">
          <img src={phone} alt="Error" className="Motp__img" />
          <div className="Motp__text">Just One Last Step</div>
        </div>
        <h1 className="Motp__heading">
          Mobile Verification
          <div className="Motp__link">
            <span>OTP</span>
          </div>
        </h1>
        <p className="otp__msg">
          Enter OTP sent to you via SMS on {partnerData.countryCode}
          {partnerData.mobileNumber}
        </p>
        <div className="otp-input">
          <input
            type="number"
            placeholder="Enter your OTP ( 6 digits )"
            value={otp}
            autoFocus
            onChange={(e) => setOtp(e.target.value.slice(0, 6))}
            disabled={loading}
          />
          <button
            className="paste-btn"
            onClick={otp_login}
            disabled={otp.length < 6 || loading}
          >
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="5" y1="12" x2="19" y2="12"></line>
              <polyline points="12 5 19 12 12 19"></polyline>
            </svg>
          </button>
        </div>
        {resend ? (
          <div className="resend__btn">
            {resend ? (
              <div className="resend__btn">
                {!resendOpt ? (
                  <span onClick={() => setResendOpt(true)}>Re-send OTP</span>
                ) : (
                  <span className="resend__otps">
                    <span onClick={() => handle_resend("SMS")}>
                      Re-send via sms
                    </span>
                    -
                    <span onClick={() => handle_resend("CALL")}>
                      Re-send via call
                    </span>
                  </span>
                )}
              </div>
            ) : (
              <div className="resend__text">Re-send OTP (After {secs}s)</div>
            )}
          </div>
        ) : (
          <div className="resend__text">Re-send OTP (After {secs}s)</div>
        )}
      </div>
      <div className="btn">
        <Button
          text="DONE"
          loading={loading}
          click={otp_login}
          disabled={otp.length < 6}
        />
      </div>
    </div>
  );
};

export default Motp;
