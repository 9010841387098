import { useState } from "react";
import upload from "../../../Assets/imgs/upload.svg";
import "./UploadDemet.css";
import CircularProgress from "@material-ui/core/CircularProgress";

const UploadDemet = ({ verify_upload }) => {
  const [loading, setLoading] = useState(false);
  let data = new FormData();

  const handelupload = async (e) => {
    data.append("file", e.target.files[0]);
    setLoading(true);
    const verified = await verify_upload(data);
    console.log(verified);
    e.target.value = null;
    setLoading(false);
  };

  return (
    <>
      <div className="updemet__container" style={{ padding: "0 20px" }}>
        {loading ? (
          <div className="demat__progress_wrapper">
            <CircularProgress className="demat__progress_loader" />
          </div>
        ) : (
          <label for="demat_input" className="updemat__header">
            <input
              id="demat_input"
              type="file"
              accept="application/pdf"
              onChange={(e) => handelupload(e)}
              className="demat_pdf"
            />
            <div className="updemat__img">
              <img
                style={{ width: "40px", height: "36px", marginRight: "6px" }}
                src={upload}
                alt=""
              />
            </div>
            <span className="updemat__headerpara">Upload CMR / CML</span>
          </label>
        )}
        <div className="align_center">
          <div
            className="updemet__para2"
            style={{ fontSize: "11px", color: "#8a8a8a" }}
          >
            You can get your CMR / CML from your stock broker / depository
            participant. <span> Click here to know more</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDemet;
