import React from "react";
import "./ErrorBox.css";

const ErrorBox = () => {
  return (
    <div className="pr__error_box">
      Your profile is incomplete. Please complete your profile immediately to
      ensure successful completion of transactions.
    </div>
  );
};

export default ErrorBox;
