import { useEffect, useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Progress from "@material-ui/core/CircularProgress";

import call from "../../../Assets/imgs/clientphone.svg";
import what from "../../../Assets/imgs/clientwhatsapp.svg";
import message from "../../../Assets/imgs/clientemail.svg";

import { toast } from "react-toastify";

import { getClient } from "../../../api/menu";
import { useSelector } from "react-redux";

import "./AllClients.scss";

const AllClients = () => {
  let user = useSelector((state) => state.user);

  const [clients, setClients] = useState(null);

  const gettingClients = async () => {
    let res = await getClient(user);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setClients(res.data);
    }
  };

  useEffect(() => {
    gettingClients();
  }, []);

  const callPhone = (number) => {
    if (number == null || !number) {
      toast.error("Mobile Number not present for client", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      window.open(`tel:+91${number}`, "_blank");
    }
  };
  const callWhatsapp = (number) => {
    if (number == null || !number) {
      toast.error("Mobile Number not present for client", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      window.open(`//api.whatsapp.com/send?phone=+91${number}`, "_blank");
    }
  };
  const callEmail = (email) => {
    window.open(`mailto:${email}`, "_blank");
  };

  return (
    <>
      {!clients ? (
        <>
          <div className="progress">
            <Progress className="bar" />
          </div>
        </>
      ) : (
        <>
          <div className="allC__container">
            <p className="allC__heading">Clients</p>
            {!clients || clients.length < 1 ? (
              <>
                <div style={{ margin: ".5rem" }}>
                  {" "}
                  You currently have no registered clients{" "}
                </div>
              </>
            ) : (
              <>
                <div className="allC__accor">
                  {clients.map((val, index) => {
                    console.log(val);
                    return (
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <div className="allC__box">
                            <p className="allC__clientname">
                              {val.fullName != null
                                ? val.fullName
                                : val.emailId}
                            </p>
                            <p
                              style={{
                                backgroundColor:
                                  val.profileComplete == true ? "green" : null,
                              }}
                              className="allC__icon2"
                            >
                              {" "}
                            </p>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="allC__expanded__view">
                            <p className="allC__para">
                              Profile Status :-{" "}
                              <span
                                style={{
                                  color:
                                    val.profileComplete == true
                                      ? "green"
                                      : "red",
                                  fontStyle: "italic",
                                }}
                              >
                                {" "}
                                {val.profileComplete == true
                                  ? "Completed"
                                  : "Incomplete"}{" "}
                              </span>
                            </p>
                            <div className="allC__icons">
                              <img
                                src={call}
                                alt=""
                                className="allC__icon1"
                                onClick={() => callPhone(val.mobileNumber)}
                              />
                              <img
                                src={what}
                                alt=""
                                className="allC__icon1"
                                onClick={() => callWhatsapp(val.mobileNumber)}
                              />
                              <img
                                src={message}
                                alt=""
                                className="allC__icon1"
                                onClick={() => callEmail(val.emailId)}
                              />
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AllClients;
