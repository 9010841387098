import React, { useState, useEffect, useRef } from "react";
import "./Details.css";
import Appbar from "./components/appbar/Appbar";
import video from "../../Assets/videos/droneview.mp4";
import DbCard from "./components/dbcard/DbCard";
import FloatButton from "./components/floatBtn/FloatBtn";
import { dashboard_company_data } from "../../api/dashboard";
import { processLocalStorageWrite } from "../../api/localstorage";
import { useHistory, useParams } from "react-router-dom";
import ScreenLoader from "../../components/screen-loader/ScreenLoader";
import { useSelector } from "react-redux";
import SearchScreen from "./components/search-screen/SearchScreen";
import ClientpartnerMenu from "../../components/client-partner-menu/ClientPartnerMenu";

const Details = () => {
  const user = useSelector((state) => state.user);
  const [currentBox, setCurrentBox] = useState(0);
  const [data, setData] = useState({});
  const params = useParams();
  const [active, setActive] = useState(null);
  const [personalised, setPersonalised] = useState(false);
  const [screenLoading, setScreenLoading] = useState();
  const [searchScreen, setSearchScreen] = useState(false);
  const [goingUp, setGoingUp] = useState(true);
  const [additionalBox, setAdditionalBox] = useState(false);
  const [partnerInUrl, setPartnerInUrl] = useState(null);
  const [slide, setSlide] = useState([
    "about",
    "funding",
    "resources",
    "invest/divest",
  ]);
  const history = useHistory();
  const prevScroll = useRef(0);
  const searchQuery = new URLSearchParams(history.location.search);
  const partnerName = searchQuery.get("partnerName");
  const option = searchQuery.get("option");
  const noRedirect = searchQuery.get("noRedirect");
  const token = useSelector((state) => state.user.token);
  const get_company = async () => {
    setScreenLoading(true);
    let response = await dashboard_company_data(
      params.symbol,
      personalised,
      partnerName !== null ? partnerName : false
    );
    if (response.error) {
      setScreenLoading(false);
      alert(response.error);
    } else {
      setScreenLoading(false);
      setData(response.data.data);
      console.log(response.data.data);
    }
  };
  const handle_scroll = (e) => {
    if (prevScroll.current < e.target.scrollTop && goingUp) {
      setGoingUp(false);
    }
    if (prevScroll.current > e.target.scrollTop && !goingUp) {
      setGoingUp(true);
    }
    prevScroll.current = e.target.scrollTop;
  };

  useEffect(() => {
    if (token !== null) {
      setPersonalised(true);
    } else {
      setPersonalised(false);
    }
    get_company();
  }, [params.symbol]);
  const invest_divest = () => {
    console.clear();
    console.log("hello");
    if (data.type === "TWO_WAY") {
      history.push({
        pathname: "/dashboard/order-entry",
        state: { company: data },
        search: `?symbol=${data.symbol}${
          partnerName !== null ? `&partnerName=${partnerName}` : ""
        } `,
      });
    } else if (data.listingStage === "GAUGING") {
      history.push({
        pathname: "/gaug-interest",
        search: `?symbol=${data.symbol}${
          partnerName !== null ? `&partnerName=${partnerName}` : ""
        } `,
      });
    } else {
      history.push({
        pathname: history.location.pathname,
        search: `?option=invest/divest${
          partnerName !== null ? `&partnerName=${partnerName}` : ""
        }`,
      });
    }
  };
  useEffect(() => {
    slide[active] == "invest/divest" && invest_divest();
  }, [active]);
  useEffect(() => {
    if (noRedirect === true) {
      setAdditionalBox(true);
    }
    if (data?.listingStage == "LIVE_NOW") {
      setAdditionalBox(true);
    }
  }, [noRedirect, history.location.search, data]);

  useEffect(() => {
    console.log(option);
    if (option === "about") {
      setActive(0);
    } else if (option === "funding") {
      setActive(1);
    } else if (option === "resources") {
      setActive(2);
    } else if (option === "invest/divest") {
      setActive(3);
    } else {
      setActive(0);
    }
  }, [option]);
  useEffect(() => {
    console.log("data: ", data);
  }, [data]);

  useEffect(() => {
    if (partnerInUrl) {
      console.log("removing partner name form url");
      searchQuery.delete("partnerName");
      history.replace({
        search: searchQuery.toString(),
      });
      if (searchQuery.get("partnerName") == null) {
        console.log("imhere00001");
        setPartnerInUrl(1);
      }
    }
    if (partnerInUrl === 1) {
      if (searchQuery.get("partnerName") != null) {
        console.log("removing partner name form url");
        searchQuery.delete("partnerName");
        history.replace({
          search: searchQuery.toString(),
        });
        setPartnerInUrl(false);
      }
    }
  }, [partnerInUrl]);

  useEffect(async () => {
    if (
      user?.profile?.userType === "INDIVIDUAL_CLIENT" ||
      user?.profile === null
    ) {
      const isPersonalised = user.token !== null ? true : false;
      const uid = user.profile == null ? 0 : user.profile.userId;
      const userToken = user.token;
      const processs = await processLocalStorageWrite(
        uid,
        isPersonalised,
        userToken,
        partnerName
      );
      if (processs === 0) {
        setPartnerInUrl(true);
        console.log("removethis");
      }
    }
  }, [partnerName, user.profile]);

  return (
    <div className="details" onScroll={handle_scroll}>
      <div className="dashboard__header_details">
        {/* {screenLoading && <ScreenLoader />} */}
        {screenLoading && (
          <div className="backdrop">
            <ScreenLoader />
          </div>
        )}
        {searchScreen && (
          <SearchScreen onClose={() => setSearchScreen(false)} />
        )}

        {!additionalBox && (
          <FloatButton text="Invest / divest" click={invest_divest} />
        )}
        <div style={{ display: goingUp ? "block" : "none" }}>
          <ClientpartnerMenu
            searchbar={true}
            onFocus={() => setSearchScreen(true)}
          />
        </div>
      </div>

      {/* <p>
        Change the content of this editor, then save it on the server.asmasfhkaf
      </p>
      <figure class="image">
        <img
          src="https://33333.cdn.cke-cs.com/rc1DFuFpHqcR3Mah6y0e/images/ffbb613de2b36fb473cae8409685511ce87ac8e00017fb3c.png"
          srcset="https://33333.cdn.cke-cs.com/rc1DFuFpHqcR3Mah6y0e/images/ffbb613de2b36fb473cae8409685511ce87ac8e00017fb3c.png/w_100 100w, https://33333.cdn.cke-cs.com/rc1DFuFpHqcR3Mah6y0e/images/ffbb613de2b36fb473cae8409685511ce87ac8e00017fb3c.png/w_200 200w, https://33333.cdn.cke-cs.com/rc1DFuFpHqcR3Mah6y0e/images/ffbb613de2b36fb473cae8409685511ce87ac8e00017fb3c.png/w_300 300w, https://33333.cdn.cke-cs.com/rc1DFuFpHqcR3Mah6y0e/images/ffbb613de2b36fb473cae8409685511ce87ac8e00017fb3c.png/w_400 400w, https://33333.cdn.cke-cs.com/rc1DFuFpHqcR3Mah6y0e/images/ffbb613de2b36fb473cae8409685511ce87ac8e00017fb3c.png/w_500 500w, https://33333.cdn.cke-cs.com/rc1DFuFpHqcR3Mah6y0e/images/ffbb613de2b36fb473cae8409685511ce87ac8e00017fb3c.png/w_600 600w, https://33333.cdn.cke-cs.com/rc1DFuFpHqcR3Mah6y0e/images/ffbb613de2b36fb473cae8409685511ce87ac8e00017fb3c.png/w_700 700w, https://33333.cdn.cke-cs.com/rc1DFuFpHqcR3Mah6y0e/images/ffbb613de2b36fb473cae8409685511ce87ac8e00017fb3c.png/w_800 800w, https://33333.cdn.cke-cs.com/rc1DFuFpHqcR3Mah6y0e/images/ffbb613de2b36fb473cae8409685511ce87ac8e00017fb3c.png/w_900 900w, https://33333.cdn.cke-cs.com/rc1DFuFpHqcR3Mah6y0e/images/ffbb613de2b36fb473cae8409685511ce87ac8e00017fb3c.png/w_928 928w"
          sizes="100vw"
          width="928"
        />
      </figure> */}

      <div className="details__body" style={{ marginTop: "50px" }}>
        <DbCard
          logo={data.logo}
          title={data?.about?.aka}
          sector={data.sector}
          isin={data.isin}
          lkv={data.lkv}
          listingStage={data.listingStage}
          shortDescription={data.shortDescription}
          highlights={data.highlights}
          mediaType={data.mediaType}
          mediaLink={data.mediaLink}
          legalName={data.legalName}
          lkvDenomination={data.lkvDenomination}
          type={data.type}
          symbol={data.symbol}
          demand={data.demand}
          threshHold={data.threshHold}
          endDate={data.endDate}
          founders={data?.about?.founders}
          industries={data?.about?.industries}
          detailsBox={slide[active]}
          slide={slide}
          setActiveBox={setActive}
          activeBox={active}
          about={data.about ? data.about : []}
          funding={data.funding}
          resources={data?.resources}
          customInformation={data.customInformation}
          get_company={get_company}
          additionalBox={option === "invest/divest" ? true : false}
        />
      </div>
    </div>
  );
};

export default Details;
