import React from "react";
import "./AddDemet.css";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Button from "../../../components/button/Button";
import { delete_demat } from "../../../api/profile";
import { useSelector } from "react-redux";
const AddDemet = ({ setOtp, demat_response, profileData, setDisabled }) => {
  const stateProfile = useSelector((state) => state.user.profile);
  return (
    <>
      <div className="bankinfo__container">
        <div className="userInfo__container">
          <div className="first__info">
            <div className="info__key">Depository :</div>
            <p className="info__value">&nbsp;{demat_response?.depository}</p>
          </div>
          <div className="first__info">
            <div className="info__key">Beneficiary Name :</div>
            <p className="info__value">
              &nbsp;{demat_response?.firstHolderName}
            </p>
          </div>
          <div className="first__info">
            <div className="info__key">Demat A/c :</div>
            <p className="info__value">
              &nbsp;{demat_response?.depositoryId}
              {demat_response?.clientId}
            </p>
          </div>
          <div className="adddemet__confirm">
            <div className="adddemet__checkbox">
              <Checkbox
                defaultChecked
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                onChange={(e) => setDisabled(!e.target.checked)}
              />
            </div>
            <div className="adddemet__confirmline">
              I confirm that the above demat account belongs to me.
            </div>
          </div>

          <div className="bankdata__otp">
            <p className="bankdata__otpmsg">
              Enter OTP sent to you via SMS on {profileData.mobileNumber}& via
              Email on
              {profileData.email}
            </p>

            <div style={{ margin: "40px 0" }} className="bankdata__otpinput">
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                placeholder="Enter OTP"
                variant="standard"
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDemet;
