import React, { useEffect, useState } from "react";
import "./Resources.css";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import ShareIcon from "@material-ui/icons/Share";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ShareScreen from "../../../../components/share-screen/ShareScreen";
import { whoami } from "../../../../api/auth"
import { ToastContainer, toast } from "react-toastify";

const Resources = ({ resources, setActiveBox }) => {
  const [shareScreen, setShareScreen] = useState(false);
  const history = useHistory();
  const searchQuery = new URLSearchParams(history.location.search);
  const partnerName = searchQuery.get("partnerName");
  const token = useSelector(state => state.user.token);
  const [myData, setMyData] = useState({});
  const my_info = async () => {
    let res = await whoami(token);
    if (res.error) {
      console.log(res.error)
    }
    else if (res) {
      setMyData(res?.data?.data)
    }
  }
  console.log(resources)

  useEffect(() => {
    history.push({
      pathname: `${history.location.pathname}`,
      search: `?${partnerName ? `partnerName=${partnerName}&` : ""}option=resources`
    });
    setActiveBox(2)
    my_info()
  }, [])
  return (
    <div className="resources__box">
      {shareScreen && <ShareScreen url={window.location.origin + window.location.pathname + "?" + searchQuery} onClose={() => setShareScreen(false)} />}
      <span className="resources__heading">Resources</span>
      <span className="resources__desc">
        Download/Share bundle of analytical resources like annual Reports,
        Corporate Presentations, Pitch Decks etc.
      </span>
      <div className="resources__buttons">
        {resources?.downloadLink && resources && resources.downloadLink != null ? <a href={resources?.downloadLink} download={resources.downloadLink.substring(resources.downloadLink.lastIndexOf('/') + 1)} target="_blank">
          <SystemUpdateAltIcon className="resources__icon" />
          <span className="resources__text">Download</span>
        </a> :
          <span onClick={() => {
            toast.warning("Nothing To Download", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }}>
            <SystemUpdateAltIcon className="resources__icon" />
            <span className="resources__text">Download</span>
          </span>}



        <a onClick={(e) => {
          if (myData && myData?.userType !== "INDIVIDUAL_CLIENT") {
            searchQuery.set("partnerName", myData.username)
          }
          e.stopPropagation()
          if (navigator.share) {
            navigator.share({
              title: "This company looks investment worthy",
              url: window.location.origin + window.location.pathname + "?" + searchQuery
            }).then(() => {
              console.log("Shared")
            }).catch(console.log("FailShare"))
          } else {
            setShareScreen(true)
          }
        }} >
          <ShareIcon className="resources__icon" />
          <span className="resources__text">Share</span>
        </a>
      </div>
    </div>
  );
};

export default Resources;
