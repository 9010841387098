import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store/ConfigureStore";
import Signin from "./screens/signin/Signin";
import Signup from "./screens/signup/Signup";
import PSignup from "./screens/PartnerSignup/PartnerSignup/PartnerSignup";
import EVar from "./screens/EmailVar/EVar";
import Otp from "./screens/otp/Otp";
import Motp from "./screens/PartnerSignup/MobileOtp/Motp";
import Dashboard from "./screens/dashboard/Dashboard";
import Details from "./screens/dashboard/Details";
import ProtectedRoutes from "./ProtectedRoutes";
// import OrderEntry from "./screens/OrderCreation/Screens/OrderEntry/OrderEntry";
import OrderEntry from "./screens/OrderCreation/Screens/OrderEntry/OrderEntry2";
import ValidateOrder from "./screens/PartnerInitiated/ValidateOrder/ValidateOrder";
import Potp from "./screens/PartnerInitiated/PartnerOtp/Potp";
import Notbidding from "./screens/OrderDetails/FreshRound/NotBidding/Notbidding";

import RefreshToken from "./components/refresh-token/RefreshToken";
import MailOtp from "./screens/PartnerSignup/mailOtp/MailOtp";
import Sign from "./screens/Register/Sign";
import SignOtp from "./screens/Register/SignOtp";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <div className="App">
          <RefreshToken />
          <ScrollToTop>
            <Switch>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/companies/:symbol" component={Details} />
              <Route exact path="/signin" component={Signin} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/partner/signup" component={PSignup} />
              <Route exact path="/partner/mailotp/:email" component={MailOtp} />
              <Route exact path="/otp" component={Otp} />
              <Route exact path="/evar" component={EVar} />
              <Route exact path="/mobile-otp" component={Motp} />
              <Route
                exact
                path="/dashboard/order-entry"
                component={OrderEntry}
              />
              <Route exact path="/validate-order" component={ValidateOrder} />
              <Route exact path="/partner-otp" component={Potp} />
              <Route exact path="/gaug-interest" component={Notbidding} />
              <Route exact path="/register" component={Sign} />
              <Route exact path="/register-otp" component={SignOtp} />

              {/* <Route exact path="/extra" component={Extra} /> */}

              <ProtectedRoutes />
            </Switch>
          </ScrollToTop>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
