import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import "./CheckboxComponent.css";

const CheckboxComponent = ({ text, checked, setChecked }) => {
  return (
    <>
      <div className="tq__confirm">
        <div className="tq__checkbox">
          <Checkbox
            style={{ color: "green" }}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
            checked={checked == true || checked == false ? checked : true}
            onChange={() =>
              setChecked ? setChecked(!checked) : null
            }
          />
        </div>
        {text ? (
          <div className="tq__confirmline"> {text} </div>
        ) : (
          <div className="tq__confirmline">I/we agree to the <a  target="_blank"
          rel="noopener noreferrer" href="https://www.fundsmap.com/tnc">T&C</a> and <a  target="_blank"
          rel="noopener noreferrer" href="https://www.fundsmap.com/policies">Policies.</a></div>
        )}
      </div>
    </>
  );
};

export default CheckboxComponent;
