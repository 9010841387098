import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { whatsapp_lead } from '../../../../api/dashboard'
import watsapp from "../../../../Assets/imgs/whatsapp.svg"
import BottomSlide from '../../../../components/bottom-slide/BottomSlide'
import Button from '../../../../components/button/Button'
import CheckboxComponent from '../../../../components/ConfirmCheckbox/CheckboxComponent'
import Mnumber from '../../../../components/Mob-num input/Mnumber'
import BSHeader from '../../../profile/components/bs-header/BSHeader'
import "./Updated.css";

const Updated = ({ setOpen }) => {
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState();
    const [checked, setChecked] = useState(true);
    const token = useSelector(state => state.user.token);
    const addWhatsapp = async () => {
        setLoading(true)
        let res = await whatsapp_lead(token, phone.slice(1, 3), phone.slice(3));
        if (res.error) {
            alert(res.error)
            setLoading(false)
        }
        else {
            console.log("phone added successfully")
            setLoading(false)
            setOpen(false)
        }


    }

    return (
        <>
            <BottomSlide> <div className="up__contianer">
                <BSHeader openSlider={() => setOpen(false)} text="Stay updated on whatsapp" />
                <div className="up__upper">
                    <div className="up__header">
                        <div> <img className="up__header__img" src={watsapp} alt="" /></div>
                        <div className="up__header__para">
                            <p style={{ fontWeight: "bolder" }} className="up__header__para1">Filtered, quality feed on global Private Equity, Startups and Exotic investments</p>
                            <p className="up__header__para2">
                                We DO NOT spam. It's a promise
                            </p>
                        </div>
                    </div>
                    <div className="up__header__body">
                        <p className="up__body__para">
                            Enter your mobile number
                        </p>
                        <Mnumber setMobile={setPhone} />
                    

                        <div className="up__chkbox__comp">
                            <CheckboxComponent text="I wish to receive WhatsApp updates" checked={true} setChecked={setChecked} />
                        </div>
                    </div>
                </div>



                <div className="up__lower">
                    <Button text="DONE" loading={loading} style={phone && phone?.length < 13 ? { background: "lightgrey", pointerEvents: "none" } : null} click={addWhatsapp} />
                </div>
            </div></BottomSlide>
        </>
    )
}

export default Updated
