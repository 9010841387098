import { refresh_token } from "../api/auth";

const SIGN_UP = "SIGN_UP";
const SET_PROFILE = "SET_PROFILE";
const SET_BANK_VERIFIED = "SET_BANK_VERIFIED";
const SET_DEMAT_VERIFIED = "SET_DEMAT_VERIFIED";

const initState = {
  token: "",
  profile: null,
  bankVerified: false,
  dematVerified: false,
};

export const signup_user = (token) => {
  return {
    type: SIGN_UP,
    payload: token,
  };
};

export const set_profile = (profileData) => {
  return {
    type: SET_PROFILE,
    payload: profileData,
  };
};

export const bank_verified = (verified) => {
  return {
    type: SET_BANK_VERIFIED,
    payload: verified,
  };
};
export const demat_verified = (verified) => {
  return {
    type: SET_DEMAT_VERIFIED,
    payload: verified,
  };
};

// export const token_refresh = async (token) => {
//   const refreshed = await refresh_token(token);
// };

const reducer = (state = initState, actions) => {
  switch (actions.type) {
    case SIGN_UP:
      return { ...state, token: actions.payload };
    case SET_PROFILE:
      return { ...state, profile: actions.payload };
    case SET_BANK_VERIFIED:
      return { ...state, bankVerified: actions.payload };
    case SET_DEMAT_VERIFIED:
      return { ...state, dematVerified: actions.payload };
    default:
      return state;
  }
};

export default reducer;
