import React from "react";
import BuyerInfo from "../../../OrderModification/Components/BuyerInfo/BuyerInfo";
import "./IndianResident.css";
import Button from "../../../../components/button/Button";
import CheckboxComponent from "../../../../components/ConfirmCheckbox/CheckboxComponent";

const IndianResident = () => {
  return (
    <>
      <div className="ir__container">
        <div className="ir__upper">
          <BuyerInfo />

          <p className="ir__heading">
            Confirm <span>Order</span>
          </p>

          <p className="ir__upper__para1">
            Rs <span style={{ fontWeight: "bolder" }}> 40,235</span> are
            required to complete this order. These margins are 100% refundable
            if transaction is not complete
            <p className="ir__para2">Required Funds : Rs. 40,235</p>
            <p className="ir__para2">Required Funds : Rs. 40,235</p>
            <p className="ir__para2" style={{ color: "red" }}>
              ShortFall : Rs. 40,235
            </p>{" "}
          </p>

          <div className="ir__body">
            <p className="ir__body__para1">
              We have worked out a deal with a seller and are now reserving
              investments on this deal.
            </p>
            <p className="ir__body__para2">
              Typically, these deals would remain open for upto 4 weeks. Once
              we've reserved enough investments we shall close the round and the
              transaction shall be settled (Transfer of funds & Allotment of
              Shares) in the following 2 weeks time.
            </p>
            <p className="ir__body__para3">
              In an unlikely event where we do not receive enough demand for an
              opened investment round, we shall call it off. in such an event
              margin funds shall be refunded and there shall be no charges
              whatsoever.
            </p>
          </div>
          <div className="ir__btn2">
            <div className="ir__btn2__left"></div>
            <div className="ir__btn2__right">
              <p style={{ color: "red" }} className="ir__comp__para1">
                Orders placed in fresh rounds, can not be cancelled once placed.
                Only, the price and the price and the quantity of the order can
                be increased.
              </p>
            </div>
          </div>

          <p className="ir__watch">
            Need clarity on the transaction process? <span>Watch Video</span>
          </p>
          <div className="ir__checkbox">
            <div className="ir__check1">
              <CheckboxComponent text="I confirm that I am Indian Resident by tax laws & hold valid PAN card." />
            </div>
            <div className="ir__check1">
              <CheckboxComponent text="I confirm that I hold an active Demat A/c in india." />
            </div>
            <div className="ir__check1" style={{ marginBottom: "6rem" }}>
              <CheckboxComponent text="I confirm that I'v read the terms policies & disclaimers" />
            </div>
          </div>
        </div>
        <div className="ir__lower">
          <Button text="CONFIRM" />
        </div>
      </div>
    </>
  );
};

export default IndianResident;
