import React from "react";
import "./ProgressBar.css";

const ProgressBar = ({ progress, threshHold }) => {
  return (
    <div className="progress__bar">
      <div className="progress__text">
        <span className="pt__demand">{progress}% Demand</span>
        <span className="pt__thresh">
          <span
            className="thresh__text"
            style={{
              marginLeft:
                threshHold && threshHold > 92
                  ? `${threshHold - 16}%`
                  : threshHold
                  ? `${threshHold - 8}%`
                  : null,
            }}
          >
            Threshold
          </span>
          <span
            className="thresh__bar"
            style={{ marginLeft: threshHold ? `${threshHold}%` : null }}
          >
            |
          </span>
        </span>
      </div>
      <div className="progress__wrap">
        <div className="progress__rate" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
