import { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import BackIcon from "../../../OrderModification/Components/BackIcon/BackIcon";
import BuyerInfo from "../../../OrderModification/Components/BuyerInfo/BuyerInfo";
import Button from "../../../../components/button/Button";
import BottomSlide from "../../../../components/bottom-slide/BottomSlide";
import SendLink from "../../../OrderModification/sendLink/SendLink";

import { toast } from "react-toastify";

import {
  orderUpdate,
  getOrderDetail,
  notify,
  defaultMargin,
} from "../../../../api/order";
import { useSelector } from "react-redux";

import "./ModifyOrder.scss";

import { Checkbox } from "@material-ui/core";
const ModifyOrder = () => {
  let history = useHistory();
  let location = useLocation();
  let user = useSelector((state) => state.user);

  let company = location.state.company;
  let quoteData = location.state.quoteData;
  let time = location.state.time;
  let orderInfo = location.state.orderInfo;
  let expireTime = location.state.expireTime;

  let minTrade = location.state.mintrade;

  const [margin, setMargin] = useState({
    defaultSpread: null,
    floorQuotedSpread: null,
  });
  const [netLimit, setNetLimit] = useState(null);
  const [check, setCheck] = useState(null);
  const [shareDetail, setShareDetail] = useState(null);

  const [openSlide, setOpenSlide] = useState({
    open: false,
    div: null,
  });

  useEffect(() => {
    console.log("modify order", user);
    if (
      orderInfo.placedByUid === orderInfo.placedForUid &&
      orderInfo.placedByUid === user?.profile?.userId
    ) {
      setCheck(1);
    } else {
      setCheck(0);
    }
    setMargin((preValue) => {
      return {
        ...preValue,
        defaultSpread: orderInfo.quotedSpread,
      };
    });
  }, []);
  useEffect(() => {
    if (check != null) {
      if (check === "1") {
        setNetLimit(quoteData.price);
        if (orderInfo.action === "ASK") {
          if (!margin || !margin.floorQuotedSpread) {
            setNetLimit(parseFloat(quoteData.price));
          } else {
            let n =
              parseFloat(quoteData.price) +
              (parseFloat(quoteData.price) *
                parseFloat(margin.floorQuotedSpread)) /
                100;
            setNetLimit(n.toFixed(3));
          }
        } else {
          if (!margin || !margin.floorQuotedSpread) {
            setNetLimit(parseFloat(quoteData.price));
          } else {
            let n =
              parseFloat(quoteData.price) -
              (parseFloat(quoteData.price) *
                parseFloat(margin.floorQuotedSpread)) /
                100;
            setNetLimit(n.toFixed(3));
          }
        }
      } else {
        if (orderInfo.action === "ASK") {
          if (!margin || !margin.defaultSpread) {
            setNetLimit(parseFloat(quoteData.price));
          } else {
            let n =
              parseFloat(quoteData.price) +
              (parseFloat(quoteData.price) * parseFloat(margin.defaultSpread)) /
                100;
            setNetLimit(n.toFixed(3));
          }
        } else {
          if (!margin || !margin.defaultSpread) {
            setNetLimit(parseFloat(quoteData.price));
          } else {
            let n =
              parseFloat(quoteData.price) -
              (parseFloat(quoteData.price) * parseFloat(margin.defaultSpread)) /
                100;
            setNetLimit(n.toFixed(3));
          }
        }
      }
    }
  }, [margin, check]);

  useEffect(() => {
    const getData = async () => {
      let res = await defaultMargin(user);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("Margin ===== ", res.data);

        setMargin((preValue) => {
          return {
            ...preValue,
            floorQuotedSpread: res.data.floorQuotedSpread,
          };
        });
      }
    };

    getData();
  }, [user]);

  const enteringMargin = (event) => {
    setMargin((preValue) => {
      return {
        ...preValue,
        defaultSpread: event.target.value,
      };
    });
  };

  const goToShareLink = async () => {
    let res = await notify(
      user.token,
      orderInfo.orderRequestId,
      orderInfo.version,
      orderInfo.command === "PLACE_ORDER" ? "register" : "modify"
    );
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      history.push({
        pathname: "/call-client",
        state: {
          company,
          orderId: orderInfo.orderRequestId,
          user: orderInfo.command === "PLACE_ORDER" ? "register" : "modify",
          orderInfo: orderInfo,
          expireTime,
        },
      });
    }
  };

  const modify = async () => {
    if (check === "0") {
      if (margin > 50 || margin < margin.floorQuotedSpread) {
        toast.error("Invalid commision", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        let res = await orderUpdate(
          user.token,
          orderInfo.orderRequestId,
          quoteData,
          time,
          orderInfo.action,
          minTrade,
          true,
          margin.defaultSpread
        );
        if (res.error != null) {
          toast.error(res.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          orderInfo.command = res.data.command;
          orderInfo.version = res.data.version;
          console.log("ORDERUPDATE --------------------- ", res.data);
          let detail = await getOrderDetail(
            user.token,
            orderInfo.orderRequestId,
            orderInfo.version
          );
          if (detail.error != null) {
            toast.error(res.error, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setShareDetail(detail.data);
            setOpenSlide({
              open: true,
              div: "sendLink",
            });
          }
        }
      }
    } else {
      let res = await orderUpdate(
        user.token,
        orderInfo.orderRequestId,
        quoteData,
        time,
        orderInfo.action,
        minTrade,
        false,
        margin.defaultSpread,
        margin.floorQuotedSpread
      );
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        history.push({
          pathname: "/confirm-order",
          search: `?orderId=${res.data.orderRequestId}&orderVersion=${res.data.version}`,
        });
      }
    }
  };
  return (
    <>
      <div className="modo__container">
        <div className="modo__upper">
          <div className="modo__heading">
            Order <span>modification</span>
          </div>
          <div className="modo__userProfile__component">
            <BuyerInfo
              path="order-modification"
              data={company}
              detail={orderInfo}
              time={expireTime}
            />
          </div>

          <div className="modo__backIcon__component">
            <BackIcon />
          </div>
          <div className="modo__heading">
            Modify{" "}
            <span>
              <span>Order</span>
            </span>
          </div>
          <div className="modo__paras">
            {orderInfo.action === "ASK" ? "Sell" : "Buy"} Qty :{" "}
            <span>{quoteData.shares} Shares</span>
          </div>
          <div className="modo__paras">
            Price for Client(incl. commission):{" "}
            <span>Rs. {quoteData.price}</span>
          </div>
          <div className="modo__paras">
            Your commision in % :{" "}
            <span className="per">
              {check === "0" ? (
                <>
                  <input
                    value={margin.defaultSpread}
                    onChange={enteringMargin}
                    type="number"
                  />
                  %
                </>
              ) : (
                margin.floorQuotedSpread
              )}
            </span>
            {check === "0" && margin.defaultSpread > 50 ? (
              <>
                <span className="pererr" style={{ color: "red" }}>
                  Quoted Spread Cannot be greater than 50%{" "}
                </span>
              </>
            ) : check === "0" &&
              margin.defaultSpread < margin.floorQuotedSpread ? (
              <>
                <span className="pererr" style={{ color: "red" }}>
                  Spread should be greater than floor spread{" "}
                  {margin.floorQuotedSpread}{" "}
                </span>
              </>
            ) : null}
          </div>
          <div className="modo__paras">
            Net Limit Price (Rs.) : <span>Rs. {netLimit}</span>
          </div>
          {orderInfo.action === "ASK" && (
            <>
              <div className="modo__paras">
                Minimum trade quantity: <span>{minTrade}</span>
              </div>
            </>
          )}
          <div className="modo__paras">
            Your commission will not be revealed to the client
          </div>
          <div className="mo__checkbox__component">
            <Checkbox
              checked={check === "0" ? true : false}
              id="0"
              style={{ color: "green" }}
            />
            <div className="mo__checkbox__para1">
              <p className="mo__checkbox__para2">
                Transact in my client's account{" "}
              </p>
              Funds / Securities shall be debited / credited to your client's
              bank / demat account
            </div>
          </div>
          <div className="mo__checkbox__component">
            <Checkbox
              checked={check === "1" ? true : false}
              id="1"
              style={{ color: "green" }}
            />
            <div className="mo__checkbox__para1">
              <p className="mo__checkbox__para2"> Transact in my account </p>
              Funds / Securities shall be debited / credited to your client's
              bank / demat account
            </div>
          </div>

          <div className="modo__footer">
            <p className="modo__footer__para1">
              Transaction account cannot be modified, please cancel this order
              and place a new one, if you wish to change transaction account
            </p>
          </div>
        </div>
        <div className="modo__lower">
          <Button
            text="CONFIRM MODIFICATION"
            click={modify}
            disabled={check === "0" ? margin?.defaultSpread > 50 : false}
          />
        </div>

        {openSlide.open && (
          <BottomSlide bottomSlide={() => setOpenSlide(false)}>
            {openSlide.div === "sendLink" ? (
              <SendLink
                data={shareDetail}
                action={goToShareLink}
                path="modify"
              />
            ) : null}
          </BottomSlide>
        )}
      </div>
    </>
  );
};

export default ModifyOrder;
