// import { Button } from "@material-ui/core";
// import VerifiedIcon from "@material-ui/icons/CheckCircle";

const AccordingButton = ({ children, disabled, active, iconSrc, onClick }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`ac__step ${active ? "active__trade_tab" : ""}`}
    >
      {children}
      {!disabled && <img className="ac__step1__img" src={iconSrc} alt="icon" />}
    </button>
  );
};

export default AccordingButton;
