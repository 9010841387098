import React, { useState } from "react";
import "./Highlights.css";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ProgressBar from "../progress-bar/ProgressBar";
import { useLocation } from "react-router-dom";

const Highlights = ({ highlights }) => {
  const [learnMore, setLearnMore] = useState(false);
  const { pathname } = useLocation();
  return (
    <div className="highlights">
      <span
        className={`${
          pathname !== "/dashboard" ? "hl__heading" : "hl__heading2"
        }`}
      >
        {highlights?.length > 0 && "Highlights"}
      </span>
      {/* {learnMore ? (
        <p className="highlights__text">
          {text && text}
          <span className="learn__more_hl" onClick={() => setLearnMore(false)}>
            Show Less
          </span>
        </p>
      ) : (
        <p className="highlights__text">
          {text && text.substr(0, 200)}
          <span className="learn__more_hl" onClick={() => setLearnMore(true)}>
            Learn More
          </span>
        </p>
      )} */}
      <div className="hl__list">
        {highlights?.length > 0 &&
          highlights?.map((item) => {
            return (
              <div className="hl__item">
                <span className="hl__icon">
                  <FiberManualRecordIcon
                    style={{ fontSize: "14px", color: "gray", opacity: "0.5" }}
                  />
                </span>
                <span className="hl__text">{item}</span>
              </div>
            );
          })}
        {/* <ProgressBar progress="80" /> */}
      </div>
    </div>
  );
};

export default Highlights;
