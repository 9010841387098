import React from "react";
import "./Obligation.scss";
import { Link } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BuyerInfo from "../../../OrderModification/Components/BuyerInfo/BuyerInfo";
import toogle from "../../../../Assets/imgs/toogle.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
);

const Obligation = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <div className="obligation__container">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary>
            <div className="accor__box">
              <p className="detail">
                {" "}
                5000 (10%) traded @ Rs. 253.25 | 12/12/2020 16:20{" "}
              </p>
              <p className="status"> Status: Open obligation </p>
              <p className="action"> ACTION REQUIRED </p>
              <div className="btn"> FULFIL OBLIGATION </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accor__expanded__view">
              <div className="ev__body1">
                <p className="ev__body1__para1">
                  <span style={{ color: "gray" }}>BUY:</span> 10000 Shares @ Rs.
                  254 each (Net) [Spread (Rs): 3.12]
                </p>
                <p className="ev__body1__para2">
                  <span style={{ color: "gray" }}>Valid till:</span> 12/12/2021
                </p>
                <p className="ev__body1__para2">
                  <span style={{ color: "gray" }}>Min trade qty:</span> 3000
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default Obligation;
