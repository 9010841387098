import { useState, useEffect } from "react";

import Button from "../../../components/button/Button";

import Progress from "@material-ui/core/CircularProgress";

import { genOtp } from "../../../api/order";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import "./Cordermodi.css";

const Cordermodi = (props) => {
  const user = useSelector((state) => state.user);

  let orderId = props?.orderId;
  let version = props?.version;

  const [time, settime] = useState(15);
  const [re, setRe] = useState(true);
  const countDown = () => {
    const run = () => {
      if (time <= 0) {
        clearInterval(id);
      } else {
        settime((preVal) => {
          if (preVal == 0) {
            clearInterval(id);
            return 0;
          } else {
            return preVal - 1;
          }
        });
      }
    };

    var id = setInterval(run, 1000);
  };

  useEffect(() => {
    countDown();
  }, [re]);

  const reCall = async () => {
    settime(15);
    setRe((preValue) => {
      return preValue ? false : true;
    });
    console.log(props.data);
    let res = await genOtp(user.token, `${orderId + version}`);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success("otp send Success", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <div className="corder__screen">
        <div className="corder__body">
          <div className="corder__heading">Confirm {props.heading}</div>
          <p className="corder__msg">
            Enter OTP sent to you via SMS on {user.profile.mobileNumber} and via
            email on {user.profile.email}
          </p>

          <div className="otp-input">
            <input
              type="number"
              placeholder="Enter your OTP ( 6 digits )"
              value={props?.otpValue}
              autoFocus
              onChange={(e) => props?.setOtpValue(e.target.value.slice(0, 6))}
              disabled={props?.otpLoader}
            />
          </div>

          <div
            className="corder__resend__text"
            onClick={time <= 0 ? reCall : null}
          >
            Re-send OTP
            <span style={{ display: time <= 0 ? "none" : null }}>
              (After {time}s)
            </span>
          </div>
        </div>
        <Button
          text={props?.otpLoader ? <Progress /> : props.btnText}
          click={props?.sendOrder}
          disabled={props?.otpLoader || props?.otpValue?.length < 6}
        />
      </div>
    </>
  );
};

export default Cordermodi;
