import React, { useState, useEffect } from "react";
import "./SearchBox.css";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "../../../../Assets/imgs/search.svg";

const SearchBox = ({ setText }) => {
  const [search, setSearch] = useState("");
  useEffect(() => {
    setText(search);
  }, [search]);
  return (
    <div className="search__bar1">
      <span className="bars__icon1">
        <img src={SearchIcon} />
      </span>
      <div className="search__box1">
        <input
          type="text"
          className="search__input1"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          value={search}
        />
        {search && (
          <CloseIcon onClick={() => setSearch("")} className="search__close1" />
        )}
      </div>
    </div>
  );
};

export default SearchBox;
