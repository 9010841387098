import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./AddedDemet.css";
import info from "../../../Assets/imgs/info.svg";
import { delete_demat, make_demat_primary } from "../../../api/profile";

import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { delete_stateDemat } from "../../../store/Demat";
import { demat_verified } from "../../../store/User";
import ConfirmBox from "../../../components/confirm-box/ConfirmBox";
import { FlashOffTwoTone } from "@material-ui/icons";
import ScreenLoader from "../../../components/screen-loader/ScreenLoader";

const arr = [
  {
    user: "1",
    name: "Despository",
    ls: "NSDL",
    status: " Active",
    id: "DP ID / Client ID",
    number: "IN123456789112",
    btn: "Dlete",
    cmr: "CMR",
  },
  {
    user: "2",
    name: "Despository",
    ls: "CSDL",
    status: "Active",
    id: "DP ID / Client ID",
    number: "12045234569112",
    btn: "Dlete",
    cmr: "CMR",
  },
  {
    user: "3",
    name: "Despository",
    ls: "CSDL",
    status: "Active",
    id: "DP ID / Client ID",
    number: "12045234569112",
    btn: "Dlete",
    cmr: "CMR",
  },
];

const AddedDemet = ({ demats, getDemats }) => {
  // const demats = useSelector((state) => state.demat);
  const [dialog, setDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [delIndex, setDelIndex] = useState();
  const stateToken = useSelector((state) => state.user.token);
  const [activeDialog, setActiveDialog] = useState(false);
  const [activeId, setActiveId] = useState();
  const [screenLoading, setScreenLoading] = useState(false);

  const handle_primary = (e) => {
    setActiveId(e.target.id);
    setActiveDialog(true);
  };
  const makePrimary = async (data) => {
    setScreenLoading(true);
    console.log(data);
    let res = await make_demat_primary(data, stateToken);
    if (res.error) {
      console.log(res);
      setScreenLoading(false);
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log(res);
      getDemats();
      setScreenLoading(false);
    }
  };

  const handle_delete = (e) => {
    setDialog(true);
    setDelIndex(e.target.id);
  };
  console.log(demats);
  const deleteDemat = async (val) => {
    setDeleting(true);
    let delete_res = await delete_demat(val?.clientId, val?.depositoryId);
    if (delete_res.error) {
      toast.error(delete_res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDeleting(false);
    } else {
      getDemats();
      setDeleting(false);
    }
  };
  return (
    <>
      {demats?.map((val, index) => (
        <>
          {dialog && (
            <ConfirmBox
              text="Are you sure you wish to un-link this account?"
              action={() => deleteDemat(demats[delIndex])}
              closeFunc={() => setDialog(false)}
            />
          )}
          {activeDialog && (
            <ConfirmBox
              text="Are you sure you wish to make it Active?"
              action={() => makePrimary(demats[activeId])}
              closeFunc={() => setActiveDialog(false)}
            />
          )}
          {screenLoading && <ScreenLoader />}
          <div className="added__container">
            <div className="addedbank__header">
              <div className="addedbank__left">{index + 1}</div>
              <div className="addedbank__right">
                <div className="ad__para1">
                  <p className="ad__p1name">Depository</p>
                  {deleting && delIndex == index ? (
                    <p className="ad__delbtn_disabled">Deleting...</p>
                  ) : (
                    <p
                      className="ad__delbtn"
                      id={index}
                      onClick={(e) =>
                        val.primary == false
                          ? handle_delete(e)
                          : toast.error("Can't delete active account", {
                              position: "top-center",
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            })
                      }
                    >
                      Delete
                    </p>
                  )}
                </div>
                <div className="ad__para2">
                  <p className="ad__p2name">{val.depository}</p>
                  <div
                    className="active__class"
                    style={{ display: "flex", position: "relative" }}
                  >
                    <p
                      className="addd__active"
                      style={{
                        color: val.primary ? "green" : "skyblue",
                      }}
                      id={index}
                      onClick={(e) => (!val.primary ? handle_primary(e) : null)}
                    >
                      {val.primary ? "Active" : "Inactive"}
                    </p>
                    <img className="addeDemat__thirdimg" src={info} alt="" />
                  </div>
                </div>
                <div className="ad__para1">
                  <p className="ad__p1name">DP ID / Client ID</p>
                  <Link className="ad__cmr">CMR</Link>
                </div>
                <div className="ad__para4">
                  <p className="ad__p4name">
                    {val.depositoryId}
                    {val.clientId}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default AddedDemet;
