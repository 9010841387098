import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./AllOrders.scss";
import { useState } from "react";
import NoOrder from "../../Components/NoOrder/NoOrder";
import { all_orders } from "../../../../api/orderDetails";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ScreenLoader from "../../../../components/screen-loader/ScreenLoader";
import { whoami } from "../../../../api/auth";

import FilterSvg from "../../../../Assets/imgs/filter.svg";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
// import Stack from '@material-ui/core/Stack';

const orderStatus = {
  AWAITING_FUNDS: "Awaiting Funds",
  AWAITING_CONFIRMATION: "Awaiting Confirmation",
  ORDER_CONFIRMED: "Order Confirmed",
  PARTIALLY_FILLED: "Partially Filled",
  FULLY_FILLED: "Fully Filled",
  CANCELLED: "Cancelled",
  INTEREST_VALIDATED: "Interest Validated",
  SIGNING: "Signing",
  ORDER_EXPIRED: "Order Expired",
  SIGNATURE_EXPIRED: "Signature Expired",
  IN_ERROR: "In Error",
  UNOPENED: "Unopened",
};
const ConsolidatedOrderStatus = [
  "AWAITING_FUNDS",
  "AWAITING_CONFIRMATION",
  "ORDER_CONFIRMED",
  "PARTIALLY_FILLED",
  "FULLY_FILLED",
  "CANCELLED",
  "INTEREST_VALIDATED",
  "SIGNING",
  "ORDER_EXPIRED",
  "SIGNATURE_EXPIRED",
  "IN_ERROR",
  "UNOPENED",
];
const placementTypeList = ["SELF_PLACED", "PARTNER_PLACED"];
const listingtypeList = ["TWO_WAY", "FRESH"];

const badgeColors = {
  AWAITING_FUNDS: { color: "#F8DA6A", backgroundColor: "#FFF8EC" },
  AWAITING_CONFIRMATION: { color: "#F8DA6A", backgroundColor: "#FFF8EC" },
  ORDER_CONFIRMED: { color: "#7AD581", backgroundColor: "#DEFEDF" },
  PARTIALLY_FILLED: { color: "#F752F4", backgroundColor: "#FEE1FD" },
  FULLY_FILLED: { color: "#5188FF", backgroundColor: "#AEC7FF" },
  CANCELLED: { color: "#FF5B59", backgroundColor: "#FFE5E5" },
  INTEREST_VALIDATED: { color: "#848485", backgroundColor: "#E6E6E6" },
  SIGNING: { color: "#F8DA6A", backgroundColor: "#FFF8EC" },
  ORDER_EXPIRED: { color: "#7AD581", backgroundColor: "#DEFEDF" },
  SIGNATURE_EXPIRED: { color: "#F8DA6A", backgroundColor: "#FFF8EC" },
  IN_ERROR: { color: "#FF5B59", backgroundColor: "#FFE5E5" },
  UNOPENED: { color: "grey", backgroundColor: "lightgrey" },
};

const AllOrders = () => {
  const [myData, setMyData] = useState({});
  const [screenLoading, setScreenLoading] = useState(false);
  const [list, setList] = useState([]);
  const history = useHistory();
  const token = useSelector((state) => state.user.token);

  const [open, setOpen] = useState(false);

  const [orderStatusFilter, setOrderStatusFilter] = useState([]);
  const [roundTypeFilter, setRoundTypeFilter] = useState([]);
  const [placementTypeFilter, setPlacementTypeFilter] = useState([]);

  const accordian = (id, v) => {
    history.push({
      pathname: "/order/" + id,
      search: "?v=" + v,
    });
  };
  const get_all_orders = async () => {
    setScreenLoading(true);
    let response = await all_orders(token);
    if (response.error) {
      setScreenLoading(false);
      toast.error(response.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let Filter1;
      let Filter2;
      let Filter3;
      if (roundTypeFilter.length >= 1) {
        // Filter1 = await response.data.data.filter((data) => roundTypeFilter.map((ma) => data.orderDetail.listingType == ma.title))
        Filter1 = await response.data.data.filter((data) =>
          roundTypeFilter.includes(data.orderDetail.listingType)
        );
      } else {
        Filter1 = response.data.data;
      }
      if (orderStatusFilter.length >= 1) {
        // Filter2 = await orderStatusFilter.map((ma)=> await Filter1.filter((data)=> data.orderDetail.consolidatedOrderStatus == ma.title) )
        // Filter2 = await Filter1.filter((data) => data.orderDetail.consolidatedOrderStatus == orderStatusFilter.map((ma) => ma.title))
        Filter2 = await Filter1.filter((data) =>
          orderStatusFilter.includes(data.orderDetail.consolidatedOrderStatus)
        );
      } else {
        Filter2 = Filter1;
      }
      if (placementTypeFilter.length >= 1) {
        console.log("FILTER 2 ============ ", Filter2);
        // Filter3 = await Filter2.filter((data) => data.orderDetail.placementType == placementTypeFilter.map((ma) => ma.title))
        Filter3 = await Filter2.filter((data) =>
          placementTypeFilter.includes(data.orderDetail.placementType)
        );
      } else {
        Filter3 = Filter2;
      }
      setList(Filter3);
      setScreenLoading(false);
    }
  };

  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        toast.error(response.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setMyData(response?.data?.data);
      }
    }
  };
  const handleOrderClick = (val) => {
    if (val.orderDetail.consolidatedOrderStatus === "UNOPENED") {
      toast.error("No further details for unopened Order", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "unopened__toast",
      });
    } else {
      accordian(val.orderDetail.orderRequestId, val.orderDetail.version);
    }
  };
  useEffect(() => {
    myself();
  }, []);
  useEffect(() => {
    get_all_orders();
  }, [orderStatusFilter, placementTypeFilter, roundTypeFilter]);
  useEffect(() => {
    console.log(list);
    console.log("myData:", myData);
  }, [list, myData]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const enteringOrderStatusFilter = (value) => {
    setOrderStatusFilter(value);
  };
  const enteringRoundTypeFilter = (value) => {
    setRoundTypeFilter(value);
  };
  const enteringPlacementTypeFilter = (value) => {
    setPlacementTypeFilter(value);
  };

  const filterList = () => {
    handleClose();
  };

  const clearFilter = () => {
    setOrderStatusFilter([]);
    setPlacementTypeFilter([]);
    setRoundTypeFilter([]);
  };
  return (
    <>
      <div className="ao__container">
        {screenLoading && <ScreenLoader />}
        <div className="ao__haeding">
          Orders{" "}
          <span className="btn" onClick={handleClickOpen}>
            {" "}
            <img src={FilterSvg} alt="ERROR" />{" "}
          </span>
        </div>
        {list.length > 0 ? (
          <div className="order_list_container">
            {list.map((val, index) => (
              <div
                key={index}
                onClick={() => handleOrderClick(val)}
                className="ao__header"
              >
                <div className="ao__first">
                  <div className="ao__background">
                    <img
                      className="ao__img"
                      src={val.companyDetail.logo}
                      alt=""
                    />
                  </div>

                  <div className="ao__second">
                    <div
                      className="ao__second__heading"
                      style={{ fontWeight: "bold" }}
                    >
                      {val.companyDetail.aka.length > 20 ? (
                        // eslint-disable-next-line jsx-a11y/no-distracting-elements
                        <marquee width="100%" direction="right">
                          {val.companyDetail.aka}
                        </marquee>
                      ) : (
                        val.companyDetail.aka
                      )}
                    </div>
                    <div
                      className="ao__second__body"
                      style={{ color: " #000000a6" }}
                    >
                      <div className="ao__body__one">
                        {val.orderDetail.action === "ASK" ? (
                          <span className="orderCardSellAction">SELL</span>
                        ) : (
                          <span className="orderCardBuyAction">BUY</span>
                        )}
                        &nbsp; |&nbsp;
                        {val.orderDetail.qty} @ {val.orderDetail.price}
                      </div>
                      <div className="ao__body__one">
                        Order ID: {val.orderDetail.orderRequestId}
                      </div>
                      <div className="ao__body__one">
                        {myData.userType === "INDIVIDUAL_CLIENT"
                          ? ""
                          : myData.username ===
                            val.placedForContactInformation.username
                          ? "Client: self"
                          : val.placedForContactInformation.fullName === null
                          ? "Client: " + val.placedForContactInformation.emailId
                          : "Client: " +
                            val.placedForContactInformation.fullName}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="ao__third"
                  style={badgeColors[val.orderDetail.consolidatedOrderStatus]}
                >
                  {orderStatus[val.orderDetail.consolidatedOrderStatus]}
                </div>
              </div>
            ))}
          </div>
        ) : screenLoading ? null : (
          <NoOrder />
        )}
        {list.length > 0 ? (
          <p className="ao__footer__para" style={{ marginTop: "20px" }}>
            <span>
              <Link to={"/dashboard"}>Explore</Link>
            </span>{" "}
            more investment opportunities in privately held innovative
            businesses.
          </p>
        ) : null}
        <br />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="dialog__content">
            <div className="dialog__heading">Filter</div>
            <div className="dialog__autocommplete">
              <Autocomplete
                multiple
                size="small"
                id="tags-standard"
                value={orderStatusFilter}
                options={ConsolidatedOrderStatus}
                getOptionLabel={(option) => option}
                onChange={(e, v) => enteringOrderStatusFilter(v)}
                // defaultValue={[top100Films[13]]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Order Status"
                  />
                )}
              />
              <Autocomplete
                multiple
                size="small"
                id="tags-standard"
                value={roundTypeFilter}
                options={listingtypeList}
                getOptionLabel={(option) => option}
                onChange={(e, v) => enteringRoundTypeFilter(v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Round Type"
                  />
                )}
              />
              <Autocomplete
                multiple
                size="small"
                id="tags-standard"
                value={placementTypeFilter}
                options={placementTypeList}
                getOptionLabel={(option) => option}
                onChange={(e, v) => enteringPlacementTypeFilter(v)}
                // defaultValue={[top100Films[13]]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Placement Type"
                  />
                )}
              />
            </div>

            <div
              style={{ marginBottom: ".5rem" }}
              onClick={clearFilter}
              className="btn"
            >
              CLEAR
            </div>
            <div onClick={filterList} className="btn">
              DONE
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AllOrders;
