import { kyc_failure, kyc_success } from "../../api/profile";
import { toast } from "react-toastify";
import { TramRounded } from "@material-ui/icons";
import { BASE_URL } from "../../axiosInstance"
const success_req = async (data, token, getDetails, setScreenLoader, search) => {
  setScreenLoader(true);
  let response = await kyc_success(data, token);
  if (response.error) {
    setScreenLoader(false);
    console.log(response.error);
    toast.error(response.error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    setScreenLoader(false);
    window.location.href = window.location.origin + "/profile" + "?" + search
    toast.success("Identity Details Fetched, Please Add Bank", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    getDetails();

  }
};
const failure_req = async (data, token, getDetails, setScreenLoader, search) => {
  setScreenLoader(true);
  let response = await kyc_failure(data, token);
  if (response.error) {
    setScreenLoader(false);
    console.log(response.error);
    toast.error(response.error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {

    setScreenLoader(false);
    window.location.href = window.location.origin + "/profile" + "?" + search
    toast.error(data.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    getDetails();

  }
};

const run_digio = (data, token, getDetails, setScreenLoader, search) => {
  console.log(token);
  console.log(data);
  const options = {
    environment: process.env.REACT_APP_PROD_STATUS == "false" ? process.env.REACT_APP_DEV_ENV : process.env.REACT_APP_PROD_ENV,
    logo: data.imageUrl,
    callback: (response) => {
      if (response.hasOwnProperty("error_code")) {
        let res = { ...response, txn_id: data.transactionId };
        failure_req(res, token, getDetails, setScreenLoader, search);

        console.log(res);
      } else {
        let res = { ...response, txn_id: data.transactionId };
        success_req(res, token, getDetails, setScreenLoader, search);
        console.log(res);
      }
    },
  };

  var digio = new window.Digio(options);
  console.log(digio);
  digio.init();
  var requestId = data.id;
  var identifier = data.customerIdentifier;
  var token_id = data.token;
  digio.submit(requestId, identifier, token_id);
};

export default run_digio;
