import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import "./Dfee.css";
import Button from "../../../components/button/Button";
import { useHistory } from "react-router-dom";
import { useState } from "react";

import { Spread__Dfee } from "../../../api/profile";
import { useSelector } from "react-redux";
import { default_partner_order } from "../../../api/auth";

const Dfee = () => {
  const [number, setNumber] = useState("");
  const [floorQuotedSpread, setFloorQuotedSpread] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const token = useSelector((state) => state.user.token);
  const state = useSelector((state) => state);
  const pathname = new URLSearchParams(window.location.search);
  const redirectPath = pathname.get("redirectPath");
  const isCommingFrom = pathname.get("origin");

  const handleChange = (event) => {
    setNumber(event.target.value);
  };

  const getDefaultFee = async () => {
    const prepopulated = await default_partner_order(
      token,
      state.user.profile.userId
    );
    if (prepopulated.error) {
      toast.error(prepopulated.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setNumber(prepopulated.data.data.defaultSpread.toFixed(1));
      setFloorQuotedSpread(prepopulated.data.data.floorQuotedSpread);
    }
  };

  const spread = async () => {
    if (number <= 50) {
      setLoading(true);
      const res = await Spread__Dfee(token, number);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        console.log(res.error);
      } else {
        console.log(res.data);
        setLoading(false);
        if (redirectPath) {
          history.push(redirectPath);
        } else {
          if (isCommingFrom === "settings") {
            history.push("/menusetting");
          } else {
            history.push("/addlogo");
          }
        }
      }
      console.log(res);
    }
  };
  useEffect(() => {
    getDefaultFee();
    console.log(state);
  }, []);

  return (
    <>
      <div className="Dfee__container">
        <div className="Dfee__header">
          <div className="Dfee__heading">
            Set your default <span>fee</span>
          </div>
          <div className="Dfee__percent">
            <input
              className="Dfee__input"
              value={number}
              type="number"
              onChange={handleChange}
            />
            %
          </div>
          <div className="Dfee__error">
            {number > 50 || number < 0.1 || number < 0
              ? "Number must be less than 50 or greater than 0.1"
              : number < floorQuotedSpread
              ? `Number must be greater than ${floorQuotedSpread}`
              : null}
          </div>
          <div className="percent__line">Of the transaction value</div>

          <div className="Dfee__body">
            <div className="Dfee__para1">
              This is the default fee that will be added to the value of every
              transaction that is done by your clients on your website or for
              any transaction done by you on your clients behalf.
            </div>
            <div className="Dfee__para2">
              Don't worry you can always change your default fee later from the
              settings tab or you can also decide a custom fee for every
              transaction separately.
            </div>
            <div className="Dfee__link">
              {/* ! this link is empty */}
              <Link>Learn more</Link>
            </div>
          </div>
        </div>

        <Button
          text="DONE"
          click={spread}
          loading={loading}
          style={
            number > 50 || number < 0.1 || number < 0
              ? { background: "lightgrey", PointerEvent: "none" }
              : null
          }
        />
      </div>
    </>
  );
};

export default Dfee;
