import { Link, useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

import Checkboxcomponent from "../../../components/ConfirmCheckbox/CheckboxComponent";

import Button from "../../../components/button/Button";
import "./PartnerSignup.css";

import "react-phone-number-input/style.css";
import { useState } from "react";
import ToggleBtn from "../../../components/toggle-btn/ToggleBtn";

import Mnumber from "../../../components/Mob-num input/Mnumber";
import { partner_signup, generate_partner_otp } from "../../../api/auth";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { signup_user } from "../../../store/User";
const PSignup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const searchQuery = new URLSearchParams(history.location.search);
  const partner_type_input = searchQuery.get("type");
  const default_partner = partner_type_input === "org" ? true : false;
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(default_partner);
  const [mobile, setMobile] = useState("");
  const [privacyCheck, setPrivacyCheck] = useState(true);
  const [whatsappCheck, setWhatsappCheck] = useState(true);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    userType: "",
    whatsappConsent: whatsappCheck,
    organisationName: "",
  });

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      countryCode: mobile && mobile.slice(0, 3),
      mobileNumber: mobile && mobile.slice(3),
      userType: checked ? "ORGANISATIONAL_PARTNER" : "INDIVIDUAL_PARTNER",
    }));
  }, [mobile]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validate = (data) => {
    if (
      data.firstName === "" ||
      data.lastName === "" ||
      data.email === "" ||
      data.countryCode === "" ||
      !data.countryCode ||
      !data.mobileNumber ||
      data.mobileNumber === ""
    ) {
      console.log(data);
      toast.error("All fields are required", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      return false;
    } else {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
        return true;
      } else {
        toast.error("Invalid Email", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        return false;
      }
    }
  };

  const validateEmail = (email) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const handlesignup = async () => {
    setLoading(true);
    console.log(data);
    const validated = validate(data);
    const data1 = { ...data, whatsappConsent: whatsappCheck };
    if (validated) {
      const response = await partner_signup(data1);
      if (response.error) {
        toast.error(response.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setLoading(false);
      } else {
        console.log(response.data.data);
        localStorage.setItem("token", response.data.data);
        dispatch(signup_user(response.data.data));
        localStorage.setItem("partner_data", JSON.stringify(data));
        console.log(JSON.stringify(data));
        console.log(data);
        console.log(localStorage.getItem("token"));
        let resp = await generate_partner_otp(response.data.data);
        if (resp.error) {
          setLoading(false);
          toast.error(resp.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setLoading(false);
          history.push({
            pathname: "/partner/mailotp/" + data.email,
          });
        }
      }
    }
  };

  return (
    <div className="psignup__wrap">
      <div className="psignup__container">
        <div className="psignup__header">
          <p className="header__para">
            Start your business in under 5 minutes.
          </p>
          <div className="header__heading">Sign up your business</div>
          <div className="psignup__toggle">
            <ToggleBtn checked={checked} setChecked={setChecked} />
            <div
              style={checked ? { color: "grey" } : { color: "#00abff" }}
              className="psignup__p1"
            >
              Individual
            </div>
            <div className="psignup__slash">/</div>
            <div
              style={checked ? { color: "#00abff" } : { color: "grey" }}
              className="psignup__p2"
            >
              Organization
            </div>
          </div>

          <div className="psignup__inputs">
            {checked && (
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label="Name of your Organization"
                variant="standard"
                onChange={handleChange}
                name="organisationName"
              />
            )}
            {checked && (
              <p className="inputs__heading">Enter contact person's name</p>
            )}
            <div className="persons__info">
              <TextField
                style={{ width: "100%", marginRight: "15px" }}
                id="outlined-basic"
                label="First Name"
                variant="standard"
                onChange={handleChange}
                value={data.firstName}
                name="firstName"
              />
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label="Last Name"
                variant="standard"
                onChange={handleChange}
                value={data.lastName}
                name="lastName"
              />
            </div>
            <p className="inputs__heading">
              {checked
                ? "Enter contact persons's mobile number"
                : "Enter your mobile number"}
            </p>
            <Mnumber setMobile={setMobile} />
            <div className="psignup__checkbox__component">
              <Checkboxcomponent
                text="Send me WhatsApp messages for important communication"
                checked={whatsappCheck}
                setChecked={setWhatsappCheck}
              />
            </div>
            {/* Send me WhatsApp messages for important communication */}
            <p className="inputs__head">
              {checked
                ? "Enter contact persons's Email ID"
                : "Enter your Email ID"}
            </p>
            <TextField
              style={{ width: "100%" }}
              id="outlined-basic"
              // label="Entre your Email"
              variant="standard"
              onChange={handleChange}
              value={data.email}
              name="email"
            />

            <div className="psignup__checkbox__component">
              <Checkboxcomponent
                checked={privacyCheck}
                setChecked={setPrivacyCheck}
              />
            </div>
            <small className="psignup__footer">
              Already registered?&nbsp;
              <Link to="/register"> Click here to signin</Link>
            </small>
          </div>
        </div>
      </div>
      <div className="bottom__sticky_btnwrapper">
        <Button
          loading={loading}
          text="DONE"
          style={
            !privacyCheck
              ? { background: "lightgray", pointerEvents: "none" }
              : null
          }
          click={handlesignup}
          disabled={
            (checked && data?.organisationName.length <= 0) ||
            data?.firstName?.length <= 0 ||
            data?.lastName?.length <= 0 ||
            data?.countryCode?.length <= 0 ||
            validateEmail(data?.email) <= 0 ||
            data?.mobileNumber?.length <= 0
          }
        />
      </div>
    </div>
  );
};

export default PSignup;
