import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { closure_details } from "../../../../api/orderDetails";
import "./ClosureBox.css";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";

const ClosureBox = ({ userData, trade, order, setDetail }) => {
  const token = useSelector((state) => state.user.token);
  const [myData, setMyData] = useState({});
  const [userType, setUserType] = useState("");
  const [closure, setClosure] = useState();
  const [isPartner, setIsPartner] = useState(false);
  const [data, setData] = useState();
  const closureDetails = async () => {
    let response = await closure_details(token, trade.fillId, trade.closureId);
    if (response.error) {
      toast.error(response.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log("closure data:", response.data.data);
      setClosure(response.data.data);
    }
  };

  const formatDateTime = (timestamp) => {
    let d = new Date(timestamp * 1000);
    var options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return d.toLocaleDateString("en-UK", options);
  };

  const openPopup = () => {
    console.log("trade:", trade);
    const { fillLifeCycleStatus, obligationStatus, closureType } = trade;
    if (
      fillLifeCycleStatus == "OBLIGATION_CREATED" &&
      obligationStatus == "PENDING" &&
      closureType == null
    ) {
      return;
    } else if (
      fillLifeCycleStatus == "OBLIGATION_FULFILLED" &&
      obligationStatus == "FULFILLED" &&
      closureType == null
    ) {
      return;
    } else if (
      fillLifeCycleStatus == "CLOSURE_CREATED" &&
      obligationStatus == "FULFILLED" &&
      closureType == "SUCCESS"
    ) {
      setDetail((preVal) => {
        if (trade.orderAction === "ASK") {
          return {
            ...preVal,
            popup4: {
              data: closure,
              open: true,
              placementType: order.orderDetail.placementType,
            },
          };
        } else {
          return {
            ...preVal,
            popup7: {
              data: closure,
              open: true,
              placementType: order.orderDetail.placementType,
            },
          };
        }
      });
    } else if (
      fillLifeCycleStatus == "CLOSURE_CREATED" &&
      (obligationStatus == "FULFILLED" || obligationStatus == "CLOSED_BEFORE_READY" ) &&
      closureType == "FAILURE"
    ) {
      setDetail((preVal) => {
        if (trade.orderAction === "ASK") {
          return {
            ...preVal,
            popup5: {
              data: closure,
              open: true,
              placementType: order.orderDetail.placementType,
            },
          };
        } else {
          return {
            ...preVal,
            popup8: {
              data: closure,
              open: true,
              placementType: order.orderDetail.placementType,
            },
          };
        }
      });
    } else if (
      fillLifeCycleStatus == "CLOSURE_COMPLETE" &&
      obligationStatus == "FULFILLED" &&
      closureType == "SUCCESS"
    ) {
      setDetail((preVal) => {
        if (trade.orderAction === "ASK") {
          return {
            ...preVal,
            popup42: {
              data: closure,
              open: true,
              placementType: order.orderDetail.placementType,
            },
          };
        } else {
          return {
            ...preVal,
            popup72: {
              data: closure,
              open: true,
              placementType: order.orderDetail.placementType,
            },
          };
        }
      });
    } else if (
      fillLifeCycleStatus == "CLOSURE_COMPLETE" &&
      (obligationStatus == "FULFILLED" || obligationStatus == "CLOSED_BEFORE_READY" ) &&
      closureType == "FAILURE"
    ) {
      setDetail((preVal) => {
        if (trade.orderAction === "ASK") {
          return {
            ...preVal,
            popup52: {
              data: closure,
              open: true,
              placementType: order.orderDetail.placementType,
            },
          };
        } else {
          return {
            ...preVal,
            popup82: {
              data: closure,
              open: true,
              placementType: order.orderDetail.placementType,
            },
          };
        }
      });
    } else if (
      fillLifeCycleStatus == "OBLIGATION_CREATED" &&
      obligationStatus == "PENDING_TIME_EXPIRED" &&
      closureType == null
    ) {
      return {};
    } else if (
      fillLifeCycleStatus == "CLOSURE_CREATED" &&
      (obligationStatus == "PENDING" ||
        obligationStatus == "PENDING_TIME_EXPIRED") &&
      closureType == "FAILURE"
    ) {
      setDetail((preVal) => {
        return {
          ...preVal,
          popup6: {
            data: closure,
            open: true,
            placementType: order.orderDetail.placementType,
          },
        };
      });
    } else if (
      fillLifeCycleStatus == "CLOSURE_COMPLETE" &&
      (obligationStatus == "PENDING" ||
        obligationStatus == "PENDING_TIME_EXPIRED") &&
      closureType == "FAILURE"
    ) {
      setDetail((preVal) => {
        return {
          ...preVal,
          popup62: {
            data: closure,
            open: true,
            placementType: order.orderDetail.placementType,
          },
        };
      });
    }
  };

  const closure_text = () => {
    console.log("trade:", trade);
    const { fillLifeCycleStatus, obligationStatus, closureType } = trade;
    if (
      fillLifeCycleStatus == "OBLIGATION_CREATED" &&
      obligationStatus == "PENDING" &&
      closureType == null
    ) {
      return {};
    } else if (
      fillLifeCycleStatus == "OBLIGATION_FULFILLED" &&
      obligationStatus == "FULFILLED" &&
      closureType == null
    ) {
      return {};
    } else if (
      fillLifeCycleStatus == "CLOSURE_CREATED" &&
      obligationStatus == "FULFILLED" &&
      closureType == "SUCCESS"
    ) {
      return {
        text: `${
          trade.orderAction === "BID" ? "Securities" : "Funds"
        } will be transferred to your ${
          isPartner ? "client's" : ""
        } account By ${formatDateTime(closure.closureSettleByTime)}`,
        noActionRequired: true,
      };
    } else if (
      fillLifeCycleStatus == "CLOSURE_CREATED" &&
      (obligationStatus == "FULFILLED" || obligationStatus == "CLOSED_BEFORE_READY") &&
      closureType == "FAILURE"
    ) {
      return {
        text: `Your ${
          isPartner ? "client's" : ""
        } funds/securities will be returned to ${
          isPartner ? "them" : "your account"
        }. Additional compensation of Rs. ${
          closure.failedClosureRefundDetails.compensationFundAmount
        } will be credited to your ${isPartner ? "client's" : ""} account`,
        noActionRequired: true,
      };
    } else if (
      fillLifeCycleStatus == "CLOSURE_COMPLETE" &&
      obligationStatus == "FULFILLED" &&
      closureType == "SUCCESS"
    ) {
      return {
        text: `${
          trade.orderAction === "BID" ? "Securities" : "Funds"
        } have been credited to your ${isPartner ? "client's" : ""} account`,
        noActionRequired: false,
      };
    } else if (
      fillLifeCycleStatus == "CLOSURE_COMPLETE" &&
      (obligationStatus == "FULFILLED" || obligationStatus == "CLOSED_BEFORE_READY") &&
      closureType == "FAILURE"
    ) {
      return {
        text: `Your ${
          isPartner ? "client's" : ""
        } funds/securities have been returned to ${
          isPartner ? "them" : "you"
        }. Additionally Rs ${
          closure.failedClosureRefundDetails.compensationFundAmount
        } has been credited to your ${
          isPartner ? "client's" : ""
        } Funds balance`,
        noActionRequired: true,
      };
    } else if (
      fillLifeCycleStatus == "OBLIGATION_CREATED" &&
      obligationStatus == "PENDING_TIME_EXPIRED" &&
      closureType == null
    ) {
      return {};
    } else if (
      fillLifeCycleStatus == "CLOSURE_CREATED" &&
      (obligationStatus == "PENDING" ||
        obligationStatus == "PENDING_TIME_EXPIRED") &&
      closureType == "FAILURE"
    ) {
      return {
        text: ` Security deposit of Rs. ${
          closure.marginBlocked
        } will be forfeited by ${formatDateTime(closure.closureSettleByTime)}`,
        noActionRequired: true,
      };
    } else if (
      fillLifeCycleStatus == "CLOSURE_COMPLETE" &&
      (obligationStatus == "PENDING" ||
        obligationStatus == "PENDING_TIME_EXPIRED") &&
      closureType == "FAILURE"
    ) {
      return {
        text: ` Security deposit of Rs. ${
          closure.marginBlocked
        } has been debited from your ${
          isPartner ? "client's" : ""
        } funds balance`,
        noActionRequired: true,
      };
    }
  };

  useEffect(() => {
    if (order && userData) {
      if (
        order?.orderDetail.placedForUid !== userData.id &&
        userData?.userType !== "INDIVIDUAL_CLIENT"
      ) {
        console.log("Partner view is true");
        setIsPartner(true);
      } else {
        console.log("Partner view is false, ie selfview mode");
        setIsPartner(false);
      }
    }
  }, [order]);

  useEffect(() => {
    closureDetails();
  }, []);
  useEffect(() => {
    if (closure) {
      setData(closure_text());
    }
    setDetail((preVal) => {
      return {
        ...preVal,
        popup3: {
          ...preVal.popup3,
          data: closure,
          placementType: order.orderDetail.placementType,
        },
        popup4: {
          ...preVal.popup4,
          data: closure,
          placementType: order.orderDetail.placementType,
        },
        popup5: {
          ...preVal.popup5,
          data: closure,
          placementType: order.orderDetail.placementType,
        },
      };
    });
  }, [closure]);

  return (
    <>
      {data ? (
        <div className="closure__box">
          <span className="closure__body">{data.text}</span>
          <span className="closure__bottom">
            {data.noActionRequired && (
              <span className="closure__bottom_action">No action required</span>
            )}
            <button className="closure__bottom_details" onClick={openPopup}>
              Details
            </button>
          </span>
        </div>
      ) : (
        <div className="trade__loader_box">
          <CircularProgress className="trade__loader" />
        </div>
      )}
    </>
  );
};

export default ClosureBox;
