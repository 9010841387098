import React, { useEffect, useState } from "react";
import Notify from "./screens/notify/Notify";

import { Route, useHistory } from "react-router-dom";

import Gstart from "./screens/PartnerSignup/Getstarted/Gstart";
import Customweb from "./screens/PartnerSignup/CustomWeb/Customweb";
import Dfee from "./screens/PartnerSignup/DefaultFee/Dfee";
import Addlogo from "./screens/PartnerSignup/AddLogo/Addlogo";
import SearchPage from "./screens/dashboard/SearchPage";
import Profile from "./screens/profile/Profile";

import BankMain from "./screens/AddBank/Bankinfo-popup/BankMain";
import Bankdata from "./screens/AddBank/Bankinfo-data/Bankdata";
import bsuccess from "./screens/AddBank/Banksuccess/bsuccess";
import EvarRedirect from "./screens/PartnerSignup/Components/evar-redirect/EvarRedirect";
import AddedBank from "./screens/AddBank/AddedBanks/AddedBank";
import AddedDemet from "./screens/Demet/AddedDemet/AddedDemet";
import AddDemet from "./screens/Demet/AddDemetAccount/AddDemet";
import UploadDemet from "./screens/Demet/UploadDemet/UploadDemet";
import MainDemet from "./screens/Demet/mainDemet";

import Menu from "./components/client-partner-menu/ClientPartnerMenu";
import Funds from "./screens/funds/Funds";
import FundsTransfer from "./screens/funds/FundsTransfer";

import OrderModi from "./screens/OrderModification/Ordermodification/OrderModi";
import Cordermodi from "./screens/OrderModification/CnfrmOrderModi/Cordermodi";
import Mtradeqty from "./screens/OrderModification/MinimumTradeQuantity/Mtradeqty";
import Reqfund from "./screens/OrderModification/RequireFunds/Reqfund";
import ChoseBank from "./screens/OrderModification/ChoseBank/ChoseBank";
import Terms from "./screens/OrderModification/AcceptanceOfTerms/Terms";
import FreshRound from "./screens/OrderModification/AgainModifyOrder/FreshRound";
import Modified from "./screens/OrderModification/ModifiedOrder/Modified";
// import BtnCom from "./screens/OrderModification/Components/BtnComponent/BtnCom";
// import Funds from "./screens/funds/Funds";

import TestSignin from "./components/test-signin/TestSignin";
import { useSelector } from "react-redux";
// import creationCard from "./screens/OrderCreation/Components/CreationCard/creationCard";
import DealsDetails from "./screens/OrderCreation/Screens/DealDetails/DealsDetails";
import Userprofile from "./screens/OrderCreation/Screens/CompleteProfile/Userprofile";
import ConfirmOrder from "./screens/OrderCreation/Screens/ConfirmOrder/ConfirmOrder";
import OrderEntry from "./screens/OrderCreation/Screens/OrderEntry/OrderEntry";
import { refresh_token } from "./api/auth";
import PlaceOrder from "./screens/PartnerInitiated/PlaceOrder/PlaceOrder";
import AddFunds from "./screens/PartnerInitiated/AddFunds/AddFunds";
import PI_Registration from "./screens/PartnerInitiated/PI-Registration/PI_Registration";
import ValidateOrder from "./screens/PartnerInitiated/ValidateOrder/ValidateOrder";
import CallClient from "./screens/PartnerInitiated/CallClient/CallClient";
import ShortFall from "./screens/PartnerInitiated/ShortFall/ShortFall";
import WhatNow from "./screens/PartnerInitiated/WhatNow/WhatNow";
import Express from "./screens/PartnerInitiated/ExpressPopup/Express";
import ModifyInfo from "./screens/Client Placed/Partners part/ModifyInfo/ModifyInfo";
import ModifyOrder from "./screens/Client Placed/Partners part/ModifyOrder/ModifyOrder";
import ValidateOrderModi from "./screens/Client Placed/Client Part/ValidateOrderModification/ValidateOrderModi";
import InvestForClient from "./screens/OrderDetails/FreshRound/InvestForClient/InvestForClient";
import IndianResident from "./screens/OrderDetails/FreshRound/IndianResident/IndianResident";
import Notbidding from "./screens/OrderDetails/FreshRound/NotBidding/Notbidding";
import Updated from "./screens/dashboard/components/updated/Updated";
import AllOrders from "./screens/OrderDetails/SelfPlaced/Orders/AllOrders";
import Obligation from "./screens/OrderDetails/Components/Obligation/Obligation";
import Accor from "./screens/OrderDetails/FreshRound/Accordian/Accordian";
import NoOrder from "./screens/OrderDetails/Components/NoOrder/NoOrder";
import Potp from "./screens/PartnerInitiated/PartnerOtp/Potp";
import SendLink from "./screens/OrderModification/sendLink/SendLink";
import {
  PopUp1,
  PopUp2,
  PopUp3,
  PopUp4,
  PopUp5,
  PopUp6,
  PopUp7,
  PopUp72,
  PopUp8,
  PopUp82,
} from "./screens/OrderDetails/Components/PopUps/PopUps";
import AllClients from "./components/client-partner-menu/AllClients/AllClients";
import TotalEarnings from "./components/client-partner-menu/TotalEarnings/TotalEarnings";
import MenuSetting from "./components/client-partner-menu/MenuSetting/MenuSetting";
import SuccModification from "./screens/OrderModification/SuccModification/SuccModification";
import Dashboard from "./screens/dashboard/Dashboard";
import OrderCancellation from "./screens/OrderModification/OrderCancellation/OrderCancellation";
import ActiveTrades from "./screens/ActiveTrades/ActiveTrades";

const Routes = () => {
  const token = useSelector((state) => state.user.token);
  const [validated, setValidated] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (token === null) {
      const redirectPath = history.location.pathname + history.location.search;
      window.location = `/register?redirectPath=${redirectPath}`;
    } else {
      setValidated(true);
    }
  }, [token]);

  return (
    <>
      {/* {validated && ( */}
      <>
        <Menu />
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/notify" component={Notify} />
        <Route exact path="/getstarted" component={Gstart} />
        <Route exact path="/customweb" component={Customweb} />
        <Route exact path="/default-fee" component={Dfee} />
        <Route exact path="/addlogo" component={Addlogo} />
        <Route exact path="/search" component={SearchPage} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/bankinfo" component={BankMain} />
        <Route exact path="/bankdata" component={Bankdata} />
        <Route exact path="/bsuccess" component={bsuccess} />
        <Route exact path="/evar-redirect" component={EvarRedirect} />
        <Route exact path="/addedbank" component={AddedBank} />
        <Route exact path="/addeddemet" component={AddedDemet} />
        <Route exact path="/adddemet" component={AddDemet} />
        <Route exact path="/uploaddemet" component={UploadDemet} />
        <Route exact path="/maindemet" component={MainDemet} />
        <Route exact path="/menu" component={Menu} />
        <Route exact path="/clients" component={AllClients} />
        <Route exact path="/earnings" component={TotalEarnings} />
        <Route exact path="/menusetting" component={MenuSetting} />

        {/* Order modification  */}

        <Route exact path="/order-modification" component={OrderModi} />
        <Route exact path="/order-cancellation" component={OrderCancellation} />
        <Route exact path="/confirm/ordermodification" component={Cordermodi} />
        <Route exact path="/minimum-trade-quantity" component={Mtradeqty} />
        <Route exact path="/send-link" component={SendLink} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/modified" component={Modified} />
        <Route exact path="/freshround" component={FreshRound} />
        <Route exact path="/success" component={SuccModification} />

        {/* Order Creation */}
        <Route exact path="/deal-details" component={DealsDetails} />
        <Route exact path="/userprofile" component={Userprofile} />
        <Route exact path="/confirm-order" component={ConfirmOrder} />
        {/* <Route exact path="/orderentry" component={OrderEntry} /> */}
        {/* <Route exact path="/dashboard/orderentry" component={OrderEntry} /> */}

        {/* Partner Initiated */}
        <Route exact path="/place-order" component={PlaceOrder} />
        <Route exact path="/addfunds" component={AddFunds} />
        <Route exact path="/registration" component={PI_Registration} />
        <Route exact path="/call-client" component={CallClient} />
        <Route exact path="/shortfall" component={ShortFall} />
        <Route exact path="/what-now" component={WhatNow} />
        <Route exact path="/express" component={Express} />

        {/* Client Part */}

        {/* Partners Part */}
        <Route exact path="/modifyinfo" component={ModifyInfo} />
        <Route exact path="/modify-order" component={ModifyOrder} />

        {/* Clients Part */}
        <Route exact path="/validateordermodi" component={ValidateOrderModi} />

        {/* Order Details */}

        <Route exact path="/investforclient" component={InvestForClient} />
        <Route exact path="/indianresident" component={IndianResident} />
        {/* <Route exact path="/gaug-interest" component={Notbidding} /> */}
        <Route exact path="/allorders" component={AllOrders} />
        <Route exact path="/activeTrades" component={ActiveTrades} />
        <Route exact path="/order/:id" component={Accor} />
        <Route exact path="/noorder" component={NoOrder} />
        <Route exact path="/popup1" component={PopUp1} />
        <Route exact path="/popup2" component={PopUp2} />
        <Route exact path="/popup3" component={PopUp3} />
        <Route exact path="/popup4" component={PopUp4} />
        <Route exact path="/popup5" component={PopUp5} />
        <Route exact path="/popup6" component={PopUp6} />
        <Route exact path="/popup7" component={PopUp7} />
        <Route exact path="/popup72" component={PopUp72} />
        <Route exact path="/popup8" component={PopUp8} />
        <Route exact path="/popup82" component={PopUp82} />

        <Route exact path="/funds" component={Funds} />
        <Route exact path="/funds/transfer" component={FundsTransfer} />
        <Route exact path="/test-signin" component={TestSignin} />
      </>
      {/* )} */}
    </>
  );
};

export default Routes;
