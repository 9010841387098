import axios from "../axiosInstance"

const getClient = async (user) => {
    let resolved = {
        data: null,
        error: null
    }

    try {
        const res = await axios({
            url: `api/v1/users/partners/${user.profile.userId}/clients`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${user.token}`
            }

        })
        resolved.data = res.data
    } catch (err) {
        if (err.response) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "SomeThing went Wrong"
        }

    }

    return resolved;
}

const getEarnings = async (user) => {
    let resolved = {
        data: null,
        error: null
    }

    try {
        const res = await axios({
            url: `api/v1/users/partners/${user.profile.userId}/payouts`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${user.token}`
            }

        })
        resolved.data = res.data
    } catch (err) {
        if (err.response) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "SomeThing went Wrong"
        }

    }

    return resolved;
}

export { getClient , getEarnings}