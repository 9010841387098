import React from 'react'
import { Link } from "react-router-dom"
import "./ExpressDeal.css"

const ExpressDeal = () => {
    return (
        <>
            <div className="co__upper__green">
                <p className="co__green__para1">Express Deal</p>
                <p className="co__green__para2">5632 shares at a W.avg price of Rs. 240 are available and Can be fulfilled in 72 working hours. Complete this order Immediately to grab this deal.</p>
                <p><Link className="co__para2__link">Learn More</Link></p>



            </div>

        </>
    )
}

export default ExpressDeal
