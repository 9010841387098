import axios from "axios";
// axios.defaults.headers.authorization = `Bearer ${localStorage.getItem(
//   "token"
// )}`;

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_PROD_STATUS == "false"
      ? process.env.REACT_APP_DEV_LINK
      : process.env.REACT_APP_PROD_LINK,
  // baseURL: "https://funds-map-prod.herokuapp.com/",
});
console.log(
  "\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ ",
  process.env.REACT_APP_PROD_STATUS
);
export const BASE_URL =
  process.env.REACT_APP_PROD_STATUS == false
    ? process.env.REACT_APP_DEV_LINK
    : process.env.REACT_APP_PROD_LINK;
// export const BASE_URL = "https://funds-map-prod.herokuapp.com/";

export default instance;
