import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import info from "../../../../Assets/imgs/info.svg";

import "./OrderValidity.css";
import { RuppeIcon, BoxesIcon } from "../../../../constants/icons";

const OrderValidity = (props) => {
  const [sharesError, setSharesError] = useState(null);
  const [priceError, setPriceError] = useState(null);

  const [findedTime, setFindedTime] = useState(null);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    let time = new Date();
    let currentMiliSecond = time.getTime();
    console.log("The Value =========== ", props.time.value);
    console.log("The Unit =========== ", props.time.unit);
    let miliSecond =
      props.time.unit == "h"
        ? parseFloat(props.time.value) * 3.6e6
        : parseFloat(props.time.value) * 8.64e7;
    console.log("Milisecond ====== ", miliSecond);
    let setTime = new Date(currentMiliSecond + miliSecond);
    setFindedTime(setTime);
    console.log(setTime);
  }, [props.time.value]);

  useEffect(() => {
    if (
      props.enteredData.shares < props.data.minimumQty ||
      props.enteredData.shares % props.data.lotSize != 0 ||
      props.enteredData.price < props.data.minimumBidPrice ||
      props.enteredData.price > props.data.maximumAskPrice
    ) {
      props.error((preValue) => {
        return {
          ...preValue,
          shares: false,
          price: false,
        };
      });
    } else if (
      props.modifyData &&
      props.enteredData.shares < props.modifyData.filledQty
    ) {
      props.error((preValue) => {
        return {
          ...preValue,
          shares: false,
          price: false,
        };
      });
    } else {
      props.error((preValue) => {
        return {
          ...preValue,
          shares: true,
          price: true,
        };
      });
    }
  }, []);

  const enteringData = (event) => {
    let { name, value } = event.target;

    switch (name) {
      case "shares":
        props.setEnteredData((preValue) => {
          return {
            ...preValue,
            [name]: value,
          };
        });
        if (value < props.data.minimumQty) {
          setSharesError(
            `Quantity must be greater than ${props.data.minimumQty}`
          );
          props.error((preValue) => {
            return {
              ...preValue,
              shares: false,
            };
          });
        } else if (value % props.data.lotSize != 0) {
          setSharesError(
            `Quantity must be a multiple of ${props.data.lotSize}`
          );
          props.error((preValue) => {
            return {
              ...preValue,
              shares: false,
            };
          });
        } else if (props.modifyData && value < props.modifyData.filledQty) {
          setSharesError(
            `Updated quantity cannot be lesser than already filled quantity ${props.modifyData.filledQty}`
          );
          props.error((preValue) => {
            return {
              ...preValue,
              shares: false,
            };
          });
        } else {
          setSharesError(null);
          props.error((preValue) => {
            return {
              ...preValue,
              shares: true,
            };
          });
        }
        break;
      case "price":
        props.setEnteredData((preValue) => {
          return {
            ...preValue,
            [name]: value,
          };
        });
        if (value < props.data.minimumBidPrice) {
          setPriceError(
            `Price cannot be lesser than ${props.data.minimumBidPrice}`
          );
          props.error((preValue) => {
            return {
              ...preValue,
              price: false,
            };
          });
        } else if (value > props.data.maximumAskPrice) {
          setPriceError(
            `Price cannot be greater than  ${props.data.maximumAskPrice}`
          );
          props.error((preValue) => {
            return {
              ...preValue,
              price: false,
            };
          });
        } else {
          setPriceError(null);
          props.error((preValue) => {
            return {
              ...preValue,
              price: true,
            };
          });
        }
        break;

      default:
        break;
    }
  };

  let numbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23,
  ];
  let days = [1, 2, 3, 7, 14, 30, 45, 60];

  const entringTimeUnit = (event) => {
    props.setTime((preValue) => {
      return {
        ...preValue,
        unit: event.target.value,
      };
    });
  };
  const entringTimeValue = (event) => {
    props.setTime((preValue) => {
      return {
        ...preValue,
        value: event.target.value,
      };
    });
  };
  console.log(props.data);
  console.log(props.time);

  useEffect(() => {
    let orignalDays = [];
    for (let i = 0; i < days.length; i++) {
      if (
        props.data.minimumValidityDuration <= days[i] &&
        props.data.maximumValidityDuration >= days[i]
      ) {
        orignalDays.push(days[i]);
      }
    }
    console.log(orignalDays[0]);
    props.setTime((preValue) => {
      return {
        ...preValue,
        value: orignalDays[0],
      };
    });
  }, []);

  return (
    <>
      <div className="ordermod__body">
        {props.text ? (
          <div className="ordermod__bodytitle">{props.text}</div>
        ) : (
          <div className="ordermod__bodytitle">Order Modification</div>
        )}
        <div className="ordermod__inputs">
          <div className="ordermod__input">
            <div className="inputbox_upper">
              <span>
                <RuppeIcon size={20} className="ordermod__ruppeicon" />
              </span>
              <TextField
                autoComplete="off"
                id="outlined-basic"
                placeholder="254"
                variant="standard"
                name="price"
                value={props.enteredData.price}
                onChange={enteringData}
                type="number"
              />
            </div>
            <p className="om_para" style={{ marginBottom: ".5rem" }}>
              Limit Price/Share
            </p>
            {priceError && (
              <p style={{ fontSize: ".55rem", color: "red", flex: 1 }}>
                {priceError}
              </p>
            )}
          </div>
          <div className="ordermod__input">
            <div className="inputbox_upper">
              <span>
                <BoxesIcon size={18} className="ordermod__boxesicon" />
              </span>
              <TextField
                autoComplete="off"
                style={{ marginRight: "15px" }}
                id="outlined-basic"
                placeholder="10000"
                name="shares"
                value={props.enteredData.shares}
                variant="standard"
                onChange={enteringData}
                type="number"
              />
            </div>
            <p className="om_para" style={{ marginBottom: ".5rem" }}>
              No. of Shares
            </p>
            {sharesError && (
              <p style={{ fontSize: ".55rem", color: "red", flex: 1 }}>
                {sharesError}
              </p>
            )}
          </div>
        </div>

        <div className="ordermod__validity">
          <div className="validity__title">Order Validity</div>

          <div className="order__options" style={{ display: "flex" }}>
            <div className="validity__days">
              <select
                value={props.time.value}
                style={{ border: "none" }}
                name=""
                id=""
                onChange={entringTimeValue}
              >
                {props.time.unit == "h"
                  ? numbers.map((num, index) => {
                      if (props.data.maximumValidityDurationUnit == "HOURS") {
                        if (
                          props.data.minimumValidityDuration <= num &&
                          props.data.maximumValidityDuration >= num
                        ) {
                          return (
                            <>
                              <option value={num}> {num} </option>
                            </>
                          );
                        }
                      } else {
                        if (props.data.minimumValidityDuration <= num) {
                          return (
                            <>
                              <option value={num}> {num} </option>
                            </>
                          );
                        }
                      }
                    })
                  : days.map((num, index) => {
                      if (props.data.minimumValidityDurationUnit == "DAYS") {
                        if (
                          props.data.minimumValidityDuration <= num &&
                          props.data.maximumValidityDuration >= num
                        ) {
                          return (
                            <>
                              <option value={num}> {num} </option>
                            </>
                          );
                        }
                      } else {
                        if (props.data.maximumValidityDuration >= num) {
                          return (
                            <>
                              <option value={num}> {num} </option>
                            </>
                          );
                        }
                      }
                    })}
              </select>
            </div>
            <div className="validity__years">
              <select
                value={props.time.unit}
                style={{ border: "none" }}
                onChange={entringTimeUnit}
              >
                {props.data.minimumValidityDurationUnit == "HOURS" &&
                props.data.maximumValidityDurationUnit == "HOURS" ? (
                  <>
                    <option value="h">Hours</option>
                  </>
                ) : props.data.minimumValidityDurationUnit == "DAYS" &&
                  props.data.maximumValidityDurationUnit == "DAYS" ? (
                  <>
                    <option value="d">Days</option>
                  </>
                ) : props.data.minimumValidityDurationUnit == "HOURS" &&
                  props.data.maximumValidityDurationUnit == "DAYS" ? (
                  <>
                    <option value="h">Hours</option>
                    <option value="d">Days</option>
                  </>
                ) : (
                  <option value="d">NO OPTION</option>
                )}
              </select>
            </div>
          </div>
        </div>

        <div className="om__validity__para">
          <div className="om__thirdimg">
            <img className="om__validity__img" src={info} alt="" />
          </div>
          What is Order Validity?
        </div>
        {findedTime && (
          <div className="ordermod__valbody">
            {props.modify ? (
              <>
                Modified order will be valid till{" "}
                {findedTime.toLocaleString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "numeric",
                  minute: "2-digit",
                })}{" "}
                or until you cancel the order, whichever is earlier.
              </>
            ) : (
              <>
                Your order shall be valid until{" "}
                {findedTime.toLocaleString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "numeric",
                  minute: "2-digit",
                })}{" "}
                or until you cancel the order, whichever is earlier.
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default OrderValidity;
