import React from 'react'
import "./Express.css"
import closeIcon from "../../../Assets/imgs/CloseIcon.svg"

import Button from "../../../components/button/Button"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const Express = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>


            <Button text="SEND LINK" click={handleClickOpen} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >





                < div className="ep__container" >
                    <div className="ep__header">
                        <p className="ep__header__heading">
                            Express Deal
                        </p>
                        <img onClick={handleClose} src={closeIcon} alt="Error" className="ep__header__img" />

                    </div>
                    <div className="ep__body">
                        <p style={{ fontWeight: "bold", marginBottom: "13px" }} className="ep__body__heading">
                            You're placing an order for 10000 shares.
                        </p>
                        <p style={{ marginBottom: "13px" }} className="ep__body__para1">
                            Currently, 5632 shares at a W.avg price Rs. 240 / share are available and can be fulfilled immediately. If these shares are not transacted by somebody else, before you complete your order, we gurantee it's fulfilment. Failinng which we will compensate you by paying a compensation of Rs. 25300.
                        </p>
                        <p className="eq__body__para2">
                            For the remaining 4368 shares we will place an order and intimate you once the order gets fulfilled. You can cancel the order for these 4368 shares at any time.
                        </p>

                    </div>
                </div >


            </Dialog>




        </>
    )
}

export default Express


























