import React from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import Check from "../../../Assets/imgs/check2.svg";
import BuyerInfo from "../../OrderModification/Components/BuyerInfo/BuyerInfo";

import "./SuccModification.scss";

const SuccModification = () => {
  const history = useHistory();
  const location = useLocation();

  const { company, orderInfo, path } = location.state;

  let expireTime = new Date(orderInfo.expiryTimestamp * 1000);

  console.log("SUCC MODI PAGE ---- ", orderInfo);

  return (
    <>
      <div className="succmodi_container">
        <div className="succ">
          <img src={Check} alt="" />
          <p>
            {" "}
            Your order has been {path == "succ" ? "modified" : "canceled"}.{" "}
          </p>
        </div>
        <BuyerInfo
          path="order-modification"
          data={company}
          detail={orderInfo}
          time={expireTime}
        />
        <p className="detail">
          You can now track the progress of your order in the{" "}
          <Link
            to={`/order/${orderInfo?.orderRequestId}?v=${orderInfo?.version}`}
          >
            Order Tab.
          </Link>
        </p>
      </div>
    </>
  );
};

export default SuccModification;
