import { useState, useEffect } from "react";
import DropCard from "./components/drop-card/DropCard";
import idCard from "../../Assets/imgs/id-card.svg";
import bankLine from "../../Assets/imgs/bank_line.svg";
import profile from "../../Assets/imgs/profile.svg";
import "./Profile.css";
import ErrorBox from "./components/error-box/ErrorBox";
import AddPhone from "./components/add-phone/AddPhone";
import { profile_details, get_demat } from "../../api/profile";
import { useHistory, useLocation } from "react-router-dom";
import BankMain from "../AddBank/Bankinfo-popup/BankMain.jsx";
import MainDemet from "../Demet/mainDemet";
import VerifyIdentity from "./components/verify-identity/VerifyIdentity.js";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { get_banks } from "../../api/profile";
import { set_profile } from "../../store/User";
import ScreenLoader from "../../components/screen-loader/ScreenLoader";
import VerifyEmail from "./components/verify-identity/components/VerifyEmail";

const Profile = () => {
  const history = useHistory();
  const location = useLocation();
  const redirectPath = new URLSearchParams(location.search).get("redirectPath");
  const dispatch = useDispatch();
  const addMobile = false;
  const [completed, setCompleted] = useState(false);
  const verifyMobile = false;
  const [bank, setBank] = useState(false);
  const [addedBanks, setAddedbanks] = useState([]);
  const [addedDemats, setaddedDemats] = useState([]);
  const [demat, setDemat] = useState(false);
  const [idty, setIdty] = useState(false);
  const [step, setStep] = useState("step1");
  const [stepD, setStepD] = useState("step1");
  const [profileData, setProfileData] = useState([]);
  const [username, setUsername] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [dematDisabled, setDematDisabled] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [ismailVerified, setEmailVerified] = useState(false);
  const [verifyEmailTab, setVerifyEmailTab] = useState(false);

  const [box, setBox] = useState({
    open: false,
    type: "mobile",
  });
  // handle tabs open and close
  const [profileTab, setProfileTab] = useState(false);
  const [bankTab, setBankTab] = useState(false);
  const [dematTab, setDematTab] = useState(false);

  const stateToken = useSelector((state) => state.user.token);

  const get_banks1 = async () => {
    const banks = await get_banks();
    if (banks.error) {
      toast.error(banks.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else {
      console.log(banks);
      setAddedbanks(
        banks !== null && banks.data != null ? banks.data.data : []
      );
    }
  };

  const get_demat1 = async () => {
    const addeddemat = await get_demat();
    if (addeddemat.error) {
      toast.error(addeddemat.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else {
      console.log(addeddemat);
      setaddedDemats(addeddemat.data?.data);
      setaddedDemats(addeddemat.data?.data);
    }
  };

  const get_details = async () => {
    const details = await profile_details(stateToken);
    console.log(details);
    if (details.error) {
      console.log("Some error in profile" + details.error);
    } else {
      if (details.data) {
        localStorage.setItem("profile", JSON.stringify(details.data.data));
        dispatch(set_profile(details.data.data));
        const {
          userName,
          emailVerified,
          identityCheckDone,

          bankAdded,
          dematAdded,
        } = details.data.data;
        setEmailVerified(emailVerified);
        setProfileData(details.data.data);
        setUsername(userName);
        setCompleted(identityCheckDone);
        setDisabled(!identityCheckDone);
        setDematDisabled(!bankAdded);
        if (!identityCheckDone) {
          setProfileTab(true);
          setBankTab(false);
          setDematTab(false);
        } else if (!bankAdded) {
          setProfileTab(false);
          setBankTab(true);
          setDematTab(false);
        } else if (!dematAdded) {
          setProfileTab(false);
          setBankTab(false);
          setDematTab(true);
        }
      }
    }
  };

  useEffect(() => {
    addMobile &&
      setBox({
        open: true,
        type: "mobile",
      });
    verifyMobile &&
      setBox({
        open: true,
        type: "otp",
      });
  }, [addMobile, verifyMobile]);

  useEffect(() => {
    get_details();
    get_banks1();
    get_demat1();
  }, []);

  useEffect(() => {
    console.log(profileData.status);
    console.log(redirectPath);
    if (profileData.status == "COMPLETE" && redirectPath) {
      let orderId = new URLSearchParams(location.search).get("orderId");
      let version = new URLSearchParams(location.search).get("orderVersion");
      history.push({
        pathname: `/${redirectPath}`,
        search: `?orderId=${orderId}&orderVersion=${version}`,
      });
    }
  }, [profileData]);

  return (
    <div className="profile__screen">
      {screenLoader && <ScreenLoader />}

      {verifyEmailTab && (
        <VerifyEmail
          setVerifyEmailTab={setVerifyEmailTab}
          setIdty={setIdty}
          get_details={get_details}
        />
      )}

      <div className="profile__header">
        <span className="pf__header_left">Profile</span>
        <span className="pf__header_right">
          <span className="pf__acc_status">Account Status:</span>
          <span className="pf__acc_info">
            {profileData.status === "INCOMPLETE"
              ? "Awaiting Profile Completion"
              : "Active"}
          </span>
        </span>
      </div>

      <div className="profile__body">
        <DropCard
          icon={idCard}
          text="Identity"
          body={completed ? "completed" : "identity"}
          setIdty={setIdty}
          data={profileData}
          open={profileTab}
          basicInfo={profileData}
          dotColor={profileData.identityCheckDone ? "green" : "red"}
          get_details={get_details}
          emailVerified={ismailVerified}
          setVerifyEmailTab={setVerifyEmailTab}
        />
        <DropCard
          icon={bankLine}
          text="Bank"
          body="bank"
          open={bankTab}
          setBank={setBank}
          setDemat={setDemat}
          banks={addedBanks}
          disabled={disabled}
          dotColor={addedBanks.length > 0 ? "green" : "red"}
          get_details={get_details}
          getBanks={get_banks1}
        />
        <DropCard
          icon={profile}
          text="Demat Details"
          body="demat"
          open={dematTab}
          setDemat={setDemat}
          setBank={setBank}
          demats={addedDemats}
          disabled={dematDisabled}
          get_details={get_details}
          dotColor={addedDemats.length > 0 ? "green" : "red"}
          getDemats={get_demat1}
        />
      </div>
      {profileData.status === "INCOMPLETE" && <ErrorBox />}
      {box?.open && (
        <AddPhone
          type={box?.type}
          setAddPhone={setBox}
          addPhone={box?.open}
          username={username}
          get_details={get_details}
          setScreenLoader={setScreenLoader}
        />
      )}
      {bank && (
        <BankMain
          get_banks1={get_banks1}
          setBank={setBank}
          bank={bank}
          step={step}
          setStep={setStep}
          get_details={get_details}
          profileData={profileData}
        />
      )}
      {demat && (
        <MainDemet
          setDemat={setDemat}
          demat={demat}
          stepD={stepD}
          setStepD={setStepD}
          get_demat1={get_demat1}
          get_details={get_details}
          profileData={profileData}
        />
      )}
      {idty && (
        <VerifyIdentity
          setIdty={setIdty}
          idty={idty}
          data={profileData}
          setMobileFlow={setBox}
          get_details={get_details}
          setScreenLoader={setScreenLoader}
        />
      )}
    </div>
  );
};

export default Profile;
