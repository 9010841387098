import axios from "../axiosInstance";

const getQuote = async (token, cname, login, partnerName) => {
  console.log("GET-QUOTE ========= ", token, cname, login, partnerName);
  let resolved = {
    data: null,
    error: null,
  };
  let logedIn = login == null ? "false" : "true";

  try {
    const res = await axios({
      url: partnerName
        ? `api/v1/quotes/${cname}?personalised=${logedIn}&partnerName=${partnerName}`
        : `api/v1/quotes/${cname}?personalised=${logedIn}`,
      method: "GET",
      headers: {
        Authorization: logedIn == "true" ? `Bearer ${token}` : null,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};
const getBookQuote = async (token, cname, login, partnerName) => {
  console.log("GET-QUOTE ========= ", token, cname, login, partnerName);
  let resolved = {
    data: null,
    error: null,
  };
  let logedIn = login == null ? "false" : "true";

  try {
    const res = await axios({
      url: partnerName
        ? `api/v1/quotes/book/${cname}?personalised=${logedIn}&partnerName=${partnerName}`
        : `api/v1/quotes/book/${cname}?personalised=${logedIn}`,
      method: "GET",
      headers: {
        Authorization: logedIn == "true" ? `Bearer ${token}` : null,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const getQuoteDetail = async (token, cname) => {
  let resolved = {
    data: null,
    error: null,
  };
  let user = token == null ? "false" : "true";

  try {
    const res = await axios({
      url: `api/v1/companies/${cname}/orderValidationDetails/`,
      method: "GET",
      headers: {
        Authorization: user == "true" ? `Bearer ${token}` : null,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const CreateOrder = async (
  token,
  userName,
  company,
  quoteData,
  time,
  minTrade,
  type,
  urlPartner,
  partnerName,
  margin,
  selfPartner,
  minQty
) => {
  // console.log("PARTNER NAME ================ ", partnerName, margin);
  var date = new Date();
  var epoch = date.getTime() / 1000;
  var minqtyper =
    type == "BID" ? 1 : parseFloat(minTrade) / parseFloat(quoteData.shares);
  var normalisedMinQty =
    type == "BID" ? quoteData.shares : parseFloat(minTrade);

  let resolved = {
    data: null,
    error: null,
  };

  if (urlPartner) {
    try {
      const res = await axios({
        url: `api/v1/order/create`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          orderRequestType: "NEW",
          quoteType: type,
          isin: company.isin,
          qty: parseFloat(quoteData.shares),
          price: parseFloat(quoteData.price),
          placedForUserName: userName,
          quotedSpread: selfPartner == "selfPartner" ? parseFloat(margin) : 0,
          minQtyPercent: minqtyper,
          expiryUnits: parseInt(time.value),
          unit: time.unit == "d" ? "DAYS" : "HOURS",
          createdTimeStamp: Math.trunc(epoch),
          minQty: normalisedMinQty,
          referredPartnerUserName: urlPartner.trim(),
        },
      });
      resolved.data = res.data;
    } catch (err) {
      if (err.response) {
        resolved.error = err.response.data.message;
      } else {
        resolved.error = "SomeThing went Wrong";
      }
    }
  } else {
    try {
      const res = await axios({
        url: `api/v1/order/create`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          orderRequestType: "NEW",
          quoteType: type,
          isin: company.isin,
          qty: parseFloat(quoteData.shares),
          price: parseFloat(quoteData.price),
          placedForUserName: userName,
          quotedSpread:
            partnerName != null ? (!margin ? 0 : parseFloat(margin)) : 0,
          minQtyPercent: minqtyper,
          minQty: normalisedMinQty,
          expiryUnits: parseInt(time.value),
          unit: time.unit == "d" ? "DAYS" : "HOURS",
          createdTimeStamp: Math.trunc(epoch),
        },
      });
      resolved.data = res.data;
    } catch (err) {
      if (err.response) {
        resolved.error = err.response.data.message;
      } else {
        resolved.error = "SomeThing went Wrong";
      }
    }
  }

  return resolved;
};

const createOrderUnregister = async (
  token,
  userEmail,
  company,
  quoteData,
  minTrade,
  type,
  margin
) => {
  var date = new Date();
  var epoch = date.getTime() / 1000;
  var minqtyper =
    type == "BID" ? 1 : parseFloat(minTrade) / parseFloat(quoteData.shares);
  var normalisedMinQty =
    type == "BID" ? quoteData.shares : parseFloat(minTrade);
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/unregisteredUser/`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        quoteType: type,
        isin: company.isin,
        qty: parseFloat(quoteData.shares),
        price: parseFloat(quoteData.price),
        placedForEmail: userEmail,
        quotedSpread: !margin ? 0 : parseFloat(margin),
        minQtyPercent: minqtyper,
        expiryUnits: parseInt(quoteData.value),
        unit: quoteData.unit == "d" ? "DAYS" : "HOURS",
        createdTimeStamp: Math.trunc(epoch),
        minQty: normalisedMinQty,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const ConfirmOrderApi = async (token, id, ver) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/validate?id=${id}&version=${ver}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const preSign = async (token, id) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/pre-sign/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const genOtp = async (token, id) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/otp/generate?txnId=${id}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const getOrder = async (token, orderId, version) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/${orderId}/${version}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const getOrderSign = async (token, orderId, version) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/sign/${orderId}/` + version,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const orderSign = async (token, orderId, version, fields, otp) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/sign/${orderId}/` + version,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        fields: fields,
        otp: parseFloat(otp),
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const orderSignAccept = async (token, data, type) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/esign/accept`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        txn_id: data.txn_id,
        digio_doc_id: data.digio_doc_id,
        message: data.message,
        response_type: type,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const whoIam = async (token) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/users/me`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const getCompany = async (token, symbol) => {
  let resolved = {
    data: null,
    error: null,
  };
  // let logedIn = toke == null ? "false" : "true"

  try {
    const res = await axios({
      url: `api/v1/companies/snapshot/${symbol}`,
      method: "Get",
      headers: {
        Authorization: token == null ? null : `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const getClients = async (user) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/users/partners/${user.profile.userId}/clients`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const checkClients = async (token, email) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/users/partners/clientLookup?email=${email}`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const registerShare = async (orderId) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/share/${orderId}`,
      method: "Get",
      headers: {
        Authorization: null,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const unRegisterShare = async (orderId) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/unregisteredUser/share/${orderId}`,
      method: "Get",
      headers: {
        Authorization: null,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const registerOtpGen = async (email) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/users/login/otp?type=EMAIL&email=${email}`,
      method: "POST",
      headers: {
        Authorization: null,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const unRegisterOtpGen = async (orderId) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/users/unregistered-user-order/send-email-verification-otp?orderId=${orderId}`,
      method: "POST",
      headers: {
        Authorization: null,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const registerOtpSignup = async (email, otp, orderId, version) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/users/login/withOrder?email=${email}&otp=${otp}&orderId=${orderId}&version=${version}`,
      method: "POST",
      headers: {
        Authorization: null,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const unRegisterOtpSignup = async (orderId, otp, email) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/users/signup/withOrder?orderId=${orderId}&otp=${otp}`,
      method: "POST",
      headers: {
        Authorization: null,
      },
      data: {
        email: email,
        userType: "INDIVIDUAL_CLIENT",
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const defaultMargin = async (user) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/users/partners/${user.profile.userId}/orders/defaults/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

// ORDER-MODIFICATION

const getConfirmedOrder = async (token, orderId) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/confirmed/${orderId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const confirmCancelOrder = async (token, orderId, version, otp) => {
  var date = new Date();
  var epoch = date.getTime() / 1000;

  // alert(otp)

  let resolved = {
    data: null,
    error: null,
    status: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/bundle/cancel/`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        orderRequestId: orderId,
        version: version,
        otp: parseFloat(otp),
        createdTimeStamp: Math.trunc(epoch),
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
      resolved.status = err.response.data.status;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const confirmOrder = async (token, orderId, version, otp) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/confirm/`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        orderId: orderId,
        version: version,
        otp: parseFloat(otp),
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const orderUpdate = async (
  token,
  orderId,
  quoteData,
  time,
  type,
  minTrade,
  partner,
  margin,
  floor,
  pName
) => {
  var date = new Date();
  var epoch = date.getTime() / 1000;
  var minqtyper =
    type == "BID" ? 1 : parseFloat(minTrade) / parseFloat(quoteData.shares);
  var normalisedMinQty =
    type == "BID" ? quoteData.shares : parseFloat(minTrade);
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/update/`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        orderRequestId: orderId,
        price: parseFloat(quoteData.price),
        qty: parseFloat(quoteData.shares),
        minimumFulfillmentPercent: minqtyper,
        quotedSpread: partner ? parseFloat(margin) : floor,
        expiryUnits: parseFloat(time.value),
        unit: time.unit == "d" ? "DAYS" : "HOURS",
        createdTimeStamp: Math.trunc(epoch),
        minimumFulfillmentQty: normalisedMinQty,
        referredPartnerUserName: pName ? pName : null,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const getOrderDetail = async (token, orderId, version) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/${orderId}/${version}/placedForUser`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const modifyRegisterShare = async (orderId) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/share/update/${orderId}`,
      method: "Get",
      headers: {
        Authorization: null,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

// GAUGING-INTEREST

const createGaug = async (user, company, price, type) => {
  var date = new Date();
  var epoch = date.getTime() / 1000;

  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/order/gaugingInterest/create`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: {
        orderRequestType: "NEW",
        action: type,
        isin: company.isin,
        amount: parseFloat(price),
        placedForUserName: user.profile.userName,
        createdTimeStamp: Math.trunc(epoch),
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

// NOTIFICATION

const notify = async (token, orderId, version, type) => {
  let link = `${window.location.protocol}//${window.location.host}/validate-order?orderId=${orderId}&client=${type}`;

  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/notifications/orders/partner/share`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        clientType: type == "unregister" ? "UNREGISTERED" : "REGISTERED",
        orderId: orderId,
        orderShareLink: link,
        orderType:
          type == "modify" ? "UPDATE" : type == "cancel" ? "CANCEL" : "NEW",
        version: parseFloat(version),
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

export {
  getQuote,
  getBookQuote,
  getQuoteDetail,
  CreateOrder,
  ConfirmOrderApi,
  preSign,
  genOtp,
  getOrder,
  orderSign,
  getOrderSign,
  orderSignAccept,
  whoIam,
  getCompany,
  getClients,
  checkClients,
  createOrderUnregister,
  registerShare,
  unRegisterShare,
  registerOtpGen,
  unRegisterOtpGen,
  registerOtpSignup,
  unRegisterOtpSignup,
  getConfirmedOrder,
  confirmCancelOrder,
  confirmOrder,
  orderUpdate,
  getOrderDetail,
  modifyRegisterShare,
  createGaug,
  defaultMargin,
  notify,
};
