import { useState } from "react";
import "./Bankinfo.css";
import Button from "../../../components/button/Button";
import BottomSlide from "../../../components/bottom-slide/BottomSlide";
import BSHeader from "../../profile/components/bs-header/BSHeader";
import Bankinfo from "./Bankinfo";
import Bankdata from "../Bankinfo-data/Bankdata";
import BankSuccess from "../Banksuccess/bsuccess";
import {
  ifsc_validator,
  otp_generate,
  bank_validator,
  bank_save,
} from "../../../api/profile";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const BankMain = ({
  bank,
  setBank,
  step,
  setStep,
  get_banks1,
  profileData,
  get_details,
}) => {
  const [ifsc, setIfsc] = useState("");
  const [accountnumber, setAccountnumber] = useState("");
  const [checkDisabled, setCheckDisabled] = useState(false);
  const [data_for_step2, setdata_for_step2] = useState({});
  const [validationkey, setValidationkey] = useState(null);
  const [bankres, setbankres] = useState(null);
  const [ifsc_res, setifsc_res] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const token = useSelector((state) => state.user.token);
  const [incorrectOtp, setIncorrectOtp] = useState(false);

  const [otp, setOtp] = useState("");

  const handleStep1 = async () => {
    setLoading(true);
    let bank = await bank_validator(ifsc, accountnumber);
    setbankres(bank);
    if (bank.error) {
      toast.error(bank.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    } else {
      console.log(bank.data.data.validationKey);
      setValidationkey(bank.data.data.validationKey);
      console.log(bank);
      if (bank.data?.data?.status != "VALID") {
        setLoading(false);
        toast.error("Bank Account is not Valid!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("validation key: ", bank.data.data.validationKey);
        let otp = await otp_generate(
          bank.data.data.validationKey,
          ifsc,
          accountnumber
        );

        if (otp.error) {
          toast.error(otp.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        } else {
          console.log(otp);
          setdata_for_step2({
            ac: accountnumber,
            bankName: bank.data?.data?.bankName,
            bankBranch: bank.data?.data?.branch,
            accountHolderName: bank.data?.data?.accountHolderName,
            validationkey: validationkey,
            ifsc: ifsc,
          });
          console.log(data_for_step2);
          setLoading(false);
          setStep("step2");
        }
      }
    }
  };
  const handel_valid_ifsc = (value) => {
    const re = /^[A-Za-z]{4}\d{7}$/;
    if (value === "" || re.test(value)) {
      setInvalid(false);
      return true;
    } else {
      setInvalid(true);
      return false;
    }
  };
  const handel_ifsc = async (val) => {
    setIfsc(val);
    const isValid = handel_valid_ifsc(val);
    if (val?.length === 11 && isValid) {
      console.log(token);
      if (isValid) {
        setLoadingData(true);
        let ifsc_v = await ifsc_validator(val, token);
        if (ifsc_v.error) {
          toast.error(ifsc_v.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingData(false);
        } else {
          setifsc_res(ifsc_v?.data?.data);
          setLoadingData(false);
          console.log(ifsc_v);
        }
      }
    }
  };

  const handleStep2 = async () => {
    setLoading(true);
    let save_Account = await bank_save(
      otp,
      validationkey,
      bankres.data?.data?.accountHolderName,
      bankres.data?.data?.bankName,
      accountnumber,
      ifsc
    );
    console.log(save_Account);
    if (save_Account.error === "Invalid OTP") {
      setLoading(false);
      setStep("step2");
      setIncorrectOtp(true);
      toast.error("Incorrect OTP!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (save_Account.error) {
      setLoading(false);
      setStep("step1");
      toast.error("Bank couldn't be saved", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setStep("step3");
    }
  };

  const handleStep3 = () => {
    get_banks1();
    get_details();
    setStep("step1");
    setBank(false);
  };

  return (
    <>
      <BottomSlide closeBSlider={bank}>
        <BSHeader
          text="Add Bank"
          setCloseBSlider={() => {
            setBank(false);
            setStep("step1");
          }}
        />
        {step === "step1" && (
          <Bankinfo
            setIfsc={setIfsc}
            setAccountnumber={setAccountnumber}
            ifsc={ifsc}
            handel_ifsc={handel_ifsc}
            ifsc_res={ifsc_res}
            loadingData={loadingData}
            invalid={invalid}
          />
        )}
        {step === "step2" && (
          <Bankdata
            data_for_step2={data_for_step2}
            setOtp={setOtp}
            setCheckDisabled={setCheckDisabled}
            profileData={profileData}
            incorrectOtp={incorrectOtp}
          />
        )}
        {step === "step3" && <BankSuccess data_for_step3 />}

        {step === "step1" && (
          <Button
            click={() => handleStep1()}
            text="VERIFY BANK ACCOUNT"
            loading={loading}
          />
        )}
        {step === "step2" && (
          <Button
            style={
              checkDisabled
                ? { background: "lightgrey", pointerEvents: "none" }
                : false
            }
            click={() => handleStep2()}
            text="ADD BANK ACCOUNT"
            loading={loading}
          />
        )}
        {step === "step3" && (
          <Button click={() => handleStep3()} text="DONE" loading={loading} />
        )}
      </BottomSlide>
    </>
  );
};

export default BankMain;
