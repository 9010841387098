import React from 'react'
import { Link, useHistory, useLocation } from "react-router-dom"

import BuyerInfo from '../../OrderModification/Components/BuyerInfo/BuyerInfo'
import Check from "../../../Assets/imgs/check2.svg"
import Button from "../../../components/button/Button"

import VideoPlayer from '../../dashboard/components/media-box/MediaBox'
// import video from "../../PartnerSignup/Components/media-box/Gstart.mp4";


import "./WhatNow.scss"


const WhatNow = (props) => {
    const history = useHistory();
    const location = useLocation();
    
    let video = "https://youtu.be/GQl3f-e_goI"

    let company = location.state ? location.state.company : null
    let succRes = location.state ? location.state.data : null
    let path = location.state ? location.state.path : null

    console.log("WHAT NOW PAGE ---- " , succRes);

    return (
        <>
            <div className="wn__container">
                <div className="wn__upper">
                    <div className="linksent">
                        <img src={Check} alt="" />
                        {
                            path == "gaug" ?
                                <>
                                    <p>  Your interest has been validated </p>
                                </>
                                :
                                <>
                                    {/* <p>  Link sent to client via SMS & Email. </p> */}
                                    <p>  Your order has been placed successfully </p>
                                </>
                        }
                    </div>
                    <div className="wn__buyerInfo__comp">
                        <BuyerInfo data={company} succRes={succRes} path={path ? "gaug" : "ordersucc"} />
                    </div>

                    <div className="wn__header">
                        <p className="wn__header__heading">
                            What now?
                        </p>
                        <p className="wn__header__para">
                            Watch this 5 minute video to get upto pace
                        </p>
                    </div>
                    <div className="wn__video__comp">
                        <VideoPlayer video={"https://youtu.be/GQl3f-e_goI"} autoplay/>
                    </div>

                    {
                        path == "gaug" ?
                            <>
                                <div className="opportunities">
                                    <div className="btn">
                                        <Button text="EXPLORE OPPORTUNITIES" click={()=> history.push("/dashboard")} />
                                    </div>
                                    <p> Explore more investment opportunities in privately held innovative businesses. </p>
                                </div>
                            </>
                            :
                            <>
                                <div className="wn__footer__para">
                                    You can now track the progress of your order in the <Link to={`/order/${succRes.orderRequestId}?v=${succRes.version}`}>orders tab.</Link>
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    )
}

export default WhatNow
