import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import Progress from "@material-ui/core/CircularProgress";

import Button from "../../../components/button/Button";

import { toast } from "react-toastify";

import {
  getClients,
  checkClients,
  CreateOrder,
  createOrderUnregister,
} from "../../../api/order";
import { useSelector } from "react-redux";

import "./PI_Registration.scss";

const PI_Registration = (props) => {
  const history = useHistory();
  let user = useSelector((state) => state.user);

  const [clients, setClients] = useState(null);
  const [email, setEmail] = useState("");
  const [checkedClient, setCheckedClient] = useState(null);

  useEffect(() => {
    const getData = async () => {
      let res = await getClients(user);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setClients(res.data);
      }
    };

    getData();
  }, []);

  const enteringEmail = (data) => {
    setEmail(data);
    setCheckedClient(null);
  };

  const checkUser = async () => {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      let res = await checkClients(user.token, email);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setCheckedClient(res.data);
      }
    } else {
      toast.error("Please Enter Valid Email", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const create = async () => {
    if (checkedClient === null) {
      toast.error("Validate email first", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (checkedClient.type === "UNREGISTERED_USER") {
        let unres = await createOrderUnregister(
          user.token,
          checkedClient.emailId,
          props.company,
          props.quoteData,
          props.minTrade,
          props.type,
          props.margin
        );
        if (unres.error != null) {
          toast.error(unres.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.log("********** ", unres.data);
          history.push({
            pathname: "/confirm-order",
            search: `?orderId=${unres.data.orderId}&by=partner&client=unregister`,
          });
        }
      } else {
        let res = await CreateOrder(
          user.token,
          checkedClient.userName,
          props.company,
          props.quoteData,
          props.quoteData,
          props.minTrade,
          props.type,
          undefined,
          true,
          props.margin
        );
        if (res.error != null) {
          toast.error(res.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          history.push({
            pathname: "/confirm-order",
            search: `?orderId=${res.data.orderRequestId}&orderVersion=${res.data.version}&by=partner&client=register`,
          });
        }
      }
    }
  };
  return (
    <>
      <div className="reg__container">
        <div className="reg__upper">
          <p className="reg__header">
            Send a payment link to your clients to transfer funds via UPI
            netbanking/NEFT/IMPS/RTGS.
          </p>
          <p className="reg__header__border"></p>

          <p className="reg__input1__heading">Client's email ID</p>

          <div className="reg__input1">
            {clients === null ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "70%",
                  }}
                >
                  <Progress size="2rem" />
                </div>
              </>
            ) : (
              <>
                <Autocomplete
                  // disablePortal={true}
                  inputValue={email}
                  style={{ width: "65%" }}
                  options={clients}
                  getOptionLabel={(option) => option?.emailId}
                  onChange={(e, n) => {
                    if (n?.emailId != null) {
                      enteringEmail(n?.emailId);
                    } else {
                      enteringEmail("");
                    }
                  }}
                  // debug
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => enteringEmail(e.target.value)}
                      id="outlined-basic"
                      placeholder="johndeo@gmail.com"
                      variant="standard"
                    />
                  )}
                />
              </>
            )}

            <button className="reg__input1__btn" onClick={checkUser}>
              DONE
            </button>
          </div>

          {checkedClient != null ? (
            <p className="extraline">
              Valid Email ID. Place and share order link with the client
            </p>
          ) : null}
        </div>

        <div className="reg__lowerr">
          <Button
            click={create}
            text="SEND LINK"
            style={
              checkedClient === null
                ? { background: "lightgrey", pointerEvents: "none" }
                : null
            }
          />
        </div>
      </div>
    </>
  );
};

export default PI_Registration;
