import React, { useState } from "react";
import "./ConfirmBox.css";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const ConfirmBox = ({ text, action, closeFunc }) => {
  const [show, setShow] = useState(true);
  return (
    <div
      className="confirm__box_backdrop"
      style={!show ? { display: "none" } : null}
    >
      <div className="confirm__box_dialog">
        <HelpOutlineIcon className="confirm__box_icon" />
        <p className="confirm__box_text">
          {text ? text : "Are you sure you want to permoform this action?"}
        </p>
        <div className="confirm__box_btns">
          <div
            className="cbb__yes"
            onClick={async () => {
              action();
              closeFunc();
            }}
          >
            Yes
          </div>
          <div
            className="cbb__no"
            onClick={() => {
              closeFunc();
            }}
          >
            No
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBox;
