import React, { useState, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";

import { makeStyles, Button, TextField, FormLabel } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Progress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";

import CurrencyInput from "react-currency-input-field";

import { ToastContainer, toast } from "react-toastify";

import BottomSlide from "../../components/bottom-slide/BottomSlide";
import Reward from "../../Assets/imgs/reward.svg";
import Check from "../../Assets/imgs/check.svg";
import "./Funds.scss";

import AddBank from "../AddBank/Bankinfo-popup/BankMain";

import {
  getFunds,
  getBanks,
  getVpa,
  addNet,
  cashFreeAccept,
  addNetConfirm,
  withDrawFunds,
} from "../../api/FundsAPI";
import { profile_details } from "../../api/profile";
import { useSelector } from "react-redux";
import { addNetFunds } from "./addFunds";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  btn1: {
    width: "100%",
    backgroundColor: "#2778ff",
    color: "white",
    padding: ".5rem 0",
    fontSize: "1.1rem",
    borderRadius: "8px",
  },
  btn2: {
    width: "100%",
    backgroundColor: "#ecf6ff",
    color: "#2a6eff",
    padding: ".5rem 0",
    fontSize: "1.1rem",
    borderRadius: "8px",
  },
}));


const Success = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <div className="success_container">
        <img src={Check} alt="ERROR" />
        <div className="message">
          {props.wsucc ? (
            <>
              <div
                className="center"
                style={{ textAlign: "center", fontSize: ".6rem" }}
              >
                <p>Your withdrawal request has been successfully placed.</p>
                <p>
                  Amount will be credited to your selected account in 2-3
                  working days.
                </p>
              </div>
            </>
          ) : (
            "Funds have been added successfully"
          )}
        </div>
        <Button className={classes.btn1} onClick={() => history.go("/funds")}>
          DONE
        </Button>
      </div>
    </>
  );
};

const AddAmountStep = (props) => {
  const classes = useStyles();
  const [ammount, setAmmount] = useState(Math.ceil(props?.amount) || "");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [haveRemovedBank, setHaveRemovedBank] = useState(false);
  const [removedBankAlter, setRemovedBankAlter] = useState("");
  const [banks, setBanks] = useState([]);

  const getBanksData = async () => {
    const { data, error } = await getBanks(props?.user?.token);
    if (data) {
      const removeBanks = ["HDFC bank"]; // we can add or remove the banks as our requirements
      console.clear();
      const userBanks = data.filter(
        (bank) =>
        !removeBanks
        .map((i) => i.toLocaleLowerCase())
        .includes(bank?.bankName?.toLocaleLowerCase())
        ); //intersection of data between removed banks and users banks
        console.log(data,userBanks);
      setBanks(userBanks);
      setIsLoading(false);
      if (userBanks.length === 0) {
        setRemovedBankAlter(
          "Net-banking payments are not supported for HDFC bank. Kindly use UPI or Offline NEFT/RTGS to make payment."
        );
        props.setType("upi");
      } else
        setRemovedBankAlter(
          "Net-banking payments are not supported for HDFC bank. Kindly select UPI or Offline NEFT/RTGS to make payment from HDFC bank."
        );
      setHaveRemovedBank(
        data
          .map((bank) =>
            removeBanks
              .map((i) => i.toLocaleLowerCase())
              .includes(bank.bankName.toLocaleLowerCase())
          )
          .includes(true)
      );
    } else if (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const selectType = (event) => {
    props.setType(event.target.value);
    console.log(event.target.value);
  };


  const sendDataToNext = () => {
    if (ammount < 1 || ammount == undefined) {
      setError("Minimum Amout is ₹1");
    } else {
      if (props.type == "net" && ammount > 2500000) {
        setError("Max amount for NetBanking ₹25,00,000");
      } else if (props.type == "upi" && ammount > 100000) {
        setError("Max Amount For UPI ₹1,00,000");
      } else {
        props.data((preValue) => {
          return {
            ...preValue,
            ammount: ammount,
            transferType: props.type,
          };
        });
        props.next("selectbank");
      }
    }
  };


  useEffect(() => {
    getBanksData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Progress />
        </div>
      ):(
        <form
        onSubmit={(e) => {
          e.preventDefault();
          sendDataToNext();
        }}
      >
        <div className="add_funds_slider">
          <div className="heading">Add Funds</div>
          <div className="title"> Amount (Rs.) </div>
          <div className="amount">
            {ammount && (
              <div className="amount_display">{ammount?.toLocaleString('hi-IN', { style: 'currency', currency: 'INR' })}</div>
              )}
            {error && (
              <div style={{ fontSize: ".75rem", color: "red" }} className="error">
                {error}
              </div>
            )}
            {haveRemovedBank && (
                <div className="hdfc-alert">{removedBankAlter}</div>
              )}
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={props.type}
                onChange={selectType}
              >
               {banks.length > 0 && <FormControlLabel
                  value="net"
                  control={<Radio />}
                  label="Net-banking"
                />}
                {ammount < 200000 && (
                  <FormControlLabel value="upi" control={<Radio />} label="UPI" />
                )}
  
                <FormControlLabel
                  value="offline"
                  control={<Radio />}
                  label="Offline NEFT/RTGS"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <Button className={classes.btn1} type="submit">
            ADD FUNDS
          </Button>
        </div>
      </form>
      )

    }
    </>
    
  );
};

const EnterUPIID = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [banks, setBanks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [netBankingCode, setnetBankingCode] = useState(0);
  const [upiid, setupiid] = useState("");
  const [isUPIidValid, setUPIisValid] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  // const [razorError, setRazorError] = useState(null)

  let { net } = props;
  let bank;

  useEffect(async () => {
    let data = await getBanks(props.user.token);
    if (data.error != null) {
      toast.error(data.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setBanks(data.data);
    }
  }, []);

  const addNetFundsClick = async () => {
    setProcessingPayment(true);
    try {
      const reasons = [
        {
          reason: "CUMULATIVE_SHORTFALL",
          id: null,
          amount: props?.shortFall,
        },
      ];
      await addNetFunds(
        setProcessingPayment,
        props,
        history,
        banks,
        props.bankIndex,
        bank,
        net,
        setnetBankingCode,
        null,
        reasons
      );
    } catch (error) {
      console.log(error);
      setProcessingPayment(false);
    }

    function changeTextCashFree() {
      setTimeout(() => {
        let cashfreeLoader = document.getElementById("cf-loader");
        if (cashfreeLoader == null) {
          changeTextCashFree();
        } else {
          cashfreeLoader.getElementsByTagName("p")[0].innerText =
            "Payment request has been sent to your UPI app.\n Please check the app";
        }
      }, 100);
    }
    changeTextCashFree();
  };
  return (
    <>
      <div className="add_bank_slide" style={{ gridGap: "0rem", gap: "0rem" }}>
        <div className="heading">
          <p> Add Funds </p>
        </div>
        <div className="detail">
          <p style={{ marginTop: "0.7rem", marginBottom: "0.7rem" }}>
            Enter UPI ID
          </p>
          <p>UPI ID entered should be linked to</p>
          <p>Bank Account No.: {props.selectedBankData?.bankAccountNumber}</p>
          <p>IFSC: {props.selectedBankData?.ifsc}</p>
        </div>
        <div id="pay-collect">
          <TextField
            style={{ width: "100%", marginBottom: "0.4rem", marginTop: "2rem" }}
            label="Enter UPI ID"
            type="text"
            variant="standard"
            value={upiid}
            onChange={(e) => {
              setupiid(e.target.value);
              let upiValidCheck = new RegExp(
                "[a-zA-Z0-9\\.\\-]{2,256}\\@[a-zA-Z][a-zA-Z]{2,64}"
              );
              if (!upiValidCheck.test(e.target.value)) {
                setUPIisValid(false);
              } else {
                setUPIisValid(true);
              }
            }}
            inputProps={{ "data-upi-id": true }}
          />
          {/* <input type="text" value="testsuccess@gocash" data-upi-id/> */}
          <div style={{ marginBottom: "4rem" }}>
            {!isUPIidValid && upiid.length !== 0 && (
              <div
                style={{ fontSize: ".75rem", color: "red" }}
                className="error"
              >
                Please enter valid UPI ID
              </div>
            )}
          </div>
          <Button
            id="pay-collect-btn"
            className={classes.btn1}
            onClick={addNetFundsClick}
            disabled={!isUPIidValid}
          >
            CONTINUE
          </Button>
        </div>
        {processingPayment && (
          <div className="backdrop">
            <Progress />
          </div>
        )}
      </div>
    </>
  );
};

const SelectBankStep = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [banks, setBanks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCashFree, setLoadingCashFree] = useState(false);
  const [netBankingCode, setnetBankingCode] = useState(0);
  // const [razorError, setRazorError] = useState(null)

  let net = props.net;
  let bank;
  let razorError = null;

  useEffect(async () => {
    let data = await getBanks(props.user.token);
    const removeBanks = ["HDFC bank"]; 
    if (data.error != null) {
      toast.error(data.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setBanks( props.type==="net"?data.data.filter(
        (bank) =>
          !removeBanks
            .map((i) => i.toLocaleLowerCase())
            .includes(bank?.bankName?.toLocaleLowerCase())
      ):data.data);
    }
  }, []);

  const selectBank = (event) => {
    props.setBankIndex(event.target.value);
    console.log(event.target.value);
    props.setSelectedBankData(banks[event.target.value].bankAccountId);
  };

  const addNetFundsClick = async () => {
    setLoadingCashFree(true);

    //create reason array with cumulative shortfall only
    if (props.type == "net") {
      const reason = [
        {
          reason: "CUMULATIVE_SHORTFALL",
          id: null,
          amount: props?.shortFall,
        },
      ];
      await addNetFunds(
        setLoading,
        props,
        history,
        banks,
        props.bankIndex,
        bank,
        net,
        setnetBankingCode,
        null, // !token was used in the function definition
        reason
      );
    } else {
      props.next("enterupi");
    }
    // console.log(banks, bank);
  };

  const sendDatatoNext = () => {
    if (banks.length < 1) {
      toast.warn("Please add bank first", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      props.data((preValue) => {
        return {
          ...preValue,
          bank: banks[props.bankIndex],
        };
      });
      props.next("fundadded");
    }
    console.log("continue");
  };

  const openAddBank = () => {
    props.closeSlider({
      open: false,
      div: "addfund",
    });
    props.openAddBankSlide(true);
  };

  useEffect(() => {
    if (banks != null && banks[0] != null) {
      props.setSelectedBankData(banks[0].bankAccountId);
    }
  }, [banks]);

  return (
    <>
      {loadingCashFree && (
        <div className="backdrop">
          <Progress />
        </div>
      )}
      <div id="pay-nb">
        <input
          type="text"
          value={netBankingCode}
          onChange={({ target }) => setnetBankingCode(target?.value)}
          data-netbanking-code
          style={{ display: "none" }}
        />
      </div>
      <div className="add_bank_slide">
        <div className="heading">
          <p> Add Funds </p>
          <div className="detail">
            Select the bank account from where you wish to remit funds via
            NEFT/RTGS
          </div>
        </div>
        <div className="select_bank">
          {!banks ? (
            <Progress style={{ margin: "auto" }} />
          ) : (
            <>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={`${props.bankIndex}`}
                  onChange={selectBank}
                >
                  {banks.map((data, index) => (
                    <FormControlLabel
                      value={`${index}`}
                      key={index}
                      control={<Radio />}
                      label={
                        <div className="bank_container">
                          <div className="name">{data.bankName}</div>
                          <div className="accnumber">
                            xxxx - xxxx - xxxx -{" "}
                            {data?.bankAccountId?.bankAccountNumber?.slice(-4)}
                          </div>
                          <div className="address">{data?.branchName}</div>
                        </div>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </>
          )}
          <div className="add_bank" onClick={openAddBank}>
            <span> + </span> Add Bank
          </div>
        </div>
        {net?.transferType == "offline" ? (
          <Button
            className={classes.btn1}
            onClick={sendDatatoNext}
            disabled={banks == null}
          >
            CONTINUE
          </Button>
        ) : (
          <Button
            className={classes.btn1}
            onClick={addNetFundsClick}
            disabled={banks == null}
          >
            {loading ? <Progress /> : "CONTINUE"}
          </Button>
        )}
      </div>
    </>
  );
};

// ! why functions names are same?
const WithdrawFunds = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [banks, setBanks] = useState(null);
  const [amount, setAmount] = useState(null);
  const [showBanks, setShowBanks] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [succStep, setSuccStep] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(null);
  const [value, setValue] = useState(0);
  const Changeradio = (event) => {
    setValue(event.target.value);
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  const enteringAmmount = (event) => {
    // if (/[^0-9]+/.test(event.target.value)) {
    //   setAmount(event.target.value.replace(/[^0-9]*/g, ""))
    //   setError(null)
    // } else {
    //   setAmount(event.target.value)
    //   setError(null)
    // }
    setAmount(event);
    setError(null);
  };
  const withdraw = async () => {
    if (withdrawAmount < 1 || withdrawAmount == undefined) {
      setError("Minimum Amount is ₹1");
    } else {
      console.log(withdrawAmount);
      setLoading(true);
      const res = await withDrawFunds(
        props.user.token,
        withdrawAmount?.amount,
        banks[value],
        [
          {
            id: null,
            reason: withdrawAmount?.type,
            amount: withdrawAmount?.amount,
          },
        ]
      );
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        setWithdrawAmount(null);
        setOpenConfirm(false);
      } else {
        toast.info("Withdraw Created Successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSuccStep(true);
      }
    }
  };

  useEffect(async () => {
    const res = await getBanks(props.user.token);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setBanks(res.data);
    }
  }, []);

  const openAddBank = () => {
    props.closeSlider({
      open: false,
      div: "addfund",
    });
    props.openAddBankSlide(true);
  };

  const ConfirmWithdraw = () => {
    return (
      <>
        <div className="confirmwithdraw_box">
          <div className="heading">
            <div> Funds Withdraw </div>
            <p>
              
              Funds will be credited to your selected bank account in 2-3
              business days.
              <br></br>Are you sure you want to place the withdrawal request?
            </p>
          </div>
          <div className="btn">
            {/* <button onClick={withdraw}> YES </button> */}
            <Button onClick={withdraw} className={classes.btn1}>
              {loading == true ? <Progress /> : "YES"}
            </Button>
          </div>
        </div>
      </>
    );
  };

  const cWithdraw = () => {
    if (banks.length < 1) {
      toast.warning("Please add bank first", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (withdrawAmount < 1 || withdrawAmount == undefined) {
        setError("Minimum Amount is ₹1");
      } else {
        setOpenConfirm(true);
      }
    }
  };
  return (
    <>
      {succStep == true ? (
        <>
          <Success wsucc />
        </>
      ) : (
        <>
          <div className="withdraw_funds_slider">
            <div className="heading">Withdraw Funds</div>
            {!showBanks ? (
              <>
                {props?.funds?.returnedAmount +
                  props?.funds?.compensationAmount +
                  props?.funds?.payoutAmount ===
                  0 && props?.funds?.free < 1 ? (
                  <Alert severity="error">
                    You don't have enough funds to withdraw.
                  </Alert>
                ) : (
                  <FormControl>
                    <FormLabel component="legend">
                      {withdrawAmount
                        ? `You selected ${
                            withdrawAmount?.type === "WITHDRAW_RETURNED"
                              ? "Non-margin funds"
                              : "Entire withdrawable funds"
                          } amount which is ${withdrawAmount?.amount}`
                        : "Choose Your Amount to withdraw"}
                    </FormLabel>
                    <RadioGroup value={withdrawAmount?.amount}>
                      {props?.funds?.returnedAmount +
                        props?.funds?.compensationAmount +
                        props?.funds?.payoutAmount >
                        0 && (
                        <FormControlLabel
                          value={
                            props?.funds?.returnedAmount +
                            props?.funds?.compensationAmount +
                            props?.funds?.payoutAmount
                          }
                          control={
                            <Radio
                              checked={
                                withdrawAmount?.type === "WITHDRAW_RETURNED"
                              }
                              value={
                                props?.funds?.returnedAmount +
                                props?.funds?.compensationAmount +
                                props?.funds?.payoutAmount
                              }
                              onChange={({ target }) =>
                                setWithdrawAmount({
                                  type: "WITHDRAW_RETURNED",
                                  amount: target.value,
                                })
                              }
                            />
                          }
                          label={
                            <>
                              Non-margin funds{" "}
                              <b>
                                {props?.funds?.returnedAmount +
                                  props?.funds?.compensationAmount +
                                  props?.funds?.payoutAmount}{" "}
                                Rs
                              </b>
                            </>
                          }
                        />
                      )}
                      {props?.funds?.free > 0 && (
                        <FormControlLabel
                          value={props?.funds?.free}
                          control={
                            <Radio
                              checked={withdrawAmount?.type === "WITHDRAW_ALL"}
                              value={props?.funds?.free}
                              onChange={({ target }) =>
                                setWithdrawAmount({
                                  type: "WITHDRAW_ALL",
                                  amount: target.value,
                                })
                              }
                            />
                          }
                          label={
                            <>
                              Entire withdrawable funds{" "}
                              <b>{props?.funds?.free} Rs </b>
                            </>
                          }
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                )}
                <Button
                  disabled={
                    props?.funds?.returnedAmount +
                      props?.funds?.compensationAmount +
                      props?.funds?.payoutAmount ===
                      0 && props?.funds?.free === 0
                      ? false
                      : !withdrawAmount
                  }
                  onClick={
                    props?.funds?.returnedAmount +
                      props?.funds?.compensationAmount +
                      props?.funds?.payoutAmount ===
                      0 && props?.funds?.free === 0
                      ? () =>
                          props?.closeSlider({ open: false, div: "withdraw" })
                      : () => setShowBanks(true)
                  }
                  className={classes.btn1}
                >
                  {props?.funds?.returnedAmount +
                    props?.funds?.compensationAmount +
                    props?.funds?.payoutAmount ===
                    0 && props?.funds?.free === 0
                    ? "Close"
                    : "Select Bank"}
                </Button>
              </>
            ) : (
              <>
                <div className="title"> Amount (Rs.) </div>
                <div className="amount">
                  <CurrencyInput
                    allowNegativeValue={false}
                    allowDecimals={false}
                    placeholder="Enter Amount"
                    value={withdrawAmount?.amount || 0}
                    readOnly={true}
                  />
                  {error && (
                    <div
                      style={{ fontSize: ".75rem", color: "red" }}
                      className="error"
                    >
                      {error}
                    </div>
                  )}
                </div>
                <div className="detail">
                  Select the bank a/c where you want us to remit the withdrawn
                  funds:
                </div>
                <div className="select_bank">
                  {banks == null ? (
                    <Progress style={{ margin: "auto" }} />
                  ) : (
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={`${value}`}
                        onChange={Changeradio}
                      >
                        {banks.map((val, index) => {
                          return (
                            <FormControlLabel
                              kry={index}
                              value={`${index}`}
                              control={<Radio />}
                              label={
                                <>
                                  <div className="bank_container">
                                    <div className="name">{val.bankName}</div>
                                    <div className="accnumber">
                                      xxxx - xxxx - xxxx -{" "}
                                      {val.bankAccountId.bankAccountNumber?.slice(
                                        -4
                                      )}
                                    </div>
                                    <div className="address">
                                      {val.branchName}
                                    </div>
                                  </div>
                                </>
                              }
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  )}

                  <div className="add_bank" onClick={openAddBank}>
                    <span> + </span> Add Bank
                  </div>
                </div>
                <Button
                  disabled={!withdrawAmount}
                  onClick={cWithdraw}
                  className={classes.btn1}
                >
                  Place Request
                </Button>
              </>
            )}

            {/* <Button
              disabled={!withdrawAmount}
              onClick={
                props?.funds?.returnedAmount +
                  props?.funds?.compensationAmount +
                  props?.funds?.payoutAmount !==
                  0 && props?.funds?.free !== 0
                  ? () => console.log("close")
                  : cWithdraw
              }
              className={classes.btn1}
            >
              {props?.funds?.returnedAmount +
                props?.funds?.compensationAmount +
                props?.funds?.payoutAmount ===
                0 && props?.funds?.free === 0
                ? "Close"
                : "Add Bank"}
            </Button> */}
          </div>
          {/* ! what does this code do? */}
          {openConfirm && (
            <BottomSlide bottomSlide={() => setOpenConfirm(false)}>
              <ConfirmWithdraw />
            </BottomSlide>
          )}
        </>
      )}
    </>
  );
};

const Funds = () => {
  const classes = useStyles();
  const history = useHistory();

  const UserData = useSelector((state) => state.user);

  const [profileData, setprofileData] = useState(null);
  const [MyFunds, setMyFunds] = useState(null);

  const [expanded, setExpanded] = useState(false);
  const [presentStep, setPresentStep] = useState("addfund");
  const [openSlide, setOpenSlide] = useState({
    open: false,
    div: null,
  });

  const [processingData, setProcessingData] = useState({
    ammount: null,
    transferType: null,
    bank: null,
  });

  const [openAddBank, setOpenAddBank] = useState(false);
  const [addBankstep, setAddBankStep] = useState("step1");
  const [type, setType] = useState("net");
  const [selectedBankData, setSelectedBankData] = useState(null);
  const [bankIndex, setBankIndex] = useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const opentheSlide = (props) => {
    setOpenSlide({
      open: true,
      div: props,
    });
    setPresentStep("addfund");
  };

  const addBankSucc = () => {
    toast.success("Bank added Success", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CompleteProfileTostify = () => {
    return (
      <>
        <div style={{ fontSize: "small" }}>
          Complete Your Profile First.
          <NavLink style={{ color: "yellow" }} to="/profile">
            Go to profile
          </NavLink>
        </div>
      </>
    );
  };

  const toogleopen = (data) => {
    if (data == "go") {
      if (
        profileData?.mobileNumber <= 4 ||
        profileData.mobileVerified == false ||
        profileData.aadhaarVerified == false ||
        profileData.panVerified == false
      ) {
        toast.error(CompleteProfileTostify, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        history.push("/funds/transfer");
      }
    } else {
      if (
        profileData?.mobileNumber <= 4 ||
        profileData.mobileVerified == false ||
        profileData.aadhaarVerified == false ||
        profileData.panVerified == false
      ) {
        toast.error(CompleteProfileTostify, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        opentheSlide(data);
      }
    }
  };
  const nextStep = (props) => {
    setPresentStep(props);
  };

  useEffect(async () => {
    let res = await profile_details(UserData.token);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setprofileData(res.data?.data);
    }
    let data = await getFunds(UserData.token);
    if (data.error != null) {
      toast.error(data.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setMyFunds(data.data);
    }
  }, []);

  const FundsData = UserData && [
    {
      balance: MyFunds && MyFunds?.freeOrderMargin,
      detail: "Free Order Margin",
      tooltip: "Funds available to be used as margin for new or update orders",
    },
    {
      balance: MyFunds && MyFunds.blocked,
      detail: "Blocked Funds",
      tooltip:
        "Funds which are blocked for certain purpose. These funds cannot be withdrawn or be used as margin for new orders",
      data: [
        {
          balance: MyFunds && MyFunds.tradeBlockedMargin,
          detail: "Blocked as margins for orders",
          tooltip:
            "Margin blocked for orders. This margin is refunded in case order expires. Margin for a specific obligation will be seized in case the obligation is not met.",
        },
        {
          balance: MyFunds && MyFunds.openOrderBlockedMargin,
          detail: "Blocked as margins orders which are currently being signed",
          tooltip:
            "Margins blocked for orders which are currently being signed",
        },
        {
          balance: MyFunds && MyFunds.tradeBlockedSettlementAmount,
          detail: "Blocked amount for ongoing obligations",
          tooltip:
            "Amount blocked for obligations. This is the amount you have transferred to fulfill your obligation. If you fail to fulfill your obligation this amount is refunded back, only margin amount is seized.",
        },
        {
          balance: MyFunds && MyFunds.pendingWithdrawalBlocked,
          detail: "Blocked as amount pending withdrawal",
          tooltip:
            "Amount blocked as per user's withdrawal process. This the amount which has not been paid out yet to client yet.",
        },
      ],
    },
    {
      balance: MyFunds && MyFunds.free,
      detail: "Withdrawable balance",
      tooltip: "Total funds that can be withdrawn.",
      data: [
        {
          balance: MyFunds && MyFunds?.freeOrderMargin,
          detail: "Free order margin ",
          tooltip:
            "Funds available to be used as margin for new or update orders.",
        },
        {
          balance: MyFunds && MyFunds?.returnedAmount,
          detail: "Refunded funds",
          tooltip:
            "These include margins refunded in case an order expires or order is cancelled, or funds refunded incase of failed closures.",
        },
        {
          balance: MyFunds && MyFunds?.compensationAmount,
          detail: "Compensation amount",
          tooltip:
            "Funds paid to you as compensation. These are paid out when you have fulfilled your obligation but there was a failed closure.",
        },
        (UserData?.profile?.userType == "INDIVIDUAL_PARTNER" ||
          UserData?.profile?.userType == "ORGANISATIONAL_PARTNER") && {
          balance: MyFunds && MyFunds?.payoutAmount,
          detail: "Partner Payouts",
          tooltip:
            "Funds paid out to you as partner payouts that are yet to be withdrawn by you. Check the “My Earnings” tab for a detailed breakdown.",
        },
      ],
    },
    {
      balance: MyFunds && MyFunds?.total,
      detail: "Total Funds",
      tooltip: "Total funds available.",
    },
    {
      img: Reward,
      balance: "0",
      detail: "Rewards",
      tooltip: "",
    },
  ];

  const FundsAddedStep = () => {
    const [vpaData, setVpaData] = useState(null);

    useEffect(async () => {
      if (processingData.bank != null) {
          let data = await getVpa(UserData.token, processingData.bank);
          if (data.error != null) {
            toast.error(data.error, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setOpenSlide(false);
          } else {
            setVpaData(data.data);
          }
      } else {
        console.log("PROCESSING_DATA MISSING", processingData);
      }
    }, []);

    return (
      <>
        <div className="funds_added_slide">
          <div className="heading">
            <p> Add Funds </p>
            <div className="detail">
              Please remit funds to the below mentioned account
            </div>
          </div>

          {vpaData == null ? (
            <>
              <div className="progress">
                <Progress className="bar" />
              </div>
            </>
          ) : (
            <>
              <div className="transection_detail">
                <div className="one_div">
                  <div className="beneficary">
                    <p className="title"> Beneficiary </p>
                    <div> {vpaData.accountHolderName} </div>
                  </div>
                  <div className="ifsc">
                    <p className="title"> IFSC </p>
                    <div> {vpaData.bankAccountId.ifsc} </div>
                  </div>
                  <div className="account">
                    <p className="title"> Account </p>
                    <div> {vpaData.bankAccountId.bankAccountNumber} </div>
                  </div>
                </div>
                <div className="two_div" style={{ opacity: "-10" }}>
                  <div className="bank">
                    <p className="title"> Bank </p>
                    <div> Static American Express </div>
                  </div>
                  <div className="branch" style={{ opacity: "-10" }}>
                    <p className="title"> Branch </p>
                    <div> Horniman Circle </div>
                  </div>
                  <div className="important">
                    <div className="important_title">
                      <p> IMPORTANT </p>
                    </div>
                    <div className="important_data">
                      <p className="detail">
                        This account number is specifically for you, it will be
                        different for other users.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="note_box">
                Once you've remitted the funds, please wait until the funds
                reach our bank account. We will intimate you via sms / email as
                soon as we receive the funds & the same shall be added to
                available funds in the Funds Tab
              </div>
              <div className="notis">
                <div className="important_title">
                  <p> IMPORTANT </p>
                </div>
                <div className="important_data">
                  <p className="detail">
                    Please note that the remittance to the above account should
                    only be made from the below mentioned account that you've
                    selected:
                  </p>
                  <div className="bank_container">
                    <div className="name">{processingData.bank.bankName}</div>
                    <div className="accnumber">
                      xxxx - xxxx - xxxx -
                      {processingData.bank.bankAccountId.bankAccountNumber.substr(
                        processingData.bank.bankAccountId.bankAccountNumber
                          .length - 4,
                        4
                      )}
                    </div>
                    <div className="address">
                      {processingData.bank.branchName}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const AddFundsStepper = (active) => {
    switch (active) {
      case "addfund":
        return (
          <AddAmountStep
            type={type}
            setType={setType}
            user={UserData}
            // amount={200}
            amount={MyFunds?.settlementShortFall}
            next={nextStep}
            data={setProcessingData}
          />
        );
      case "selectbank":
        return (
          <SelectBankStep
            setBankIndex={setBankIndex}
            bankIndex={bankIndex}
            setSelectedBankData={setSelectedBankData}
            type={type}
            setType={setType}
            next={nextStep}
            user={UserData}
            data={setProcessingData}
            net={processingData}
            shortFall={MyFunds?.settlementShortFall}
            closeSlider={setOpenSlide}
            openAddBankSlide={setOpenAddBank}
          />
        );
      case "enterupi":
        return (
          <EnterUPIID
            bankIndex={bankIndex}
            selectedBankData={selectedBankData}
            type={type}
            setType={setType}
            next={nextStep}
            user={UserData}
            shortFall={MyFunds?.settlementShortFall}
            data={setProcessingData}
            net={processingData}
          />
        );
      case "fundadded":
        return <FundsAddedStep />;
      case "succ":
        return <Success />;
      default:
        return <AddAmountStep type={type} setType={setType} />;
    }
  };

  return (
    <>
      {MyFunds == null ? (
        <>
          <div className="progress">
            <Progress className="bar" />
          </div>
        </>
      ) : (
        <>
          <div className="funds_container">
            <div className="funds_box">
              <div className="funds_title">
                <p className="title">Funds</p>
                <p className="dec">
                  <a
                    href="https://fundsmap.freshdesk.com/a/solutions/articles/84000352181"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here
                  </a>
                  to understand how fund balances are represented.
                </p>
              </div>
              {MyFunds.settlementShortFall == 0 ? null : (
                <>
                  <div className="important">
                    <div className="important_title">
                      <p> IMPORTANT </p>
                    </div>
                    <div className="important_data">
                      <p className="detail">
                        You've a shortfall of Rs. {MyFunds.settlementShortFall}
                        for an ongoing deal closure. Please add funds to ensure
                        deal closure.
                      </p>
                      <NavLink to="/allorders"> View Closure Details </NavLink>
                    </div>
                  </div>
                </>
              )}

              <div className="funds_data">
                {FundsData.map((data, index) => {
                  return (
                    <Accordion
                      key={index}
                      expanded={expanded === `panel${index}`}
                      onChange={
                        data.data ? handleChange(`panel${index}`) : null
                      }
                    >
                      <AccordionSummary
                        expandIcon={data.data ? <ExpandMoreIcon /> : null}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <div className="balance_box">
                          {data.img ? (
                            <>
                              <div className="img">
                                <img src={data.img} alt="ERROR" />
                              </div>
                            </>
                          ) : null}
                          <div className="balance_data">
                            <div className="balance">
                              <p className="cur"> ₹ </p>
                              <p>{data.balance}</p>
                            </div>
                            <div className="detail" style={{ display: "flex" }}>
                              {data.detail}
                              {data.tooltip && (
                                <Tooltip title={data.tooltip}>
                                  <Info
                                    style={{
                                      height: "1rem",
                                      width: "1rem",
                                      marginLeft: "0.5rem",
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {data?.data?.map((subData, sunIndex) => {
                          if (subData)
                            return (
                              <div
                                key={`1${sunIndex}`}
                                className="balance_data"
                              >
                                <div className="balance">
                                  <p> ₹ </p>
                                  <p>{subData.balance}</p>
                                </div>
                                <div
                                  className="detail"
                                  style={{ display: "flex" }}
                                >
                                  {subData.detail}
                                  <Tooltip title={subData.tooltip}>
                                    <Info
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                        marginLeft: "0.5rem",
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
              <div className="funds_transfer">
                {MyFunds?.settlementShortFall ? (
                  <div className="add_funds">
                    <Button
                      className={classes.btn1}
                      onClick={() => toogleopen("add")}
                    >
                      ADD FUNDS
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                <div className="withdraw_funds">
                  <Button
                    className={classes.btn2}
                    onClick={() => toogleopen("withdraw")}
                  >
                    WITHDRAW FUNDS
                  </Button>
                </div>
                <div className="view_funds">
                  <Button
                    className={classes.btn2}
                    onClick={() => toogleopen("go")}
                  >
                    VIEW TRANSFERS
                  </Button>
                </div>
              </div>
            </div>
            {openSlide.open && (
              <BottomSlide bottomSlide={() => setOpenSlide(false)}>
                {openSlide.div == "add" ? (
                  AddFundsStepper(presentStep)
                ) : (
                  <>
                    <WithdrawFunds
                      next={nextStep}
                      user={UserData}
                      funds={MyFunds}
                      closeSlider={setOpenSlide}
                      openAddBankSlide={setOpenAddBank}
                    />
                  </>
                )}
              </BottomSlide>
            )}
            {openAddBank && (
              <AddBank
                bank={openAddBank}
                setBank={setOpenAddBank}
                step={addBankstep}
                setStep={setAddBankStep}
                get_banks1={addBankSucc}
                profileData={UserData.profile}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Funds;
