// /api/v1/fills/views/dashboard/active
import axios from "../axiosInstance";

const getActiveTrades = async ({ token, login }) => {
  let resolved = {
    data: null,
    error: null,
  };
  let loggedIn = login == null ? "false" : "true";

  try {
    const res = await axios({
      url: `/api/v1/fills/views/dashboard/active`,
      method: "GET",
      headers: {
        Authorization: loggedIn == "true" ? `Bearer ${token}` : null,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

export { getActiveTrades };
