import React, { useState, useEffect } from "react";
import Backarrow from "../../../components/backarrow/Backarrow";
import Button from "../../../components/button/Button";
import "./MailOtp.scss";
import { useHistory, useParams } from "react-router-dom";
import { verify_partner_otp, generate_partner_otp } from "../../../api/auth";
import { toast } from "react-toastify";

const MailOtp = () => {
  const { email } = useParams();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [resend, setResend] = useState(false);
  const [secs, setSecs] = useState(15);
  const [resendOpt, setResendOpt] = useState(false);
  const history = useHistory();
  const token = localStorage.getItem("token");

  const handle_resend = async (type) => {
    setResendOpt(false);
    setResend(false);
    resendTimer();
    setSecs(30);

    const sent = await generate_partner_otp(token);
    if (sent.error) {
      toast.error(sent.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      history.push({ pathname: "/partner/mailotp/" + email });
    }
  };
  const resendTimer = () => {
    if (!resend) {
      setInterval(
        () =>
          setSecs((secs) => {
            if (secs > 0) {
              return secs - 1;
            } else {
              setResend(true);
              return 0;
            }
          }),
        1000
      );
    }
  };

  const otp_login = async () => {
    setLoading(true);
    let response = await verify_partner_otp(token, otp);
    if (response.error) {
      setLoading(false);
    } else {
      console.log("otp token data :", response.data.data);

      localStorage.setItem("user", JSON.stringify(response.data.data));

      history.push({ pathname: "/mobile-otp" });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!resend) {
      setInterval(
        () =>
          setSecs((secs) => {
            if (secs > 0) {
              return secs - 1;
            } else {
              setResend(true);
              return 0;
            }
          }),
        1000
      );
    }
  }, []);
  return (
    <div className="otp__screen">
      <Backarrow />
      <div className="opt__body">
        <h1 className="otp__heading">
          Enter <span>OTP</span> to Login
        </h1>

        <p className="otp__msg">
          Enter OTP sent to you via e-mail on your id {email}
        </p>

        <div className="otp-input">
          <input
            type="number"
            placeholder="Enter your OTP ( 6 digits )"
            value={otp}
            autoFocus
            onChange={(e) => setOtp(e.target.value.slice(0, 6))}
            disabled={loading}
          />
          <button
            className="paste-btn"
            onClick={otp_login}
            disabled={otp.length < 6 || loading}
          >
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="5" y1="12" x2="19" y2="12"></line>
              <polyline points="12 5 19 12 12 19"></polyline>
            </svg>
          </button>
        </div>
        {resend ? (
          <div className="resend__btn">
            {!resendOpt ? (
              <span onClick={handle_resend}>Re-send OTP</span>
            ) : (
              <span className="resend__otps">
                <span onClick={() => handle_resend("SMS")}>
                  Re-send via sms
                </span>
                -
                <span onClick={() => handle_resend("CALL")}>
                  Re-send via call
                </span>
              </span>
            )}
          </div>
        ) : (
          <div className="resend__text">Re-send OTP (After {secs}s)</div>
        )}
      </div>
      <div className="btn">
        <Button
          text="Sign In"
          click={otp_login}
          loading={loading}
          disabled={otp.length < 6}
        />
      </div>
    </div>
  );
};

export default MailOtp;
