import React, { useState, useEffect } from "react";
import "./SearchScreen.css";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchBox from "../search-box/SearchBox";
import SearchData from "../search-data/SearchData";
import { dashboard_search } from "../../../../api/dashboard";
import { useSelector } from "react-redux";
import ScreenLoader from "../../../../components/screen-loader/ScreenLoader";
import SearchNotFound from "../search-not-found/SearchNotFound";

const SearchScreen = ({ onClose }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [data, setData] = useState([]);
  const [screenLoading, setScreenLoading] = useState(false);
  const token = useSelector((state) => state.user.token);
  const get_data = async () => {
    setScreenLoading(true);
    let response = await dashboard_search(text, token);
    if (response.error) {
      setScreenLoading(false);
      //alert(response.error);
    } else {
      setData(response.data.data);
      setScreenLoading(false);
      console.log(response.data.data);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };
  useEffect(() => {
    handleOpen();
    get_data();
  }, []);
  useEffect(() => {
    handleOpen();
    get_data();
  }, [text]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="search__modal"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="search__results" onClick={(e) => e.stopPropagation()}>
          <ArrowBackIcon
            className="search__screen_backarrow"
            onClick={onClose}
          />
          {screenLoading && <ScreenLoader />}
          <SearchBox setText={setText} text={text} />
          {data?.length > 0 && (
            <div className="search__results_data">
              {data?.map((item) => (
                <SearchData
                  close={onClose}
                  legalName={item.legalName}
                  endDate={item.endDate}
                  aka={item.aka}
                  listingStage={item.listingStage}
                  sector={item.sector}
                  type={item.type}
                  symbol={item.symbol}
                  key={item.id}
                />
              ))}
            </div>
          )}
          {data?.length <= 0 && !screenLoading && <SearchNotFound />}
        </div>
      </Fade>
    </Modal>
  );
};

export default SearchScreen;
