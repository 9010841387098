import { kyc_failure, kyc_success } from "../../../../api/profile";
import { toast } from "react-toastify";
// import { Sync, TramRounded } from "@material-ui/icons";



let run_digio = async (data, onSucc, onError) => {
  console.log(data);
  // alert("jjj")
  const options = {
    environment: process.env.REACT_APP_PROD_STATUS == "false" ? process.env.REACT_APP_DEV_ENV : process.env.REACT_APP_PROD_ENV,
    logo: data.imageUrl,
    callback: (response) => {
      if (response.hasOwnProperty("error_code")) {
        console.log("digio error", response)
        toast.info("You can retry this signing again..", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        onError && onError()
      } else {
        console.log("DGIO SUCCESS", response);
        toast.success("Signing successful!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        onSucc(response)
      }
    },
  };



  var requestId = data.id;
  var identifier = data.customerIdentifier;
  var token_id = data.token;

    var digio = new window.Digio(options);
    digio.init();
    digio.submit(requestId, identifier, token_id);
};

export default run_digio;
