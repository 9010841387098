import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  dematDetails,
  fund_obligation,
  share_obligation,
} from "../../../../api/orderDetails";
import { toast } from "react-toastify";
import "./Obligation.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { TransferSecurities } from "../PopUps/PopUps";

const ObligationBox = ({ userData, trade, order, setDetail }) => {
  const history = useHistory();
  const [shareOb, setShareOb] = useState();
  const [fundOb, setFundOb] = useState();
  const [obData, setObData] = useState();
  const [isPartner, setIsPartner] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);
  const [dematDetail, setDemateDetails] = useState();
  const token = useSelector((state) => state.user.token);
  const shareObligation = async () => {
    let response = await share_obligation(token, trade.obligationId);
    if (response.error) {
      toast.error(response.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log("share obligation", response.data);
      setShareOb(response.data.data);
    }
  };

  const getDematDetails = async () => {
    let response = await dematDetails(
      token,
      shareOb.targetBoId,
      shareOb.targetDPId
    );
    if (response.error) {
      toast.error(response.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log("share obligation", response.data);
      setDemateDetails(response.data.data);
    }
  };

  const formatDateTime = (timestamp) => {
    let d = new Date(timestamp * 1000);
    var options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return d.toLocaleDateString("en-UK", options);
  };
  const fundsObligation = async () => {
    let response = await fund_obligation(token, trade.obligationId);
    if (response.error) {
      toast.error(response.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log("fund obligation", response.data);
      setFundOb(response.data.data);
    }
  };
  const obligation_text_bid = () => {
    console.log("trade:", trade);
    const { fillLifeCycleStatus, obligationStatus, closureType } = trade;
    if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "PENDING" &&
      closureType === null
    ) {
      return {
        heading: `Add funds: Rs. ${fundOb.shortfallAmount}`,
        actionRequired: true,
        noActionRequired: false,
        actionText: isPartner
          ? "SEND LINK TO CLIENT TO ADD FUNDS"
          : "ADD FUNDS",
        desc: `Last date & time for fulfilling obligation: ${formatDateTime(
          fundOb.expiryTimestamp
        )}`,
        link: "/funds",
      };
    } else if (
      fillLifeCycleStatus === "OBLIGATION_FULFILLED" &&
      obligationStatus === "FULFILLED" &&
      closureType === null
    ) {
      return {
        heading: `Obligation Fulfilled`,
        actionRequired: false,
        noActionRequired: true,
        desc: ``,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      obligationStatus === "FULFILLED" &&
      closureType === "SUCCESS"
    ) {
      return {
        heading: `Obligation Fulfilled`,
        actionRequired: false,
        noActionRequired: true,
        desc: ``,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      obligationStatus === "FULFILLED" &&
      closureType === "FAILURE"
    ) {
      return {
        heading: `Obligation Fulfilled`,
        actionRequired: false,
        noActionRequired: true,
        desc: ``,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      obligationStatus === "FULFILLED" &&
      closureType === "SUCCESS"
    ) {
      return {
        heading: `Obligation Fulfilled`,
        actionRequired: false,
        noActionRequired: true,
        desc: ``,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      obligationStatus === "FULFILLED" &&
      closureType === "FAILURE"
    ) {
      return {
        heading: `Obligation Fulfilled`,
        actionRequired: false,
        noActionRequired: true,
        desc: ``,
      };
    } else if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "PENDING_TIME_EXPIRED" &&
      closureType === null
    ) {
      return {
        heading: ``,
        actionRequired: false,
        noActionRequired: false,
        desc: `We did not recieve your ${
          isPartner ? "client's" : ""
        } funds until ${formatDateTime(
          fundOb.expiryTimestamp
        )}, we will withhold ${fundOb.marginAmount}`,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      (obligationStatus === "PENDING" ||
        obligationStatus === "PENDING_TIME_EXPIRED") &&
      closureType === "FAILURE"
    ) {
      return {
        heading: ``,
        actionRequired: false,
        noActionRequired: false,
        actionText: isPartner
          ? "SEND LINK TO CLIENT TO ADD FUNDS"
          : "ADD FUNDS",
        desc: `We did not recieve ${
          isPartner ? "your client's" : "your"
        } funds until ${formatDateTime(
          fundOb.expiryTimestamp
        )}, we will withhold your ${
          isPartner ? "your client's" : "your"
        } margin Rs. ${fundOb.marginAmount}`,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      (obligationStatus === "PENDING" ||
        obligationStatus === "PENDING_TIME_EXPIRED") &&
      closureType === "FAILURE"
    ) {
      return {
        heading: ``,
        actionRequired: false,
        noActionRequired: false,
        actionText: isPartner
          ? "SEND LINK TO CLIENT TO ADD FUNDS"
          : "ADD FUNDS",
        desc: `We did not recieve your ${
          isPartner ? "client's" : ""
        } funds until ${formatDateTime(
          fundOb.expiryTimestamp
        )}, we will withhold your ${isPartner ? "client's" : ""} margin Rs. ${
          fundOb.marginAmount
        }`,
      };
    } else if (
      (fillLifeCycleStatus === "CLOSURE_COMPLETE" ||
        fillLifeCycleStatus === "CLOSURE_CREATED") &&
      obligationStatus === "CLOSED_BEFORE_READY" &&
      closureType === "FAILURE"
    ) {
      return {
        heading: ``,
        actionRequired: false,
        noActionRequired: false,
        actionText: isPartner
          ? "SEND LINK TO CLIENT TO ADD FUNDS"
          : "ADD FUNDS",
        desc: `We could not fulfill your ${
          isPartner ? "client's" : ""
        } trade, as a result we will pay ${
          isPartner ? "your client" : "you"
        } penalty amount`,
      };
    }
  };
  const obligation_text_ask = () => {
    console.log("trade:", trade);
    const { fillLifeCycleStatus, obligationStatus, closureType } = trade;
    if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "PENDING" &&
      closureType === null
    ) {
      return {
        heading: `Transfer Securities: ${shareOb.qty} Qty`,
        actionRequired: true,
        noActionRequired: false,
        actionText: isPartner
          ? "ASK CLIENT TRANSFER SECURITIES"
          : "TRANSFER SECURITIES",
        desc: `Last date & time for fulfilling obligation: ${formatDateTime(
          shareOb.expiryTimestamp
        )}`,
      };
    } else if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "NEW" &&
      closureType === null
    ) {
      return {
        heading: `Transfer Securities: ${shareOb.qty} Qty`,
        actionRequired: true,
        actionText: "Prepare DIS",
        desc: `You can start preparing for the share transfer process by preparing the DIS. Share transfer window will open latest by: ${formatDateTime(
          shareOb?.obligationReadyStartTimeDeadline
        )}. You will be notified once this window is open, please transfer the shares then. You will need to complete the transfer before ${formatDateTime(
          shareOb?.transactionExpiryTime
        )}.`,
      };
    } else if (
      fillLifeCycleStatus === "OBLIGATION_FULFILLED" &&
      obligationStatus === "FULFILLED" &&
      closureType === null
    ) {
      return {
        heading: `Obligation Fulfilled`,
        actionRequired: false,
        noActionRequired: true,
        desc: ``,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      obligationStatus === "FULFILLED" &&
      closureType === "SUCCESS"
    ) {
      return {
        heading: `Obligation Fulfilled`,
        actionRequired: false,
        noActionRequired: true,
        desc: ``,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      obligationStatus === "FULFILLED" &&
      closureType === "FAILURE"
    ) {
      return {
        heading: `Obligation Fulfilled`,
        actionRequired: false,
        noActionRequired: true,
        desc: ``,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      obligationStatus === "FULFILLED" &&
      closureType === "SUCCESS"
    ) {
      return {
        heading: `Obligation Fulfilled`,
        actionRequired: false,
        noActionRequired: true,
        desc: ``,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      obligationStatus === "FULFILLED" &&
      closureType === "FAILURE"
    ) {
      return {
        heading: `Obligation Fulfilled`,
        actionRequired: false,
        noActionRequired: true,
        desc: ``,
      };
    } else if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "PENDING_TIME_EXPIRED" &&
      closureType == null
    ) {
      return {
        heading: ``,
        actionRequired: false,
        noActionRequired: false,
        desc: `We did not recieve your ${
          isPartner ? "client's" : ""
        } securities until ${formatDateTime(
          shareOb.expiryTimestamp
        )}, we will withhold Rs. ${shareOb.marginAmount}`,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      (obligationStatus === "PENDING" ||
        obligationStatus === "PENDING_TIME_EXPIRED") &&
      closureType === "FAILURE"
    ) {
      return {
        heading: ``,
        actionRequired: false,
        noActionRequired: false,
        actionText: isPartner
          ? "SEND LINK TO CLIENT TO ADD FUNDS"
          : "ADD FUNDS",
        desc: `We did not recieve your ${
          isPartner ? "client's" : ""
        } securities until ${formatDateTime(
          shareOb.expiryTimestamp
        )}, we will withhold your ${isPartner ? "client's" : ""} margin  ${
          shareOb.marginAmount
        }`,
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      (obligationStatus === "PENDING" ||
        obligationStatus === "PENDING_TIME_EXPIRED") &&
      closureType === "FAILURE"
    ) {
      return {
        //Add funds: Rs. ${shareOb.shortfallAmount}
        heading: ``,
        actionRequired: false,
        noActionRequired: false,
        actionText: isPartner
          ? "SEND LINK TO CLIENT TO ADD FUNDS"
          : "ADD FUNDS",
        desc: `We did not recieve your ${
          isPartner ? "client's" : ""
        } securities until ${formatDateTime(
          shareOb.expiryTimestamp
        )}, we will withhold your ${isPartner ? "client's" : ""} margin ${
          shareOb.marginAmount
        }`,
      };
    } else if (
      (fillLifeCycleStatus === "CLOSURE_COMPLETE" ||
        fillLifeCycleStatus === "CLOSURE_CREATED") &&
      obligationStatus === "CLOSED_BEFORE_READY" &&
      closureType === "FAILURE"
    ) {
      return {
        heading: ``,
        actionRequired: false,
        noActionRequired: false,
        actionText: isPartner
          ? "SEND LINK TO CLIENT TO ADD FUNDS"
          : "ADD FUNDS",
        desc: `We could not fulfill your ${
          isPartner ? "client's" : ""
        } trade, as a result we will pay ${
          isPartner ? "your client" : "you"
        } penalty amount`,
      };
    }
  };

  useEffect(() => {
    if (order && userData) {
      if (
        order?.orderDetail.placedForUid !== userData.id &&
        userData.userType !== "INDIVIDUAL_CLIENT"
      ) {
        setIsPartner(true);
      } else {
        setIsPartner(false);
      }
    }
  }, [order, userData]);

  useEffect(() => {
    console.log(trade);
    if (trade.orderAction === "ASK") {
      shareObligation();
    } else {
      fundsObligation();
    }
  }, []);

  useEffect(() => {
    if (fundOb) {
      const data = obligation_text_bid();
      setObData(data);
      // setDetail(preVal => {
      //     return { ...preVal, popup1: { ...preVal.popup1, data: fundOb } }
      // })
    }

    if (shareOb) {
      const data = obligation_text_ask();
      setObData(data);
      getDematDetails();
      // setDetail(preVal => {
      //     return { ...preVal, popup2: { ...preVal.popup2, data: shareOb } }
      // })
    }
  }, [fundOb, shareOb]);
  return (
    <>
      {obData && userData !== null ? (
        <div className="obligation__box">
          <span className="ob__box_heading">{obData.heading}</span>
          {obData.actionRequired && (
            <span className="ob__box_action">ACTION REQUIRED </span>
          )}
          {obData.noActionRequired && (
            <span className="ob__box_noaction">NO ACTION REQUIRED </span>
          )}
          {obData.actionRequired && (
            <button
              className="ob__box_btn"
              onClick={() => {
                if (obData.actionText === "ADD FUNDS") {
                  history.push("/funds");
                } else if (
                  obData.actionText === "SEND LINK TO CLIENT TO ADD FUNDS"
                ) {
                  // TODO: Implement notifaction function
                  console.log("TODO: Implement notifaction function");
                } else {
                  setTransferOpen(true);
                }
              }}
            >
              {obData.actionText}
            </button>
          )}
          <span className="ob__box_date">{obData.desc}</span>
          <button
            className="ob__box_bottom"
            onClick={() => {
              setDetail((preVal) => {
                if (fundOb) {
                  return {
                    ...preVal,
                    popup1: { ...preVal.popup1, open: true, data: fundOb },
                  };
                } else if (shareOb) {
                  return {
                    ...preVal,
                    popup2: { ...preVal.popup2, open: true, data: shareOb },
                  };
                }
              });
            }}
          >
            Details
          </button>
        </div>
      ) : (
        <div className="trade__loader_box">
          <CircularProgress className="trade__loader" />
        </div>
      )}
      {transferOpen && (
        <TransferSecurities
          setTransferOpen={setTransferOpen}
          trade={trade}
          dematDetail={dematDetail}
          obligation={shareOb || fundOb}
          showTargetDematDetails={
            trade?.fillLifeCycleStatus === "OBLIGATION_CREATED" &&
            trade?.obligationStatus === "PENDING" &&
            trade?.closureType === null
          }
        />
      )}
    </>
  );
};

export default ObligationBox;
