import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import img1 from "../../../../Assets/imgs/buyerInfo.svg";
import info from "../../../../Assets/imgs/info.svg";
import AmountFormater from "../../../dashboard/components/amount-format/AmountFormat";

import "./BuyerInfo.css";

function formatString(string) {
  // HELTH CARE to Health Care
  if (string) {
    let word_string = string.replaceAll("_", " ").split(" ");
    let newString = "";
    for (let i = 0; i < word_string.length; i++) {
      newString +=
        word_string[i].charAt(0) + word_string[i].slice(1).toLowerCase() + " ";
    }
    return newString;
  } else {
    return string;
  }
}

const BuyerInfo = (props) => {
  return (
    <>
      <div className="ordermod__header">
        <div style={{ display: "flex" }}>
          <div className="img__background">
            <img className="ordermod__img" src={props.data?.logo} alt="" />
          </div>
          <div className="ordermod__second">
            <div className="second__heading">
              {props.data?.legalName.length > 25 ? (
                <marquee>{props.data.legalName}</marquee>
              ) : (
                <span>{props.data?.legalName}</span>
              )}
              {/* <span>{title.length > 35 ? <marquee hspace="5%">{title}</marquee> : <span>{title}</span>}</span> */}
            </div>
            {props.path == "entry" ? (
              <>
                <div className="bi__body__para2">
                  Sector : <span>{formatString(props.data?.sector)}</span>{" "}
                </div>
                <div className="bi__body__para2">
                  ISIN : <span>{props.data?.isin}</span>
                </div>
                {props?.allowedDepositories && (
                  <div className="bi__body__para2">
                    Depositories :{" "}
                    <span>{props?.allowedDepositories?.join(", ")}</span>
                  </div>
                )}
                {/* <div className="body__one">LKV : ${AmountFormater(props.data?.lkv)}</div> */}
              </>
            ) : props.path == "mintrade" ? (
              <>
                <div className="body__one">
                  {" "}
                  <span style={{ fontWeight: "bold", color: "red" }}>
                    {" "}
                    SELL{" "}
                  </span>{" "}
                  | {props.quote?.shares} @ {props.quote?.price}
                </div>
              </>
            ) : props.path == "confirmorder" ? (
              <>
                <div className="body__one">
                  {" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: props.detail.action
                        ? props.detail.action == "BID"
                          ? "green"
                          : "red"
                        : props.detail.orderDetails.quoteType
                        ? props.detail.orderDetails.quoteType == "BID"
                          ? "green"
                          : "red"
                        : "yellow",
                    }}
                  >
                    {" "}
                    {props.detail.action
                      ? props.detail.action == "BID"
                        ? "BUY"
                        : "SELL"
                      : props.detail.orderDetails.quoteType
                      ? props.detail.orderDetails.quoteType == "BID"
                        ? "BUY"
                        : "SELL"
                      : "yellow"}{" "}
                  </span>{" "}
                  | {props.quote?.shares} @ {props.quote?.price}
                </div>
                <div className="body__one">
                  Status:{" "}
                  <span style={{ color: "#f2c314" }}>
                    {" "}
                    {props.detail.orderStatus
                      ? props.detail.orderStatus
                      : "CREATED"}
                  </span>
                </div>
                <div className="body__one">
                  Order ID:{" "}
                  {props.detail.orderRequestId
                    ? props.detail.orderRequestId
                    : props.detail.orderId}
                </div>
              </>
            ) : props.path == "ordersucc" ? (
              <>
                <div className="body__one">
                  {" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: props.succRes.action == "ASK" ? "red" : "green",
                    }}
                  >
                    {" "}
                    {props.succRes.action == "ASK" ? "SELL" : "BUY"}
                  </span>{" "}
                  | {props.succRes.qty} @ {props.succRes.price}
                </div>
                <div className="body__one">
                  Status:{" "}
                  <span style={{ color: "green" }}> Order Confirmed </span>
                </div>
                <div className="body__one">
                  Order ID: {props.succRes.orderRequestId}
                </div>
              </>
            ) : props.path == "gaug" ? (
              <>
                <div className="body__one">
                  {" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: props.succRes.action == "BID" ? "green" : "red",
                    }}
                  >
                    {" "}
                    {props.succRes.action == "BID" ? "BUY" : "SELL"}{" "}
                  </span>{" "}
                  | Worth Rs. {props.succRes.amount}
                </div>
                <div className="body__one">
                  Status:{" "}
                  <span style={{ color: "green" }}> Interest Validated </span>
                </div>
                <div className="body__one">
                  Order ID: {props.succRes.orderRequestId}
                </div>
              </>
            ) : props.path == "order-modification" ? (
              <>
                <div className="body__one">
                  {" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: props.detail.action == "BID" ? "green" : "red",
                    }}
                  >
                    {" "}
                    {props.detail.action == "BID" ? "BUY" : "SELL"}{" "}
                  </span>{" "}
                  | {props.detail.qty} @ {props.detail.price}
                </div>
                <div className="body__one">
                  Validity : {("0" + props.time.getDate()).slice(-2)}/
                  {("0" + (props.time.getMonth() + 1)).slice(-2)}/
                  {props.time.getFullYear()}{" "}
                  {("0" + props.time.getHours()).slice(-2)}:
                  {("0" + props.time.getMinutes()).slice(-2)}{" "}
                </div>
                {props.detail.action == "ASK" && (
                  <div className="body__one">
                    Min. Trade Qty :{" "}
                    {/* {Math.trunc(
                      Math.ceil(
                        props.detail.minimumFulfillmentPercent *
                          props.detail.qty
                      )
                    )} */}
                    {props?.detail?.minimumFulfillmentQty || 0}
                  </div>
                )}
                <div className="body__one">
                  Order ID: {props.detail.orderRequestId}
                </div>
              </>
            ) : null}
          </div>
        </div>
        {props.path == "gaug" || props.g == "g" ? (
          <>
            <div className="ordermod__gaug">Gauging Interest</div>
            <div className="ordermod__fresh">Fresh Round</div>
          </>
        ) : (
          <>
            <div className="ordermod__third">
              2-way quotes
              <div className="om__thirdimg">
                <img className="om__thirdimg" src={info} alt="" />
              </div>
            </div>
            <div className="ordermod__express">Express Deal</div>
          </>
        )}
      </div>
    </>
  );
};

export default BuyerInfo;
