import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Progress from "@material-ui/core/CircularProgress";

import BuyerInfo from "../Components/BuyerInfo/BuyerInfo";
import BottomSlide from "../../../components/bottom-slide/BottomSlide";
import Cordermodi from "../CnfrmOrderModi/Cordermodi";
import OrderValidity from "../../OrderCreation/Components/OrderValidity/OrderValidity";
import SendLink from "../sendLink/SendLink";

import {
  getConfirmedOrder,
  getCompany,
  getQuoteDetail,
  orderUpdate,
  whoIam,
  notify,
  getBookQuote,
} from "../../../api/order";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import "./OrderModi.scss";
import {
  getDefaultUserId,
  readPartnerNameFromLS,
} from "../../../api/localstorage";
import Confirm from "../../../constants/Confirm";
import { cancelOrder } from "../../../constants";

const OrderModi = () => {
  const history = useHistory();
  const location = useLocation();
  let user = useSelector((state) => state.user);

  const useriD = user?.profile?.userId || getDefaultUserId();
  let urlPartnerget =
    new URLSearchParams(location.search).get("partnerName") ||
    readPartnerNameFromLS(useriD);

  let orderId = new URLSearchParams(location.search).get("orderId");

  const [quote, setQuote] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [expireTime, setExpireTime] = useState(null);
  const [company, setCompany] = useState(null);
  const [quoteDetail, setQuoteDetail] = useState(null);
  const [shareDetail, setShareDetail] = useState(null);

  const [enteredData, setEnteredData] = useState({
    shares: "",
    price: "",
  });
  const [time, setTime] = useState({
    unit: quoteDetail?.minimumValidityDurationUnit == "HOURS" ? "h" : "d",
    value: "",
  });

  // ! error is not getting used anywhere
  const [error, setError] = useState({
    shares: false,
    price: false,
  });

  const [otp, setOtp] = useState(null);
  const [partnerName, setPartnerName] = useState(null);

  const [otpLoader, setOtpLoader] = useState(false);
  const [openslide, setOpenslide] = useState({
    open: false,
    div: null,
  });
  useEffect(() => {
    gettingConfirmOrder();
    gettingWhoIam();
  }, []);

  useEffect(() => {
    if (orderData != null) {
      gettingCompany();
      gettingValidate();
      gettingQuote();
    }
  }, [orderData]);

  const gettingConfirmOrder = async () => {
    let res = await getConfirmedOrder(user.token, orderId);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setOrderData(res.data);
      var time = new Date(res.data.expiryTimestamp * 1000);
      setExpireTime(time);
      setEnteredData({
        shares: res.data.qty,
        price: res.data.price,
      });
    }
  };
  const gettingCompany = async () => {
    let res = await getCompany(user.token, orderData.symbol);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setCompany(res.data);
    }
  };
  const gettingValidate = async () => {
    let res = await getQuoteDetail(user.token, orderData.symbol);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setQuoteDetail(res.data);
      setTime({
        unit: res.data.minimumValidityDurationUnit == "HOURS" ? "h" : "d",
        value: res.data.minimumValidityDuration,
      });
    }
  };
  const gettingWhoIam = async () => {
    let res = await whoIam(user.token);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setPartnerName(
        res.data.userType != "INDIVIDUAL_CLIENT" ? res.data.username : null
      );
    }
  };
  const gettingQuote = async () => {
    // let res = await getQuote(user.token, orderData.symbol, true)
    let res = await getBookQuote(
      user.token,
      orderData.symbol,
      true,
      urlPartnerget
    );
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setQuote(res.data);
    }
  };

  const opentheSlide = (prop) => {
    setOpenslide({
      open: true,
      div: prop,
    });
  };
  const goToShareLink = async () => {
    let res = await notify(
      user.token,
      orderData.orderRequestId,
      orderData.version,
      "cancel"
    );
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      history.push({
        pathname: "/call-client",
        state: {
          company,
          orderId: orderData.orderRequestId,
          user: "cancel",
          orderInfo: orderData,
          expireTime,
        },
      });
    }
  };

  const modifyOrder = async () => {
    if (orderData.action == "BID" && partnerName == null) {
      let res = await orderUpdate(
        user.token,
        orderData.orderRequestId,
        enteredData,
        time,
        "BID"
      );
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        history.push({
          pathname: "/confirm-order",
          search: `?orderId=${res.data.orderRequestId}&orderVersion=${res.data.version}`,
        });
      }
    } else if (orderData.action == "ASK") {
      history.push({
        pathname: "/minimum-trade-quantity",
        state: {
          company: company,
          quoteData: enteredData,
          time: time,
          data: quoteDetail,
          modify: orderData,
          expireTime: expireTime,
        },
      });
    } else {
      history.push({
        pathname: "/modify-order",
        state: {
          company,
          quoteData: enteredData,
          time,
          orderInfo: orderData,
          expireTime,
        },
      });
    }
  };

  return (
    <>
      {!orderData || !quoteDetail || !company ? (
        <>
          <div className="progress">
            <Progress className="bar" />
          </div>
        </>
      ) : (
        <>
          <div className="ordermod__container">
            <div className="ordermod__heading">
              Order <span>Modification</span>
            </div>
            <BuyerInfo
              data={company}
              detail={orderData}
              path="order-modification"
              time={expireTime}
            />

            {quote == null ? (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Progress />
                </div>
              </>
            ) : quote.askCount == 0 && quote.bidCount == 0 ? (
              <>
                <div className="quotes_box">
                  <div className="quotesheading">
                    <div className="heading">Quotes</div>
                    <div className="bestbid">
                      <div className="title">Best Bid</div>
                      <div className="price">-</div>
                      <div className="detail">Best price to divest</div>
                    </div>
                    <div className="bestoffer">
                      <div className="title">Best offer</div>
                      <div className="price">-</div>
                      <div className="detail">Best price to invest</div>
                    </div>
                  </div>
                  <div className="quoteslist">
                    <div className="quotesmessage">
                      Currently, there are no quotes available for this
                      instrument. However, you may please make a bid / offer to
                      invest / divest in this instrument.
                    </div>
                  </div>
                  <div className="totalquots">
                    <div className="totalbid">
                      <div className="qty">
                        <div className="title">Total Bid Qty</div>
                        <div className="price">-</div>
                      </div>
                      <div className="wag">
                        <div className="title">W.avg Bid</div>
                        <div className="price">-</div>
                      </div>
                    </div>
                    <div className="totaloffer">
                      <div className="qty">
                        <div className="title">Total Offer Qty</div>
                        <div className="price">-</div>
                      </div>
                      <div className="wag">
                        <div className="title">W.avg Offer</div>
                        <div className="price">-</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="quotes_box">
                  <div className="quotesheading">
                    <div className="heading">Quotes</div>
                    <div className="bestbid">
                      <div className="title">Best Bid</div>
                      <div className="price">{quote.bestBidPrice}</div>
                      <div className="detail">Best price to divest</div>
                    </div>
                    <div className="bestoffer">
                      <div className="title">Best offer</div>
                      <div className="price">{quote.bestAskPrice}</div>
                      <div className="detail">Best price to invest</div>
                    </div>
                  </div>
                  <div className="quoteslist">
                    <div className="bids">
                      <div className="price">
                        <p className="hea"> Bids </p>
                        <p> {quote.bids[0] ? quote.bids[0].price : "-"} </p>
                        {/* <p> {quote.bids[1] ? quote.bids[1].price : "-"} </p>
                        <p> {quote.bids[2] ? quote.bids[2].price : "-"} </p> */}
                      </div>
                      <div className="qty">
                        <p className="hea"> Min Qty </p>
                        <p> {quote.bids[0] ? quote.bids[0].qty : "-"} </p>
                        {/* <p> {quote.bids[1] ? quote.bids[1].qty : "-"} </p>
                        <p> {quote.bids[2] ? quote.bids[2].qty : "-"} </p> */}
                      </div>
                    </div>
                    <div className="line"></div>
                    <div className="offers">
                      <div className="price">
                        <p className="hea"> Offers </p>
                        <p> {quote.asks[0] ? quote.asks[0].price : "-"} </p>
                        {/* <p> {quote.asks[1] ? quote.asks[1].price : "-"} </p>
                        <p> {quote.asks[2] ? quote.asks[2].price : "-"} </p> */}
                      </div>
                      <div className="qty">
                        <p className="hea"> Min Qty </p>
                        <p> {quote.asks[0] ? quote.asks[0].qty : "-"} </p>
                        {/* <p> {quote.asks[1] ? quote.asks[1].qty : "-"} </p>
                        <p> {quote.asks[2] ? quote.asks[2].qty : "-"} </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="totalquots">
                    <div className="totalbid">
                      <div className="qty">
                        <div className="title">Total Bid Qty</div>
                        <div className="price">{quote.totalBidQty}</div>
                      </div>
                    </div>
                    <div className="totaloffer">
                      <div className="qty">
                        <div className="title">Total Offer Qty</div>
                        <div className="price">{quote.totalAskQty}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="om__ordervalidity__component">
              <OrderValidity
                text="Order modification"
                data={quoteDetail}
                enteredData={enteredData}
                setEnteredData={setEnteredData}
                time={time}
                setTime={setTime}
                error={setError}
                modifyData={orderData}
                modify={true}
              />
            </div>

            {/* <div className="ordermod__footer">
                Un-filled order qty is less than asked modification. please enter a
                quantity greater than {orderData.filledQty}.
              </div> */}
          </div>

          <div className="ordermod__btns">
            <button className="ordermod__btn1" onClick={modifyOrder}>
              MODIFY ORDER
            </button>
            <button
              onClick={() => opentheSlide("confirm")}
              className="ordermod__btn2"
            >
              CANCEL ORDER
            </button>
          </div>
          {openslide.open && (
            <BottomSlide
              bottomSlide={() => {
                setOpenslide(false);
              }}
            >
              {openslide.div == "confirm" ? (
                <>
                  <Confirm
                    orderData={orderData}
                    user={user}
                    partnerName={partnerName}
                    setShareDetail={setShareDetail}
                    setOpenslide={setOpenslide}
                    setOtpLoader={setOtpLoader}
                  />
                </>
              ) : openslide.div == "otp" ? (
                <>
                  <Cordermodi
                    heading="order cancellation"
                    btnText="CANCEL ORDER"
                    otpValue={otp}
                    setOtpValue={setOtp}
                    otpLoader={otpLoader}
                    sendOrder={() =>
                      cancelOrder({
                        setOtpLoader,
                        user,
                        orderData,
                        otp,
                        history,
                        company,
                      })
                    }
                    orderId={orderData.orderRequestId}
                    version={orderData.version}
                  />
                </>
              ) : openslide.div == "sendlink" ? (
                <>
                  <SendLink data={shareDetail} action={goToShareLink} />
                </>
              ) : null}
            </BottomSlide>
          )}
        </>
      )}
    </>
  );
};

export default OrderModi;
