import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { refresh_token } from "../../api/auth";
import { signup_user } from "../../store/User";

const RefreshToken = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const refresh = async () => {
    const curToken = localStorage.getItem("token");
    if (curToken) {
      let res = await refresh_token(curToken);
      if (res.error) {
        console.log("unable to refresh token");
        localStorage.removeItem("token");
        history.push("/register");
      } else {
        console.log(res.data.data);
        dispatch(signup_user(res.data.data));
        localStorage.setItem("token", res.data.data);
      }
    }
    setTimeout(refresh, 1.2e6);
  };
  refresh();
  //  ! why empty return?
  return <div></div>;
};

export default RefreshToken;
