import React, { useEffect, useState } from 'react'

import { ToastContainer, toast } from "react-toastify";
import ShareScreen from "../../../components/share-screen/ShareScreen"

import { useSelector } from "react-redux"
import  { Redirect } from 'react-router-dom'

import "./MenuSetting.scss"

const MenuSetting = () => {
    let user = useSelector((state) => state.user)
    const [shareScreen, setShareScreen] = useState(false);
    const userType = user?.profile?.userType;
    console.log(userType)
    if(userType==="INDIVIDUAL_CLIENT" || userType===undefined){
        console.log("redirecting");
        return <Redirect to='/dashboard'  />
    }

    const partnerName = user.profile.userName===null ? "": "?partnerName="+user.profile.userName;
    const addLogoWithPartnerNameHref = "/addlogo"+ partnerName;

    console.log("************* ", user);
    return (
        <>
          {shareScreen && <ShareScreen url={`${window.location.origin}/dashboard?partnerName=${user?.profile?.userName}`} onClose={() => { setShareScreen(false) }} />}
            <div className="ms__container">
                <div className="ms__heading">Settings</div>
                <div className="setting_list">
                    <div className="fee" style={{ cursor: "pointer" }} onClick={() => window.location.href = "/default-fee?origin=settings"}>
                        <div className="heading">
                            Fee
                        </div>
                        <div className="detail">
                            Change the default fee. This is the default commission you get for your client orders.
                        </div>
                    </div>
                    <div className="fee" style={{ cursor: "pointer" }} onClick={() => window.location.href = addLogoWithPartnerNameHref}>
                        <div className="heading">
                            Logo
                        </div>
                        <div className="detail">
                            Change the logo for your website.
                        </div>
                    </div>
                    <div className="fee" style={{ cursor: "pointer" }} onClick={(e) => {
                        e.stopPropagation()
                        if (navigator.share) {
                        navigator.share({
                            title: "Share Link",
                            url: `${window.location.origin}/dashboard?partnerName=${user?.profile?.userName}`
                        })
                        } else {
                        setShareScreen(true)
                        }
                    }}>
                        <div className="heading">
                            Get Sharable Dashboard Link
                        </div>
                        <div className="detail">
                        Share this link with your clients and earn commissions on orders placed by them using this link.
                        </div>
                    </div>
                </div>
                {/* <div className="ms__header">
                    <div className="ms__header__heading">Whatsapp Updates</div>
                    <div className="ms__toogle">
                        {checked == true ? <p className="ms__toogle__para">ON</p> : <p className="ms__toogle__para">OFF</p>}
                        <label className="toggle_switch" style={checked ? null : { backgroundColor: "#E6E6E6", borderColor: "#E6E6E6" }}>
                            <input
                                type="checkbox"
                                onChange={(e) => { setChecked(e.target.checked) }}
                                className="toggle_checkbox"
                                checked={checked}
                            />
                            <div className="toggle_circle"></div>
                        </label>
                    </div>
                </div>

                <p className="ms__footer__para">
                    Filtered, quality feed on global Private Equity, Startups and Exotic investments
                </p> */}
            </div>
        </>
    )
}

export default MenuSetting
