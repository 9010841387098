import React, { useState, useEffect } from "react";
import "./AddPhone.css";
import BottomSlide from "../../../../components/bottom-slide/BottomSlide";
import Checkbox from "@material-ui/core/Checkbox";
import BSHeader from "../bs-header/BSHeader";
import Button from "../../../../components/button/Button";
import whatsapp from "../../../../Assets/imgs/whatsapp.svg";
import Check from "../../../../Assets/imgs/check.svg";
import OtpInput from "react-otp-input";
import Mnumber from "../../../../components/Mob-num input/Mnumber";
import {
  kyc_failure,
  kyc_request,
  kyc_success,
  mobile_verification,
  verify_otp,
} from "../../../../api/profile";
import { add_mobile, resend_otp } from "../../../../api/auth";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../../axiosInstance";
import run_digio from "../../../../components/run-digio/RunDigio";

const AddPhone = ({
  type,
  username,
  addPhone,
  setAddPhone,
  get_details,
  setScreenLoader,
}) => {
  const [box, setBox] = useState(type);
  const [otp, setOtp] = useState("");
  const [mobile, setMobile] = useState(null);
  const [resend, setResend] = useState(false);
  const [secs, setSecs] = useState(15);
  const [checked, setChecked] = useState(true);
  const [closeBSlider, setCloseBSlider] = useState(false);
  const stateToken = useSelector((state) => state.user.token);
  const stateProfile = useSelector((state) => state.user.profile);
  const [resendOpt, setResendOpt] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchQuery = new URLSearchParams(window.location.search);
  const add_phone = async () => {
    setLoading(true);
    const data = {
      username: username,
      countryCode: mobile?.slice(0, 3),
      mobileNo: mobile?.slice(3),
    };
    const added = await add_mobile(data, stateToken);
    if (added.error) {
      setLoading(false);
      toast.error(added.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log("number added: " + added.data);
      const generate_otp = await mobile_verification(stateToken);
      if (generate_otp.error) {
        setLoading(false);
        toast.error(generate_otp.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setLoading(false);
        console.log("otp generated");
        setBox({});
        setSecs(15);
        setBox("otp");
      }
    }
  };

  const changePhone = () => {
    setBox("mobile");
  };
  const kyc_check = async (success, data) => {
    if (success) {
      let response = await kyc_success(data);
      if (response.error) {
        toast.error(response.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("kyc verification completed successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      let response = await kyc_failure(data);
      if (response.error) {
        toast.success(response.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("kyc verification completed successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const otp_verification = async () => {
    setLoading(true);
    console.log("verifying otp");
    const verified = await verify_otp(otp, stateToken);
    if (verified.error) {
      setBox({});
      setLoading(false);
      console.log(verified.error);
      setBox("wrongotp");
    } else {
      setBox({});
      setLoading(false);
      setBox("whatsapp");
    }
  };

  const handle_resend = async (type) => {
    setResendOpt(false);
    setSecs(30);
    setResend(false);
    resendTimer();

    let res = await resend_otp(stateToken, type);
    if (res.error) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log("otp resent successfully");
    }
  };

  const resendTimer = () => {
    if (!resend) {
      setInterval(
        () =>
          setSecs((secs) => {
            if (secs > 0) {
              return secs - 1;
            } else {
              setResend(true);
              return 0;
            }
          }),
        1000
      );
    }
  };
  useEffect(() => {
    if (!resend) {
      setInterval(
        () =>
          setSecs((secs) => {
            if (secs > 0) {
              return secs - 1;
            } else {
              setResend(true);
              return 0;
            }
          }),
        1000
      );
    }
  }, []);
  return (
    <>
      {addPhone && (
        <BottomSlide closeBSlider={closeBSlider}>
          <BSHeader
            text={box == "whatsapp" && "Stay updated on WhatsApp"}
            setCloseBSlider={(open) =>
              setAddPhone((preVal) => ({ ...preVal, open: !open }))
            }
          />
          <div className="add__phone_body">
            <div className="ap__inner_body">
              {box === "mobile" && (
                <>
                  <span>Enter your cell phone number</span>
                  <Mnumber setMobile={setMobile} />
                </>
              )}
              {box === "otp" && (
                <>
                  <span className="ap__otp_subtitle">
                    Enter OTP sent to you via SMS on {mobile}
                  </span>
                  <OtpInput
                    value={otp}
                    onChange={(otp) => setOtp(otp)}
                    numInputs={6}
                    inputStyle="opt__box_success"
                    containerStyle="otp__wrapper_success"
                    separator={<span>&nbsp;</span>}
                    isInputNum="true"
                  />
                  <div className="ap__resend_box">
                    {resend ? (
                      <span className="ap__resend_btn">
                        {resend ? (
                          <div className="resend__btn resend__btn_model">
                            {!resendOpt ? (
                              <span onClick={() => setResendOpt(true)}>
                                Re-send OTP
                              </span>
                            ) : (
                              <span className="resend__otps">
                                <span onClick={() => handle_resend("SMS")}>
                                  Re-send via sms
                                </span>
                                -
                                <span onClick={() => handle_resend("CALL")}>
                                  Re-send via call
                                </span>
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="resend__text">
                            Re-send OTP (After {secs}s)
                          </div>
                        )}
                      </span>
                    ) : (
                      <span className="ap__resend_text">
                        Re-send OTP (After {secs}s)
                      </span>
                    )}
                    <span className="ap__change_num" onClick={changePhone}>
                      | Change mobile number
                    </span>
                  </div>
                </>
              )}
              {box === "wrongotp" && (
                <>
                  <span className="ap__otp_subtitle">
                    Enter OTP sent to you via SMS on {mobile}
                  </span>
                  <OtpInput
                    value={otp}
                    onChange={(otp) => setOtp(otp)}
                    numInputs={6}
                    inputStyle="opt__box_error"
                    containerStyle="otp__wrapper_error"
                    separator={<span>&nbsp;</span>}
                    isInputNum="true"
                  />
                  <span className="ap__otp_errtext">
                    Incorrect OTP. Please try again
                  </span>
                  <div className="ap__resend_box">
                    {resend ? (
                      <span className="ap__resend_btn">
                        {resend ? (
                          <div className="resend__btn resend__btn_model">
                            {!resendOpt ? (
                              <span onClick={() => setResendOpt(true)}>
                                Re-send OTP
                              </span>
                            ) : (
                              <span className="resend__otps">
                                <span onClick={() => handle_resend("SMS")}>
                                  Re-send via sms
                                </span>
                                -
                                <span onClick={() => handle_resend("CALL")}>
                                  Re-send via call
                                </span>
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="resend__text">
                            Re-send OTP (After {secs}s)
                          </div>
                        )}
                      </span>
                    ) : (
                      <span className="ap__resend_text">
                        Re-send OTP (After {secs}s)
                      </span>
                    )}
                    <span className="ap__change_num">
                      | Change mobile number
                    </span>
                  </div>
                </>
              )}
              {box === "whatsapp" && (
                <>
                  <img
                    src={whatsapp}
                    className="ap__whatsapp_logoo"
                    alt="whatsapp logo"
                  />
                  <span className="ap__whatsapp_text">
                    Filtered, quality feed on global Private Equity, Startups
                    and Exotic investments
                  </span>
                  <span className="ap__notify_confirm">
                    <span className="ap__notify_checkbox">
                      <Checkbox
                        defaultChecked
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                      />
                    </span>
                    <span className="ap__confirm_text">
                      I wish to receive WhatsApp updates
                    </span>
                  </span>
                </>
              )}
              {box === "success" && (
                <>
                  <img
                    src={Check}
                    className="ap__whatsapp_logo"
                    alt="whatsapp logo"
                  />
                  <span className="ap__confirm_text2">
                    Phone number has been added successfully
                  </span>
                </>
              )}
            </div>
            {box === "mobile" && (
              <Button loading={loading} text="DONE" click={add_phone} />
            )}
            {box === "otp" && (
              <Button
                loading={loading}
                text="DONE"
                click={otp_verification}
                style={
                  otp.length < 6
                    ? { background: "lightgrey", pointerEvents: "none" }
                    : ""
                }
              />
            )}
            {box === "wrongotp" && (
              <Button
                loading={loading}
                text="DONE"
                click={otp_verification}
                style={
                  otp.length < 6
                    ? { background: "lightgrey", pointerEvents: "none" }
                    : ""
                }
              />
            )}
            {box === "whatsapp" && (
              <Button
                loading={loading}
                text="DONE"
                click={() => {
                  setBox("success");
                }}
              />
            )}
            {box === "success" && (
              <Button
                loading={loading}
                text="DONE"
                click={async () => {
                  setLoading(true);
                  const response = await kyc_request(stateToken);
                  if (response.error) {
                    toast.error(response.error, {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setLoading(false);
                  } else {
                    console.log(response.data);
                    const {
                      id,
                      customerIdentifier,
                      token,
                      imageUrl,
                      transactionId,
                      search,
                    } = response.data.data;

                    run_digio(
                      {
                        id,
                        customerIdentifier,
                        token,
                        imageUrl,
                        transactionId,
                      },
                      stateToken,
                      get_details,
                      setScreenLoader,
                      searchQuery
                    );
                    setLoading(false);
                  }
                  setAddPhone((preVal) => ({ ...preVal, open: false }));
                }}
              />
            )}
          </div>
        </BottomSlide>
      )}
    </>
  );
};

export default AddPhone;
