import React, { useEffect, useState } from "react";
import "./Popups.scss";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { whoami } from "../../../../api/auth";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Icon, Tooltip } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";

const formatDate = (timestamp) => {
  let d = new Date(timestamp * 1000);
  let ye = new Intl.DateTimeFormat("en", { year: "2-digit" }).format(d);
  let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  console.log(`${da}-${mo}-${ye}`);
  let formatted = da + "/" + mo + "/" + ye;
  return formatted;
};

export const formatAmount = (amount) => amount?.toLocaleString("en-IN");

const formatDateTime = (timestamp) => {
  let d = new Date(timestamp * 1000);
  var options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  return d.toLocaleDateString("en-UK", options);
};
const PopUp1 = ({ order, setDetail, data }) => {
  const [myData, setMyData] = useState({});
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        toast.error(response.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setMyData(response.data.data);
      }
    }
  };

  console.log(data);

  return (
    <div className="popup__modal_parent">
      <div className="popup__modal_backdrop">
        <div className="popup__modal">
          <CloseIcon
            className="popup__modal_closeicon"
            onClick={() => {
              setDetail((preVal) => {
                return { ...preVal, popup1: { ...preVal.popup1, open: false } };
              });
            }}
          />
          <div className="popup1__container">
            <div className="popup__body1">
              <p className="body1__heading">Obligation details</p>
              <p className="body1__para1">
                Trade obligation: Rs. {data.obligationAmount}
              </p>
              <p className="body1__para2">
                (-) Security deposit adj.: Rs. {data.marginAmount}
              </p>
              <p className="body1__para3">
                Net obligation: Rs. {data.netObligationAmount}
              </p>
            </div>
            <div className="popup__body2">
              <p className="body2__heading">Shortfall (if any)</p>
              <p className="body2__para1">
                Net obligation: Rs. {data.netObligationAmount}
              </p>
              <p className="body2__para2">
                (-) Funds available: Rs. {data.fundsTransferred}
              </p>
              <p className="body2__para3">
                Required Funds: {data.shortfallAmount}
              </p>
            </div>
            <div className="popup__body3">
              <p className="body3__para1">Obligation ID: {data.obligationId}</p>
              <p className="body3__para2">
                Status: {data.obligationStatus.replace(/_/g, " ").toLowerCase()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const PopUp2 = ({ order, setDetail, data }) => {
  const [myData, setMyData] = useState({});
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        toast.error(response.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setMyData(response.data.data);
      }
    }
  };
  console.log(data);
  return (
    <div className="popup__modal_parent">
      <div
        className="popup__modal_backdrop"
        onClick={() => {
          setDetail((preVal) => {
            return { ...preVal, popup2: { ...preVal.popup2, open: false } };
          });
        }}
      ></div>
      <div className="popup__modal">
        <CloseIcon
          className="popup__modal_closeicon"
          onClick={() => {
            setDetail((preVal) => {
              return { ...preVal, popup2: { ...preVal.popup2, open: false } };
            });
          }}
        />
        <div className="popup2__container">
          <div className="popup2__body1">
            <p className="body1__heading">Obligation details</p>
            <p className="body1__para1">Trade obligation: {data.qty} Qty</p>
            <p className="body1__para2">
              (-) Securities Available.:
              {data.linkedShareTransactions?.length > 0
                ? data.linkedShareTransactions?.reduce(
                    (total, item) => total + item.qty,
                    0
                  )
                : "0"}
              Qty
            </p>
            <p
              className="body1__para3"
              style={
                data.obligationStatus === "FULFILLED"
                  ? { color: "green" }
                  : null
              }
            >
              Required Securities:
              {data.linkedShareTransactions.length > 0
                ? data.qty -
                  data.linkedShareTransactions?.reduce(
                    (total, item) => total + item.qty,
                    0
                  )
                : data.qty}
              Qty
            </p>
          </div>
          <div className="popup__body2">
            <p className="body2__para1">Obligation ID: {data.obligationId}</p>
            <p className="body2__para2">
              Status: {data.obligationStatus.replace(/_/g, " ").toLowerCase()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const PopUp3 = ({ order, setDetail, data, setOpen }) => {
  const [myData, setMyData] = useState({});
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        toast.error(response.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setMyData(response.data.data);
      }
    }
  };
  console.log("popup 3:", data);
  return (
    <div className="popup__modal_parent">
      <div
        className="popup__modal_backdrop"
        onClick={() => {
          console.log("hello");
          setDetail((preVal) => {
            return { ...preVal, popup3: { ...preVal.popup3, open: false } };
          });
        }}
      ></div>
      <div className="popup__modal">
        <CloseIcon
          className="popup__modal_closeicon"
          onClick={() => {
            setDetail((preVal) => {
              return { ...preVal, popup3: { ...preVal.popup3, open: false } };
            });
          }}
        />
        <div className="popup3__container">
          <p className="popup3__heading">Closure details</p>

          <div className="popup3__body1">
            <p className="popup3__para1">Securities: 5000 Qty</p>
            <p className="popup3__para2">
              Release of Security deposit: RS. 50000
            </p>
          </div>
          <p className="popup3__body2">
            <span style={{ fontWeight: "600" }}>Securities</span> shall be
            transferred to your client's demat account
            <Link style={{ textDecoration: "underline" }}>IN123136985214</Link>
            by 12/12/2021 19:00
          </p>
          <p className="popup3__body2">
            <span style={{ fontWeight: "600" }}>Security deposit </span> shall
            be added to your client's fund balance by 12/12/2021 19:00. After
            that they can withdraw it to their bank from their
            <Link style={{ textDecoration: "underline" }}>Funds Page</Link>
          </p>

          <div className="popup3__body4">
            <p className="body4__para1">Closure ID: 234234234234</p>
            <p className="body4__para2">
              Status: {data.obligationStatus.replace(/_/g, " ").toLowerCase()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const PopUp4 = ({ order, setDetail, data, placementType }) => {
  const [myData, setMyData] = useState({});
  const [userType, setUserType] = useState("");
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        console.log(response.error);
      } else {
        setMyData(response.data.data);
        setUserType(response.data.data.userType);
      }
    }
  };

  useEffect(() => {
    myself();
  }, []);
  console.log("popup 4:", data);
  return (
    <div className="popup__modal_parent">
      <div className="popup__modal_backdrop">
        <div className="popup__modal">
          <CloseIcon
            className="popup__modal_closeicon"
            onClick={() => {
              setDetail((preVal) => {
                return { ...preVal, popup4: { ...preVal.popup4, open: false } };
              });
            }}
          />
          {order &&
          myData.id &&
          order.orderDetail.placedForUid !== myData.id &&
          userType !== "INDIVIDUAL_CLIENT" ? (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Trade Amount: Rs. {data.fundObligation.amount}
                </p>
                <p className="popup4__para2">
                  (+)Released Security deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total Amount:</span>Rs.
                  {formatAmount(
                    data.fundObligation.amount + data.marginBlocked
                  )}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Trade Amount </span>will
                  be transferred to your client's bank a/c:
                  {data.fundObligation.bankAccountId.bankAccountNumber}
                  {data.fundObligation.bankAccountId.ifsc}
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Security deposit </span>
                  will be returned to your client's Funds Balance by
                  {formatDateTime(data.closureSettleByTime)}. After that you can
                  withdraw it to your client's bank account
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure Status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          ) : (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Trade amount: Rs. {data.fundObligation.amount}
                </p>
                <p className="popup4__para2">
                  (+)Released security deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total amount:</span>Rs.
                  {formatAmount(
                    data.fundObligation.amount + data.marginBlocked
                  )}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Trade amount </span>will
                  be transferred to your bank a/c:
                  {data.fundObligation.bankAccountId.bankAccountNumber}
                  {data.fundObligation.bankAccountId.ifsc}
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Security deposit </span>
                  will be returned to your Funds Balance by
                  {formatDateTime(data.closureSettleByTime)}. After that you can
                  withdraw it to your bank account
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const PopUp8 = ({ order, setDetail, data, placementType }) => {
  const [myData, setMyData] = useState({});
  const [userType, setUserType] = useState("");
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        console.log(response.error);
      } else {
        setUserType(response.data.data.userType);
        setMyData(response.data.data);
      }
    }
  };
  useEffect(() => {
    myself();
  }, []);
  console.log("popup 8:", data);
  console.log(userType, placementType);
  return (
    <div className="popup__modal_parent">
      <div className="popup__modal_backdrop">
        <div className="popup__modal">
          <CloseIcon
            className="popup__modal_closeicon"
            onClick={() => {
              setDetail((preVal) => {
                return { ...preVal, popup8: { ...preVal.popup8, open: false } };
              });
            }}
          />
          {order &&
          myData.id &&
          order.orderDetail.placedForUid !== myData.id &&
          userType !== "INDIVIDUAL_CLIENT" ? (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Trade amount to be returned: Rs.
                  {
                    data.failedClosureRefundDetails
                      .additionalTransferredFundsAmountToBeRefunded
                  }
                </p>
                <p className="popup4__para2">
                  (+)Released security deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para2">
                  (+)Closure failure compensation: Rs.
                  {data.failedClosureRefundDetails.compensationFundAmount}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total amount:</span>Rs.
                  {formatAmount(
                    data.failedClosureRefundDetails.compensationFundAmount +
                      data.marginBlocked +
                      data.failedClosureRefundDetails
                        .additionalTransferredFundsAmountToBeRefunded
                  )}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>
                    Trade amount + security deposit
                  </span>
                  will be returned to your client's Funds Balance by
                  {formatDateTime(data.closureSettleByTime)} After that you can
                  withdraw it to your client's bank account.
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>
                    Closure failure compensation
                  </span>
                  will be added to your client's funds balance. After that you
                  can withdraw it to your bank account.
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          ) : (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Trade amount to be returned: Rs.
                  {
                    data.failedClosureRefundDetails
                      .additionalTransferredFundsAmountToBeRefunded
                  }
                </p>
                <p className="popup4__para2">
                  (+)Released security Deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para2">
                  (+)Closure failure compensation: Rs.
                  {data.failedClosureRefundDetails.compensationFundAmount}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total amount:</span>Rs.
                  {formatAmount(
                    data.failedClosureRefundDetails.compensationFundAmount +
                      data.marginBlocked +
                      data.failedClosureRefundDetails
                        .additionalTransferredFundsAmountToBeRefunded
                  )}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>
                    Trade Amount + Security deposit
                  </span>
                  will be returned to your funds balance by
                  {formatDateTime(data.closureSettleByTime)} After that you can
                  withdraw it to your bank account.
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>
                    Closure failure compensation
                  </span>
                  will be added to your funds balance. After that you can
                  withdraw it to your bank account.
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure Status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const PopUp82 = ({ order, setDetail, data, placementType }) => {
  const [myData, setMyData] = useState({});
  const [userType, setUserType] = useState("");
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        console.log(response.error);
      } else {
        setUserType(response.data.data.userType);
        setMyData(response.data.data);
      }
    }
  };
  useEffect(() => {
    myself();
  }, []);
  console.log("popup 82:", data);
  return (
    <div className="popup__modal_parent">
      <div className="popup__modal_backdrop">
        <div className="popup__modal">
          <CloseIcon
            className="popup__modal_closeicon"
            onClick={() => {
              setDetail((preVal) => {
                return {
                  ...preVal,
                  popup82: { ...preVal.popup82, open: false },
                };
              });
            }}
          />
          {order &&
          myData.id &&
          order.orderDetail.placedForUid !== myData.id &&
          userType !== "INDIVIDUAL_CLIENT" ? (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Trade amount to be returned: Rs.
                  {
                    data.failedClosureRefundDetails
                      .additionalTransferredFundsAmountToBeRefunded
                  }
                </p>
                <p className="popup4__para2">
                  (+)Released security deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para2">
                  (+)Closure failure compensation: Rs.
                  {data.failedClosureRefundDetails.compensationFundAmount}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total amount:</span>Rs.
                  {formatAmount(
                    data.failedClosureRefundDetails
                      .additionalTransferredFundsAmountToBeRefunded +
                      data.failedClosureRefundDetails.compensationFundAmount +
                      data.marginBlocked
                  )}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>
                    Trade Amount + security deposit
                  </span>
                  has been returned to your client's Funds Balance.
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>
                    Closure failure compensation
                  </span>
                  has been added to to your client's funds balance
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">
                  Closure Completion time:
                  {formatDateTime(data.closureCompletionTime)}
                </p>
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          ) : (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Trade amount to be returned: Rs.
                  {
                    data.failedClosureRefundDetails
                      .additionalTransferredFundsAmountToBeRefunded
                  }
                </p>
                <p className="popup4__para2">
                  (+)Released security deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para2">
                  (+)Closure failure compensation: Rs.
                  {data.failedClosureRefundDetails.compensationFundAmount}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total amount:</span>Rs.
                  {formatAmount(
                    data.failedClosureRefundDetails
                      .additionalTransferredFundsAmountToBeRefunded +
                      data.failedClosureRefundDetails.compensationFundAmount +
                      data.marginBlocked
                  )}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>
                    Trade amount + security deposit
                  </span>
                  has been returned to your funds balance.
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>
                    Closure failure compensation
                  </span>
                  has been added to your funds balance
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">
                  Closure completion time:
                  {formatDateTime(data.closureCompletionTime)}
                </p>
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const PopUp42 = ({ order, setDetail, data, placementType }) => {
  const [myData, setMyData] = useState({});
  const [userType, setUserType] = useState("");
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        console.log(response.error);
      } else {
        setUserType(response.data.data.userType);
        setMyData(response.data.data);
      }
    }
  };
  useEffect(() => {
    myself();
  }, []);
  console.log("popup 42:", data);
  return (
    <div className="popup__modal_parent">
      <div className="popup__modal_backdrop">
        <div className="popup__modal">
          <CloseIcon
            className="popup__modal_closeicon"
            onClick={() => {
              setDetail((preVal) => {
                return {
                  ...preVal,
                  popup42: { ...preVal.popup42, open: false },
                };
              });
            }}
          />
          {order &&
          myData.id &&
          order.orderDetail.placedForUid !== myData.id &&
          userType !== "INDIVIDUAL_CLIENT" ? (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Trade Amount: Rs. {data.fundObligation.amount}
                </p>
                <p className="popup4__para2">
                  (+)Released security deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total amount:</span>Rs.
                  {data.fundObligation.amount + data.marginBlocked}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Trade amount </span>will
                  be transferred to your client's bank a/c:
                  {data.fundObligation.bankAccountId.bankAccountNumber}
                  {data.fundObligation.bankAccountId.ifsc} via UTR:
                  {data.fundObligation.linkedFundTransferTransactions.map(
                    (item, index) => (
                      <span>
                        {index > 0 && ","}
                        {item.transactionId}
                      </span>
                    )
                  )}
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Security deposit </span>
                  has been returned to your client's funds balance.
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">
                  Closure Completion time:
                  {formatDateTime(data.closureCompletionTime)}
                </p>
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure Status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          ) : (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Trade amount: Rs. {data.fundObligation.amount}
                </p>
                <p className="popup4__para2">
                  (+)Released security deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total amount: </span>Rs.
                  {data.fundObligation.amount + data.marginBlocked}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Trade amount </span>has
                  been transferred to your bank a/c:
                  {data.fundObligation.bankAccountId.bankAccountNumber}
                  {data.fundObligation.bankAccountId.ifsc} via UTR:
                  {data.fundObligation.linkedFundTransferTransactions.map(
                    (item, index) => (
                      <span>
                        {index > 0 && ","}
                        {item.transactionId}
                      </span>
                    )
                  )}
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Security deposit </span>
                  has been returned to your funds balance.
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">
                  Closure completion time:
                  {formatDateTime(data.closureCompletionTime)}
                </p>
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PopUp5 = ({ order, setDetail, data, placementType }) => {
  const [myData, setMyData] = useState({});
  const [userType, setUserType] = useState("");
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        console.log(response.error);
      } else {
        setUserType(response.data.data.userType);
        setMyData(response.data.data);
      }
    }
  };
  useEffect(() => {
    myself();
  }, []);
  console.log("popup 5:", data);
  return (
    <div className="popup__modal_parent">
      <div className="popup__modal_backdrop">
        <div className="popup__modal">
          <CloseIcon
            className="popup__modal_closeicon"
            onClick={() => {
              setDetail((preVal) => {
                return { ...preVal, popup5: { ...preVal.popup5, open: false } };
              });
            }}
          />
          {order &&
          myData.id &&
          order.orderDetail.placedForUid !== myData.id &&
          userType !== "INDIVIDUAL_CLIENT" ? (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Securities: {data.shareObligation.qty}
                </p>
                <p className="popup4__para2">
                  (+)Release of security deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para2">
                  (+)Closure failure compensation: Rs.
                  {data.failedClosureRefundDetails.compensationFundAmount}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total amount:</span>Rs.
                  {formatAmount(
                    data.failedClosureRefundDetails.compensationFundAmount +
                      data.marginBlocked
                  )}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Securities</span>will be
                  transferred to your client's demat account
                  {data.shareObligation.targetDPId +
                    data.shareObligation.targetBoId}
                  by {formatDateTime(data.closureSettleByTime)}
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Security deposit </span>
                  will be returned to your client's funds balance by
                  {formatDateTime(data.closureSettleByTime)}. After that you can
                  withdraw it to your client's bank account.
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>
                    Closure failure compensation
                  </span>
                  will be added to your funds balance. After that you can
                  withdraw it to your bank account.
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          ) : (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Securities: {data.shareObligation.qty}
                </p>
                <p className="popup4__para2">
                  (+)Release of security deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para2">
                  (+)Closure failure compensation: Rs.
                  {data.failedClosureRefundDetails.compensationFundAmount}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total amount:</span>Rs.
                  {formatAmount(
                    data.failedClosureRefundDetails.compensationFundAmount +
                      data.marginBlocked
                  )}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Securities</span>will be
                  transferred to your demat account
                  {data.shareObligation.targetDPId +
                    data.shareObligation.targetBoId}
                  by {formatDateTime(data.closureSettleByTime)}
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Security deposit </span>
                  will be returned to your funds balance by
                  {formatDateTime(data.closureSettleByTime)}. After that you can
                  withdraw it to your bank account.
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>
                    Closure failure compensation
                  </span>
                  will be added to your funds balance. After that you can
                  withdraw it to your bank account.
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const PopUp7 = ({ order, setDetail, data, placementType }) => {
  const [myData, setMyData] = useState({});
  const [userType, setUserType] = useState("");
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        console.log(response.error);
      } else {
        setUserType(response.data.data.userType);
        setMyData(response.data.data);
      }
    }
  };
  useEffect(() => {
    myself();
  }, []);
  console.log("popup 7:", data);
  return (
    <div className="popup__modal_parent">
      <div className="popup__modal_backdrop">
        <div className="popup__modal">
          <CloseIcon
            className="popup__modal_closeicon"
            onClick={() => {
              setDetail((preVal) => {
                return { ...preVal, popup7: { ...preVal.popup7, open: false } };
              });
            }}
          />
          {order &&
          myData.id &&
          order.orderDetail.placedForUid !== myData.id &&
          userType !== "INDIVIDUAL_CLIENT" ? (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Securities: </span>&nbsp;
                  {data.shareObligation.qty}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Securities </span>
                  &nbsp; will transferred to your client's demat account
                  {data.shareObligation.targetDPId +
                    data.shareObligation.targetBoId}
                  by {formatDateTime(data.closureSettleByTime)}
                </p>
              </div>

              <div className="popup4__body4">
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure Status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          ) : (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Securities: </span>&nbsp;
                  {data.shareObligation.qty}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Securities </span>
                  &nbsp;will transferred to your demat account
                  {data.shareObligation.targetDPId +
                    data.shareObligation.targetBoId}
                  by {formatDateTime(data.closureSettleByTime)}
                </p>
              </div>

              <div className="popup4__body4">
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure Status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const PopUp72 = ({ order, setDetail, data, placementType }) => {
  const [myData, setMyData] = useState({});
  const [userType, setUserType] = useState("");
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        console.log(response.error);
      } else {
        setUserType(response.data.data.userType);
        setMyData(response.data.data);
      }
    }
  };
  useEffect(() => {
    myself();
  }, []);
  return (
    <div className="popup__modal_parent">
      <div className="popup__modal_backdrop">
        <div className="popup__modal">
          <CloseIcon
            className="popup__modal_closeicon"
            onClick={() => {
              setDetail((preVal) => {
                return {
                  ...preVal,
                  popup72: { ...preVal.popup72, open: false },
                };
              });
            }}
          />
          {order &&
          myData.id &&
          order.orderDetail.placedForUid !== myData.id &&
          userType !== "INDIVIDUAL_CLIENT" ? (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Securities: </span>&nbsp;
                  {data.shareObligation.qty}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Securities </span>
                  &nbsp;has been transferred to your client's demat account
                  {data.shareObligation.targetDPId +
                    data.shareObligation.targetBoId}
                  via ID:
                  {data.shareObligation.linkedShareTransactions.map(
                    (item, index) => (
                      <span>
                        {index > 0 && ","}
                        {item.transactionId}
                      </span>
                    )
                  )}
                </p>
              </div>

              <div className="popup4__body4">
                <p className="body4__para1">
                  Closure completion time:
                  {formatDateTime(data.closureCompletionTime)}
                </p>
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          ) : (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Securities: </span>&nbsp;
                  {data.shareObligation.qty}
                </p>
              </div>
              <div className="popup4__body2">
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Securities </span>
                  &nbsp;has been transferred to your demat account
                  {data.shareObligation.targetDPId +
                    data.shareObligation.targetBoId}
                  via ID:
                  {data.shareObligation.linkedShareTransactions.map(
                    (item, index) => (
                      <span>
                        {index > 0 && ","}
                        {item.transactionId}
                      </span>
                    )
                  )}
                </p>
              </div>

              <div className="popup4__body4">
                <p className="body4__para1">
                  Closure completion time:
                  {formatDateTime(data.closureCompletionTime)}
                </p>
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Closure status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const PopUp52 = ({ order, setDetail, data, placementType }) => {
  console.log("nilay", order, data);
  const [myData, setMyData] = useState({});
  const [userType, setUserType] = useState("");
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        console.log(response.error);
      } else {
        setUserType(response.data.data.userType);
        setMyData(response.data.data);
      }
    }
  };
  useEffect(() => {
    console.log("data", data);
    myself();
  }, []);
  return (
    <div className="popup__modal_parent">
      <div className="popup__modal_backdrop">
        <div className="popup__modal">
          <CloseIcon
            className="popup__modal_closeicon"
            onClick={() => {
              setDetail((preVal) => {
                return {
                  ...preVal,
                  popup52: { ...preVal.popup52, open: false },
                };
              });
            }}
          />
          {order &&
          myData.id &&
          order.orderDetail.placedForUid !== myData.id &&
          userType !== "INDIVIDUAL_CLIENT" ? (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Securities:{" "}
                  {data.shareObligation ? data.shareObligation.qty : 0}
                </p>
                <p className="popup4__para2">
                  (+)Release of security deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para2">
                  (+)Closure failure compensation: Rs.
                  {data.failedClosureRefundDetails.compensationFundAmount}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total amount:</span>Rs.
                  {formatAmount(
                    data.failedClosureRefundDetails.compensationFundAmount +
                      data.marginBlocked
                  )}
                </p>
              </div>
              <div className="popup4__body2">
                {data.shareObligation ? (
                  <p className="popup4__para3">
                    <span style={{ fontWeight: "700" }}>Securities</span>has
                    been transferred to your client's demat account
                    {data.shareObligation.targetDPId +
                      data.shareObligation.targetBoId}
                    via ID:
                    {data.shareObligation.linkedShareTransactions.map(
                      (item, index) => (
                        <span>
                          {index > 0 && ","}
                          {item.transactionId}
                        </span>
                      )
                    )}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Security deposit </span>
                  has been returned to your client's Funds Balance
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>
                    Closure failure compensation
                  </span>
                  has been added to your client's funds balance.
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">
                  Closure Completion time:
                  {formatDateTime(data.closureCompletionTime)}
                </p>
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          ) : (
            <div className="popup4__container">
              <p className="popup4__heading">Closure details</p>
              <div className="popup4__body1">
                <p className="popup4__para1">
                  Securities:{" "}
                  {data.shareObligation ? data.shareObligation.qty : "NA"}
                </p>
                <p className="popup4__para2">
                  (+)Release of security deposit: Rs. {data.marginBlocked}
                </p>
                <p className="popup4__para2">
                  (+)Closure Failure compensation: Rs.
                  {data.failedClosureRefundDetails.compensationFundAmount}
                </p>
                <p className="popup4__para3">
                  <span style={{ fontWeight: "700" }}>Total amount:</span>Rs.
                  {formatAmount(
                    data.failedClosureRefundDetails.compensationFundAmount +
                      data.marginBlocked
                  )}
                </p>
              </div>
              <div className="popup4__body2">
                {data.shareObligation ? (
                  <p className="popup4__para3">
                    <span style={{ fontWeight: "700" }}>Securities</span>has
                    been transferred to your demat account
                    {data.shareObligation.targetDPId +
                      data.shareObligation.targetBoId}
                    via ID:
                    {data.shareObligation.linkedShareTransactions.map(
                      (item, index) => (
                        <span>
                          {index > 0 && ","}
                          {item.transactionId}
                        </span>
                      )
                    )}
                  </p>
                ) : (
                  <p>No Securities to be transferred back</p>
                )}
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>Security deposit </span>
                  has been returned to your Funds Balance
                </p>
              </div>
              <div className="popup4__body3">
                <p className="popup4__para1">
                  <span style={{ fontWeight: "700" }}>
                    Closure failure compensation
                  </span>
                  has been added to your funds balance.
                </p>
              </div>
              <div className="popup4__body4">
                <p className="body4__para1">
                  Closure completion time:
                  {formatDateTime(data.closureCompletionTime)}
                </p>
                <p className="body4__para1">Closure ID: {data.closureId}</p>
                <p className="body4__para2">
                  Status: {data.state.replace(/_/g, " ").toLowerCase()}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PopUp6 = ({ order, setDetail, data, placementType }) => {
  const [myData, setMyData] = useState({});
  const [userType, setUserType] = useState("");
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        console.log(response.error);
      } else {
        setUserType(response.data.data.userType);
        setMyData(response.data.data);
      }
    }
  };
  const additionalText = (data) => {
    if (data.failedClosureRefundDetails !== null) {
      if (
        data.failedClosureRefundDetails
          .additionalTransferredFundsAmountToBeRefunded > 0
      ) {
        return (
          "Your additional transferred funds Rs." +
          data.failedClosureRefundDetails
            .additionalTransferredFundsAmountToBeRefunded +
          "will be returned."
        );
      }
    }
    if (data.shareObligation !== null) {
      if (data.shareObligation.qty > 0) {
        return `Your additional shares ${
          data.shareObligation.qty
        } will be returned to your demat account ${
          data.shareObligation.targetDPId + data.shareObligation.targetBoId
        }.`;
      }
    }
  };
  useEffect(() => {
    myself();
  }, []);
  console.log(data);

  return (
    <div className="popup__modal_parent">
      <div className="popup__modal_backdrop"></div>
      <div className="popup__modal">
        <CloseIcon
          className="popup__modal_closeicon"
          onClick={() => {
            setDetail((preVal) => {
              return { ...preVal, popup6: { ...preVal.popup6, open: false } };
            });
          }}
        />
        {order &&
        myData.id &&
        order.orderDetail.placedForUid !== myData.id &&
        userType !== "INDIVIDUAL_CLIENT" ? (
          <div className="popup3__container">
            <p className="popup3__heading">Closure details</p>

            <div className="popup3__deatails__div">
              Your client failed to fulfill obligation, as a result we will have
              to forfeit your client's security deposit of Rs.
              {data.marginBlocked}.{additionalText(data)}
            </div>
            <div className="popup3__body4">
              <p className="body4__para1">Closure ID: {data.closureId}</p>
              <p className="body4__para2">
                Closure Status: {data.state?.replace(/_/g, " ")?.toLowerCase()}
              </p>
            </div>
          </div>
        ) : (
          <div className="popup3__container">
            <p className="popup3__heading">Closure details</p>

            <div className="popup3__deatails__div">
              You failed to fulfill your obligation, as a result we will have to
              forfeit your security deposit of Rs. {data.marginBlocked}.
              {additionalText(data)}
            </div>
            <div className="popup3__body4">
              <p className="body4__para1">Closure ID: {data.closureId}</p>
              <p className="body4__para2">
                Closure Status: {data.state?.replace(/_/g, " ")?.toLowerCase()}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PopUp62 = ({ order, setDetail, data, placementType }) => {
  const [myData, setMyData] = useState({});
  const [userType, setUserType] = useState("");
  const token = useSelector((state) => state.user.token);
  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        console.log(response.error);
      } else {
        setUserType(response.data.data.userType);
        setMyData(response.data.data);
      }
    }
  };
  const additionalText = (data) => {
    if (data.failedClosureRefundDetails !== null) {
      if (
        data.failedClosureRefundDetails
          .additionalTransferredFundsAmountToBeRefunded > 0
      ) {
        return (
          "Your additional transferred funds Rs." +
          data.failedClosureRefundDetails
            .additionalTransferredFundsAmountToBeRefunded +
          "has been returned."
        );
      }
    }
    if (data.shareObligation !== null) {
      if (data.shareObligation.qty > 0) {
        return `Your additional shares ${
          data.shareObligation.qty
        } has been returned to your demat account ${
          data.shareObligation.targetDPId + data.shareObligation.targetBoId
        }.`;
      }
    }
  };
  useEffect(() => {
    myself();
  }, []);
  console.log("popup 62:", data);
  return (
    <div className="popup__modal_parent">
      <div
        className="popup__modal_backdrop"
        onClick={() => {
          setDetail((preVal) => {
            return { ...preVal, popup62: { ...preVal.popup62, open: false } };
          });
        }}
      ></div>
      <div className="popup__modal">
        <CloseIcon
          className="popup__modal_closeicon"
          onClick={() => {
            setDetail((preVal) => {
              return { ...preVal, popup62: { ...preVal.popup62, open: false } };
            });
          }}
        />
        {order &&
        myData.id &&
        order.orderDetail.placedForUid !== myData.id &&
        userType !== "INDIVIDUAL_CLIENT" ? (
          <div className="popup3__container">
            <p className="popup3__heading">Closure details</p>

            <div className="popup3__deatails__div">
              Your client failed to fulfill obligation, as a result we have
              forfeited your client's security deposit of Rs.
              {data.marginBlocked}.{additionalText(data)}
            </div>
            <div className="popup3__body4">
              <p className="body4__para1">
                Closure completing Time:
                {formatDateTime(data.closureCompletionTime)}
              </p>
              <p className="body4__para1">Closure ID: {data.closureId}</p>
              <p className="body4__para2">
                Closure status: {data.state?.replace(/_/g, " ")?.toLowerCase()}
              </p>
            </div>
          </div>
        ) : (
          <div className="popup3__container">
            <p className="popup3__heading">Closure details</p>

            <div className="popup3__deatails__div">
              You failed to fulfill your obligation, as a result we have
              forfeited your security deposit of Rs. {data.marginBlocked}.
              {additionalText(data)}
            </div>
            <div className="popup3__body4">
              <p className="body4__para1">
                Closure Completing Time:
                {formatDateTime(data.closureCompletionTime)}
              </p>
              <p className="body4__para1">Closure ID: {data.closureId}</p>
              <p className="body4__para2">
                Closure status: {data.state?.replace(/_/g, " ")?.toLowerCase()}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Popup9 = ({ orderData, setClientIdIsOpen, heading }) => {
  const [copytoClip, setcopytoClip] = useState(false);
  const [copydata, setcopydata] = useState("");

  useEffect(() => {
    if (copydata !== "") {
      let copyText = document.getElementById(copydata).innerText;
      navigator.clipboard.writeText(copyText);
      setcopytoClip(true);
    }
  }, [copydata]);

  useEffect(() => {
    if (copytoClip) {
      setTimeout(() => {
        setcopytoClip(false);
      }, 2000);
    }
  }, [copytoClip]);

  const copyToClipboardSVG = (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="15px"
        viewBox="0 0 24 24"
        width="15px"
        fill="#000000"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
      </svg>
    </>
  );

  return (
    <div className="popup__modal_parent">
      <div
        className="popup__modal_backdrop"
        onClick={() => setClientIdIsOpen(false)}
      ></div>
      <div className="popup__modal" style={{ zIndex: "auto" }}>
        <CloseIcon
          className="popup__modal_closeicon"
          onClick={() => {
            setClientIdIsOpen(false);
          }}
        />
        <div className="popup3__container">
          <p className="popup3__heading">{heading}</p>

          <div className="popup3__deatails__div"></div>
          <div className="popup3__body4">
            <p className="body4__para1">
              Name: <span id="copytext001">{orderData.fullName}</span>
              <Tooltip title="Copy" placement="top" arrow>
                <IconButton
                  onClick={() => {
                    setcopydata("copytext001");
                  }}
                >
                  {copyToClipboardSVG}
                </IconButton>
              </Tooltip>
            </p>
            <p className="body4__para1">
              Mobile: <span id="copytext002">{orderData.mobileNumber}</span>
              <Tooltip title="Copy" placement="top" arrow>
                <IconButton
                  onClick={() => {
                    setcopydata("copytext002");
                  }}
                >
                  {copyToClipboardSVG}
                </IconButton>
              </Tooltip>
            </p>
            <p className="body4__para2">
              Email: <span id="copytext003">{orderData.emailId}</span>
              <Tooltip title="Copy" placement="top" arrow>
                <IconButton
                  onClick={() => {
                    setcopydata("copytext003");
                  }}
                >
                  {copyToClipboardSVG}
                </IconButton>
              </Tooltip>
            </p>
            <p className="body4__para1">
              User Id: <span id="copytext004">{orderData.userId}</span>
              <Tooltip title="Copy" placement="top" arrow>
                <IconButton
                  onClick={() => {
                    setcopydata("copytext004");
                  }}
                >
                  {copyToClipboardSVG}
                </IconButton>
              </Tooltip>
            </p>
          </div>
        </div>
      </div>
      <Snackbar open={copytoClip} message={"Copied to your clipboard."} />
    </div>
  );
};

const Popup92 = ({ orderData, setPopOpen }) => {
  const [snackOpen, setSnackOpen] = useState(false);
  const CopyIcon = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20px"
        viewBox="0 0 24 24"
        width="20px"
        {...props}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
      </svg>
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setSnackOpen(true);
    setTimeout(() => {
      setSnackOpen(false);
    }, 2000);
  };

  return (
    <div className="popup__modal_parent">
      <div
        className="popup__modal_backdrop"
        onClick={() => setPopOpen(false)}
      ></div>
      <div className="popup__modal">
        <IconButton
          className="popup__modal_closeicon"
          size="small"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          onClick={() => setPopOpen(false)}
        >
          <CloseIcon
            style={{
              color: "#333",
              fontSize: "20px",
            }}
          />
        </IconButton>
        <div className="popup3__container">
          <div className="popup3__heading">Detail</div>

          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Date:</b>
              <div>
                {new Date(orderData?.closureDate * 1000)
                  ?.toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour12: true,
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                  .toUpperCase()}
              </div>
            </div>
            <Tooltip
              title="Copy Date"
              placement="top"
              arrow
              style={{
                zIndex: "999999999",
              }}
            >
              <IconButton
                onClick={() =>
                  copyToClipboard(
                    new Date(orderData?.closureDate * 1000)
                      ?.toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour12: true,
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                      .toUpperCase()
                  )
                }
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>

          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Payout ID:</b>
              <div>{` ${orderData?.payoutId}`}</div>
            </div>
            <Tooltip title="Copy Payout ID" placement="top" arrow>
              <IconButton onClick={() => copyToClipboard(orderData?.payoutId)}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Total Payout Amount:</b>
              <div>{`Rs. ${orderData?.payoutAmount}`}</div>
            </div>
            <Tooltip title="Copy Total Payout Amount" placement="top" arrow>
              <IconButton
                onClick={() =>
                  copyToClipboard(`Rs. ${orderData?.payoutAmount}`)
                }
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Fill ID:</b>
              <div>{` ${orderData?.fillId}`}</div>
            </div>
            <Tooltip title="Copy Fill ID" placement="top" arrow>
              <IconButton onClick={() => copyToClipboard(orderData?.fillId)}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Fill Price:</b>
              <div>{`Rs. ${orderData?.fillPrice}`}</div>
            </div>
            <Tooltip title="Copy Fill Price:" placement="top" arrow>
              <IconButton
                onClick={() => copyToClipboard(`Rs. ${orderData?.fillPrice}`)}
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Fill Quantity:</b>
              <div>{` ${orderData?.fillQty}`}</div>
            </div>
            <Tooltip title="Copy Fill Quantity" placement="top" arrow>
              <IconButton onClick={() => copyToClipboard(orderData?.fillQty)}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Match Type:</b>
              <div>{` ${orderData?.type}`}</div>
            </div>
            <Tooltip title="Copy Match Type:" placement="top" arrow>
              <IconButton onClick={() => copyToClipboard(orderData?.type)}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Spread Rate:</b>
              <div>{` ${orderData?.spreadRate}%`}</div>
            </div>
            <Tooltip title="Copy Spread Rate:" placement="top" arrow>
              <IconButton
                onClick={() => copyToClipboard(orderData?.spreadRate)}
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Spread Share:</b>
              <div>{` ${orderData?.spreadShare}%`}</div>
            </div>
            <Tooltip title="Copy Spread Share:" placement="top" arrow>
              <IconButton
                onClick={() => copyToClipboard(orderData?.spreadShare)}
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Spread Amount:</b>
              <div>{`Rs. ${orderData?.spreadAmount}`}</div>
            </div>
            <Tooltip title="Copy Spread Amount" placement="top" arrow>
              <IconButton
                onClick={() =>
                  copyToClipboard(`Rs. ${orderData?.spreadAmount}`)
                }
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Compensation Rate:</b>
              <div>{` ${orderData?.compensationRate}%`}</div>
            </div>
            <Tooltip title="Copy Compensation Rate" placement="top" arrow>
              <IconButton
                onClick={() => copyToClipboard(orderData?.compensationRate)}
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Compensation Amount:</b>
              <div>{`Rs. ${orderData?.compensationAmount}`}</div>
            </div>
            <Tooltip title="Copy Compensation Amount" placement="top" arrow>
              <IconButton
                onClick={() =>
                  copyToClipboard(`Rs. ${orderData?.compensationAmount}`)
                }
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="popup3__Item">
            <div className="popup3__inner">
              <b>Status:</b>
              <div>{orderData?.paid ? "Paid Out" : "Not Paid Out"}</div>
            </div>
            <Tooltip title="Copy Status" placement="top" arrow>
              <IconButton
                onClick={() =>
                  copyToClipboard(orderData?.paid ? "Not Paid Out" : "Paid Out")
                }
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <Snackbar open={snackOpen} message={"Copied to your clipboard."} />
    </div>
  );
};

const TransferSecurities = ({
  setTransferOpen,
  trade,
  obligation,
  dematDetail,
  showTargetDematDetails,
}) => {
  console.log("obligation", obligation);
  // console.clear();
  return (
    <div className="popup__modal_parent">
      <div className="popup__modal_backdrop">
        <div className="popup__modal" style={{ zIndex: "auto" }}>
          <CloseIcon
            className="popup__modal_closeicon"
            onClick={() => {
              setTransferOpen(false);
            }}
          />
          <div className="popup3__container">
            <p
              className="popup3__heading"
              style={{ fontSize: "0.9rem", fontWeight: 700 }}
            >
              Transfer details for DIS
            </p>

            <div className="popup3__deatails__div"></div>
            <div
              className="popup3__body4"
              style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }}
            >
              <p
                className="popup3__heading"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: 700,
                  fontStyle: "inherit",
                }}
              >
                Trade Information:
              </p>
              <p className="body4__para1">ISIN: {obligation?.isin}</p>
              <p className="body4__para1">Quantity: {obligation?.qty}</p>
              <p className="body4__para1">
                Consideration Amount: Rs.{trade.price * trade.qty}
              </p>
              <p className="body4__para1">Fill ID: {obligation?.fillId}</p>
              <p className="body4__para1">
                Reason Code: “2” if your account (source account) is CDSL “1” if
                your account (source account is NSDL)
              </p>
            </div>
            <div className="popup3__body4" style={{ paddingBottom: "0.5rem" }}>
              <p
                className="popup3__heading"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: 700,
                  fontStyle: "inherit",
                }}
              >
                Source Demat Account Information (Source Account):
              </p>
              <p className="body4__para1">
                Source DP ID: {obligation?.sourceDPId}
              </p>
              <p className="body4__para1">
                Source BO ID: {obligation?.sourceBoId}
              </p>
            </div>
            <div className="popup3__body4">
              {showTargetDematDetails ? (
                <>
                  <p
                    className="popup3__heading"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 700,
                      fontStyle: "inherit",
                    }}
                  >
                    Target Demat Account Information (Target Account):
                  </p>
                  <p className="body4__para1">
                    Counter DP ID: {obligation?.targetDPId}
                  </p>
                  <p className="body4__para1">
                    Counter BO ID: {obligation?.targetBoId}
                  </p>
                  <p className="body4__para1">
                    Counter BO name: {dematDetail?.firstHolderName}
                  </p>
                  <p className="body4__para1">
                    Depository Type: {dematDetail?.depository}
                  </p>

                  <div className="popup3__body4">
              {/* {obligation?.linkedBankAccount && ( */}
              <>
                <p
                  className="popup3__heading"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: 700,
                    fontStyle: "inherit",
                  }}
                >
                  Bank Account Details:
                </p>
                <p className="body4__para1">
                  Transferee Name: FundsMap Pvt Ltd
                </p>
                <p className="body4__para1">
                  Bank : {dematDetail?.linkedBankAccount?.bankName}
                </p>
                <p className="body4__para1">
                  Bank Account no :{" "}
                  {
                    dematDetail?.linkedBankAccount?.bankAccountId
                      ?.bankAccountNumber
                  }
                </p>
                <p className="body4__para1">
                  IFSC : {dematDetail?.linkedBankAccount?.bankAccountId?.ifsc}
                </p>
                <p className="body4__para1">
                  Branch : {dematDetail?.linkedBankAccount?.branchName}
                </p>
                <p className="body4__para1">
                  Reference ID : {obligation?.fillId}
                </p>
              </>
              {/* )} */}
            </div>
            </>
              ) : (
                <p>
                  Target demat details will be shown during the share transfer
                  window. Please use the information above to prepare for the
                  transfer, so as to complete the share transfer in time."
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {
  PopUp1,
  PopUp2,
  PopUp3,
  PopUp4,
  PopUp42,
  PopUp5,
  PopUp52,
  PopUp6,
  PopUp62,
  PopUp7,
  PopUp72,
  PopUp8,
  PopUp82,
  Popup9,
  Popup92,
  TransferSecurities,
};
