import axios from "../axiosInstance";

const getFunds = async (token) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: "api/v1/funds/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    }
    resolved.error = "SomeThing Went Wrong";
  }

  return resolved;
};

const getBanks = async (token) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: "api/v1/users/banks/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    }
    resolved.error = "SomeThing Went Wrong";
  }

  return resolved;
};

const getVpa = async (token, bank) => {
  let acc = bank.bankAccountId.bankAccountNumber;
  let ifsc = bank.bankAccountId.ifsc;
  console.log("THE ACC AT API ========== ", acc);
  console.log("THE IFSC AT API ========== ", ifsc);
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/funds/virtualAccount?bankAccountNo=${acc}&ifsc=${ifsc}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong In VPA";
    }
  }

  return resolved;
};

const addNet = async (token, data, bank, mode, reasons) => {
  let amount = data.ammount;
  let acc = bank.bankAccountId.bankAccountNumber;
  let ifsc = bank.bankAccountId.ifsc;
  let am = parseFloat(amount) * 100;

  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: "api/v1/funds/add/",
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        amount: am,
        mode: mode,
        currency: "INR",
        type: "ADD",
        bankAccountId: {
          ifsc: `${ifsc}`,
          bankAccountNumber: `${acc}`,
        },
        reasons,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong In ADD NET AMMOUNT";
    }
  }

  return resolved;
};

const cashFreeAccept = async (token, data) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: "api/v1/funds/cashfree/accept/",
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        fundsMapOrderId: `${data.order.orderId}`,
        paymentId: `${data.transaction.transactionId}`,
        transactionAmount: data.transaction.transactionAmount,
        transactionStatus: `${data.transaction.txStatus}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong";
    }
  }

  return resolved;
};

const addNetConfirm = async (token, data) => {
  let orderId = data.razorpay_order_id;
  let paymentId = data.razorpay_payment_id;
  let sig = data.razorpay_signature;

  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: "api/v1/funds/razorpay/accept/",
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        razorpay_order_id: orderId,
        razorpay_payment_id: paymentId,
        razorpay_signature: sig,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong In ADD NET AMMOUNT CONFIRM";
    }
  }

  return resolved;
};

const getFundTransfer = async (token) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/funds/transfers?type=ALL`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong In getFundsTransfer";
    }
  }

  return resolved;
};

const withDrawFunds = async (token, amount, bank, reasons) => {
  let resolved = {
    data: null,
    error: null,
  };

  try {
    const res = await axios({
      url: `api/v1/funds/withdraw`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        amount: (parseFloat(amount)* 100).toFixed(0),
        mode: "NETBANKING",
        currency: "INR",
        type: "WITHDRAW",
        bankAccountId: {
          ifsc: bank.bankAccountId.ifsc,
          bankAccountNumber: bank.bankAccountId.bankAccountNumber,
        },
        reasons,
      },
    });
    resolved.data = res.data;
  } catch (err) {
    if (err.response) {
      resolved.error = err.response.data.message;
    } else {
      resolved.error = "SomeThing went Wrong In getFundsTransfer";
    }
  }

  return resolved;
};

export {
  getFunds,
  getBanks,
  getVpa,
  addNet,
  cashFreeAccept,
  addNetConfirm,
  getFundTransfer,
  withDrawFunds,
};
