import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import User from "./User";
import Demat from "./Demat";
import Bank from "./Bank";
import Model from "./Model";

const reducers = combineReducers({
  user: User,
  demat: Demat,
  bank: Bank,
  model: Model,
});

const token = localStorage.getItem("token");
const profile = localStorage.getItem("profile")
const initialState = {
  user: {
    profile: profile ? JSON.parse(profile) : null,
    token: token ? token : null,
    bankVerified: false,
    dematVerified: false,
    userType: "",
  },
};
const configureStore = createStore(reducers, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default configureStore;
