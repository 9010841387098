import React, { useState, useEffect } from "react";
import ShareIcon from "@material-ui/icons/Share";
import Bookmark from "@material-ui/icons/TurnedInNot";
import "./CardContent.css";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Link, useHistory } from "react-router-dom";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CurrencySymbols from "./CurrencySymbols";
import { bookmark, delete_bookmark } from "../../../../api/dashboard";
import { useSelector } from "react-redux";
import ScreenLoader from "../../../../components/screen-loader/ScreenLoader";
import ActionAlert from "../../../../components/action-alert/ActionAlert";
import ShareScreen from "../../../../components/share-screen/ShareScreen";
import AmountFormatter from "../amount-format/AmountFormat";
import { BASE_URL } from "../../../../axiosInstance";
import { whoami } from "../../../../api/auth";

function formatString(string) {
  // HELTH CARE to Health Care
  if (string) {
    let word_string = string.replaceAll("_", " ").split(" ");
    let newString = "";
    for (let i = 0; i < word_string.length; i++) {
      newString +=
        word_string[i].charAt(0) + word_string[i].slice(1).toLowerCase() + " ";
    }
    return newString;
  } else {
    return string;
  }
}
const CardContent = ({
  closingSoon,
  logo,
  badge1Style,
  badge2Style,
  badge1,
  badge2,
  title,
  sector,
  isin,
  lkv,
  iconsBoxStyle,
  shortDescription,
  lkvDenomination,
  symbol,
  bookmarked,
  load_dashboard,
  get_company,
  myData,
}) => {
  const [learnMore, setLearnMore] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [shareScreen, setShareScreen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState();
  const [actionAlert, setActionAlert] = useState(false);

  const history = useHistory();
  const searchQuery = new URLSearchParams(history.location.search);
  const partnerName = new URLSearchParams(history.location.search).get(
    "partnerName"
  );
  const [bookmarked2, setBookmarked2] = useState();

  const stateToken = useSelector((state) => state.user.token);
  const [bookmarkRemoved, setBookmarkRemoved] = useState(false);
  // const [myData, setMyData] = useState({});

  const handle_bookmark = () => {
    if (stateToken) {
      bookmarkCompany();
    } else {
      setActionAlert(true);
    }
  };
  const go_to_signin = () => {
    history.push("/register");
  };
  // const my_info = async () => {
  //   let res = await whoami(stateToken);
  //   if (res.error) {
  //     console.log(res.error)
  //   }
  //   else {
  //     setMyData(res?.data?.data)
  //   }
  // }

  const bookmarkCompany = async () => {
    // setLoadingScreen(true);
    setBookmarked2(true);
    let response = await bookmark(symbol, stateToken);
    if (response.error) {
      setLoadingScreen(false);
      // alert(response.error)
    } else {
      console.log(response.data);
      // get_company && get_company();
      // load_dashboard && load_dashboard();
      setLoadingScreen(false);
      console.log("bookmarked:", bookmarked);
    }
  };
  const deleteBookmark = async () => {
    // setLoadingScreen(true);
    setBookmarked2(false);
    setBookmarkRemoved(true);
    let response = await delete_bookmark(symbol, stateToken);
    if (response.error) {
      setLoadingScreen(false);
      alert(response.error);
    } else {
      console.log(response.data);
      // get_company && get_company();
      // load_dashboard && load_dashboard();
      setLoadingScreen(false);
    }
  };
  useEffect(() => {
    setCurrentUrl(window.location.href);
    setBookmarked2(bookmarked);
    // my_info()
  }, []);

  return (
    <div
      className="card__content"
      style={
        history.location.pathname !== "/dashboard"
          ? { paddingBottom: "0px" }
          : null
      }
    >
      {loadingScreen && <ScreenLoader />}
      {shareScreen && (
        <ShareScreen
          url={`${window.location.origin}/companies/${symbol}${
            window.location.search && window.location.search
          }${
            myData?.userType === "ORGANISATIONAL_PARTNER" ||
            myData?.userType === "INDIVIDUAL_PARTNER"
              ? `?partnerName=${myData.username}`
              : ""
          }${window.location.search ? `?partnerName=${partnerName}` : ""}`}
          onClose={() => {
            setShareScreen(false);
          }}
        />
      )}
      {actionAlert && (
        <ActionAlert
          text="You need to verify your email ID to Buy/Sell."
          btnText="Verify Email"
          action={go_to_signin}
          onClose={() => setActionAlert(false)}
        />
      )}
      {window.location.pathname === "/dashboard" ? (
        <div>
          <span className="card__header">
            <img className="ch__image" src={logo} />
            <div className="header__info">
              <div className="info__left">
                <span className="hi__title">
                  <span>
                    {title.length > 35 ? (
                      <marquee hspace="5%" scrollamount="2">
                        {title !== null ? title : null}
                      </marquee>
                    ) : (
                      <span>{title !== null ? title : null}</span>
                    )}
                  </span>
                </span>
                <span className="hi__desc">
                  Sector : {sector !== null ? formatString(sector) : ""}
                </span>
                <span className="hi__desc">
                  ISIN : {isin !== null ? isin : ""}
                </span>
                {/* <span className="hi__desc">
                  LKV :&nbsp;
                  {CurrencySymbols
                    ? CurrencySymbols[lkvDenomination]?.symbol_native :
                    "$"}
                  {AmountFormatter(lkv)}
                </span> */}
              </div>
              <div className="info__right">
                <span
                  className="hi__badge1"
                  style={badge1Style ? badge1Style : null}
                >
                  {badge1 !== null ? badge1 : null}
                </span>
                <span
                  className="hi__badge2"
                  style={badge2Style ? badge2Style : null}
                >
                  <span>{badge2 !== null ? badge2 : null}</span>
                  <ErrorOutlineIcon style={{ fontSize: "13px" }} />
                </span>
              </div>
            </div>
          </span>
          <div className="dbc__body">
            <Link to={`/companies/${symbol}`} className="card__body_link">
              {" "}
              {learnMore ? (
                <div className="body__left">
                  {shortDescription !== null ? shortDescription : null}
                  <span
                    className="learn__more"
                    onClick={() => setLearnMore(false)}
                  >
                    Show Less
                  </span>
                </div>
              ) : (
                <div className="body__left">
                  {shortDescription && shortDescription.substr(0, 250)}
                  <span
                    className="learn__more"
                    onClick={() => setLearnMore(true)}
                  >
                    Learn More
                  </span>
                </div>
              )}
            </Link>
            <div className="body__right" style={iconsBoxStyle}>
              <div className="br__icons">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    if (bookmarked2 || (bookmarked && !bookmarkRemoved)) {
                      deleteBookmark();
                    } else {
                      handle_bookmark();
                    }
                  }}
                  style={
                    bookmarked2 || (bookmarked && !bookmarkRemoved)
                      ? {
                          backgroundImage:
                            "linear-gradient(180deg, #ff9191 0%, #ff240f 100%)",
                          color: "black",
                        }
                      : null
                  }
                >
                  <Bookmark />
                </span>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    if (myData) {
                      if (myData.userType !== "INDIVIDUAL_CLIENT") {
                        searchQuery.set("partnerName", myData.username);
                      }
                    }

                    if (navigator.share) {
                      navigator
                        .share({
                          title: "This company looks investment worthy",
                          url:
                            window.location.origin +
                            window.location.pathname +
                            "?" +
                            searchQuery,
                        })
                        .then(() => {
                          console.log("Shared");
                        })
                        .catch(console.log("FailShare"));
                    } else {
                      setShareScreen(true);
                    }
                  }}
                >
                  <ShareIcon />
                </span>
              </div>
              <div
                className="closing__soon"
                style={{ visibility: closingSoon ? "visible" : "hidden" }}
              >
                <AccessTimeIcon fontSize="small" />
                <span>Closing Soon</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <span>
            <span className="card__header">
              <img className="ch__image" src={logo} />
              <div className="header__info">
                <div className="info__left">
                  <span className="hi__title">
                    <span>
                      {title?.length > 35 ? (
                        <marquee>{title !== null ? title : null}</marquee>
                      ) : (
                        <span>{title !== null ? title : null}</span>
                      )}
                    </span>
                  </span>
                  <span className="hi__desc">
                    Sector : {sector !== null ? formatString(sector) : ""}
                  </span>
                  <span className="hi__desc">
                    ISIN : {isin !== null ? isin : ""}
                  </span>
                  <span className="hi__desc">
                    {/* LKV :&nbsp;
                {CurrencySymbols
                  ? CurrencySymbols[lkvDenomination]?.symbol_native :
                  "$"}
                {AmountFormatter(lkv)} */}
                  </span>
                </div>
                <div className="info__right">
                  <span
                    className="hi__badge1"
                    style={badge1Style ? badge1Style : null}
                  >
                    {badge1 !== null ? badge1 : null}
                  </span>
                  <span
                    className="hi__badge2"
                    style={badge2Style ? badge2Style : null}
                  >
                    <span> {badge2 !== null ? badge2 : null}</span>
                    <ErrorOutlineIcon style={{ fontSize: "13px" }} />
                  </span>
                </div>
              </div>
            </span>
          </span>
          <div className="dbc__body" style={{ display: "none" }}>
            <span to={`/companies/${symbol}`} className="card__body_link">
              {learnMore ? (
                <div className="body__left">
                  {shortDescription}
                  <span
                    className="learn__more"
                    onClick={() => setLearnMore(false)}
                  >
                    Show Less
                  </span>
                </div>
              ) : (
                <div className="body__left">
                  {shortDescription && shortDescription.substr(0, 250)}
                  <span
                    className="learn__more"
                    onClick={() => setLearnMore(true)}
                  >
                    Learn More
                  </span>
                </div>
              )}
            </span>
            <div className="body__right" style={iconsBoxStyle}>
              <div className="br__icons">
                <span
                  onClick={
                    bookmarked2 || bookmarked ? deleteBookmark : handle_bookmark
                  }
                  style={
                    bookmarked2 || bookmarked
                      ? {
                          backgroundImage:
                            "linear-gradient(180deg, #ff9191 0%, #ff240f 100%)",
                          color: "black",
                        }
                      : null
                  }
                >
                  <Bookmark />
                </span>
                <span>
                  <ShareIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      if (navigator.share) {
                        navigator
                          .share({
                            title: "FundsMap Refral",
                            url: `${
                              window.location.origin
                            }/companies/${symbol}${
                              window.location.search && window.location.search
                            }${
                              window.location.search
                                ? `?partnerName=${partnerName}`
                                : ""
                            }`,
                          })
                          .then(() => {
                            console.log("Shared");
                          })
                          .catch(console.log("FailShare"));
                      } else {
                        setShareScreen(true);
                      }
                    }}
                  />
                </span>
              </div>
              <div
                className="closing__soon"
                style={{ visibility: closingSoon ? "visible" : "hidden" }}
              >
                <AccessTimeIcon fontSize="small" />
                <span>Closing Soon</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardContent;
