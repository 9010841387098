import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "./Slide.css";

const Slide = ({ items, activeId, methods, setActiveBox }) => {
  const [active, setActive] = useState(0);

  const handleActive = (e) => {
    console.clear();
    console.log(e.target.id);
    setActive(e.target.id);
    if (setActiveBox) setActiveBox(e.target.id);
  };
  useEffect(() => {
    if (methods) {
      methods[active]();
    }
  }, [active]);
  useEffect(() => {
    if (activeId) {
      setActive(activeId);
    }
  }, [activeId]);
  return (
    <div className="slide__wrapper">
      <Swiper
        spaceBetween={50}
        slidesPerView={3}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {items.map((item, index) => (
          <SwiperSlide
            className={`slide__item ${active == index && "active"}`}
            id={index}
            key={index}
            onClick={handleActive}
          >
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slide;
