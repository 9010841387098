import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import Progress from "@material-ui/core/CircularProgress";

import info from "../../../Assets/imgs/info.svg";
import Button from "../../../components/button/Button";

import BtnCom from "../Components/BtnComponent/BtnCom";

import { ToastContainer, toast } from "react-toastify";

import { getBanks, addNet, addNetConfirm } from "../../../api/FundsAPI";
import { useSelector } from "react-redux";
import { addNetFunds } from "../../funds/addFunds";

import "./ChoseBank.css";
import ScreenLoader from "../../../components/screen-loader/ScreenLoader";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const ChoseBank = (props) => {
  const user = useSelector((state) => state.user);

  const [selectedValue, setSelectedValue] = React.useState(0);
  const [banks, setBanks] = useState([]);
  const [loadingCashFree, setLoadingCashFree] = useState(false);
  const history = useHistory();
  const [netBankingCode, setnetBankingCode] = useState(0);

  const handleChange = (event) => {
    props.setBankIndex(event.target.value);
    setSelectedValue(event.target.value);
    console.log(event.target.value);
  };

  const addBank = () => {
    props.setOpenSlide((preValue) => {
      return {
        ...preValue,
        open: false,
      };
    });
    props.setOpenAddBank(true);
  };

  const addNetFundsClick = async () => {
    setLoadingCashFree(true);
    let reasons = [];
    // if order shortfall exists push on the array with order id
    if (props?.shortfall) {
      if (props?.data?.margin?.previousPendingAmount > 0) {
        reasons.push({
          reason: "CUMULATIVE_SHORTFALL",
          id: props?.data?.orderId?.orderRequestId,
          amount: props?.data?.margin.previousPendingAmount,
        });
      }
    }
    // if order margin exists push on the array with order id
    if (props?.margin) {
      if (props?.data?.margin.marginShortfall > 0) {
        reasons.push({
          reason: "ORDER_MARGIN",
          id: props?.data?.orderId.orderRequestId,
          amount: props?.data?.margin.marginShortfall,
        });
      }
    }
    props.setAddFundsDetails((preValue) => {
      console.log("in prev value ", selectedValue);
      return {
        ...preValue,
        bank: banks[selectedValue],
      };
    });
    if (props.addFundsDetail.type == "net") {
      let net = {
        ammount:
          props.data.margin.shortfall < 1
            ? 1
            : Math.ceil(props.data.margin.shortfall),
        transferType: props.addFundsDetail.type,
      };
      let bank;

      await addNetFunds(
        setLoadingCashFree,
        props,
        history,
        banks,
        selectedValue,
        bank,
        net,
        setnetBankingCode,
        user.token,
        reasons
      );
    } else {
      props.setOpenSlide({
        open: true,
        div: "enterupi",
      });
    }
  };

  useEffect(async () => {
    let res = await getBanks(user.token);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // setBanks(res.data);
      const removeBanks = ["HDFC bank"]; // we can add or remove the banks as our requirements

      setBanks(
        props.addFundsDetail.type === "net"
          ? res.data.filter(
              (bank) =>
                !removeBanks
                  .map((i) => i.toLocaleLowerCase())
                  .includes(bank?.bankName?.toLocaleLowerCase())
            )
          : res.data
      );
    }
  }, []);

  const addOffline = () => {
    props.setAddFundsDetails((preValue) => {
      return {
        ...preValue,
        bank: banks[selectedValue],
      };
    });
    props.setOpenSlide({
      open: true,
      div: "offlineNet",
    });
  };

  return (
    <>
      {loadingCashFree && (
        <div className="backdrop">
          <ScreenLoader />
        </div>
      )}
      <div id="pay-nb">
        <input
          type="text"
          value={netBankingCode}
          data-netbanking-code
          style={{ display: "none" }}
        />
      </div>
      <div className="choseBank__container">
        <div className="choseBank__title">
          <p className="heading"> Choose your bank account </p>
        </div>
        <BtnCom data={props.data.margin.shortfall} />

        {banks == null || banks.length < 1 ? (
          <>
            <div
              style={{
                margin: "1rem auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Progress className="bar" />
            </div>
          </>
        ) : (
          <>
            {banks.map((bank, index) => (
              <div className="chosebank__confirm" key={index}>
                <div className="chosebank__radio">
                  <GreenRadio
                    checked={selectedValue == index}
                    onChange={handleChange}
                    value={index}
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "C" }}
                  />
                </div>
                <div className="chosebank__radio__confirm">
                  <p className="chosebank__confirm1">
                    {bank.bankName} XXX
                    {bank.bankAccountId.bankAccountNumber.substr(
                      bank.bankAccountId.bankAccountNumber.length - 4,
                      4
                    )}
                  </p>
                  <p className="chosebank__confirm2">{bank.branchName}</p>
                </div>
              </div>
            ))}
          </>
        )}

        <div className="chosebank__addbank" onClick={addBank}>
          <span> + </span>
          Add another bank account
        </div>
      </div>
      {props.addFundsDetail.type == "offline" ? (
        <>
          <Button text="ADD FUNDS" click={addOffline} />
        </>
      ) : (
        <>
          <Button text="ADD FUNDS" click={addNetFundsClick} />
        </>
      )}
    </>
  );
};

export default ChoseBank;
