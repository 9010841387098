import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import BuyerInfo from "../../../OrderModification/Components/BuyerInfo/BuyerInfo";
import Slide from "../../../dashboard/components/slide/Slide";
import OrderValidity from "../../Components/OrderValidity/OrderValidity";
import ActionAlert from "../../../../components/action-alert/ActionAlert";

import MenuComponent from "../../../../components/client-partner-menu/ClientPartnerMenu";

import Progress from "@material-ui/core/CircularProgress";

import { useSelector } from "react-redux";

import {
  CreateOrder,
  getBookQuote,
  getQuoteDetail,
  whoIam,
  getCompany,
} from "../../../../api/order";
import {
  getDefaultUserId,
  processLocalStorageWrite,
  readPartnerNameFromLS,
  writePartnerNameToLS,
} from "../../../../api/localstorage";

import { toast } from "react-toastify";

import ls from "localstorage-slim";

import "./OrderEntry2.scss";
import ScreenLoader from "../../../../components/screen-loader/ScreenLoader";
import SearchScreen from "../../../dashboard/components/search-screen/SearchScreen";
import {
  OrderEntryLoadingCard1,
  OrderEntryLoadingCard2,
} from "../../../../components/loadingSkelecton/loadingSkeleton";
import { get_resources } from "../../../../api/dashboard";
import BlurredQuotesBox from "./BlurredQuotesBox";

const OrderEntry = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const searchQuery = new URLSearchParams(history.location.search);
  const redirected = new URLSearchParams(location.search).get("loggedIn");
  let companySymbol = new URLSearchParams(location.search).get("symbol");
  const useriD = user?.profile?.userId || getDefaultUserId();
  let urlPartnerget =
    new URLSearchParams(location.search).get("partnerName") ||
    readPartnerNameFromLS(useriD);
  const preValue =
    redirected == "true" ? JSON.parse(localStorage.getItem("quote")) : null;
  let companyId = companySymbol
    ? companySymbol
    : redirected == "true"
    ? preValue?.companyId
    : null;
  let urlPartner = urlPartnerget;
  const [company, setCompany] = useState(null);
  const [quote, setQuote] = useState(null);
  const [quoteDetail, setQuoteDetail] = useState(null);
  const [partnerInUrl, setPartnerInUrl] = useState(null);
  const [activeBox, setActiveBox] = useState(3);
  const [userType, setUserType] = useState(null);
  const [partnerName, setPartnerName] = useState(null);
  const [actionAlert, setActionAlert] = useState(false);
  const [loadingBackDrop, setLoadingBackDrop] = useState(false);

  const [enteredData, setEnteredData] = useState({
    shares: "",
    price: "",
  });
  const [time, setTime] = useState({
    unit: quoteDetail?.minimumValidityDurationUnit == "HOURS" ? "h" : "d",
    value: "",
  });
  const [error, setError] = useState({
    shares: false,
    price: false,
  });

  useEffect(async () => {
    if (user.token || user.token != null) {
      let res = await whoIam(user.token);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setUserType(res.data);
        setPartnerName(
          res.data.userType == "INDIVIDUAL_CLIENT" ? null : res.data.username
        );
      }
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getCompany(user.token, companyId);
        setCompany(data);
      } catch (error) {
        console.log(error);
      }
    };
    if (companyId) {
      fetchData();
    }
  }, [companyId]);
  useEffect(() => {
    if (company != null) {
      gettingQuote();
      gettingQuoteDetail();
      setData();
    }
  }, [company]);

  useEffect(() => {
    if (partnerInUrl) {
      console.log("removing partner name form url");
      searchQuery.delete("partnerName");
      history.replace({
        search: searchQuery.toString(),
      });
      if (searchQuery.get("partnerName") == null) {
        console.log("imhere00001");
        setPartnerInUrl(1);
      }
    }
    if (partnerInUrl === 1) {
      if (searchQuery.get("partnerName") != null) {
        console.log("removing partner name form url");
        searchQuery.delete("partnerName");
        history.replace({
          search: searchQuery.toString(),
        });
        setPartnerInUrl(false);
      }
    }
  }, [partnerInUrl]);

  const addParamsToURL = (name, value) => {
    if (value) {
      searchQuery.set(name, value);
      history.replace({
        search: searchQuery.toString(),
      });
    }
  };

  const writeLocalStorage = async () => {
    if (
      user?.profile?.userType === "INDIVIDUAL_CLIENT" ||
      user?.profile === null
    ) {
      const isPersonalised = user.token !== null ? true : false;
      const uid = user.profile == null ? 0 : user.profile.userId;
      const token = user.token;
      const partner = urlPartnerget;
      const processs = processLocalStorageWrite(
        uid,
        isPersonalised,
        token,
        partner
      );
      console.log("hgfhgfhgf", processs);
      if (processs > 0) {
        addParamsToURL("partnerName", partner);
      }
      if (processs < 0) {
        setPartnerInUrl(true);
        console.log("removethis");
      }
    } else {
      addParamsToURL("partnerName", user?.profile?.userName);
    }
  };

  useEffect(() => {
    writeLocalStorage();
  }, [urlPartnerget, user.profile]);

  const [showBlurredComponent, setShowBlurredComponent] = useState(false);

  let gettingQuote = async () => {
    if (!user.token || user.token == null) {
      let res = await getBookQuote(
        user.token,
        company.symbol,
        null,
        urlPartner
      );
      if (res.error) {
        setLoadingBackDrop(false);
        setShowBlurredComponent(true);
      } else {
        setQuote(res.data);
      }
    } else {
      let res = await whoIam(user.token);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (res.data.userType == "INDIVIDUAL_CLIENT") {
          let res = await getBookQuote(
            user.token,
            company.symbol,
            true,
            urlPartner
          );
          if (res.error != null) {
            setLoadingBackDrop(false);
            setShowBlurredComponent(true);
          } else {
            setQuote(res.data);
          }
        } else {
          let res = await getBookQuote(user.token, company.symbol, true);
          if (res.error != null) {
            setShowBlurredComponent(true);
            setLoadingBackDrop(false);
          } else {
            setQuote(res.data);
          }
        }
      }
    }
  };
  // useEffect(async () => {
  let gettingQuoteDetail = async () => {
    let res = await getQuoteDetail(user.token, company.symbol);
    if (res.error != null) {
      setLoadingBackDrop(false);
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setQuoteDetail(res.data);
      // alert("stop")
      if (redirected != "true") {
        setTime({
          unit: res.data.maximumValidityDurationUnit == "HOURS" ? "h" : "d",
          value: res.data.minimumValidityDuration,
        });
      }
    }
  };
  // useEffect(() => {
  let setData = () => {
    if (redirected == "true") {
      setEnteredData({
        shares: preValue.quoteData.shares,
        price: preValue.quoteData.price,
      });
      setTime({
        unit: preValue.quoteData.unit,
        value: preValue.quoteData.value,
      });
    } else {
    }
  };

  const go_to_signin = () => {
    history.push({
      // pathname: "/signin",
      pathname: "/register",
      search: `?redirectPath=${history.location.pathname}`,
    });
  };

  const sendQuote = async (type) => {
    if (error.shares == true && error.price == true) {
      if (!user.token || user.token == null) {
        let data = {
          quoteData: {
            ...enteredData,
            ...time,
          },
          companyId: companyId,
          urlPartner: urlPartner,
        };
        localStorage.setItem("quote", JSON.stringify(data));
        setActionAlert(true);
      } else {
        if (type == "BID" && partnerName == null) {
          let res = await CreateOrder(
            user.token,
            user.profile.userName,
            company,
            enteredData,
            time,
            quoteDetail,
            "BID",
            urlPartner
          );
          if (res.error != null) {
            setLoadingBackDrop(false);
            toast.error(res.error, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            history.push({
              pathname: "/confirm-order",
              search: `?orderId=${res.data.orderRequestId}&orderVersion=${
                res.data.version
              }${
                userType.userType == "INDIVIDUAL_CLIENT" &&
                urlPartnerget != null
                  ? `&partnerName=${urlPartnerget}`
                  : ""
              }`,
            });
          }
        } else if (type == "ASK") {
          history.push({
            pathname: "/minimum-trade-quantity",
            state: {
              data: quoteDetail,
              quoteData: enteredData,
              time: time,
              company: company,
              urlPartner: urlPartner,
              type: type,
            },
          });
        } else {
          history.push({
            pathname: "/place-order",
            search: `?&symbol=${companyId}&quotedata=${JSON.stringify({
              ...enteredData,
              ...time,
            })}&type=${type}`,
          });
        }
      }
    } else {
      setTimeout(() => {
        setLoadingBackDrop(false);
        toast.error("Please enter valid quantity / price", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }, 2000);
    }
  };
  useEffect(() => {
    if (activeBox != 3) {
      if (activeBox == 0) {
        history.push(
          "/companies/" +
            companyId?.trim() +
            "?option=about" +
            `${urlPartner !== null ? `&partnerName=${urlPartner}` : ""}`
        );
      } else if (activeBox == 1) {
        history.push(
          "/companies/" +
            companyId?.trim() +
            "?option=funding" +
            `${urlPartner !== null ? `&partnerName=${urlPartner}` : ""}`
        );
      } else if (activeBox == 2) {
        history.push(
          "/companies/" +
            companyId?.trim() +
            "?option=resources" +
            `${urlPartner !== null ? `&partnerName=${urlPartner}` : ""}`
        );
      } else if (activeBox == 3) {
        history.push(
          "/dashboard/order-entry?symbol=" +
            companyId?.trim() +
            `${urlPartner !== null ? `&partnerName=${urlPartner}` : ""}`
        );
      }
    }
  }, [activeBox]);

  const [searchScreen, setSearchScreen] = useState(false);

  return (
    <>
      {loadingBackDrop && (
        <div className="backdrop" onClick={() => setLoadingBackDrop(false)}>
          <ScreenLoader />
        </div>
      )}
      {actionAlert && (
        <ActionAlert
          text="You need to verify your email ID to Buy/Sell"
          btnText="Verify Email"
          action={go_to_signin}
          onClose={() => {
            setLoadingBackDrop(false);
            setActionAlert(false);
          }}
        />
      )}
      {searchScreen && <SearchScreen onClose={() => setSearchScreen(false)} />}
      <MenuComponent searchbar={true} onFocus={() => setSearchScreen(true)} />
      <div className="oe__container">
        <div className="oe__upper">
          <div className="or__userProfile__conponent">
            <BuyerInfo
              path="entry"
              data={company}
              allowedDepositories={quoteDetail?.allowedDepositories}
            />
          </div>
          <div className="oe__slide__component">
            <Slide
              items={["about", "funding", "resources", "invest/divest"]}
              activeId={activeBox}
              setActiveBox={setActiveBox}
            />
          </div>
          {quoteDetail?.orderInstruction &&
            quoteDetail?.orderInstruction?.length > 0 && (
              <div className="instruction">
                Note: {quoteDetail?.orderInstruction}
              </div>
            )}
          {quote === null ? (
            <>
              {showBlurredComponent ? (
                <BlurredQuotesBox />
              ) : (
                <OrderEntryLoadingCard1 />
              )}
            </>
          ) : quote.askCount == 0 && quote.bidCount == 0 ? (
            <>
              <div className="quotes_box">
                <div className="quotesheading">
                  <div className="heading">Quotes</div>
                  <div className="bestbid">
                    <div className="title">Best Bid</div>
                    <div className="price">-</div>
                    <div className="detail">Best price to divest</div>
                  </div>
                  <div className="bestoffer">
                    <div className="title">Best offer</div>
                    <div className="price">-</div>
                    <div className="detail">Best price to invest</div>
                  </div>
                </div>
                <div className="quoteslist">
                  <div className="quotesmessage">
                    Currently, there are no quotes available for this
                    instrument. However, you may please make a bid / offer to
                    invest / divest in this instrument.
                  </div>
                </div>
                <div className="totalquots">
                  <div className="totalbid">
                    <div className="qty">
                      <div className="title">Total Bid Qty</div>
                      <div className="price">-</div>
                    </div>
                  </div>
                  <div className="totaloffer">
                    <div className="qty">
                      <div className="title">Total Offer Qty</div>
                      <div className="price">-</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="quotes_box">
                <div className="quotesheading">
                  <div className="heading">Quotes</div>
                  <div className="bestbid">
                    <div className="title">Best Bid</div>
                    <div className="price">{quote.bestBidPrice}</div>
                    <div className="detail">Best price to divest</div>
                  </div>
                  <div className="bestoffer">
                    <div className="title">Best offer</div>
                    <div className="price">{quote.bestAskPrice}</div>
                    <div className="detail">Best price to invest</div>
                  </div>
                </div>
                <div className="quoteslist">
                  <div className="bids">
                    <div className="price">
                      <p className="hea"> Bids </p>
                      <p> {quote.bids[0] ? quote.bids[0].price : "-"} </p>
                      {/* <p> {quote.bids[1] ? quote.bids[1].price : "-"} </p>
                      <p> {quote.bids[2] ? quote.bids[2].price : "-"} </p> */}
                    </div>
                    <div className="qty">
                      <p className="hea"> Min Qty </p>
                      <p> {quote.bids[0] ? quote.bids[0].qty : "-"} </p>
                      {/* <p> {quote.bids[1] ? quote.bids[1].qty : "-"} </p>
                      <p> {quote.bids[2] ? quote.bids[2].qty : "-"} </p> */}
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="offers">
                    <div className="price">
                      <p className="hea"> Offers </p>
                      <p> {quote.asks[0] ? quote.asks[0].price : "-"} </p>
                      {/* <p> {quote.asks[1] ? quote.asks[1].price : "-"} </p>
                      <p> {quote.asks[2] ? quote.asks[2].price : "-"} </p> */}
                    </div>
                    <div className="qty">
                      <p className="hea"> Min Qty </p>
                      <p> {quote.asks[0] ? quote.asks[0].qty : "-"} </p>
                      {/* <p> {quote.asks[1] ? quote.asks[1].qty : "-"} </p>
                      <p> {quote.asks[2] ? quote.asks[2].qty : "-"} </p> */}
                    </div>
                  </div>
                </div>
                <div className="totalquots">
                  <div className="totalbid">
                    <div className="qty">
                      <div className="title">Total Bid Qty</div>
                      <div className="price">{quote.totalBidQty}</div>
                    </div>
                  </div>
                  <div className="totaloffer">
                    <div className="qty">
                      <div className="title">Total Offer Qty</div>
                      <div className="price">{quote.totalAskQty}</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {!showBlurredComponent && (
            <>
              {quoteDetail === null ? (
                <OrderEntryLoadingCard2 />
              ) : (
                <>
                  <div className="oe__component">
                    <OrderValidity
                      text="Order Entry"
                      data={quoteDetail}
                      enteredData={enteredData}
                      setEnteredData={setEnteredData}
                      time={time}
                      setTime={setTime}
                      error={setError}
                    />
                  </div>
                </>
              )}
            </>
          )}

          {/* <p className="oe__footer__para">
                        invest/divest for a client
                    </p> */}
        </div>
        <div className="oe__lower">
          <div className="oe__btns">
            <button
              type="button"
              disabled={enteredData.price && enteredData.shares ? false : true}
              className="oe__btn1"
              onClick={() => {
                sendQuote("BID");
                setLoadingBackDrop(true);
              }}
            >
              BUY
            </button>
            <button
              type="button"
              disabled={enteredData.price && enteredData.shares ? false : true}
              className="oe__btn2"
              onClick={() => {
                sendQuote("ASK");
                setLoadingBackDrop(true);
              }}
            >
              SELL
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderEntry;
