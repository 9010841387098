import React, { useState, useEffect } from "react";
import "../../add-phone/AddPhone.css";
import BottomSlide from "../../../../../components/bottom-slide/BottomSlide";
import BSHeader from "../../bs-header/BSHeader";
import Button from "../../../../../components/button/Button";
import OtpInput from "react-otp-input";
import {
  generate_partner_otp,
  verify_partner_otp,
} from "../../../../../api/auth";
import { useSelector } from "react-redux";

const VerifyEmail = ({
  type,
  username,
  addPhone,
  setAddPhone,
  get_details,
  setScreenLoader,
  setVerifyEmailTab,
  setIdty,
}) => {
  const [otp, setOtp] = useState("");
  const [resend, setResend] = useState(false);
  const [secs, setSecs] = useState(15);
  const [closeBSlider, setCloseBSlider] = useState(false);
  const stateToken = useSelector((state) => state.user.token);
  const stateProfile = useSelector((state) => state.user.profile);
  const [resendOpt, setResendOpt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);

  const otp_verification = async () => {
    setLoading(true);
    console.log("verifying otp");

    const verified = await verify_partner_otp(stateToken, otp);
    if (verified.error) {
      setWrongOtp(true);
      setLoading(false);
      console.log(verified.error);
    } else {
      get_details();
      setLoading(false);
      setVerifyEmailTab(false);
      setIdty(true);
    }
  };
  const generate_otp = async () => {
    console.log("generating otp");
    const generated = await generate_partner_otp(stateToken);
    if (generated.error) {
      setLoading(false);
      console.log(generated.error);
    } else {
      console.log("generated successfully");
    }
  };

  const handle_resend = async (type) => {
    setResendOpt(false);
    setSecs(30);
    setResend(false);
    resendTimer();
    console.log("generating otp");
    const generated = await generate_partner_otp(stateToken);
    if (generated.error) {
      setLoading(false);
      console.log(generated.error);
    } else {
      console.log("generated successfully");
    }
  };

  const resendTimer = () => {
    if (!resend) {
      setInterval(
        () =>
          setSecs((secs) => {
            if (secs > 0) {
              return secs - 1;
            } else {
              setResend(true);
              return 0;
            }
          }),
        1000
      );
    }
  };
  useEffect(() => {
    if (!resend) {
      setInterval(
        () =>
          setSecs((secs) => {
            if (secs > 0) {
              return secs - 1;
            } else {
              setResend(true);
              return 0;
            }
          }),
        1000
      );
    }
  }, []);
  useEffect(() => {
    generate_otp();
  }, []);
  return (
    <>
      <BottomSlide closeBSlider={closeBSlider}>
        <BSHeader
          text={"Verify Email"}
          setCloseBSlider={() => setVerifyEmailTab(false)}
        />
        <div className="add__phone_body">
          <div className="ap__inner_body">
            <>
              <span className="ap__otp_subtitle">
                Enter OTP sent to you via email on {stateProfile.email}
              </span>
              <OtpInput
                value={otp}
                onChange={(otp) => setOtp(otp)}
                numInputs={6}
                inputStyle={`opt__box ${wrongOtp && "wrong__otp"}`}
                containerStyle="otp__wrapper_success"
                separator={<span>&nbsp;</span>}
                isInputNum="true"
              />
              <div className="ap__resend_box">
                {resend ? (
                  <span className="ap__resend_btn">
                    {resend ? (
                      <div className="resend__btn resend__btn_model">
                        {!resendOpt ? (
                          <span onClick={handle_resend}>Re-send OTP</span>
                        ) : (
                          <span className="resend__otps">
                            <span onClick={() => handle_resend("SMS")}>
                              Re-send via sms
                            </span>
                            -
                            <span onClick={() => handle_resend("CALL")}>
                              Re-send via call
                            </span>
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="resend__text">
                        Re-send OTP (After {secs}s)
                      </div>
                    )}
                  </span>
                ) : (
                  <span className="ap__resend_text">
                    Re-send OTP (After {secs}s)
                  </span>
                )}
              </div>
              <Button
                click={otp_verification}
                text="DONE"
                loading={loading}
                style={
                  otp.length < 6
                    ? { background: "lightgrey", pointerEvents: "none" }
                    : ""
                }
              />
            </>
          </div>
        </div>
      </BottomSlide>
    </>
  );
};

export default VerifyEmail;
