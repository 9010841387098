import longarrow from "../../../../Assets/imgs/longarrow.svg";
import varifiedIcon from "../../../../Assets/imgs/varified.svg";
import notvarified from "../../../../Assets/imgs/notvarified.svg";
import failed from "../../../../Assets/imgs/faild.svg";
import Pencilicom from "../../../../Assets/imgs/pencil.svg";

const AccordionRead = () => {
  return (
    <>
      <div style={{ padding: "1rem" }}>
        <div style={{ padding: "3rem", fontWidth: 300 }}>
          <p>Trades for this order will be shown here.</p>
          <p>Currently, there are no trades for this order.</p>
        </div>
        <div className="accor__expanded__view">
          <div className="ac__steps__container">
            <div className={`ac__step trade`}>
              Order
              <img className="ac__step1__img" src={varifiedIcon} alt="Error" />
            </div>
            <img className="ac__steps__arrow" src={longarrow} alt="Error" />
            <div className={`ac__step`}>Trade</div>
            <img className="ac__steps__arrow" src={longarrow} alt="Error" />
            <div className={`ac__step`}>Obligation</div>
            <img className="ac__steps__arrow" src={longarrow} alt="Error" />
            <div className={`ac__step`}>Closure</div>
          </div>
        </div>
        <center>
          <button
            className={"btn__container ref_friend"}
            style={{ marginTop: "2rem" }}
            onClick={()=>{
              const newWindow = window.open('https://fundsmap.freshdesk.com/en/support/solutions/articles/84000352171-process-after-placing-an-order', '_blank', 'noopener,noreferrer')
              if (newWindow) newWindow.opener = null
            }}
          >
            TRANSACTION PROCESS GUIDE
          </button>
        </center>
      </div>
    </>
  );
};
export default AccordionRead;
