import React from "react";
import { useHistory } from "react-router-dom";
import "./NoOrder.css";
const NoOrder = () => {
  const history = useHistory();
  return (
    <>
      <div className="no__container">
        <div className="no__header">You've not placed any order yet.</div>
        <div className="no__body">
          <button
            className="no__btn"
            onClick={() => history.push("/dashboard")}
          >
            EXPLORE OPPORTUNITIES
          </button>
          <p className="no__body__para">
            Explore investment opportnities in privately held innovatives
            business.
          </p>
        </div>
        <div className="no__footer"></div>
      </div>
    </>
  );
};

export default NoOrder;
