import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Textfield from "@material-ui/core/TextField";

import Email from "../../Assets/imgs/evar.svg";
import Button from "../../components/button/Button";

import { ToastContainer, toast } from "react-toastify";

import { registerEmail } from "../../api/register";
import backarrow from "../../Assets/imgs/backarrow.svg";
import "./Sign.scss";
import {
  getDefaultUserId,
  processLocalStorageWrite,
  readPartnerNameFromLS,
} from "../../api/localstorage";
import { useSelector } from "react-redux";

const Sign = () => {
  let history = useHistory();
  let location = useLocation();

  const redirectPath = new URLSearchParams(location.search).get("redirectPath");
  let referredBy = new URLSearchParams(location.search).get("referredBy");

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const user = useSelector((state) => state.user);
  const searchQuery = new URLSearchParams(history.location.search);

  const enteringEmail = (event) => {
    setEmailError(null);
    setEmail(event.target.value);
  };

  const check = async () => {
    // alert(referredBy)
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      const userID =
        user?.profile?.email === email
          ? user?.profile?.userId
          : getDefaultUserId();
      const partnerName =
        searchQuery.get("partnerName") || readPartnerNameFromLS(userID) || null;
      setLoading(true);

      if (userID === getDefaultUserId()) {
        // token null isPersonalized false
        const token = null;
        const isPersonalized = false;
        await processLocalStorageWrite(
          userID,
          isPersonalized,
          token,
          partnerName
        );
      } else if (user?.profile?.userType === "INDIVIDUAL_CLIENT") {
        const { token } = user;
        const isPersonalised = token ? true : false;
        console.log({ userID, isPersonalised, token, partnerName });
        await processLocalStorageWrite(
          userID,
          isPersonalised,
          token,
          partnerName
        );
      }

      let res = await registerEmail(email, referredBy, partnerName);
      if (res.error != null) {
        setLoading(false);
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        history.push({
          pathname: "/register-otp",
          search: redirectPath ? `?redirectPath=${redirectPath}` : undefined,
          state: { email, data: res.data },
        });
      }
    } else {
      setEmailError("Invalid Email");
    }
  };

  const isValidEmail = (email) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      
      return (
        <>
      <div
        style={{ marginTop: "2rem", cursor: "pointer" }}
        className="co__bottom__header"
        onClick={() => {
          const userID =
          user?.profile?.email === email
            ? user?.profile?.userId
            : getDefaultUserId();
        const partnerName =
          searchQuery.get("partnerName") || readPartnerNameFromLS(userID) || null;
          console.log(partnerName);
          history.push({
            pathname:"dashboard",
            search:`?partnerName=${partnerName}`
          });
          // history.goBack();
        }}
      >
        <img
          style={{ width: "20px", height: "20px" }}
          src={backarrow}
          alt="Error"
        />
        <p className="title">Back</p>
      </div>
      <div className="register_container">
        <div className="box">
          <div className="data">
            <div className="email">
              <img src={Email} alt="ERROR" />
              <div className="title">Enter Email</div>
            </div>
            <div className="input">
              {/* <Textfield
                type="text"
                className="textfield"
                value={email}
                onChange={enteringEmail}
                label="Enter your Email ID"
              /> */}
              <div className="email-input">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                />
                <button
                  className="send-btn"
                  onClick={check}
                  disabled={!isValidEmail(email) || loading}
                >
                  <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                </button>
              </div>
              {emailError && (
                <p
                  style={{
                    fontSize: "small",
                    color: "red",
                    marginTop: "-1rem",
                    marginLeft: ".3rem",
                  }}
                >
                  {emailError}{" "}
                </p>
              )}
            </div>
          </div>
          <div className="btn">
            <Button
              loading={loading}
              text="DONE"
              click={check}
              disabled={!isValidEmail(email) || loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sign;
