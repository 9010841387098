import React from "react";
import "./InvestForClient.css";
import TextField from "@material-ui/core/TextField";
import Slide from "../../../dashboard/components/slide/Slide";
import BuyerInfo from "../../../OrderModification/Components/BuyerInfo/BuyerInfo";
import Button from "../../../../components/button/Button";

const InvestForClient = () => {
  return (
    <>
      <div className="ifc__container">
        <div className="ifc__upper">
          <div className="ifc__buyerInfo__comp">
            <BuyerInfo />
          </div>
          <div className="ifc__slide__component">
            <Slide
              items={[
                "funding",
                "resources",
                "invest/divest",
                "modify",
                "order",
                "create",
              ]}
            />
          </div>
          <div className="ifc__header">
            <div className="ifc__inputs">
              <div className="ifc__input1">
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  placeholder="No. of Shares"
                  variant="standard"
                />
              </div>
              <p className="ifc__input1__para">@</p>

              <div className="ifc__input2">
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  placeholder="Price/Share (Rs.)"
                  variant="standard"
                />
                <p
                  className="ifc__input2__para"
                  style={{ fontWeight: "500", fontSize: "14px" }}
                >
                  LTP : 237.25
                </p>
              </div>
            </div>
            <div className="ifc__btn1" style={{ marginBottom: "2rem" }}>
              <div className="ifc__btn1__left"></div>
              <div className="ifc__btn1__right">
                <p className="comp__para1">
                  Placing your order with a higher price per share incerease
                  your chances of getting an allocation. <span>know more</span>
                </p>
              </div>
            </div>
            <div className="ifc__btn">
              <Button text="INVEST" />
              <p className="ifc__btn__para">For a Client</p>
            </div>
          </div>
          <div className="ifc__body">
            <p className="ifc__body__para1" style={{ fontWeight: "500" }}>
              We have worked out a deal with a seller and are now reserving
              investments on this deal.
            </p>
            <p className="ifc__body__para2">
              Typically, these deals would remain open for upto 4 weeks. Once
              we've reserved enough investments we shall close the round and the
              transaction shall be settled (Transfer of funds & Allotment of
              Shares) in the following 2 weeks time.
            </p>
            <p className="ifc__body__para3">
              In an unlikely event where we do not receive enough demand for an
              opened investment round, we shall call it off. in such an event
              margin funds shall be refunded and there shall be no charges
              whatsoever.
            </p>
            <p className="ifc__watch">
              Need clarity on the transaction process? <span>Watch Video.</span>
            </p>
          </div>
        </div>

        <div className="ifc__lower"></div>
      </div>
    </>
  );
};

export default InvestForClient;
