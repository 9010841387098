import { CardActionArea, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getActiveTrades } from "../../api/activeTrades";

// style
import "./ActiveTrades.scss";

import FilterSvg from "../../Assets/imgs/filter.svg";

const ActiveTradesCard = ({ trade }) => {
  const history = useHistory();
  const getColor = (orderAction) => {
    let color = "";
    switch (orderAction) {
      case "BUY":
        color = "#00b300";
        break;
      case "ASK":
        color = "#ff0000";
        break;
      case "BID":
        color = "#00b300";
        break;
      case "SELL":
        color = "#ff0000";
        break;
      default:
        color = "#000000";
        break;
    }
    return color;
  };
  return (
    <CardActionArea
      onClick={() => {
        history.push(
          `/order/${trade?.orderId}?v=${trade?.version}&fill=${trade?.fillId}`
        );
      }}
    >
      <div
        className="ao__header"
        style={{
          margin: "0",
          flexWrap: "wrap",
        }}
      >
        <div className="ao__first">
          <div className="ao__background">
            <img className="ao__img" src={trade?.companyLogo} alt="" />
          </div>
          <div className="ao__second">
            <div className="ao__second__heading" style={{ fontWeight: "bold" }}>
              {trade?.symbol.length > 20 ? (
                <marquee width="100%" direction="right">
                  {trade?.symbol}
                </marquee>
              ) : (
                trade?.symbol
              )}
            </div>
            <div
              className="ao__second__body"
              style={{ color: "rgba(0, 0, 0, 0.65)" }}
            >
              <div className="ao__body__one">
                <span
                  style={{
                    color: getColor(trade?.orderAction),
                  }}
                >
                  {trade?.orderAction}{" "}
                </span>
                | Qty {trade?.qty} @ Rs. {trade?.price}
              </div>
              <div className="ao__body__one">Order ID: {trade?.orderId}</div>
              <div className="ao__body__one">Fill ID: {trade?.fillId}</div>
            </div>
          </div>
        </div>
        <div
          className="ao__third"
          style={{
            color: "rgb(248, 218, 106)",
            backgroundColor: "rgb(255, 248, 236)",
          }}
        >
          {trade?.obligationStatus?.replaceAll("_", " ")}
        </div>
        {trade?.fillLifeCycleStatus === "OBLIGATION_CREATED" && (
          <p className="warning">
            <img
              src="https://www.citypng.com/public/uploads/small/11640517302ysiqropo8rqprchu5fpsqmabqycjokdg2uksbqpwxhvoal10qbbyrefn0jeornu5lmbzfo0qupxtcrponywubkzuyg7swrcthje5.png"
              className="warning-icon"
              alt="warning-icon"
            />
            Complete your obligation before{" "}
            <b>
              {new Date(trade?.fillExpirationTime * 1000).toLocaleDateString(
                "en-GB",
                {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
            </b>{" "}
            to avoid deposit forfeiture.
          </p>
        )}
      </div>
    </CardActionArea>
  );
};

const ActiveTrades = () => {
  const user = useSelector((state) => state.user);
  const [trades, setTrades] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    console.clear();
    const getData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getActiveTrades({
          token: user.token,
          login: user ? true : false,
        });
        console.log(data);
        setIsLoading(false);
        setTrades(data?.fills);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  return (
    <div
      style={{
        padding: "0 20px",
      }}
    >
      <div
        className="ao__haeding"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        Active Trades
        <button
          type="button"
          className="btn"
          onClick={(e) => console.log("open")}
          style={{
            background: "transparent",
            border: "none",
          }}
        >
          <img src={FilterSvg} alt="ERROR" />
        </button>
      </div>
      <div className="order_list_container">
        {isLoading ? (
          <>
            <div className="loader no-scrollbar">
              <CircularProgress />
              <p>Loading...</p>
            </div>
          </>
        ) : (
          <>
            {trades.map((trade, index) => (
              <ActiveTradesCard trade={trade} key={index} />
            ))}
          </>
        )}
        {trades.length === 0 && !isLoading && (
          <div className="loader no-scrollbar">
            <p>No Active Trades</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveTrades;
