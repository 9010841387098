import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import TextField from "@material-ui/core/TextField";

import backarrow from "../../../Assets/imgs/backarrow.svg";
import Button from "../../../components/button/Button";
import BuyerInfo from "../Components/BuyerInfo/BuyerInfo";
import CheckboxComponent from "../../../components/ConfirmCheckbox/CheckboxComponent";

import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { CreateOrder, orderUpdate } from "../../../api/order";
import {
  getDefaultUserId,
  readPartnerNameFromLS,
} from "../../../api/localstorage";
import "./Mtradqty.css";
import ScreenLoader from "../../../components/screen-loader/ScreenLoader";

const Mtradeqty = () => {
  const history = useHistory();
  const location = useLocation();
  const [UserData, setUserData] = useState(useSelector((state) => state.user));

  const [minVal, setMinVal] = useState(null);
  const [error, setError] = useState(null);
  const [partnerName, setPartnerName] = useState(null);
  const [loadingBackDrop, setLoadingBackDrop] = useState(false);

  let company = location.state.company;
  let quoteData = location.state.quoteData;
  let time = location.state.time;
  let data = location.state.data;
  const useriD = UserData?.profile?.userId || getDefaultUserId();
  let urlPartner = location.state.urlPartner || readPartnerNameFromLS(useriD);
  let type = location.state.type;

  let modify = location.state.modify;
  let expireTime = location.state.expireTime;

  useEffect(() => {
    const getData = () => {
      const partner =
        UserData?.profile?.userType === "INDIVIDUAL_CLIENT" ? false : true;
      console.log("isaPartner {}", partner);
      console.log("User is", UserData?.profile);
      if (partner) {
        console.log("partner is {}", UserData?.profile?.userName);
        setPartnerName(UserData?.profile?.userName);
      }
    };

    getData();
  }, []);
  useEffect(() => {
    if (modify) {
      setMinVal(modify?.minimumFulfillmentQty || 0);
    }
  }, []);

  const drive = data.minimumQtyPercentLowerLimit * quoteData.shares;
  let ceilMinQty = drive < data.lotSize ? data.lotSize : drive;
  let floorMinQty =
    0.1 * quoteData.shares < data.lotSize
      ? Math.floor(data.lotSize)
      : Math.floor(((0.1 * quoteData.shares) / data.lotSize) * data.lotSize);

  console.log("maxMinQty =============== ", ceilMinQty);
  const handleChange = (event) => {
    setMinVal(event.target.value);
    if (event.target.value.includes(".")) {
      setError("typeError");
    } else if (
      event.target.value > ceilMinQty ||
      event.target.value < data.lotSize
    ) {
      setError("sizeError");
    } else {
      setError(null);
    }
  };
  console.log(minVal);
  console.log("Data ==========", data);

  const goBack = () => {
    history.goBack();
  };

  const sendData = async () => {
    setLoadingBackDrop(true);
    if (minVal < floorMinQty || minVal > ceilMinQty) {
      setError("sizeError");
    } else {
      setError(null);
      if (modify) {
        if (partnerName === null) {
          let res = await orderUpdate(
            UserData.token,
            modify.orderRequestId,
            quoteData,
            time,
            "ASK",
            minVal
          );
          if (res.error != null) {
            toast.error(res.error, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            history.push({
              pathname: "/confirm-order",
              search: `?orderId=${res.data.orderRequestId}&orderVersion=${res.data.version}`,
            });
          }
        } else {
          history.push({
            pathname: "/modify-order",
            state: {
              company,
              quoteData,
              time,
              orderInfo: modify,
              expireTime,
              mintrade: minVal,
            },
          });
        }
      } else {
        if (partnerName === null) {
          let res = await CreateOrder(
            UserData.token,
            UserData.profile.userName,
            company,
            quoteData,
            time,
            minVal,
            "ASK",
            urlPartner
          );
          if (res.error != null) {
            toast.error(res.error, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            history.push({
              pathname: "/confirm-order",
              search: `?orderId=${res.data.orderRequestId}&orderVersion=${res.data.version}`,
            });
          }
        } else {
          history.push({
            pathname: "/place-order",
            search: `?symbol=${company.symbol}&quotedata=${JSON.stringify({
              ...quoteData,
              ...time,
            })}&type=${type}&mintrade=${minVal}`,
          });
        }
      }
    }
  };

  return (
    <>
      {loadingBackDrop && (
        <div className="backdrop">
          <ScreenLoader />
        </div>
      )}
      {/* <MenuComponent/> */}
      <div className="tradeqty__container">
        {modify ? (
          <>
            <BuyerInfo
              path="order-modification"
              data={company}
              detail={modify}
              time={expireTime}
            />
          </>
        ) : (
          <>
            <BuyerInfo path="mintrade" quote={quoteData} data={company} />
          </>
        )}
        <div className="tq__bottom__header" onClick={goBack}>
          <img
            style={{ width: "20px", height: "20px" }}
            src={backarrow}
            alt="Error"
          />
          <p className="tq__para">Back</p>
        </div>
        <div className="tq__body">
          <div className="tq__body__heading">
            Minimum trade <Link to="#">quantity</Link>
          </div>

          <div className="tq__body__para">
            You've placed an order for {quoteData.shares} shares, but it is
            possible that we may be able to fulfil your order with multiple
            transactions. Please let us know what shall be the minimum quantity
            that you are willing to transact per transaction.
          </div>
          <a
            style={{ fontSize: "11px" }}
            href="https://youtu.be/GQl3f-e_goI"
            target="_blank"
            rel="noopener noreferrer"
          >
            Watch video
          </a>

          <div className="tq__input">
            <TextField
              style={{ width: "120px" }}
              id="outlined-basic"
              placeholder="200"
              variant="standard"
              onChange={handleChange}
              value={minVal}
              type="number"
            />
            <p className="tq__bottom__para1">Minimum qty per transaction</p>
            <p
              className="tq__bottom__para2"
              style={{ color: error != null ? "red" : null }}
            >
              {error === "typeError" ? (
                <>Integers only allowed</>
              ) : error === "sizeError" ? (
                <>
                  Enter Quantity between {Math.floor(floorMinQty)} and{" "}
                  {Math.floor(ceilMinQty)}.
                </>
              ) : error === "lotError" ? (
                <>Quantity must be a multiple of {Math.floor(data.lotSize)}.</>
              ) : (
                <>
                  Enter Quantity between {Math.floor(floorMinQty)} and{" "}
                  {Math.floor(ceilMinQty)}.
                </>
              )}
            </p>
            {/* <p
              style={{ color: "red", marginTop: "3px" }}
              className="tq__bottom__para1"
            >
              Minimum quantity can not be higher than 30% of your total quantity
            </p> */}
          </div>
          <div className="tq__checkbox__component">
            <CheckboxComponent />
          </div>
        </div>
      </div>
      <div className="min__btn">
        <Button
          text="CREATE"
          click={sendData}
          disabled={
            !minVal ||
            minVal < Math.floor(floorMinQty) ||
            minVal > ceilMinQty ||
            error === "typeError"
          }
        />
      </div>
    </>
  );
};

export default Mtradeqty;
