import React, { useEffect, useState } from "react";

import Progress from "@material-ui/core/CircularProgress";

import { getEarnings } from "../../../api/menu";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { Popup92 } from "../../../screens/OrderDetails/Components/PopUps/PopUps";
import "./TotalEarnings.scss";

const TotalEarnings = () => {
  const { user } = useSelector((state) => state);

  const [earnings, setEarnings] = useState(null);
  const [IsPopOpen, setPopOpen] = useState(false);
  const [orderData, setOrderData] = useState(null);

  const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "MyReport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    let uri = `data:text/csv;charset=utf-8,${escape(CSV)}`;
    // console.log(uri);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getEarnings(user);
        setEarnings(data);
      } catch (error) {
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    getData();
  }, []);
  return (
    <>
      {!earnings ? (
        <>
          <div className="progress">
            <Progress className="bar" />
          </div>
        </>
      ) : (
        <>
          <div className="alE__container">
            <div className="earnings-header">
              <div className="flex">
                <p className="alE__heading">My Earnings</p>
                <p className="alE__heading">
                  <button
                    type="button"
                    className="download-btn"
                    onClick={() =>
                      JSONToCSVConvertor(
                        JSON.stringify(
                          earnings?.payouts?.map((arr) => ({
                            Date: new Date(arr?.closureDate * 1000)
                              ?.toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour12: true,
                                hour: "2-digit",
                                minute: "2-digit",
                              })
                              .toUpperCase(),
                            "Payout ID": arr?.payoutId,
                            "Total Payout Amount (In Rs)": arr?.payoutAmount,
                            "Fill ID": arr?.fillId,
                            "Fill Price": arr?.fillPrice,
                            "Fill Quantity": arr?.fillQty,
                            "Match Type": arr?.type,
                            "Spread Rate": arr?.spreadRate,
                            "Spread Share": arr?.spreadShare,
                            "Spread Amount (In Rs)": arr?.spreadAmount,
                            "Compensation Rate": arr?.compensationRate,
                            "Compensation Amount (In Rs)":
                              arr?.compensationAmount,
                            Status: arr?.paid ? "Paid Out" : "Not Paid Out",
                          }))
                        ),
                        "payouts",
                        true
                      )
                    }
                  >
                    Download
                  </button>
                </p>
              </div>
              <div className="alE__header">
                <div className="alE__header__1st">
                  <p className="alE__header__para1">Total Earnings</p>
                  <p className="alE__header__para2">
                    Rs. {earnings?.totalPayoutAmount}
                  </p>
                </div>
                <div className="alE__header__1st">
                  <p className="alE__header__para1">Settled Earnings</p>
                  <p className="alE__header__para2">
                    Rs. {earnings?.settledPayoutAmount}
                  </p>
                </div>
              </div>
            </div>
            <div className="alE__body">
              <p className="alE__body__heading">Details</p>
              {earnings?.payouts
                ?.sort((a, b) => b.closureDate - a.closureDate)
                ?.map((payout) => {
                  return (
                    <div key={payout?.payoutId} className="alE__details">
                      <div className="alE__details__1st">
                        <p className="alE__details__para3">
                          {payout?.companyName}
                        </p>
                        <p className="alE__details__para4">
                          {/* Closure Date :{payout?.closureDate} */}
                          {new Date(payout?.closureDate * 1000)
                            ?.toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour12: true,
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                            .toUpperCase()}
                        </p>
                      </div>
                      <div className="alE__details__2nd">
                        <p className="alE__details__para1">
                          Rs. {payout?.payoutAmount}
                        </p>
                        <button
                          onClick={() => {
                            setPopOpen(true);
                            setOrderData(payout);
                          }}
                          className="alE__details__para2"
                        >
                          Details
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
            {IsPopOpen && (
              <Popup92 orderData={orderData} setPopOpen={setPopOpen} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TotalEarnings;
