import React from "react";

const IdtyText = ({ setBox, setShowToggle }) => {
  return (
    <div className="idty__text_wrapper">
      <p className="idty__text_item">
        Currently we only support Indian and NRI individuals for online identity
        verification.
      </p>
      <p className="idty__text_item">
        For all other types of entities we conduct an offline identity
        verification.
      </p>
      <p className="idty__text_item">
        We will run background identity checks on your entity and if it
        qualifies our checks we will get in touch with you on the contact
        details provided by you.
      </p>
      <p className="idty__text_item">
        Alternatively if you wish to expedite the process you can choose to
        <span
          style={{ color: "#2772FD" }}
          onClick={() => {
            setBox("company");
            setShowToggle(true);
          }}
        >
          {" "}
          continue as an individual
        </span>{" "}
        to complete the identity verification process online.
      </p>
    </div>
  );
};

export default IdtyText;
