import React, { useState, useEffect } from "react";
import "./Funding.css";
import ShareIcon from "@material-ui/icons/Share";
import Table from "../table/Table";
import { useHistory } from "react-router-dom";
import ShareScreen from "../../../../components/share-screen/ShareScreen";

const Funding = ({ funding, setActiveBox, get_company }) => {
  const history = useHistory();
  const [shareScreen, setShareScreen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState();
  const searchQuery = new URLSearchParams(history.location.search);
  const partnerName = searchQuery.get("partnerName");
  const fundingData = [
    {
      heading: funding?.fundingRounds,
      desc: "No of Funding Rounds",
    },
    {
      heading: funding?.fundingAmount,
      desc: "Total Funding Amount",
    },
    {
      heading: funding?.lastFundingRound,
      desc: "Last Funding Round",
    },
    {
      heading: funding?.noOfInvestors,
      desc: "No of Investors",
    },
    {
      heading: funding?.noOfInvestments,
      desc: "Investments",
    },
    {
      heading: funding?.acquisitions,
      desc: "Acquisitions",
    },
  ];
  useEffect(() => {
    setCurrentUrl(window.location.href);
    console.log(currentUrl);
  });

  const investorData = [];
  funding?.investorList?.forEach((item) => {
    if (item.isLeadInvestor) {
      investorData.push({ ...item, isLeadInvestor: "Yes" });
    } else {
      console.log("t");
      investorData.push({ ...item, isLeadInvestor: "No" });
    }
  });
  useEffect(() => {
    history.push({
      pathname: `${history.location.pathname}`,
      search: `?${
        partnerName ? `partnerName=${partnerName}&` : ""
      }option=funding`,
    });
    get_company();
    setActiveBox(1);
  }, []);

  return (
    <div className="funding__wrapper">
      {shareScreen && (
        <ShareScreen
          url={currentUrl}
          onClose={() => {
            setShareScreen(false);
          }}
        />
      )}
      <div className="funding__header">
        <span className="fh__text">Funding Summary</span>
        <span className="fh__icon">
          <ShareIcon onClick={() => setShareScreen(true)} />
        </span>
      </div>
      <div className="funding__body">
        {fundingData.map((item, index) => (
          <div className={`funding__item ${index % 2 !== 0 && "bordered"}`}>
            <span className="fi__heading">{item ? item.heading : null}</span>
            <span className="fi__desc">{item ? item.desc : null}</span>
          </div>
        ))}
      </div>
      <div className="funding__list">
        <div className="fl__item">
          {funding ? funding.fundingSummary : null}
        </div>
      </div>
      <p className="funding__theading">Investors</p>
      <Table
        headings={["Name", "Lead Investor?", "Round"]}
        data={investorData}
        colWidth={{ gridTemplateColumns: "2fr 1.2fr 1.2fr" }}
      />
      <p className="funding__theading">Funding Rounds</p>
      <Table
        headings={["Date", "Transaction", "No. of Investors", "Money raised"]}
        data={funding?.fundingRoundList}
        colWidth={{
          gridTemplateColumns: "7fr 15fr 2fr 2fr",
        }}
      />
    </div>
  );
};

export default Funding;
