import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import TextField from "@material-ui/core/TextField";

import BuyerInfo from "../../OrderModification/Components/BuyerInfo/BuyerInfo";
import CheckboxComponent from "../../../components/ConfirmCheckbox/CheckboxComponent";
import Button from "../../../components/button/Button";
import BottomSlide from "../../../components/bottom-slide/BottomSlide";

import { toast } from "react-toastify";

import {
  getCompany,
  registerOtpGen,
  registerShare,
  unRegisterOtpGen,
  unRegisterShare,
  modifyRegisterShare,
} from "../../../api/order";
import { get_resources } from "../../../api/dashboard";

import { TailSpin } from "react-loader-spinner";

import "./ValidateOrder.scss";

const ValidateOrder = () => {
  const history = useHistory();
  const location = useLocation();

  let orderId = new URLSearchParams(location.search).get("orderId");
  let client = new URLSearchParams(location.search).get("client");

  const [orderInfo, setOrderInfo] = useState(null);
  const [company, setCompany] = useState(null);
  const [share, setShare] = useState(null);
  const [partner, setPartner] = useState(null);

  const [oldOrder, setOldOrder] = useState(null);
  let [expireTime, setExpireTime] = useState(null);
  let [oldExpireTime, setOldExpireTime] = useState(null);

  const [email, setEmail] = useState(null);
  const [validEmail, setValidEmail] = useState(null);

  const [error, setError] = useState(null);
  const [openSlide, setOpenSlide] = useState({
    open: false,
    div: null,
  });

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      if (client === "register" || client === "cancel") {
        let res = await registerShare(orderId);
        if (res.error != null) {
          toast.error(res.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setOrderInfo(res.data);
          setShare({
            shares: res.data.qty,
            price: res.data.price,
          });
          setValidEmail(res.data.placedForEmail);
          setEmail(res.data.placedForEmail);
          let exTime = new Date(res.data.expiryTimestamp * 1000);
          setExpireTime(exTime);
        }
      } else if (client === "unregister") {
        let data = await unRegisterShare(orderId);
        if (data.error != null) {
          toast.error(data.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setOrderInfo(data.data);
          setShare({
            shares: data.data.orderDetails.qty,
            price: data.data.orderDetails.price,
          });
          setValidEmail(data.data.placedForContactInformation.emailId);
          setEmail(data.data.placedForContactInformation.emailId);
        }
      } else {
        let res = await modifyRegisterShare(orderId);
        if (res.error != null) {
          toast.error(res.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          let oldt = new Date(res.data.oldOrder.expiryTimestamp * 1000);
          let newt = new Date(res.data.updatedOrder.expiryTimestamp * 1000);
          setOrderInfo(res.data.updatedOrder);
          setShare({
            shares: res.data.updatedOrder.qty,
            price: res.data.updatedOrder.price,
          });
          setValidEmail(res.data.updatedOrder.placedForEmail);
          setEmail(res.data.updatedOrder.placedForEmail);
          setOldOrder(res.data.oldOrder);
          setOldExpireTime(oldt);
          setExpireTime(newt);
        }
      }
    };

    getData();
  }, []);
  useEffect(() => {
    if (orderInfo != null) {
      Promise.all([gettingCompany(), getPartner()])
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          toast.error("Unable to Process Request", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }, [orderInfo]);

  const gettingCompany = async () => {
    let res = await getCompany(
      null,
      client === "unregister" ? orderInfo.orderDetails.symbol : orderInfo.symbol
    );
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setCompany(res.data);
    }
  };
  const getPartner = async () => {
    let res = await get_resources(null, orderInfo.partnerUserName);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setPartner(res.data);
    }
  };

  const enteringEmail = (event) => {
    setEmail(event.target.value);
    setError(null);
  };

  const validate = async () => {
    if (email !== validEmail) {
      setError(true);
    } else {
      if (client === "unregister") {
        let res = await unRegisterOtpGen(orderInfo.orderId);
        if (res.error != null) {
          toast.error(res.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          history.push({
            pathname: "/partner-otp",
            state: {
              orderInfo,
              email,
              client: "unregister",
              partner: partner.data,
            },
          });
        }
      } else {
        let res = await registerOtpGen(email);
        if (res.error != null) {
          toast.error(res.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.log(partner.data);
          history.push({
            pathname: "/partner-otp",
            state: {
              company,
              orderInfo,
              email,
              client: client,
              partner: partner.data,
            },
          });
        }
      }
    }
  };

  return (
    <>
      {isLoading !== true ? (
        <>
          <div className="vo__container">
            <div className="vo__upper">
              <div className="vo__header">
                <div className="vo__heading">
                  <img src={partner.data.partnerLogo} alt="" />
                </div>
                <p className="vo__header__heading">
                  Validate{" "}
                  <span>
                    {client === "modify"
                      ? "order modification"
                      : client === "cancel"
                      ? "order cancellation"
                      : "order"}{" "}
                  </span>
                </p>
                <p className="vo__header__para">
                  Please validate your intent to{" "}
                  {client === "modify"
                    ? "modify order"
                    : client === "cancel"
                    ? "cancel order"
                    : "place an order as stated below and confirm that your details are correct"}{" "}
                  :
                </p>

                <div className="vo__userInfo__comp">
                  {client === "modify" ? (
                    <>
                      <p
                        style={{
                          textAlign: "center",
                          margin: ".5rem",
                          color: "gray",
                          fontSize: ".8rem",
                        }}
                      >
                        {" "}
                        Current order{" "}
                      </p>
                      <BuyerInfo
                        path="order-modification"
                        data={company}
                        detail={oldOrder}
                        time={oldExpireTime}
                      />
                      <p
                        style={{
                          textAlign: "center",
                          margin: ".5rem",
                          marginTop: "2rem",
                          color: "gray",
                          fontSize: ".8rem",
                        }}
                      >
                        {" "}
                        Order modification{" "}
                      </p>
                      <BuyerInfo
                        path="order-modification"
                        data={company}
                        detail={orderInfo}
                        time={expireTime}
                      />
                    </>
                  ) : client === "cancel" ? (
                    <>
                      <BuyerInfo
                        path="order-modification"
                        data={company}
                        detail={orderInfo}
                        time={expireTime}
                      />
                    </>
                  ) : (
                    <BuyerInfo
                      path="confirmorder"
                      data={company}
                      quote={share}
                      detail={orderInfo}
                    />
                  )}
                </div>

                <div className="vo__input">
                  <p className="vo__input1__heading">Email ID</p>
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    variant="filled"
                    value={email}
                    onChange={enteringEmail}
                  />
                  {error && (
                    <p
                      style={{
                        fontSize: ".7rem",
                        margin: ".3rem",
                        color: "red",
                      }}
                    >
                      {" "}
                      Email does not match with Order's email.{" "}
                    </p>
                  )}
                </div>
                {/* <div className="vo__input">
                                        <p className="vo__input1__heading">
                                            Mobile Number
                                        </p>
                                        <TextField
                                            style={{ width: "100%" }}
                                            id="outlined-basic"
                                            type="number"
                                            variant="filled"
                                        />
                                    </div> */}
              </div>
              <CheckboxComponent />
            </div>

            <div className="vo__lower">
              <Button text="VALIDATE" click={validate} />
            </div>

            {openSlide.open && (
              <BottomSlide bottomSlide={() => setOpenSlide(false)}>
                {openSlide.div === "addfunds" ? <></> : null}
              </BottomSlide>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            top: "40vh",
          }}
        >
          <TailSpin color="#00BFFF" height={80} width={80} />
        </div>
      )}
    </>
  );
};

export default ValidateOrder;
