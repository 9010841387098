import React, { useState, useEffect, useRef } from "react";

import { get_resources } from "../../api/dashboard";

import { useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import BtnBar from "../../screens/dashboard/components/btn-bar/BtnBar";
import {
  getDefaultUserId,
  readPartnerNameFromLS,
} from "../../api/localstorage";

export default function LogoLogic({ pagelogo }) {
  const history = useHistory();
  const { user } = useSelector((state) => state);
  const searchQuery = new URLSearchParams(history.location.search);
  const useriD = user?.profile?.userId || getDefaultUserId();
  const partnerName =
    searchQuery.get("partnerName") || readPartnerNameFromLS(useriD);

  const [activePage, setActivePage] = useState(1);
  const [incomplete, setIncomplete] = useState(false);
  const [count, setCount] = useState(0);

  const handlePageChange = (value) => {
    setActivePage(value);
  };

  const [pageLogoNew, setPageLogo] = useState("");

  const get_logo = async () => {
    let response = await get_resources(
      user.token != null ? true : false,
      partnerName !== null ? partnerName : false,
      user.token
    );
    if (response.error) {
      console.log("Could not get logo");
    } else {
      setPageLogo(response?.data?.data);
    }
  };

  useEffect(() => get_logo(), []);

  return (
    <>
      {incomplete ? (
        <BtnBar />
      ) : (
        <>
          <div className="dashboard__page_logo">
            <img
              src={
                // "https://cdn.worldvectorlogo.com/logos/novonto-logo-long-with-text.svg"
                // "https://i.ibb.co/VLp7HFc/Screenshot-2022-09-17-192316.png"
                // ||
                pagelogo || pageLogoNew?.partnerLogo
              }
              alt={pageLogoNew?.organisationName}
              loading="lazy"
            />
          </div>
        </>
      )}
    </>
  );
}
