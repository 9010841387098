import React from 'react'

import Button from "../../../components/button/Button"

import "./SendLink.scss"

const SendLink = (props) => {
    return (
        <>
            <div className="sendlink_container">
                <div className="box">
                    <div className="heading"> Send order for <span style={{ color: "blue" }}> {props.path && props.path == "modify" ? "modification" : "cancellation"} </span> </div>
                    <div className="detail"> we will send a link to your client, via email on {props.data.emailId} {props.data.mobileNumber == 0 ? " " : ` and via sms on ${props.data.mobileNumber}`} </div>
                    <div className="detail"> they will need to confirm the {props.path && props.path == "modify" ? "modification" : "cancellation"} by clicking the link sent to them. </div>
                </div>
                <Button text={`SEND ${props.path && props.path == "modify" ? "MODIFICATION " : "CANCELLATION"} REQUEST TO CLIENT`} click={props.action} />
            </div>
        </>
    )
}

export default SendLink
