import axios from "../axiosInstance"
export const all_orders = async (token) => {
    let resolved = {
        data: null,
        error: null,
    };

    try {
        resolved.data = await axios({
            url: `/api/v1/order/views/dashboard`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        if (err.response) {
            resolved.error = err.response;
        } else {
            resolved.error = "SomeThing went Wrong";
        }
    }

    return resolved;
};

export const order_details = async (token, id) => {
    let resolved = {
        data: null,
        error: null,
    };

    try {
        resolved.data = await axios({
            url: `/api/v1/order/views/dashboard/${id}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        if (err.response) {
            resolved.error = err.response;
        } else {
            resolved.error = "SomeThing went Wrong";
        }
    }

    return resolved;
};

export const dematDetails = async (token, targetBoId, targetDPId) => {
    let resolved = {
        data: null,
        error: null,
    };

    try {
        resolved.data = await axios({
            url: `/api/v1/demat/id/fundsmap?boId=${targetBoId}&dpId=${targetDPId}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        if (err.response) {
            resolved.error = err.response;
        } else {
            resolved.error = "SomeThing went Wrong";
        }
    }

    return resolved;
};

export const download_signed_document = async (token, id) => {
    let resolved = {
        data: null,
        error: null,
    };

    try {
        resolved.data = await axios({
            url: `/api/v1/orderContract/download?orderId=${id}&version=1`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob'
        });
    } catch (err) {
        if (err.response) {
            if(err.response.status){
                resolved.error = "Unable to fetch signed document right now. Please try after some time";
            }else{
                resolved.error = err.response;
            }
        } else {
            resolved.error = "SomeThing went Wrong";
        }
    }

    return resolved;
};

export const get_trades = async (token, id) => {
    let resolved = {
        data: null,
        error: null,
    };

    try {
        resolved.data = await axios({
            url: `/api/v1/fills/fillSnapshot?orderId=${id}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        if (err.response) {
            resolved.error = err.response;
        } else {
            resolved.error = "SomeThing went Wrong";
        }
    }
    return resolved;
};
export const get_trade = async (token, id) => {
    let resolved = {
        data: null,
        error: null,
    };

    try {
        resolved.data = await axios({
            url: `/api/v1/fills/fillSnapshot/${id}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        if (err.response) {
            resolved.error = err.response;
        } else {
            resolved.error = "SomeThing went Wrong";
        }
    }
    return resolved;
};

export const share_obligation = async (token, id) => {
    let resolved = {
        data: null,
        error: null,
    };
    try {
        resolved.data = await axios({
            url: `/api/v1/client/shareObligations/${id}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        if (err.response) {
            resolved.error = err.response;
        } else {
            resolved.error = "SomeThing went Wrong";
        }
    }
    return resolved;
};



export const fund_obligation = async (token, id) => {
    let resolved = {
        data: null,
        error: null,
    };
    try {
        resolved.data = await axios({
            url: `/api/v1/client/fundObligations/${id}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        if (err.response) {
            resolved.error = err.response;
        } else {
            resolved.error = "SomeThing went Wrong";
        }
    }
    return resolved;
};

export const closure_details = async (token, fillId, closureId) => {
    let resolved = {
        data: null,
        error: null,
    };
    try {
        resolved.data = await axios({
            url: `/api/v1/fills/${fillId}/closures/${closureId}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        if (err.response) {
            resolved.error = err.response;
        } else {
            resolved.error = "SomeThing went Wrong";
        }
    }
    return resolved;
};