import { useState } from "react";
import { toast } from "react-toastify";
import { genOtp, getOrderDetail } from "../api/order";

import Progress from "@material-ui/core/CircularProgress";
import { opentheSlide } from ".";

const Confirm = ({
  orderData,
  setOpenslide,
  user,
  setShareDetail,
  setOtpLoader,
}) => {
  const [loading, setLoading] = useState(false);
  const goToCancel = async () => {
    if (user?.profile?.userId != orderData.placedForUid) {
      setLoading(true);
      let res = await getOrderDetail(
        user.token,
        orderData.orderRequestId,
        orderData.version
      );
      if (res.error != null) {
        setLoading(false);
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setShareDetail(res.data);
        opentheSlide("sendlink", setOpenslide);
      }
    } else {
      setLoading(true);
      let data = await genOtp(
        user.token,
        `${orderData.orderRequestId}${orderData.version}`
      );
      if (data.error != null) {
        setLoading(false);
        toast.error(data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setOtpLoader(false);
        opentheSlide("otp", setOpenslide);
      }
    }
  };
  return (
    <div className="confirmorder_box">
      <div className="heading">
        <div> Order Cancellation </div>
        <p style={{ marginLeft: "4px" }}>
          {" "}
          Are you sure you want to cancel current order? <br /> This action
          cannot be undone.{" "}
        </p>
      </div>
      <div className="btn">
        <button onClick={goToCancel} disabled={loading}>
          {loading ? <Progress /> : " YES, CANCEL ORDER"}
        </button>
      </div>
    </div>
  );
};

export default Confirm;
