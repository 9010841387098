import axios from '../axiosInstance'

const registerEmail = async (email, by, partnerName) => {
    let resolved = {
        data: null,
        error: null
    }

    try {
        const res = await axios({
            url: by ? `api/v1/users/continue-with-email?referredBy=${by}` : "api/v1/users/continue-with-email",
            method: "POST",
            headers: {
                Authorization: null
            },
            data: {
                "email": email,
                "partnerName": partnerName,
            }
        })
        resolved.data = res.data
    } catch (err) {
        if (err.response) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "SomeThing Went Wrong"
        }
    }

    return resolved;
}

const loginOtp = async (email, otp) => {
    let resolved = {
        data: null,
        error: null
    }

    try {
        const res = await axios({
            url: `api/v1/users/login?type=EMAIL&email=${email}&otp=${otp}&mode=OTP`,
            method: "POST",
            headers: {
                Authorization: null
            },
        })
        resolved.data = res.data
    } catch (err) {
        if (err.response) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "SomeThing Went Wrong"
        }
    }

    return resolved;
}

const registerOtp = async (token, otp) => {
    let resolved = {
        data: null,
        error: null
    }

    try {
        const res = await axios({
            url: `api/v1/users/verify-email-otp`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                "otp": parseFloat(otp)
            }
        })
        resolved.data = res.data
    } catch (err) {
        if (err.response) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "SomeThing Went Wrong"
        }
    }

    return resolved;
}

const resendRegisterOtp = async (email) => {
    let resolved = {
        data: null,
        error: null
    }

    try {
        const res = await axios({
            url: `api/v1/users/login/otp?type=EMAIL&email=${email}`,
            method: "POST",
            headers: {
                Authorization: null
            },
        })
        resolved.data = res.data
    } catch (err) {
        if (err.response) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "SomeThing Went Wrong"
        }
    }

    return resolved;
}

const resendUnRegisterOtp = async (token, email) => {
    let resolved = {
        data: null,
        error: null
    }

    try {
        const res = await axios({
            url: `api/v1/users/send-email-verification-otp`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                "email": email
            }
        })
        resolved.data = res.data
    } catch (err) {
        if (err.response) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "SomeThing Went Wrong"
        }
    }

    return resolved;
}

export { registerEmail, loginOtp, registerOtp, resendRegisterOtp, resendUnRegisterOtp }