import React, { useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import "../VerifyIdentity.css";

const IdtyCompany = ({ self, setSelf, toggleView }) => {
  return (
    <div className="idtycomp__wrapper">
      <FormControl component="fieldset">
        {/* <span component="legend" className="idtycomp__heading">
          I'm an:
        </span> */}
        <RadioGroup
          aria-label="self"
          name="self"
          className="idtycomp__body"
          value={self}
          onChange={(e) => setSelf(e.target.value)}
        >
          {(toggleView === "Individual" || toggleView === null) && (
            <>
              <FormControlLabel
                value="indian"
                control={<Radio />}
                label="Indvidual (Indian Resident)"
              />
              <FormControlLabel
                value="INDIVIDUAL_NRI"
                control={<Radio />}
                label="Individual (NRI - Non-Resident Indian)"
              />
              <FormControlLabel
                value="INDIVIDUAL_FOREIGN"
                control={<Radio />}
                label="Individual (Foreign National)"
              />
            </>
          )}
          {/* <span component="legend" className="idtycomp__heading">
            I'm representing a/an:
          </span> */}
          {toggleView === "Company" && (
            <div style={{
              padding:"50px 0"
            }}>
              We are excited to get your business onboarded.
              We’ll require some details from you to verify your company. 
              Once the company is verified we can start transacting!
              {/* <FormControlLabel
                value="COMPANY_INDIAN_PVT"
                control={<Radio />}
                label="Indian Company (Pvt. Ltd.)"
              />
              <FormControlLabel
                value="COMPANY_INDIAN_LLP"
                control={<Radio />}
                label="Indian Company (LLP)"
              />
              <FormControlLabel
                value="COMPANY_INDIAN_PARTNERSHIP"
                control={<Radio />}
                label="Indian Company (Partnership)"
              />
              <FormControlLabel
                value="COMPANY_INDIAN_SOLE_PROPRIETORSHIP"
                control={<Radio />}
                label="Indian Company (Sole Proprietorship)"
              />
              <FormControlLabel
                value="COMPANY_NON_INDIAN"
                control={<Radio />}
                label="Non-Indian Company"
              />
              <FormControlLabel
                value="OTHER"
                control={<Radio />}
                label="Other"
              /> */}
            </div>
          )}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default IdtyCompany;
