const HANDLE_CONFIRM_OPEN = 'HANDLE_CONFIRM_OPEN'
const HANDLE_CONFIRM = 'HANDLE_CONFIRM'

const initialState = {confirmModel:{open:false,confirmed:false}}



export const handle_confirm_open = (open) =>{

    return{
      type:HANDLE_CONFIRM_OPEN,
      payload:open
    }

} 
export const handle_confirm= (confirmed) =>{

    return{
      type:HANDLE_CONFIRM,
      payload:confirmed
    }

} 

const reducer = (state = initialState, action) => {
    switch(action.type)
    {
        case HANDLE_CONFIRM_OPEN:
            return {...state , confirmModel:{...state.confirmModel , open:action.payload}}
        case HANDLE_CONFIRM:
            return {...state , confirmModel:{...state.confirmModel , confirmed:action.payload}}
        default:
            return state
    }
}

export default reducer