import { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Addlogo.css";
import BackupIcon from "@material-ui/icons/Backup";

import Screenshot from "../../../Assets/imgs/dashboardscreen.jpeg";
import ScreenLoader from "../../../components/screen-loader/ScreenLoader";

const Addlogo = () => {
  const [uploadBtn, setUploadBtn] = useState(true);
  const [logo, setLogo] = useState("");
  const [screenLoading, setScreenLoading] = useState();
  const history = useHistory();

  const handleChange = (e) => {
    setLogo(e.target.files[0]);
    const file = e.target.files[0];
    console.log(file);
    const data = new FormData();
    data.append("file", file);
    setLogo("");
  };

  return (
    <div className="Addlogo__container">
      {screenLoading && <ScreenLoader />}
      <div className="Addlogo__footer">
        <div
          className="Addlogo__btnns"
          style={!uploadBtn ? { display: "none" } : null}
        >
          <label className="addlogo__btn1" for="addlogoinput">
            <input
              type="file"
              className="logo__input"
              id="addlogoinput"
              accept="image/png, image/gif, image/jpeg"
              onChange={handleChange}
              value={logo}
            />
            <div className="footer__logo">
              <BackupIcon />
            </div>
            <div className="footer__text">UPLOAD YOUR LOGO</div>
          </label>
          <button
            className="addlogo__btn2"
            onClick={() => {
              history.push("/dashboard");
              setUploadBtn(false);
            }}
          >
            <div className="btn2__or">or</div>
            <div className="btn2__text">Upload later</div>
          </button>
        </div>
      </div>
      <div className="Addlogo__header">
        <div className="header__heading">
          Add your logo to<span>your website</span>
        </div>
      </div>
      <div className="Addlogo__body">
        <div className="Addlogo__title">
          <h5>Your logo goes here</h5>
        </div>
      </div>
      <img
        src={Screenshot}
        className="dashboard__screenshot"
        alt="screenshot"
      />
    </div>
  );
};

export default Addlogo;
