import { useState, useEffect } from "react";
import "./ShareScreen.css";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const ShareScreen = ({ onClose, url, text }) => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    handleOpen();
  }, []);

  const copyToClipBorad = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="share__screen_modal"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            className="share__screen_box"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="share__screen_button">
              <WhatsappShareButton url={url}>
                <WhatsappIcon />
              </WhatsappShareButton>
            </span>
            <span className="share__screen_button">
              <FacebookShareButton url={url}>
                <FacebookIcon />
              </FacebookShareButton>
            </span>
            <span className="share__screen_button">
              <TwitterShareButton url={url}>
                <TwitterIcon />
              </TwitterShareButton>
            </span>
            <span className="share__screen_button">
              <EmailShareButton url={url}>
                <EmailIcon />
              </EmailShareButton>
            </span>
            <span className="share__screen_button">
              <TelegramShareButton url={url}>
                <TelegramIcon />
              </TelegramShareButton>
            </span>
            <button
              className="share__screen_button"
              onClick={() => copyToClipBorad(url)}
            >
              <span className="ss__clipboard_section">
                <span
                  className="sscs__text"
                  style={{ color: copied ? "grey" : "#007aff" }}
                >
                  {copied ? "Copied" : "Copy"}
                </span>{" "}
                <FilterNoneIcon
                  className="copytoclipboard__icon"
                  style={{ color: copied ? "grey" : "#007aff" }}
                />
              </span>
            </button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ShareScreen;
