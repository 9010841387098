import React from "react";

const BlurredQuotesBox = () => {
  return (
    <div className="blurred-box">
      <div className="blurred-message">
        Please get a valid link from your Partner to view active prices. Or if
        you already have an account, please sign-in.
      </div>
      <div className="quotes_box blurred noselect">
        <div className="quotesheading">
          <div className="heading">Quotes</div>
          <div className="bestbid">
            <div className="title">Best Bid</div>
            <div className="price">123.32</div>
            <div className="detail">Best price to divest</div>
          </div>
          <div className="bestoffer">
            <div className="title">Best offer</div>
            <div className="price">123.32</div>
            <div className="detail">Best price to invest</div>
          </div>
        </div>
        <div className="quoteslist">
          <div className="bids">
            <div className="price">
              <p className="hea"> Bids </p>
              <p> 123.32</p>
              {/* <p> 123.32</p>
              <p> 123.32</p> */}
            </div>
            <div className="qty">
              <p className="hea"> Min Qty </p>
              <p> 123.32</p>
              {/* <p> 123.32</p>
              <p> 123.32</p> */}
            </div>
          </div>
          <div className="line"></div>
          <div className="offers">
            <div className="price">
              <p className="hea"> Offers </p>
              <p> 123.32</p>
              {/* <p> 123.32</p>
              <p> 123.32</p> */}
            </div>
            <div className="qty">
              <p className="hea"> Min Qty </p>
              <p> 123.32</p>
              {/* <p> 123.32</p>
              <p> 123.32</p> */}
            </div>
          </div>
        </div>
        <div className="totalquots">
          <div className="totalbid">
            <div className="qty">
              <div className="title">Total Bid Qty</div>
              <div className="price">123.32</div>
            </div>
          </div>
          <div className="totaloffer">
            <div className="qty">
              <div className="title">Total Offer Qty</div>
              <div className="price">123.32</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlurredQuotesBox;
