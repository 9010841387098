import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
// import DbCard from "./components/dbcard/DbCard";
import Slide from "./components/slide/Slide";
import "./Dashboard.css";
import BtnBar from "./components/btn-bar/BtnBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import CompleteProfile from "./components/complete-profile/CompleteProfile";

import {
  dashboard_data,
  dashboard_filtered_data,
  get_resources,
} from "../../api/dashboard";
import { useHistory } from "react-router-dom";
import ScreenLoader from "../../components/screen-loader/ScreenLoader";
import { useSelector } from "react-redux";
import SearchScreen from "./components/search-screen/SearchScreen";
import ClientpartnerMenu from "../../components/client-partner-menu/ClientPartnerMenu";
import { profile_details } from "../../api/profile";
import {
  writePartnerNameToLS,
  readPartnerNameFromLS,
  getDefaultUserId,
} from "../../api/localstorage";
import Updated from "./components/updated/Updated";
import { whoami } from "../../api/auth";
import { DbCardLoader } from "../../components/loadingSkelecton/loadingSkeleton";

// lazy components
const DbCard = lazy(() => import("./components/dbcard/DbCard"));

const Dashboard = ({ hideLogo }) => {
  const user = useSelector((state) => state.user);
  const [dashboard, setDashboard] = useState([]);
  const incomplete = false;
  const [content, setContent] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [data, setData] = useState({});
  const [activeId, setActiveId] = useState(0);
  const [screenLoading, setScreenLoading] = useState();
  const [bottomLoading, setBottomLoading] = useState(true);
  const [pageLogo, setPageLogo] = useState("");
  const [searchScreen, setSearchScreen] = useState(false);
  const [personalised, setPersonalised] = useState();
  const [whatsappBox, setWhatsappBox] = useState(false);
  const [stopLoader, setStopLoader] = useState(false);
  const [goingUp, setGoingUp] = useState(true);
  const [loadedPages, setLoadedPages] = useState([1]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [partnerInUrl, setPartnerInUrl] = useState(null);
  const slideItems = ["All", "2 Way Quote", "Fresh Round", "Gauging Interest"];
  const history = useHistory();
  const [myData, setMyData] = useState({});

  const loader = useRef();
  const searchQuery = new URLSearchParams(history.location.search);
  const useriD = user?.profile?.userId || getDefaultUserId();
  const partnerName =
    searchQuery.get("partnerName") || readPartnerNameFromLS(useriD);
  const listingType = searchQuery.get("listingType");
  const prevScroll = useRef(0);

  const token = useSelector((state) => state.user.token);
  const handlePageChange = (value) => {
    setActivePage(value);
  };

  const handle_scroll = (e) => {
    setScrollPosition(e.target.scrollTop);
    if (prevScroll.current < e.target.scrollTop && goingUp) {
      setGoingUp(false);
    }
    if (prevScroll.current > e.target.scrollTop && !goingUp) {
      setGoingUp(true);
    }
    prevScroll.current = e.target.scrollTop;
  };
  const my_info = async () => {
    let res = await whoami(token);
    if (res.error) {
      console.log(res.error);
    } else {
      setMyData(res?.data?.data);
    }
  };
  useEffect(() => {
    if (partnerInUrl === true) {
      searchQuery.delete("partnerName");
      history.replace({
        search: searchQuery.toString(),
      });
      if (searchQuery.get("partnerName") == null) {
        setPartnerInUrl(1);
      }
    }
    if (partnerInUrl === 1) {
      if (searchQuery.get("partnerName") != null) {
        searchQuery.delete("partnerName");
        history.replace({
          search: searchQuery.toString(),
        });
        setPartnerInUrl(false);
      }
    }
  }, [partnerInUrl, searchQuery]);

  const get_logo = async () => {
    let response = await get_resources(
      personalised,
      partnerName !== null ? partnerName : false,
      token
    );
    if (response.error) {
      console.log(response.error);
    } else {
      setPageLogo(response?.data?.data?.partnerLogo);

      if (
        user?.profile?.userType === "INDIVIDUAL_CLIENT" ||
        user?.profile === null
      ) {
        //check for 0 user in ls
        // if yes then after user is  1 set to state 1
        const partner = response?.data?.data?.partnerName || undefined;
        const uid = user?.profile == null ? 0 : user.profile.userId;
        if (partner) {
          writePartnerNameToLS(uid, partner);
        } else {
          setPartnerInUrl(true);
        }
      }
    }
  };
  const get_companies = async (page) => {
    if (activePage == 1) {
      setLoadedPages([1]);
    } else if (page) {
      setActivePage(page);
    } else {
      setLoadedPages([...loadedPages, activePage]);
    }

    if (personalised === true || personalised === false) {
      // page && setActivePage(page);
      setScreenLoading(true);
      let response = await dashboard_data(
        personalised,
        partnerName !== null ? partnerName : false,
        token,
        page ? page : activePage
      );
      if (
        history.location.pathname === "/" ||
        history.location.pathname === "/dashboard" ||
        history.location.pathname === "/dashboard/"
      ) {
        if (response.error) {
          setScreenLoading(false);
        } else {
          setActiveId(0);
          setContent(response?.data?.data?.content);
          setData(response?.data?.data);
          page &&
            page < response?.data?.data?.totalPages &&
            setBottomLoading(true);
          if (partnerName == null) {
            history.push("/dashboard");
          } else {
            history.push({
              pathname: "/dashboard",
              search: "partnerName=" + partnerName,
            });
          }
          setScreenLoading(false);
        }
      }
    }
  };

  const two_way_quotes = async (page) => {
    if (activePage == 1) {
      setLoadedPages([1]);
    } else if (page) {
      setActivePage(page);
    } else {
      setLoadedPages([...loadedPages, activePage]);
    }
    // page && setActivePage(page);
    setScreenLoading(true);
    let response = await dashboard_filtered_data(
      "TWO_WAY",
      personalised,
      partnerName !== null ? partnerName : false,
      token,
      page ? page : activePage
    );
    if (
      history.location.pathname === "/" ||
      history.location.pathname === "/dashboard"
    ) {
      if (response.error) {
        setScreenLoading(false);
        //alert(response.error);
      } else {
        setActiveId(1);
        setContent(response?.data?.data?.content);
        setData(response?.data?.data);
        page &&
          page < response?.data?.data?.totalPages &&
          setBottomLoading(true);
        if (partnerName == null) {
          history.push({
            pathname: "/dashboard",
            search: "?listingType=TWO_WAY",
          });
        } else {
          history.push({
            pathname: "/dashboard",
            search: "?partnerName=" + partnerName + "&listingType=TWO_WAY",
          });
        }

        setScreenLoading(false);
      }
    }
  };
  const fresh_round = async (page) => {
    if (activePage == 1) {
      setLoadedPages([1]);
    } else if (page) {
      setActivePage(page);
    } else {
      setLoadedPages([...loadedPages, activePage]);
    }
    // page && setActivePage(page);
    setScreenLoading(true);
    let response = await dashboard_filtered_data(
      "FRESH",
      personalised,
      partnerName !== null ? partnerName : false,
      token,
      page ? page : activePage
    );
    if (
      history.location.pathname === "/" ||
      history.location.pathname === "/dashboard"
    ) {
      if (response.error) {
        setScreenLoading(false);
        //alert(response.error);
      } else {
        setActiveId(2);
        setContent(response?.data?.data?.content);
        setData(response?.data?.data);
        page &&
          page < response?.data?.data?.totalPages &&
          setBottomLoading(true);
        if (partnerName == null) {
          history.push({
            pathname: "/dashboard",
            search: "?listingType=FRESH",
          });
        } else {
          history.push({
            pathname: "/dashboard",
            search: "?partnerName=" + partnerName + "&listingType=FRESH",
          });
        }

        setScreenLoading(false);
      }
    }
  };
  const gauging_interest = async (page) => {
    if (activePage == 1) {
      setLoadedPages([1]);
    } else if (page) {
      setActivePage(page);
    } else {
      setLoadedPages([...loadedPages, activePage]);
    }
    // page && setActivePage(page);
    setScreenLoading(true);
    let response = await dashboard_filtered_data(
      "GAUGING_INTEREST",
      personalised,
      partnerName !== null ? partnerName : false,
      token,
      page ? page : activePage
    );
    if (
      history.location.pathname === "/" ||
      history.location.pathname === "/dashboard"
    ) {
      if (response.error) {
        setScreenLoading(false);
        //alert(response.error);
      } else {
        setActiveId(3);
        setContent(response?.data?.data?.content);
        setData(response?.data?.data);
        page &&
          page < response?.data?.data?.totalPages &&
          setBottomLoading(true);
        if (partnerName == null) {
          history.push({
            pathname: "/dashboard",
            search: "?listingType=GAUGING_INTEREST",
          });
        } else {
          history.push({
            pathname: "/dashboard",
            search:
              "?partnerName=" + partnerName + "&listingType=GAUGING_INTEREST",
          });
        }
        setScreenLoading(false);
      }
    }
  };

  const load_dashboard = () => {
    if (listingType == "TWO_WAY") {
      two_way_quotes(1);
    } else if (listingType == "FRESH") {
      fresh_round(1);
    } else if (listingType == "GAUGING_INTEREST") {
      gauging_interest(1);
    } else {
      get_companies(1);
    }
  };
  const get_details = async () => {
    if (token && token !== null) {
      const details = await profile_details(token);
      if (details.error) {
        console.log(details.error);
      } else {
        if (details.data) {
          const { mobileVerified } = details.data.data;
          if (!mobileVerified) {
            setTimeout(() => {
              setWhatsappBox(true);
            }, 45000);
          } else {
            setWhatsappBox(false);
          }
        }
      }
    } else {
      setTimeout(() => {
        setWhatsappBox(true);
      }, 45000);
    }
  };
  const handleObserver = (entries) => {
    if (entries[0].isIntersecting) {
      if (activePage < data.totalPages && !stopLoader) {
        if (activePage > Math.max(...loadedPages)) {
          setActivePage(activePage + 1);
          setStopLoader(true);
        } else {
          setActivePage(Math.max(...loadedPages) + 1);
          setStopLoader(true);
        }
      }
    }
  };
  useEffect(() => {
    const options = {
      root: null,
      RootMargin: "0px 0px -20px 0px",
      threshold: [...Array(100).keys()].map((x) => x / 100),
    };

    const observer = new IntersectionObserver(handleObserver, options);

    loader.current && observer.observe(loader.current);
  }, [handleObserver]);
  useEffect(() => {
    if (token !== null) {
      setPersonalised(true);
      setData([]);
      setContent([]);
      setDashboard([]);
      my_info();
    } else {
      setPersonalised(false);
    }
  }, []);
  useEffect(() => {
    if (personalised === true) {
      get_logo();
      load_dashboard();
      setTimeout(get_details, 4500);
    } else if (personalised === false) {
      get_logo();
      load_dashboard();
      setTimeout(get_details, 4500);
    }
  }, [personalised]);
  useEffect(() => {
    let methods = [
      get_companies,
      two_way_quotes,
      fresh_round,
      gauging_interest,
    ];
    let curpage = activePage;
    if (activePage > 1 && !loadedPages.includes(activePage)) {
      methods[activeId]();
    }

    activePage >= data.totalPages && setBottomLoading(false);
  }, [activePage]);

  //getting unique companies list
  useEffect(() => {
    if (content) {
      if (activeId > 0) {
        const filteredDuplicateData = [...dashboard, ...content];
        const map1 = new Map();
        let unique = [];
        for (const item of filteredDuplicateData) {
          if (!map1.has(item.symbol)) {
            if (activeId == 3) {
              if (item.listingStage == "GAUGING") {
                map1.set(item.symbol, true);
                unique.push(item);
              }
            } else {
              if (
                item.type == "FRESH" &&
                item.listingStage != "GAUGING" &&
                activeId == 2
              ) {
                map1.set(item.symbol, true);
                unique.push(item);
              } else if (item.type == "TWO_WAY" && activeId == 1) {
                map1.set(item.symbol, true);
                unique.push(item);
              }
            }
          }
        }
        setDashboard(unique);
      } else {
        const duplicateData = [...dashboard, ...content];
        const map1 = new Map();
        let unique = [];
        for (const item of duplicateData) {
          if (!map1.has(item.symbol)) {
            map1.set(item.symbol, true);
            unique.push(item);
          }
        }
        setDashboard(unique);
      }
      setStopLoader(false);
    }
  }, [content]);

  return (
    <div className="dashboard__wrapper" onScroll={handle_scroll}>
      <div className="dashboard__header">
        {searchScreen && (
          <SearchScreen onClose={() => setSearchScreen(false)} />
        )}
        {!hideLogo && (
          <>
            {incomplete ? (
              <BtnBar />
            ) : (
              <>
                <div className="dashboard__page_logo">
                  <img src={pageLogo} />
                </div>{" "}
              </>
            )}
          </>
        )}
        {whatsappBox && <Updated setOpen={setWhatsappBox} />}
        {incomplete && <CompleteProfile />}
        <div
          className="dummy__header"
          style={{
            visibility: !goingUp && scrollPosition > 150 ? "hidden" : "visible",
          }}
        ></div>

        <div
          className="dashboard__menu_wrapper"
          style={{
            visibility: !goingUp && scrollPosition > 150 ? "hidden" : "visible",
            top: scrollPosition > 35,
          }}
        >
          <ClientpartnerMenu
            pageLogo={pageLogo}
            searchbar={true}
            onFocus={() => setSearchScreen(true)}
          />
          <Slide
            items={slideItems}
            methods={[
              () => {
                setDashboard([]);
                get_companies(1);
                document.querySelector(".dashboard__wrapper").scrollTop = 0;
              },
              () => {
                two_way_quotes(1);
                document.querySelector(".dashboard__wrapper").scrollTop = 0;
              },
              () => {
                fresh_round(1);
                document.querySelector(".dashboard__wrapper").scrollTop = 0;
              },
              () => {
                gauging_interest(1);
                document.querySelector(".dashboard__wrapper").scrollTop = 0;
              },
            ]}
            activeId={activeId}
          />
        </div>
      </div>

      <div className="db__main">
        {screenLoading && !stopLoader && <DbCardLoader count={10} />}

        {dashboard.length <= 0 ? (
          <div className="db__main_empty">
            {screenLoading
              ? () => {
                  <DbCardLoader count={10} />;
                }
              : "No companies currently present for this criteria"}
          </div>
        ) : (
          dashboard?.map((card) => {
            return (
              <Suspense fallback={<DbCardLoader />} key={card?.id}>
                <DbCard
                  logo={card.logo}
                  title={card.aka}
                  sector={card.sector}
                  isin={card.isin}
                  lkv={card.lkv}
                  badge1Style={{
                    color: "rgb(114, 229, 112)",
                    background: "rgba(114, 229, 112, 0.288)",
                  }}
                  badge2Style={{
                    color: "rgb(126, 123, 123)",
                    background: "rgba(211, 211, 211, 0.541)",
                  }}
                  myData={myData}
                  detailsBox={false}
                  listingStage={card.listingStage}
                  shortDescription={card.shortDescription}
                  highlights={card.highlights}
                  mediaType={card.mediaType}
                  mediaLink={card.mediaLink}
                  legalName={card.legalName}
                  lkvDenomination={card.lkvDenomination}
                  type={card.type}
                  symbol={card.symbol}
                  demand={card.demand}
                  threshHold={card.threshHold}
                  endDate={card.endDate}
                  bookmarked={card.bookmarked}
                  load_dashboard={load_dashboard}
                  setScreenLoading={setScreenLoading}
                />
              </Suspense>
            );
          })
        )}
        {dashboard.length > 0 && bottomLoading && (
          <div ref={loader} id="bottomLoader">
            <CircularProgress className="dashboard__loader" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
