import "./ShortFall.css";

import { Link } from "react-router-dom";
import BuyerInfo from "../../OrderModification/Components/BuyerInfo/BuyerInfo";
import ExpressDeal from "../../OrderCreation/Components/ExpressDeal/ExpressDeal";
import Button from "../../../components/button/Button";

const ConfirmOrder = () => {
  return (
    <>
      <div className="sf__container">
        <div className="sf__upper">
          <BuyerInfo />

          <p className="sf__heading">
            Confirm <span>Order</span>
          </p>

          <p className="sf__upper__para1">
            Rs <span style={{ fontWeight: "bolder" }}> 40,235</span> are
            required to complete this order. These margins are 100% refundable
            if transaction is not complete
            <p className="sf__para2">Required Funds : Rs. 40,235</p>
            <p className="sf__para2">Required Funds : Rs. 40,235</p>
            <p className="sf__para2" style={{ color: "red" }}>
              ShortFall : Rs. 40,235
            </p>{" "}
          </p>

          <div className="sf__expressDealComponent">
            <ExpressDeal />
          </div>

          <div className="sf__filable__qty">
            <p className="sf__filable__para1">
              Minimum fillable <span>Quantity</span>
            </p>
            <p
              style={{ color: "gray", fontSize: "19px" }}
              className="sf__filable__para2"
            >
              XXXXXXXX
            </p>
            <p className="sf__filable__para1">
              Minimum qty per transaction
              <span>
                {/* this is empty */}
                <Link className="sf__para2__link">Learn More</Link>
              </span>
            </p>
          </div>

          <p className="sf__watch">
            Need clarity on the transaction process? <span>Watch Video</span>
          </p>
        </div>
        <div className="upper">
          <Button text="CONFIRM" />
        </div>
      </div>
    </>
  );
};

export default ConfirmOrder;
