import React from "react";
import "./LoadingSkeleton.scss";

export const DbCardLoader = ({ count }) => (
  <>
    {[...Array(count || 1)]?.map((i, index) => (
      <div className="blog-post o-media" key={index}>
        <div className="o-media__figure">
          <span
            className="skeleton-box"
            style={{ width: 100, height: 100, aspectRatio: "1/1" }}
          />
        </div>
        <div className="o-media__body">
          <div className="o-vertical-spacing">
            <h3 className="blog-post__headline">
              <span className="skeleton-box" style={{ width: "55%" }} />
            </h3>
            <p>
              <span className="skeleton-box" style={{ width: "80%" }} />
              <span className="skeleton-box" style={{ width: "90%" }} />
              <span className="skeleton-box" style={{ width: "83%" }} />
              <span className="skeleton-box" style={{ width: "80%" }} />
            </p>
            <div className="blog-post__meta">
              <span className="skeleton-box" style={{ width: 70 }} />
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);

export const OrderEntryLoadingCard1 = () => (
  <div
    className="o-vertical-spacing"
    style={{
      boxShadow: "0 2px 4px rgb(0 0 0 / 12%)",
      padding: "10px",
      borderRadius: "5px",
    }}
  >
    <div
      className="o-media__figure"
      style={{
        display: "flex",
        width: "100%",
        gap: 10,
      }}
    >
      <div
        className="skeleton-box"
        style={{
          width: "100%",
          height: 50,
        }}
      />
      <div
        className="skeleton-box"
        style={{
          width: "100%",
          height: 50,
        }}
      />
      <div
        className="skeleton-box"
        style={{
          width: "100%",
          height: 50,
        }}
      />
    </div>
    <div className="o-vertical-spacing">
      <p>
        <span className="skeleton-box" style={{ width: "80%" }} />
        <span className="skeleton-box" style={{ width: "90%" }} />
        <span className="skeleton-box" style={{ width: "63%" }} />
        <span className="skeleton-box" style={{ width: "50%" }} />
      </p>
    </div>
    <div className="o-vertical-spacing">
      <div className="skeleton-box" style={{ width: "100%", height: 30 }} />
    </div>
  </div>
);
export const OrderEntryLoadingCard2 = () => (
  <div
    className="o-vertical-spacing"
    style={{
      boxShadow: "0 2px 4px rgb(0 0 0 / 12%)",
      padding: "10px",
      borderRadius: "5px",
      marginTop: 20,
    }}
  >
    <div
      className="o-media__figure"
      style={{
        display: "flex",
        width: "100%",
        gap: 10,
      }}
    >
      <div
        className="skeleton-box"
        style={{
          width: "100%",
          height: 40,
        }}
      />
      <div
        className="skeleton-box"
        style={{
          width: "100%",
          height: 40,
        }}
      />
    </div>
    <div className="o-vertical-spacing">
      <div
        className="a"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gap: 10,
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <div className="skeleton-box" style={{ width: "35%", height: 25 }} />
        </div>
        <div
          style={{
            display: "flex",
            width: "40%",
            gap: 10,
            // border: "1px red solid",
          }}
        >
          <div className="skeleton-box" style={{ width: "100%", height: 25 }} />
          <div className="skeleton-box" style={{ width: "100%", height: 25 }} />
        </div>
      </div>
    </div>
    <div className="skeleton-box" style={{ width: "100%", height: 20 }} />
    <div className="skeleton-box" style={{ width: "100%", height: 30 }} />
  </div>
);

const LoadingSkeleton = () => {
  return <div>LoadingSkeleton</div>;
};

export default LoadingSkeleton;
