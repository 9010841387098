import React, { useEffect, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import Button from "../../../components/button/Button";
import BtnCom from "../Components/BtnComponent/BtnCom";

import Progress from "@material-ui/core/CircularProgress";

import "./Reqfund.css";
import { getBanks } from "../../../api/FundsAPI";

const Reqfund = (props) => {
  const [selectedValue, setSelectedValue] = useState("net");
  const [isLoading, setIsLoading] = useState(true);
  const [haveRemovedBank, setHaveRemovedBank] = useState(false);
  const [removedBankAlter, setRemovedBankAlter] = useState("");
  const [banks, setBanks] = useState([]);
  const getBanksData = async () => {
    const { data, error } = await getBanks(props?.token);
    if (data) {
      const removeBanks = ["HDFC bank"]; // we can add or remove the banks as our requirements
      console.clear();
      const userBanks = data.filter(
        (bank) =>
          !removeBanks
            .map((i) => i.toLocaleLowerCase())
            .includes(bank?.bankName?.toLocaleLowerCase())
      ); //intersection of data between removed banks and users banks
      setBanks(userBanks);
      setIsLoading(false);
      if (userBanks.length === 0) {
        setRemovedBankAlter(
          "Net-banking payments are not supported for HDFC bank. Kindly use UPI or Offline NEFT/RTGS to make payment."
        );
        setSelectedValue("upi");
      } else
        setRemovedBankAlter(
          "Net-banking payments are not supported for HDFC bank. Kindly select UPI or Offline NEFT/RTGS to make payment from HDFC bank."
        );
      setHaveRemovedBank(
        data
          .map((bank) =>
            removeBanks
              .map((i) => i.toLocaleLowerCase())
              .includes(bank.bankName.toLocaleLowerCase())
          )
          .includes(true)
      );
    } else if (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const selectType = (event) => {
    setSelectedValue(event.target.value);
  };

  const nextPage = () => {
    props.setAddFundsDetails((preValue) => {
      return {
        ...preValue,
        type: selectedValue,
      };
    });
    props.setOpenSlide({
      open: true,
      div: "selectBank",
    });
  };

  useEffect(() => {
    getBanksData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Progress />
        </div>
      ) : (
        <>
          <div className="reqfund__container">
            <div className="reqfund__title">
              <p className="heading"> Choose your preferred mode of payment </p>
              <p className="detail">
                {" "}
                These margins are 100% refundable if transaction is not
                completed.{" "}
              </p>
            </div>
            {haveRemovedBank && (
              <div className="hdfc-alert">{removedBankAlter}</div>
            )}
            {props.info && (
              <div className="reqfund__header">
                Rs. <span style={{ fontWeight: "bolder" }}>40,235</span> are
                required as additional mafgins to complete this order
                modification. These margins are 100% refundable if transactionis
                not complete
              </div>
            )}
            <div className="reqfund__body">
              <div className="reqfund__req">
                <div className="req1">
                  <span style={{ fontWeight: "bolder" }}>Margin Amount :</span>
                  &nbsp;Rs {props.data.margin.orderMargin}
                </div>
                <div className="req1">
                  <span style={{ fontWeight: "bolder" }}>
                    Previous Pending Amount :
                  </span>
                  &nbsp;Rs {props.data.margin.previousPendingAmount}
                </div>
                <div className="req1">
                  <span style={{ fontWeight: "bolder" }}>Required Funds :</span>
                  &nbsp;Rs {props.data.margin.shortfall}
                </div>
                <div className="avail">
                  <span style={{ fontWeight: "bolder" }}>
                    Available Funds :
                  </span>
                  &nbsp;Rs {props.data.margin.freeFund}
                </div>
              </div>

              <BtnCom data={props.data.margin.shortfall} />

              <div className="reqfund__confirm">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={selectedValue}
                    onChange={selectType}
                  >
                    {banks.length > 0 && (
                      <FormControlLabel
                        value="net"
                        control={<Radio />}
                        label="Net Banking"
                      />
                    )}
                    {props.data.margin.shortfall < 200000 && (
                      <FormControlLabel
                        value="upi"
                        control={<Radio />}
                        label="UPI"
                      />
                    )}
                    <FormControlLabel
                      value="offline"
                      control={<Radio />}
                      label="Offline NEFT /  RTGS"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          <Button text="CONTINUE" click={nextPage} />
        </>
      )}
    </>
  );
};

export default Reqfund;
