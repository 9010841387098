import { useEffect, useState } from "react";
import { get_trade } from "../../../../api/orderDetails";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

const Trade = ({ id, orderQty }) => {
  const [trade, setTrade] = useState();
  const token = useSelector((state) => state.user.token);
  const getTrade = async () => {
    let response = await get_trade(token, id);
    if (response.error) {
      toast.error(response.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log(response.data.data);
      setTrade(response.data.data);
    }
  };
  const formatDateTime = (timestamp) => {
    let d = new Date(timestamp * 1000);
    var options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return d.toLocaleDateString("en-UK", options);
  };
  const formatDate = (timestamp) => {
    let d = new Date(timestamp * 1000);
    let ye = new Intl.DateTimeFormat("en", { year: "2-digit" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    let formatted = da + "/" + mo + "/" + ye;
    return formatted;
  };

  useEffect(() => {
    getTrade();
  }, []);
  return (
    <>
      {trade ? (
        <div>
          {" "}
          <div className="aev__heading">Traded successfully.</div>
          <div className="aev__paras">
            <p className="aev__para1">Trade ID : {trade.fillId}</p>
            <p className="aev__para2">
              Traded Qty : {trade.qty}({(trade.qty / orderQty) * 100}% of total
              order)
            </p>
            <p className="aev__para3">Traded @ W.avg Rs. {trade.price}</p>
            <p className="aev__para4">
              Traded on {formatDate(trade.fillCreationTime)}
            </p>
          </div>
          <div className="aev__footer__paras">
            <p className="aev__footer__para1">
              Obligation End time :{" "}
              {formatDateTime(trade?.obligationExpirationTime)}
            </p>
            <p className="aev__footer__para1">
              Closure phase beginning :{" "}
              {formatDateTime(trade?.fillExpirationTime)}
            </p>
            <p className="aev__footer__para1">
              Closure phase End time :{" "}
              {formatDateTime(trade?.closureExpectedEndTime)}
            </p>
          </div>
        </div>
      ) : (
        <div className="trade__loader_box">
          <CircularProgress className="trade__loader" />
        </div>
      )}
    </>
  );
};

export default Trade;
