import React, { useState } from "react";
import Button from "../../../../components/button/Button";
import CheckboxComponent from "../../../../components/ConfirmCheckbox/CheckboxComponent";
import BuyerInfo from "../../../OrderModification/Components/BuyerInfo/BuyerInfo";
import "./ValidateOrderModi.css";

const ValidateOrderModi = () => {
  // why state?
  const [cancel, setCancel] = useState(true);

  return (
    <>
      <div className="vom__container">
        <div className="vom__upper">
          <div className="vom__header">
            <p className="vom__header__para1">Partner's Logo</p>

            {cancel === true ? (
              <p className="vom__header__heading">
                Validate order <span>modification</span>
              </p>
            ) : (
              <p className="vom__header__heading">
                Validate order <span>cancellation</span>
              </p>
            )}
            <p className="vom__para2">
              Please validate your intent to{" "}
              {cancel === false ? "modify" : "cancel"} order
            </p>
          </div>

          <div className="vom__body">
            {cancel === true ? (
              <div className="vom__body__card1">
                <p className="vom__card1__para1">Current order</p>
                <div className="vom__buyerInfo__comp">
                  <BuyerInfo />
                </div>
              </div>
            ) : null}
            <div className="vom__body__card1">
              {cancel === true ? (
                <p className="vom__card1__para1">Order modification</p>
              ) : null}

              <div className="vom__buyerInfo__comp">
                <BuyerInfo />
              </div>
            </div>
          </div>

          <div className="vom__checkbox__com">
            <CheckboxComponent />
          </div>
        </div>

        <div className="vom__lower">
          {cancel === true ? (
            <Button text="MODIFY ORDER" />
          ) : (
            <Button text="CANCEL ORDER" />
          )}
        </div>
      </div>
    </>
  );
};

export default ValidateOrderModi;
