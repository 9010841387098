import { toast } from "react-toastify";
import { confirmCancelOrder } from "../api/order";

export const opentheSlide = (prop, setOpenslide) => {
  setOpenslide({
    open: true,
    div: prop,
  });
};

export const cancelOrder = async ({
  setOtpLoader,
  user,
  orderData,
  otp,
  history,
  company,
}) => {
  console.clear();
  setOtpLoader(true);
  let res = await confirmCancelOrder(
    user.token,
    orderData.orderRequestId,
    orderData.version,
    otp
  );
  if (res.error != null) {
    setOtpLoader(false);
    toast.error(res.error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (res.status != 417) {
      setOtpLoader(false);
      setTimeout(() => {
        history.go(0);
      }, 2000);
    }
  } else {
    setOtpLoader(false);
    history.push({
      pathname: "/success",
      state: {
        company,
        orderInfo: orderData,
        path: "fail",
      },
    });
  }
};
