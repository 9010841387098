import React, { useState, useEffect, Suspense } from "react";
import "./DbCard.css";
import { useLocation, useHistory } from "react-router-dom";
import CardContent from "../card-content/CardContent";
import MediaBox from "../media-box/MediaBox";
import Highlights from "../highlights/Highlights";
import Slide from "../slide/Slide";
import Resources from "../resources/Resources";
import Funding from "../funding/Funding";
// import About from "../about/About";
import ProgressBar from "../progress-bar/ProgressBar";
import { lazy } from "react";

const About = lazy(() => import("../about/About"));

const DbCard = (props) => {
  const history = useHistory();
  const [closingSoon, setClosingSoon] = useState(false);
  const location = useLocation();
  const closingSoonDays = 5;
  const date = new Date();
  date.setDate(date.getDate() + closingSoonDays);
  const endDate = new Date(props.endDate);

  useEffect(() => {
    setClosingSoon(+date >= +endDate);
  }, []);
  return (
    <div
      className="db__card"
      onClick={() => {
        location.pathname === "/dashboard" &&
          history.replace(
            props.listingStage == "EXPRESS_DEAL"
              ? `/dashboard/order-entry?symbol=${props?.symbol}`
              : `/companies/${props?.symbol}`
          );
      }}
    >
      {props.mediaType === "VIDEO" && (
        <MediaBox video={props.mediaLink} type="video/mp4" />
      )}
      {props.mediaType === "IMAGE" && <MediaBox image={props.mediaLink} />}
      <CardContent
        load_dashboard={props.load_dashboard}
        get_company={props.get_company}
        myData={props.myData}
        logo={props.logo}
        title={props.title}
        sector={props.sector}
        isin={props.isin}
        lkvDenomination={props.lkvDenomination}
        lkv={props.lkv}
        symbol={props.symbol}
        bookmarked={props.bookmarked}
        badge1={
          props.listingStage === "LIVE_NOW"
            ? "Live Now"
            : props.listingStage === "EXPRESS_DEAL"
            ? "Express Deal"
            : "Gauging Interest"
        }
        badge2={props.type === "FRESH" ? "Fresh Round" : "2 Way Quote"}
        badge1Style={
          props.listingStage === "LIVE_NOW"
            ? {
                color: "#2eb139",
                fontWeight: "500",
                background: "#e4ffe5",
              }
            : props.listingStage === "EXPRESS_DEAL"
            ? {
                color: "#fcc777",
                background: "rgba(255, 193, 7, 0.288) ",
                position: "absolute",
                top: "0",
                borderTopRightRadius: "0px",
                borderTopLeftRadius: "0px",
              }
            : {
                color: "rgb(165, 131, 67)",
                background: "rgba(228, 177, 100, 0.288)",
              }
        }
        badge2Style={
          props.type === "FRESH"
            ? {
                color: "#a7a7a7",
                backgroundColor: "#f1f1f1",
                marginTop:
                  props.listingStage === "EXPRESS_DEAL" ? "8px" : "5px",
              }
            : {
                color: "#2e92b1",
                background: "rgba(21, 187, 242, 0.341)",
                marginTop:
                  props.listingStage === "EXPRESS_DEAL" ? "8px" : "5px",
              }
        }
        shortDescription={props.shortDescription}
        closingSoon={closingSoon}
        turnedInStyle={props.turnedInStyle}
        iconsBoxStyle={props.iconsBoxStyle}
      />
      {props.slide && (
        <Slide
          items={props.slide}
          setActiveBox={props.setActiveBox}
          activeId={props.activeBox}
        />
      )}
      {props.additionalBox && location.pathname !== "/dashboard" && (
        <div className="additional__box">
          <div>No active quotes to view for this product</div>
        </div>
      )}

      {(!props.highlights && props.detailsBox !== "about") ||
      (props.highlights &&
        props.detailsBox &&
        props.detailsBox !== "about") ? null : (
        <Highlights highlights={props.highlights} />
      )}
      {props.listingStage === "LIVE_NOW" && !props.additionalBox && (
        <ProgressBar
          progress={props.demand * 100}
          threshHold={props.threshHold * 100}
        />
      )}
      {props.detailsBox === "about" && (
        <Suspense fallback={<div>Loading..</div>}>
          <About
            industries={props.industries}
            founders={props.founders}
            about={props?.about}
            legalName={props.legalName}
            customInformation={props.customInformation}
            setActiveBox={props.setActiveBox}
            activeBox={props.activeBox}
          />
        </Suspense>
      )}
      {props.detailsBox === "funding" && (
        <Funding
          funding={props.funding}
          setActiveBox={props.setActiveBox}
          activeBox={props.activeBox}
          get_company={props.get_company}
        />
      )}
      {props.detailsBox === "resources" && (
        <Resources
          resources={props.resources}
          setActiveBox={props.setActiveBox}
          activeBox={props.activeBox}
        />
      )}
    </div>
  );
};

export default DbCard;
