import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import OtpInput from "react-otp-input";
import Button from "../../../components/button/Button";
import backicon from "../../../Assets/imgs/backarrow.svg";
import lock from "../../../Assets/imgs/lock.svg";

import { toast } from "react-toastify";

import { registerOtpSignup, unRegisterOtpSignup } from "../../../api/order";
import { set_profile, signup_user } from "../../../store/User";
import { profile_details } from "../../../api/profile";
import { useDispatch } from "react-redux";

import "./Potp.scss";

const Potp = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  let orderInfo = location.state.orderInfo;
  let email = location.state.email;
  let client = location.state.client;
  let partner = location.state.partner;

  const [secs, setSecs] = useState(10);
  const [otp, setOtp] = useState("");

  // !these state are not in use
  const [token, setToken] = useState(null);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    setInterval(
      () =>
        setSecs((secs) => {
          if (secs > 0) {
            return secs - 1;
          } else {
            return 0;
          }
        }),
      1000
    );
  }, []);

  const login = async () => {
    console.log("in confirm ::", partner);
    if (client === "register" || client === "modify" || client === "cancel") {
      let res = await registerOtpSignup(
        email,
        otp,
        orderInfo.orderRequestId,
        orderInfo.version
      );
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setToken(res.data.token);
        dispatch(signup_user(res.data.token));
        localStorage.setItem("token", res.data.token);
        let profile = await profile_details(res.data.token);
        if (profile.error != null) {
          toast.error(res.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          dispatch(set_profile(profile.data.data));
          localStorage.setItem("profile", JSON.stringify(profile.data.data));
          setProfile(profile.data.data);
          if (client === "cancel") {
            history.push(`/order-cancellation?orderId=${res?.data?.orderId}`);
          } else if (client === "modify") {
            history.push({
              pathname: "/confirm-order",
              search: `?orderId=${res.data.orderId}&orderVersion=${res.data.orderVersion}&partnerName=${partner?.partnerName}`,
            });
          } else {
            history.push({
              pathname: "/confirm-order",
              search: `?orderId=${res.data.orderId}&orderVersion=${res.data.orderVersion}&extra=true&partnerName=${partner?.partnerName}`,
            });
          }
        }
      }
    } else {
      let res = await unRegisterOtpSignup(orderInfo.orderId, otp, email);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("OTP ============ ", res.data);
        dispatch(signup_user(res.data.token));
        localStorage.setItem("token", res.data.token);
        let profile = await profile_details(res.data.token);
        if (profile.error != null) {
          toast.error(res.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          dispatch(set_profile(profile.data.data));
          localStorage.setItem("profile", JSON.stringify(profile.data.data));
          setProfile(profile.data.data);
          history.push({
            pathname: "/confirm-order",
            search: `?orderId=${res.data.orderId}&orderVersion=${res.data.orderVersion}&extra=true&partnerName=${partner?.partnerName}`,
          });
        }
      }
    }
  };

  return (
    <>
      <div className="potp__container">
        <div className="potp__upper">
          <img className="potp__back" src={backicon} alt="" />

          <div className="potp__header">
            <img src={lock} alt="" className="ptop__header__img" />
            <p className="potpo__hader__para">
              Enter <span>OTP</span>
            </p>
          </div>

          <div className="potp__body">
            <p className="potp__body__para">
              Enter OTP send to you via e-mail on your ID {email}
            </p>

            <OtpInput
              value={otp}
              onChange={(otp) => setOtp(otp)}
              numInputs={6}
              inputStyle={`opt__box`}
              containerStyle="otp__wrapper"
              separator={<span>&nbsp;</span>}
              isInputNum="true"
            />
          </div>

          <div className="potp__bottom">
            <p className="potp__bottom__para1">Didn't receive OTP?</p>
            {secs === 0 ? (
              <p className="potp__resend">Re-Send OTP </p>
            ) : (
              <p className="potp__bottom__para12">
                Re-send Email (After {secs}) |{" "}
                <span style={{ color: "black" }}>Change Email ID</span>
              </p>
            )}
          </div>
        </div>

        <div className="potp__lower">
          <Button
            text="LOGIN"
            style={{ position: "sticky", bottom: 0 }}
            click={login}
          />
        </div>
      </div>
    </>
  );
};

export default Potp;
