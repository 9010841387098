import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Progress from "@material-ui/core/CircularProgress";

import Slide from "../../../dashboard/components/slide/Slide";
import BuyerInfo from "../../../OrderModification/Components/BuyerInfo/BuyerInfo";
import ActionAlert from "../../../../components/action-alert/ActionAlert";

import MenuComponent from "../../../../components/client-partner-menu/ClientPartnerMenu";

import { getCompany, getQuoteDetail, createGaug } from "../../../../api/order";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";

import "./NotBidding.css";

const Notbidding = () => {
  let history = useHistory();
  let location = useLocation();
  let user = useSelector((state) => state.user);

  let companySymbol = new URLSearchParams(location.search).get("symbol");
  let urlPartnerget = new URLSearchParams(location.search).get("partnerName");
  const redirected = new URLSearchParams(location.search).get("loggedIn");

  let preValue =
    redirected === "true" ? JSON.parse(localStorage.getItem("gug")) : null;
  let companyId = companySymbol
    ? companySymbol
    : redirected === "true"
    ? preValue.companyId
    : null;

  const [company, setCompany] = useState(null);
  const [quoteDetail, setQuoteDetail] = useState(null);
  const [actionAlert, setActionAlert] = useState(false);
  const [error, setError] = useState(null);
  const [activeBox, setActiveBox] = useState(3);

  const [price, setPrice] = useState(null);

  useEffect(() => {
    gettingCompany();
  }, []);
  useEffect(() => {
    if (company != null) {
      gettingQuoteDetail();
    }
  }, [company]);
  useEffect(() => {
    if (quoteDetail != null) {
      if (
        price < quoteDetail.minimumQty * quoteDetail.minimumBidPrice ||
        price > quoteDetail.minimumQty * quoteDetail.maximumAskPrice
      ) {
        setError(true);
      } else {
        setError(null);
      }
    }
  }, [price, quoteDetail]);
  useEffect(() => {
    if (redirected === "true") {
      setPrice(preValue.price);
    }
  }, [redirected]);
  useEffect(() => {
    if (activeBox === 0) {
      history.push(
        "/companies/" +
          companySymbol.trim() +
          "?option=about" +
          `${urlPartnerget !== null ? `&partnerName=${urlPartnerget}` : ""}`
      );
    } else if (activeBox === 1) {
      history.push(
        "/companies/" +
          companySymbol.trim() +
          "?option=funding" +
          `${urlPartnerget !== null ? `&partnerName=${urlPartnerget}` : ""}`
      );
    } else if (activeBox === 2) {
      history.push(
        "/companies/" +
          companySymbol.trim() +
          "?option=resources" +
          `${urlPartnerget !== null ? `&partnerName=${urlPartnerget}` : ""}`
      );
    } else if (activeBox === 3) {
      history.push(
        "/gaug-interest?symbol=" +
          companySymbol.trim() +
          `${urlPartnerget !== null ? `&partnerName=${urlPartnerget}` : ""}`
      );
    }
  }, [activeBox]);

  const gettingCompany = async () => {
    let res = await getCompany(user.token, companyId);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setCompany(res.data);
    }
  };
  const gettingQuoteDetail = async () => {
    let res = await getQuoteDetail(user.token, company.symbol);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setQuoteDetail(res.data);
    }
  };

  const enteringPrice = (event) => {
    setPrice(event.target.value);
  };

  const createOrder = async (props) => {
    if (!error) {
      if (!user.token || user.token === null) {
        let data = {
          price: price,
          companyId: companyId,
        };
        localStorage.setItem("gug", JSON.stringify(data));
        setActionAlert(true);
      } else {
        let res = await createGaug(user, company, price, props);
        if (res.error != null) {
          toast.error(res.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          history.push({
            pathname: "/what-now",
            state: { company, data: res.data, path: "gaug" },
          });
        }
      }
    }
  };

  const go_to_signin = () => {
    history.push({
      pathname: "/register",
      search: `?redirectPath=${history.location.pathname}`,
    });
  };

  return (
    <>
      <MenuComponent />
      {actionAlert && (
        <ActionAlert
          text="You need to verify your email ID to Buy/Sell"
          btnText="Verify Email"
          action={go_to_signin}
          onClose={() => setActionAlert(false)}
        />
      )}
      {!company || !quoteDetail ? (
        <>
          <div className="progress">
            <Progress className="bar" />
          </div>
        </>
      ) : (
        <>
          <div className="nb__container">
            <div className="nb__buyerinfo__comp">
              <BuyerInfo path="entry" data={company} g="g" />
            </div>
            <div className="nb__slide__comp">
              <Slide
                items={["about", "funding", "resources", "invest/divest"]}
                activeId={activeBox}
                setActiveBox={setActiveBox}
              />
            </div>
            <div className="nb__input" style={{ marginTop: "1rem" }}>
              <TextField
                value={price}
                style={{ width: "100%" }}
                id="outlined-basic"
                placeholder="Amount (Rs.)"
                variant="standard"
                onChange={enteringPrice}
                type="number"
              />
              <p className="nb__input__para" style={{ fontSize: "14px" }}>
                Amount that you wish to invest/divest
              </p>
              {error && (
                <p style={{ color: "red", fontSize: ".7rem" }}>
                  {" "}
                  Amount should be between{" "}
                  {quoteDetail.minimumQty *
                    quoteDetail.minimumBidPrice} and{" "}
                  {quoteDetail.minimumQty * quoteDetail.maximumAskPrice}{" "}
                </p>
              )}
            </div>

            <div className="nb__buttons">
              <div className="nb__button1">
                <button className="nb__btn1" onClick={() => createOrder("BID")}>
                  BUY
                </button>
              </div>
              <div className="nb__button2">
                <button className="nb__btn2" onClick={() => createOrder("ASK")}>
                  SELL
                </button>
              </div>
            </div>
            <div className="nb__link" style={{ marginBottom: "2rem" }}>
              <div className="nb__link____left"></div>
              <div className="nb__link__right">
                <p className="nb__link__para">
                  The amount or your expression of interest is <br />{" "}
                  <span>NOT BINDING</span>
                </p>
              </div>
            </div>

            <p className="nb__footer__para">
              You can always choose to change your opinion / interest / decision
              later at no cost.
            </p>

            <p className="nb__footer__para2">
              Need more clarity on the transaction process?{" "}
              <a
                href="https://youtu.be/GQl3f-e_goI"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Watch Video.
              </a>
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default Notbidding;
