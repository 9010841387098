import React from "react";
import { Link } from "react-router-dom";

const Notfound = ({ resError, link, text }) => {
  return (
    <div className="not-found">
      <div className="box">
        <img
          src="https://cdn-icons-png.flaticon.com/512/564/564619.png"
          alt="warning icon"
          className="warning-logo"
        />
        <h2>Unable to access this page.</h2>
        <p className="problem">{resError}</p>
        <Link to={link}>{text}</Link>
      </div>
    </div>
  );
};

export default Notfound;
