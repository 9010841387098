import React, { useState } from "react";
import "./MediaBox.css";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const VideoPlayer = ({ video, image }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="media__box">
      {video && (
        <>
          <div className="play__screen">
            <div
              className="placeholder__box_forvideo"
              onClick={handleOpen}
            ></div>
            <iframe
              src={`https://www.youtube.com/embed/${video?.split("?v=")[1]}`}
              className="yt-video"
            />
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="video__modal"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className="video__box">
                <iframe
                  src={`https://www.youtube.com/embed/${
                    video?.split("?v=")[1]
                  }`}
                  className="yt-video"
                />
              </div>
            </Fade>
          </Modal>
        </>
      )}
      {image && <img src={image} className="mb__image" />}
    </div>
  );
};

export default VideoPlayer;
