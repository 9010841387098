import React from 'react'
import { useHistory } from "react-router-dom"
import './Userprofile.css'
import profile from "../../../../Assets/imgs/profile.svg"
import Button from '../../../../components/button/Button'

const Userprofile = (props) => {
    const gotToProfile = ()=>{
        props.next()
    }
    return (
        <>
            <div className="up__container">
                <div className="up__upper">
                    <div className="up__icon">
                        <img src={profile} alt="Error" />
                    </div>
                    <div className="up__para1">
                        Before you place your first order, we need to know a few things about you.
                    </div>
                    <div className="up__para2">This is one time process.</div>
                </div>
                <div className="up__lower">
                    <Button text="Complete Profile" click={gotToProfile} />
                </div>
            </div>

        </>
    )
}

export default Userprofile
