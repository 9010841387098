import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Progress from "@material-ui/core/CircularProgress";
import BackArrow from "../../Assets/imgs/backarrow.svg";

import "./FundsTransfer.scss";

import { useSelector } from "react-redux";

import { getFundTransfer } from "../../api/FundsAPI";

const FundsTransferData = [
  {
    status: "succ",
    date: "02/04/2021",
    balance: "1,00,00,000",
    detail: "Net-Banking",
    data: {
      account: "123123123123",
      bank: "HDFC",
      ifsc: "HDFC000000123 (Horniman Circle)",
      ref: "MMASDIJ2314K234",
    },
  },
  {
    status: "fail",
    date: "02/04/2021",
    balance: "65,00,000",
    detail: "UPI",
    data: {
      account: "123123123123",
      bank: "HDFC",
      ifsc: "HDFC000000123 (Horniman Circle)",
      ref: "MMASDIJ2314K234",
      err: "Transaction failed as funds were received from a bank accountthat is not registered.",
    },
  },
  {
    status: "succ",
    date: "02/04/2021",
    balance: "35,00,000",
    detail: "NEFT / RTGS",
    data: {
      account: "123123123123",
      bank: "HDFC",
      ifsc: "HDFC000000123 (Horniman Circle)",
      ref: "MMASDIJ2314K234",
    },
  },
  {
    status: "fail",
    date: "02/04/2021",
    balance: "35,00,000",
    detail: "Net-Banking",
    data: {
      account: "123123123123",
      bank: "HDFC",
      ifsc: "HDFC000000123 (Horniman Circle)",
      ref: "MMASDIJ2314K234",
      err: "Transaction failed as funds were received from a bank accountthat is not registered.",
    },
  },
];

const FundsTransfer = () => {
  let history = useHistory();

  const [userData, setUserData] = useState(useSelector((state) => state.user));
  const [transfers, setTransfers] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //   useEffect(async () => {
  //     let res = await getFundTransfer(userData.token);
  //     if (res.error != null) {
  //       alert("Error");
  //     } else {
  //       let newArry = [];
  //       if (res.data && res.data != null) {
  //         res.data.map((data) => {
  //           if (data.type == "ADD" && data.status == "CREATED") {
  //           } else {
  //             newArry.push(data);
  //           }
  //         });
  //       }
  //       console.clear();
  //       console.log(newArry);
  //       setTransfers(newArry);
  //       // setTransfers(res.data)
  //     }
  //   }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getFundTransfer(userData.token);
        console.clear();
        if (data) {
          // arrange temp array in descending order according to transactionTime
          setTransfers(
            data
              ?.filter((item) => {
                if (item?.type == "ADD" && item.status == "CREATED") {
                  return false;
                } else return item;
              })
              .sort((a, b) => b.transactionTime - a.transactionTime)
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className="fundstransfer_container">
        <div className="fundstransfer_box">
          <div className="fundstransfer_title">
            <p className="title"> Funds Transfer </p>
            <div className="back" onClick={goBack}>
              <img src={BackArrow} alt="ERROR" /> <span> Back </span>
            </div>
          </div>
          <div className="dec">
            <span> Note : </span> Transfer’s done via non-electronic NEFT/RTGS
            show up here, once the funds hit our bank account. Until then you
            won’t be able to see any status here.
          </div>
          <div className="fundstransfer_data">
            {transfers == null ? (
              <>
                <div className="progress">
                  <Progress className="bar" />
                </div>
              </>
            ) : transfers.length < 1 ? (
              <>
                <div className="progress">
                  <div className="warn"> No funds transfers to show </div>
                </div>
              </>
            ) : (
              transfers.map((data, index) => {
                let d = new Date(data.transactionTime * 1000);
                return data.type == "ADD" && data.status == "CREATED" ? null : (
                  <>
                    <Accordion
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                      key={data?.orderId}
                      style={{
                        borderColor:
                          data.status == "CREATED" ||
                          data.status == "IN_PROCESS" ||
                          data.status == "PROCESSED"
                            ? "#dce100"
                            : data.status == "FAIL" || data.status == "CANCEL"
                            ? "red"
                            : null,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <div className="balance_box">
                          <div className="date">
                            Date : {("0" + d.getDate()).slice(-2)}/
                            {("0" + (d.getMonth() + 1)).slice(-2)}/
                            {d.getFullYear()} {("0" + d.getHours()).slice(-2)}:
                            {("0" + d.getMinutes()).slice(-2)}
                          </div>
                          <div className="balance_data">
                            <div
                              className="balance"
                              style={{
                                color: data.type == "ADD" ? "green" : "red",
                              }}
                            >
                              <p className="cur">
                                <span>{data.type == "ADD" ? "+" : "-"}</span>₹
                              </p>
                              <p>{data.amount}</p>
                            </div>
                            <div className="detail">
                              {data.type == "ADD" ? "Addition" : "withdrawal"}
                              Via {data.mode}
                            </div>
                          </div>
                          <div
                            className="success_box"
                            style={{
                              color:
                                data.status == "CREATED" ||
                                data.status == "IN_PROCESS"
                                  ? "#dce100"
                                  : data.status == "FAIL" ||
                                    data.status == "CANCEL"
                                  ? "red"
                                  : data.status == "PROCESSED"
                                  ? "pink"
                                  : null,
                            }}
                          >
                            <span
                              style={{
                                backgroundColor:
                                  data.status == "CREATED" ||
                                  data.status == "IN_PROCESS"
                                    ? "#dce100"
                                    : data.status == "FAIL" ||
                                      data.status == "CANCEL"
                                    ? "red"
                                    : data.status == "PROCESSED"
                                    ? "pink"
                                    : null,
                              }}
                            ></span>
                            {data.status == "CREATED"
                              ? "Created"
                              : data.status == "IN_PROCESS"
                              ? "IN-Process"
                              : data.status == "FAIL"
                              ? "Fail"
                              : data.status == "CANCEL"
                              ? "Cancel"
                              : data.status == "PROCESSED"
                              ? "Processed"
                              : "Successful"}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="balance_detail">
                          <div className="account">
                            Bank Account: {data.bankAccountId.bankAccountNumber}
                          </div>
                          <div className="ifsc">
                            IFSC: {data.bankAccountId.ifsc}
                          </div>
                          <div className="ref">
                            Transaction reference: {data.orderId}
                          </div>
                          {data.status == "PROCESSED" && (
                            // data.status == "CREATED" &&
                            <p
                              style={{
                                color: "pink",
                                fontSize: ".6rem",
                                marginTop: ".2rem",
                              }}
                            >
                              Your Request has been processed. Funds should be
                              visible in your bank account in 2-3 Business Days
                            </p>
                          )}
                          {/* {
                                                        <>
                                                        data.err &&
                                                        <div className="err_note">
                                                        Transaction failed as funds were received from a bank accountthat is not registered. <br /> Please click here to <a href="#"> Add a bank account </a>
                                                        </div>
                                                        </>
                                                    } */}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FundsTransfer;
