import { toast } from "react-toastify";
import { addNet } from "../../api/FundsAPI";
import { cashfreeGateway } from "./cashfree";

export const addNetFunds = async (
  setLoading,
  props,
  history,
  banks,
  value,
  bank,
  net,
  setnetBankingCode,
  token,
  reasons
) => {
  if (banks.length < 1) {
    toast.warning("Please add bank first", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    bank = banks[value];
    setLoading(true);
    let data = await addNet(
      props?.user?.token || token,
      net,
      bank,
      props.type === "net" ? "NETBANKING" : "UPI",
      reasons
    );
    if (data.error != null) {
      setLoading(false);
      toast.error("Funds not added", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log("0001", data.data.cashfree.bankCode);
      const bankCode =
        data.data.cashfree.bankCode === null
          ? 3021
          : data.data.cashfree.bankCode;
      setnetBankingCode(bankCode);
      if (data.data.externalPaymentGatewaySystem === "CASHFREE") {
        if (bankCode) {
          await cashfreeGateway(
            props?.user?.token || token,
            data,
            history,
            props.type
          );
        }
      } else {
        setLoading(false);
        toast.error("Payment Failed", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }
};
