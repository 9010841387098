import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import clsx from "clsx";
import { Button, makeStyles } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import CloseIcon from "@material-ui/icons/Close";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import LogoLogic from "../logo-logic/LogoLogic";

import Button1 from "../../components/button/Button";

import notifications from "../../Assets/imgs/notification.svg";
import toggle from "../../Assets/imgs/toggle.svg";
import search from "../../Assets/imgs/search.svg";
import filter from "../../Assets/imgs/filter.svg";
import Profile from "../../Assets/imgs/profile.svg";
import Dashboard from "../../Assets/imgs/dashboard.svg";
import Funds from "../../Assets/imgs/funds.svg";
import Order from "../../Assets/imgs/order.svg";
import ActiveTradeIcon from "../../Assets/imgs/ActiveTradeIcon.svg";
import Clients from "../../Assets/imgs/clients.svg";
import Setting from "../../Assets/imgs/setting.svg";
import Earnings from "../../Assets/imgs/earnings.svg";
import Pitch from "../../Assets/imgs/pitch.svg";
import ShareScreenComponent from "../share-screen/ShareScreen";

// icons
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import LoginIcon from "@material-ui/icons/AccountCircle";
import ShareIcon from '@material-ui/icons/Share';

import { toast } from "react-toastify";

import { whoIam } from "../../api/order";
import { useSelector, useDispatch } from "react-redux";

import "./ClientPartnerMenu.scss";
import { profile_details } from "../../api/profile";
import { set_profile, signup_user } from "../../store/User";
import { useHistory } from "react-router-dom";

const ClientpartnerMenu = (props) => {
  const user = useSelector((state) => state.user);
  const [isMobile, setIsMobile] = useState(false);

  const useStyles = makeStyles({
    list: {
      width: "100%",
    },
    fullList: {
      width: "auto",
    },
    menuDrawer: {
      "& .MuiPaper-root": {
        width: isMobile ? "80%" : "100%",
      },
    },
    login: {
      width: "100%",
      backgroundColor: "#2778ff",
      color: "white",
      fontWeight:"700",
      padding: ".5rem 0",
      borderRadius: "5px",
    },
    logout: {
      width: "100%",
      backgroundColor: "#ff0909",
      color: "white",
      fontWeight:"700",
      padding: ".5rem 0",
      borderRadius: "5px",
    },
    reffer: {
      width: "100%",
      backgroundColor: "#2778ff",
      color: "white",
      fontWeight:"700",
      padding: ".5rem 0",
      borderRadius: "5px",
    },
  });

  const classes = useStyles();
  const [state, setState] = useState({
    left: false,
  });
  const [shareScreen, setShareScreen] = useState({
    open: false,
    url: "",
  });

  const [userType, setUserType] = useState(null);
  const [width, setWidth] = useState(true);
  const [isTokenExpire, setisTokenExpire] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  // this code isn't doing anything
  const get_details = async () => {
    const details = await profile_details(localStorage.getItem("token"));
    console.log(details);
    if (details.error) {
      toast.error(details.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (details.data) {
        localStorage.setItem("profile", JSON.stringify(details.data.data));
        dispatch(set_profile(details.data.data));
      }
    }
  };
  useEffect(async () => {
    try {
      const { data } = await whoIam(localStorage.getItem("token"));
      console.log(data);
      // if token is not valid then null will be returned
      setisTokenExpire(data ? false : true);
      if (data && data.error == null) {
        setUserType(data?.userType);
        setisTokenExpire(false);
      }
    } catch (error) {
      console.log(error);
      setisTokenExpire(true);
    }
  }, []);
  const handleClose = () => {
    setState({
      left: false,
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (isMobile) {
      setState({ ...state, [anchor]: open });
    }
  };

  useEffect(() => {
    const screenWidth = window.innerWidth;
    // console.log(screenWidth, "screenwidth");
    if (screenWidth > 540) {
      setState({ left: true });
      setWidth(false);
    } else {
      setIsMobile(true);
    }
  }, []);

  const share = () => {
    let link = `${window.location.protocol}//${window.location.host}/register?referredBy=${user.profile.userId}`;
    if (navigator.share) {
      navigator
        .share({
          title: "FundsMap Refral",
          url: link,
        })
        .then(() => {
          console.log("Shared");
        })
        .catch(console.log("FailShare"));
    } else {
      setShareScreen({
        open: true,
        url: link,
      });
      // alert("No Mobile")
    }
  };
  const shareClient = () => {
    let link = `${window.location.protocol}//${window.location.host}/dashboard`;
    if (navigator.share) {
      navigator
        .share({
          title: "Platform for unlisted share trading",
          url: link,
        })
        .then(() => {
          console.log("Shared");
        })
        .catch(console.log("FailShare"));
    } else {
      setShareScreen({
        open: true,
        url: link,
      });
    }
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={() => {
        toggleDrawer(anchor, false);
      }}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="menu_box">
        {width && (
          <Button onClick={handleClose} className="menu_close">
            <CloseIcon />
          </Button>
        )}

        <div className="avater_box">
          <div className="avater">
            <PersonOutlineIcon className="signup_icon" />
          </div>
          <div className="avater_title add_ellipses">
            {isTokenExpire !== null &&
              (isTokenExpire ? (
                <Link to="/register">Sign up / Sign in</Link>
              ) : (
                user?.profile?.email
              ))}
          </div>
        </div>
        <div className="menu_data">
          <NavLink
            to="/profile"
            className="nav_link"
            activeClassName="active_nav_link"
            onClick={() =>
              setState({ ...state, left: isMobile ? false : true })
            }
          >
            <div className="inp">
              <img src={Profile} alt="ERROR" />
              <div className="inp_title fontweight_500"> My Profile </div>
            </div>
          </NavLink>
          <NavLink
            to="/dashboard"
            className="nav_link"
            activeClassName="active_nav_link"
            onClick={() =>
              setState({ ...state, left: isMobile ? false : true })
            }
          >
            <div className="inp">
              <img src={Dashboard} alt="ERROR" />
              <div className="inp_title fontweight_500"> Dashboard </div>
            </div>
          </NavLink>
          <NavLink
            to="/funds"
            className="nav_link"
            activeClassName="active_nav_link"
            onClick={() =>
              setState({ ...state, left: isMobile ? false : true })
            }
          >
            <div className="inp">
              <img src={Funds} alt="ERROR" />
              <div className="inp_title fontweight_500"> Funds </div>
            </div>
          </NavLink>
          <NavLink
            to="/allorders"
            className="nav_link"
            activeClassName="active_nav_link"
            onClick={() =>
              setState({ ...state, left: isMobile ? false : true })
            }
          >
            <div className="inp">
              <img src={Order} alt="ERROR" />
              <div className="inp_title fontweight_500"> Orders </div>
            </div>
          </NavLink>
          <NavLink
            to="/activeTrades"
            className="nav_link"
            activeClassName="active_nav_link"
            onClick={() =>
              setState({ ...state, left: isMobile ? false : true })
            }
          >
            <div className="inp">
              <img src={ActiveTradeIcon} alt="ERROR" />
              <div className="inp_title fontweight_500"> Active Trades </div>
            </div>
          </NavLink>
          {userType == "ORGANISATIONAL_PARTNER" ||
          userType == "INDIVIDUAL_PARTNER" ? (
            <>
              <NavLink
                to="/clients"
                className="nav_link"
                activeClassName="active_nav_link"
                onClick={() =>
                  setState({ ...state, left: isMobile ? false : true })
                }
              >
                <div className="inp">
                  <img src={Clients} alt="ERROR" />
                  <div className="inp_title fontweight_500"> Clients </div>
                </div>
              </NavLink>
            </>
          ) : null}

          {userType == "ORGANISATIONAL_PARTNER" ||
          userType == "INDIVIDUAL_PARTNER" ? (
            <>
              <NavLink
                to="/menusetting"
                className="nav_link"
                activeClassName="active_nav_link"
                onClick={() =>
                  setState({ ...state, left: isMobile ? false : true })
                }
              >
                <div className="inp">
                  <img src={Setting} alt="ERROR" />
                  <div className="inp_title fontweight_500"> Settings </div>
                </div>
              </NavLink>
              <NavLink
                to="/earnings"
                className="nav_link"
                activeClassName="active_nav_link"
                onClick={() =>
                  setState({ ...state, left: isMobile ? false : true })
                }
              >
                <div className="inp">
                  <img src={Earnings} alt="ERROR" />
                  <div className="inp_title fontweight_500"> My Earnings </div>
                </div>
              </NavLink>
              {/* <NavLink
                  to="/pitch"
                  className="nav_link"
                  activeClassName="active_nav_link"
                >
                  <div className="inp">
                    <img src={Pitch} alt="ERROR" />
                    <div className="inp_title fontweight_500"> Pitch to clients </div>
                  </div>
                </NavLink> */}
            </>
          ) : null}
        </div>
        {userType == "ORGANISATIONAL_PARTNER" ||
        userType == "INDIVIDUAL_PARTNER" ? (
          <>
            <div className="ref_box">
              <p className="ref_title">Share link</p>
              <p className="ref_detail fontweight_500">
                Share this link with your clients
              </p>
              <Button1 wid="width" text="SHARE LINK" click={share}></Button1>
            </div>
          </>
        ) : (
          <>
            <div className="ref_box">
              <p className="ref_title">Share</p>
              <p className="ref_detail fontweight_500">
                Share this platform with your friends
              </p>
          <Button
            variant="contained"
            fullWidth
            className={classes?.reffer}
            startIcon={
             <ShareIcon/>
            }
            onClick={shareClient}
            
          >
            
            Refer a Friend
          </Button>
            </div>
          </>
        )}
        <div className="becomepartner_box">
          <Button
            variant="contained"
            fullWidth
            className={user?.token || user?.profile ? classes?.logout : classes?.login}
            startIcon={
              user?.token || user?.profile ? <LogoutIcon /> : <LoginIcon />
            }
            onClick={() => {
              if (user?.token) {
                localStorage.removeItem("token");
                localStorage.removeItem("profile");
                dispatch(signup_user(null));
                dispatch(set_profile(null));
                history.push("/register");
              } else {
                history.push("/register");
              }
            }}
          >
            {user?.token || user?.profile ? "Log Out" : "Login"}
          </Button>
          <a
            className="feedback fontweight_500"
            href="https://fundsmap.freshdesk.com/support/tickets/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            Feedback
          </a>
          {/* <p className="rateus fontweight_500"> Rate us </p>  */}
          <a
            href="https://www.fundsmap.com"
            className="partner fontweight_500"
            activeClassName="partnerActive"
            target="_blank"
            rel="noopener noreferrer"
          >
            Powered by FundsMap
          </a>
          <a
            href="https://www.fundsmap.com/disclaimer"
            className="partner fontweight_500"
            activeClassName="partnerActive"
            target="_blank"
            rel="noopener noreferrer"
            style={{color : '#B33F40'}}
          >
            Disclaimer
          </a>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {shareScreen.open && (
        <ShareScreenComponent
          url={shareScreen.url}
          onClose={() => setShareScreen({ open: false, url: "" })}
        />
      )}
      <div className="menu_container">
        <div className="menu_nav">
          {width && (
            <Button onClick={toggleDrawer("left", true)}>
              <img src={toggle} alt="ERROR" className="toggle_icon" />
            </Button>
          )}
          <LogoLogic pagelogo={props.pageLogo} hideLogo="false" />
          {props.searchbar ? (
            <>
              <div className="menu_nav_search">
                <img
                  src={search}
                  alt="ERROR"
                  className="search_icon"
                  onClick={() => {
                    props.onFocus && props.onFocus();
                  }}
                />
                <div className="search_bar">
                  <input
                    type="text"
                    className="search_input"
                    placeholder="Search"
                    onClick={() => {
                      props.onFocus && props.onFocus();
                    }}
                  />
                  {/* <img src={filter} alt="ERROR" className="filter_icon" /> */}
                </div>
              </div>
            </>
          ) : null}

          <SwipeableDrawer
            disableEnforceFocus
            className={classes.menuDrawer}
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
            transitionDuration={
              isMobile === true
                ? { enter: 500, exit: 250 }
                : { enter: 0, exit: 0 }
            }
          >
            {list("left")}
          </SwipeableDrawer>
        </div>
      </div>
    </>
  );
};

export default ClientpartnerMenu;
