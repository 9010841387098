import axios from "axios";

import {
    setupCache,
    buildMemoryStorage,
    defaultKeyGenerator,
    defaultHeaderInterpreter
  } from 'axios-cache-interceptor';
  

  const instance = axios.create({
    baseURL: process.env.REACT_APP_PROD_STATUS == "false" ? process.env.REACT_APP_DEV_LINK : process.env.REACT_APP_PROD_LINK,
  });
  console.log("\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ " , process.env.REACT_APP_PROD_STATUS);
  

  const axiosCacheInstance = setupCache(
    // axios instance
    instance,
  
    // All options with their default values
    {
      // The storage to save the cache data. There are more available by default.
      //
      // https://axios-cache-interceptor.js.org/#/pages/storages
      storage: buildMemoryStorage(),
  
      // The mechanism to generate a unique key for each request.
      //
      // https://axios-cache-interceptor.js.org/#/pages/request-id
      generateKey: defaultKeyGenerator,
  
      // The mechanism to interpret headers (when cache.interpretHeader is true).
      //
      // https://axios-cache-interceptor.js.org/#/pages/global-configuration?id=headerinterpreter
      headerInterpreter: defaultHeaderInterpreter,
  
      // The function that will receive debug information.
      // NOTE: For this to work, you need to enable development mode.
      //
      // https://axios-cache-interceptor.js.org/#/pages/development-mode
      // https://axios-cache-interceptor.js.org/#/pages/global-configuration?id=debug
      debug: undefined
    }
  );



export default axiosCacheInstance;
