import React, { useState } from 'react'
import { Link, useLocation, useHistory } from "react-router-dom"

import BuyerInfo from '../../OrderModification/Components/BuyerInfo/BuyerInfo'
import message from "../../../Assets/imgs/message.svg"
import Button from '../../../components/button/Button'
import Check from "../../../Assets/imgs/check2.svg"
import Copy from "../../../Assets/imgs/copy.svg"

import ShareScreenComponent from "../../../components/share-screen/ShareScreen"

import './CallClient.scss'



const CallClient = () => {
    const history = useHistory();
    const location = useLocation();

    let orderId = location.state.orderId
    let company = location.state.company
    let client = location.state.user

    let orderInfo = location.state.orderInfo
    let expireTime = location.state.expireTime

    let link = `${window.location.protocol}//${window.location.host}/validate-order?orderId=${orderId}&client=${client}`

    const [copied, setCopied] = useState(null)
    const [shareScreen, setShareScreen] = useState(false)
    const copyLink = () => {
        navigator.clipboard.writeText(link)
        setCopied(true)
    }

    const share = () => {
        if (navigator.share) {
            navigator.share({
                title: "FundsMap Refral",
                url: `${link}`
            }).then(() => {
                console.log("Shared")
            }).catch(console.log("FailShare"))
        } else {
            setShareScreen(true)
        }
    }

    return (
        <>
            {shareScreen && <ShareScreenComponent url={link} onClose={() => setShareScreen(false)} />}
            <div className="cc__container">
                <div className="cc__upper">
                    {
                        client == "cancel" || client == "modify" ?
                            <>
                                <div className="heading">
                                    Order <span> { client == "modify" ? "Modification" : "Cancellation"} </span>
                                </div>
                            </>
                            :
                            null
                    }
                    <div className="cc__buyerInfo__comp">
                        {
                            client == "cancel" ?
                                <>
                                    <BuyerInfo path="order-modification" data={company} detail={orderInfo} time={expireTime} />
                                </>
                                :
                                <>
                                    <BuyerInfo path="entry" data={company} />
                                </>
                        }
                    </div>
                    <div className="cc__alert">
                        <div className="linksent">
                            <img src={Check} alt="" />
                            <p> Link sent to client via email.</p>
                        </div>
                        <div className="linkcopy">
                            <a href="javascript:void(0)" > {link} </a>
                            <div className="copyimg" onClick={copyLink}>
                                <img src={Copy} alt="" />
                                <p style={{ color: copied ? "green" : null }}> {copied ? "copied" : "copy"} </p>
                            </div>
                        </div>
                    </div>
                    <div className="cc__img">
                        <img src={message} alt="" className="cc__message__icon" />

                    </div>
                    <p className="cc__para1">
                        At this point you can call your client to inform them to proceed with the {client == "cancel" || client == "modify" ? "modification / cancellation" : "funds transfer"} .
                    </p>
                    {
                        client != "cancel" || client != "modify" ?
                            <>
                                <p className="cc__para1">
                                    Here on you can track the progress of this transaction in the <Link to="/allorders">Order Tab.</Link>
                                </p>
                            </>
                            :
                            null
                    }
                </div>
                <div className="cc__lower bot_btn">
                    <Button text="Share on Whatsapp" click={share} />
                </div>
            </div>
        </>
    )
}

export default CallClient
