import { useState, useEffect } from "react";
import Button from "../../components/button/Button";
import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import "./Evar.css";
import Backarrow from "../../components/backarrow/Backarrow";
import { toast } from "react-toastify";

import evar from "../../Assets/imgs/evar.svg";
import { email_verification } from "../../api/auth";
const Evar = () => {
  const location = useLocation();
  const [resend, setResend] = useState(false);
  const [secs, setSecs] = useState(15);
  const stateToken = useSelector((state) => state.user.token);
  console.log(location);
  console.log(location.state);

  const handle_resend = async () => {
    const verification = await email_verification(stateToken);
    if (verification.error) {
      toast.error(verification.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setSecs(30);
      setResend(false);
    }
  };

  useEffect(() => {
    if (!resend) {
      setInterval(
        () =>
          setSecs((secs) => {
            if (secs > 0) {
              return secs - 1;
            } else {
              setResend(true);
              return 0;
            }
          }),
        1000
      );
    }
  }, []);
  return (
    <>
      <div className="Evar__container">
        <div className="Evar__header">
          <Backarrow />
          <img src={evar} alt="Error" className="signin__img" />

          <div className="Evar__heading">Email Verification</div>

          <div className="body">
            <div className="Evar__para1">
              Check your email inbox. We've mailed you an e-mail verification
              link on your id {location.state && location.state.email}
            </div>

            <div className="Evar__para2">
              <p className="ptwo1">Didn't receive an email?</p>
              <p className="ptwo2">
                {!resend ? (
                  `Re-send Email (After ${secs}s)`
                ) : (
                  <span className="resend_email" onClick={handle_resend}>
                    {" "}
                    Re-send Email
                  </span>
                )}
                |
                <Link className="para2span" to="/partner/signup">
                  Change Email ID
                </Link>
              </p>
            </div>
          </div>
        </div>
        <a href="mailto:">
          <Button text="OPEN MAIL" />
        </a>
      </div>
    </>
  );
};

export default Evar;
