import BottomSlide from "../../components/bottom-slide/BottomSlide";
import BSHeader from "../profile/components/bs-header/BSHeader";
import { useState } from "react";
import UploadDemet from "./UploadDemet/UploadDemet";
import Button from "../../components/button/Button";
import AddDemet from "./AddDemetAccount/AddDemet";
import { add_demat, otp_generate, confirm_demat } from "../../api/profile";

import { toast } from "react-toastify";

function MainDemet({
  demat,
  setDemat,
  stepD,
  setStepD,
  get_demat1,
  profileData,
  get_details,
}) {
  const [pdf_file, setPdf_color] = useState(null);
  const [demat_response, setDemat_response] = useState();
  const [otp, setOtp] = useState();
  const [data_for_step2, setData_for_step2] = useState();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const verify_upload = async (data) => {
    let demat_res = await add_demat(data);
    if (demat_res.error) {
      toast.error(demat_res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log("res", demat_res);
      setDemat_response(demat_res);
      let otp_res = await otp_generate(demat_res.data?.data?.validationKey);
      if (otp_res.error) {
        toast.error(otp_res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setData_for_step2({ clientid: demat_res.data?.data?.clientId });
        setStepD("step2");
      }
    }
  };

  const confirm_demat1 = async () => {
    setLoading(true);
    let confirm_res = await confirm_demat(
      demat_response.data?.data?.clientId,
      demat_response.data?.data?.depositoryId,
      demat_response.data?.data?.validationKey,
      otp
    );
    if (confirm_res.error) {
      setLoading(false);
      toast.error(confirm_res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      get_demat1();
      get_details();
      setStepD("step1");
      setDemat(false);
      setLoading(false);
    }
  };

  return (
    <div>
      <BottomSlide closeBSlider={demat}>
        <BSHeader
          setCloseBSlider={() => {
            setDemat(false);
            setStepD("step1");
          }}
          text="Add Demat A/C"
        />
        {stepD === "step1" && (
          <UploadDemet
            setPdf_color={setPdf_color}
            verify_upload={verify_upload}
          />
        )}
        {stepD === "step2" && (
          <AddDemet
            demat_response={demat_response?.data?.data}
            setOtp={setOtp}
            profileData={profileData}
            setDisabled={setDisabled}
          />
        )}
        {stepD === "step2" && (
          <Button
            loading={loading}
            click={() => confirm_demat1()}
            text="CONFIRM DEMAT ACCOUNT"
            style={
              disabled
                ? { background: "lightgrey", pointerEvents: "none" }
                : null
            }
          />
        )}
      </BottomSlide>
    </div>
  );
}

export default MainDemet;
