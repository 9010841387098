import React, { useEffect, useState } from "react";
import "./Completed.css";
import { adhar_details, pan_details } from "../../../../api/profile";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Completed = ({ basicInfo }) => {
  const [adharDetails, setAdharDetails] = useState("");
  const [panDetails, setPanDetails] = useState("");
  const stateToken = useSelector((state) => state.user.token);

  const get_adhar = async () => {
    let response = await adhar_details(stateToken);
    if (response.error) {
      toast.error(response.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log(response.data);
      setAdharDetails(response.data.data);
    }
  };
  const get_pan = async () => {
    let response = await pan_details(stateToken);
    if (response.error) {
      toast.error(response.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log(response?.data);
      setPanDetails(response?.data?.data);
    }
  };

  useEffect(() => {
    get_adhar();
    get_pan();
    console.log("basic info :", basicInfo);
  }, []);

  return (
    <div className="profile__completed_wrapper">
      <div className="pcomp__item">
        <span className="pcomp__subitem">
          <span className="pcomp__item_label">ID:</span>
          <span>{basicInfo.userId}</span>
        </span>
        <span className="pcomp__subitem">
          <span className="pcomp__item_label">Email:</span>
          <span>{basicInfo.email}</span>
        </span>
      </div>
      <div className="pcomp__item">
        <span className="pcomp__subitem">
          <span className="pcomp__item_label">Name:</span>
          <span>{panDetails.name}</span>
        </span>
        <span className="pcomp__subitem">
          <span className="pcomp__item_label">Status:</span>
          <span>Individual (Indian Resident)</span>
        </span>
        <span className="pcomp__subitem">
          <span className="pcomp__item_label">Phone:</span>
          <span>
            {basicInfo.mobileNumber ? basicInfo.mobileNumber : "Not Available"}
          </span>
        </span>
      </div>
      <div className="pcomp__item">
        <span className="pcomp__item_label">Permanent Address:</span>
        <span>{adharDetails && adharDetails.permanentAddress.address}</span>
      </div>
      <div className="pcomp__item">
        <span className="pcomp__item_label">Current Address:</span>
        <span>{adharDetails && adharDetails.currentAddress.address}</span>
      </div>
      <div className="pcomp__item">
        <span className="pcomp__item_label">Legal Information</span>
        <span className="pcomp__subitem">
          <span className="pcomp__item_label">PAN:</span>
          <span>
            {panDetails && panDetails.panId}
            <span className="pcomp__item_label">
              &nbsp; (DOB:&nbsp;
              {panDetails &&
                panDetails.dob.join("/").split("/").reverse().join("/")}
              )
            </span>
          </span>
        </span>
        <span className="pcomp__subitem">
          <span className="pcomp__item_label">AADHAAR:</span>
          <span>{adharDetails && adharDetails.aadhaarId}</span>
        </span>
      </div>
    </div>
  );
};

export default Completed;
