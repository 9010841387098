import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import Progress from "@material-ui/core/CircularProgress";
import MButton from "@material-ui/core/Button";

import BuyerInfo from "../../../OrderModification/Components/BuyerInfo/BuyerInfo";
import Reqfund from "../../../OrderModification/RequireFunds/Reqfund";
import BottomSlide from "../../../../components/bottom-slide/BottomSlide";
import Userprofile from "../CompleteProfile/Userprofile";
import backarrow from "../../../../Assets/imgs/backarrow.svg";
import CheckboxComponent from "../../../../components/ConfirmCheckbox/CheckboxComponent";

import Button from "../../../../components/button/Button";
import ChoseBank from "../../../OrderModification/ChoseBank/ChoseBank";
import AddBank from "../../../AddBank/Bankinfo-popup/BankMain";

import ConfirmOrderOtp from "../../../OrderModification/CnfrmOrderModi/Cordermodi";
import Cordermodi from "../../../OrderModification/CnfrmOrderModi/Cordermodi";
import CloseIcon from "@material-ui/icons/Close";

import { toast } from "react-toastify";

import {
  ConfirmOrderApi,
  preSign,
  getOrder,
  getCompany,
  getOrderSign,
  orderSignAccept,
  unRegisterShare,
  genOtp,
  confirmOrder,
  notify,
  orderSign,
} from "../../../../api/order";
import { getBanks, getVpa } from "../../../../api/FundsAPI";
import { get_resources } from "../../../../api/dashboard";
import run_digio from "../DealDetails/DigioIntegration";
import { useSelector } from "react-redux";

import Check from "../../../../Assets/imgs/check.svg";
import "./ConfirmOrder.scss";
import ScreenLoader from "../../../../components/screen-loader/ScreenLoader";
import { addNetFunds } from "../../../funds/addFunds";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  btn1: {
    width: "100%",
    backgroundColor: "#2778ff",
    color: "white",
    padding: ".5rem 0",
    fontSize: "1.1rem",
    borderRadius: "8px",
  },
  btn2: {
    width: "100%",
    backgroundColor: "#ecf6ff",
    color: "#2a6eff",
    padding: ".5rem 0",
    fontSize: "1.1rem",
    borderRadius: "8px",
  },
}));

const Success = (props) => {
  const classes = useStyles();

  const done = () => {
    props.setOpenSlide({
      open: false,
      div: "addfunds",
    });
    props.setReload((preValue) => {
      return preValue === false ? true : false;
    });
  };

  return (
    <>
      <div className="success_container">
        <img src={Check} alt="ERROR" />
        <div className="message"> Funds has been added successfully </div>
        <MButton className={classes.btn1} onClick={done}>
          DONE
        </MButton>
      </div>
    </>
  );
};

const EnterUPIID = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [banks, setBanks] = useState(null);
  const [upiid, setupiid] = useState("");
  const [netBankingCode, setnetBankingCode] = useState(0);

  const [isUPIidValid, setUPIisValid] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  // const [razorError, setRazorError] = useState(null)

  let bank;

  useEffect(() => {
    const getData = async () => {
      let data = await getBanks(props.user.token);
      if (data.error != null) {
        toast.error(data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setBanks(data.data);
      }
    };

    getData();
  }, []);

  const addNetFundsClick = async () => {
    setProcessingPayment(true);
    // make reason array with order margin and cumulative shortfall with proper check
    let reasons = [];

    // if order shortfall exists push on the array with order id
    if (props.shortfall) {
      if (props.confirmOrderDetail.margin.previousPendingAmount > 0) {
        reasons.push({
          reason: "CUMULATIVE_SHORTFALL",
          id: props.confirmOrderDetail.orderId.orderRequestId,
          amount: props.confirmOrderDetail.margin.previousPendingAmount,
        });
      }
    }
    // if order margin exists push on the array with order id
    if (props.margin) {
      if (props.confirmOrderDetail.margin.marginShortfall > 0) {
        reasons.push({
          reason: "ORDER_MARGIN",
          id: props.confirmOrderDetail.orderId.orderRequestId,
          amount: props.confirmOrderDetail.margin.marginShortfall,
        });
      }
    }

    let net = {
      ammount:
        props.confirmOrderDetail.margin.shortfall < 1
          ? 1
          : Math.ceil(props.confirmOrderDetail.margin.shortfall),
      transferType: props.selectedBankData.type,
    };
    console.log(props);
    try {
      await addNetFunds(
        props.setLoadingBackDrop,
        props,
        history,
        banks,
        props.bankIndex,
        bank,
        net,
        setnetBankingCode,
        null,
        reasons
      );
    } catch (err) {
      console.log(err);
      props.closeBottom();
      toast.error("Funds not added");
    }

    function changeTextCashFree() {
      setTimeout(() => {
        let cashfreeLoader = document.getElementById("cf-loader");
        if (cashfreeLoader === null) {
          changeTextCashFree();
        } else {
          cashfreeLoader.getElementsByTagName("p")[0].innerText =
            "Payment request has been sent to your UPI app.\n Please check the app";
        }
      }, 100);
    }
    changeTextCashFree();
  };
  return (
    <>
      <div className="add_bank_slide" style={{ gridGap: "0rem", gap: "0rem" }}>
        <div className="heading">
          <p> Add Funds </p>
        </div>
        <div className="detail">
          <p style={{ marginTop: "0.7rem", marginBottom: "0.7rem" }}>
            Enter UPI ID
          </p>
          <p>UPI ID entered should be linked to</p>
          <p>
            Bank Account No.:
            {props.selectedBankData?.bank?.bankAccountId?.bankAccountNumber}
          </p>
          <p>IFSC: {props.selectedBankData?.bank?.bankAccountId?.ifsc}</p>
        </div>
        <div id="pay-collect">
          <TextField
            style={{ width: "100%", marginBottom: "0.4rem", marginTop: "2rem" }}
            label="Enter UPI ID"
            type="text"
            variant="standard"
            value={upiid}
            onChange={(e) => {
              setupiid(e.target.value);
              let upiValidCheck = new RegExp(
                "[a-zA-Z0-9\\.\\-]{2,256}\\@[a-zA-Z][a-zA-Z]{2,64}"
              );
              if (!upiValidCheck.test(e.target.value)) {
                setUPIisValid(false);
              } else {
                setUPIisValid(true);
              }
            }}
            inputProps={{ "data-upi-id": true }}
          />
          {/* <input type="text" value="testsuccess@gocash" data-upi-id/> */}
          <div style={{ marginBottom: "4rem" }}>
            {!isUPIidValid && upiid.length !== 0 && (
              <div
                style={{ fontSize: ".75rem", color: "red" }}
                className="error"
              >
                Please enter valid UPI ID
              </div>
            )}
          </div>
          <Button
            id="pay-collect-btn"
            text="CONTINUE"
            className={classes.btn1}
            click={addNetFundsClick}
            disabled={!isUPIidValid}
          />
        </div>
        {processingPayment && (
          <div className="backdrop">
            <Progress />
          </div>
        )}
      </div>
    </>
  );
};

const ConfirmOrder = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  const orderId = new URLSearchParams(location.search).get("orderId");
  const version = new URLSearchParams(location.search).get("orderVersion");
  const by = new URLSearchParams(location.search).get("by");
  const client = new URLSearchParams(location.search).get("client");
  const extra = new URLSearchParams(location.search).get("extra");

  const [orderInfo, setOrderInfo] = useState(null);
  const [company, setCompany] = useState(null);
  const [share, setShare] = useState(null);
  const [confirmOrderDetail, setConfirmOrderDetail] = useState(null);
  const [loadingBackDrop, setLoadingBackDrop] = useState(false);
  const [bankIndex, setBankIndex] = useState(0);

  const [otp, setOtp] = useState("");

  const [digioData, setDigioData] = useState(null);
  const [otpValue, setOtpValue] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);

  const [openSlide, setOpenSlide] = useState({
    open: false,
    div: null,
  });

  const [addFundsDetail, setAddFundsDetails] = useState({
    type: "",
    bank: null,
  });

  const [openAddBank, setOpenAddBank] = useState(false);
  const [addBankstep, setAddBankStep] = useState("step1");

  const loadingMSGs = [
    "Hang on tight..",
    "Checking your Order Detail",
    "Preparing an agreement for you",
    "Dotting the I's and crossing the T'",
    "Doing necessary risk check",
    "Almost there",
  ];

  const [reload, setReload] = useState(false);
  const [resError, setResError] = useState(null);
  const [pageLoading, setPageLoading] = useState(null);
  const [loadingText, setLoadingText] = useState(loadingMSGs[0]);

  useEffect(() => {
    if (client !== "unregister") {
      gettingOrder();
    } else {
      gettingUnRegisterShare();
    }
  }, []);
  useEffect(() => {
    orderInfo && gettingCompany();
    if (orderInfo != null && client === "register") {
      if (
        orderInfo.orderStatus === "CREATED" ||
        orderInfo.orderStatus === "SIGNING"
      ) {
        gettingOrderValidate();
      }
    } else if (orderInfo != null && client === "unregister") {
      setConfirmOrderDetail(true);
    } else if (orderInfo != null && !client) {
      gettingOrderValidate();
    }
  }, [orderInfo, reload]);

  const gettingOrder = async () => {
    let res = await getOrder(user.token, orderId, version);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setOrderInfo(res.data);
      setShare({
        shares: res.data.qty,
        price: res.data.price,
      });
    }
  };
  const gettingCompany = async () => {
    let res = await getCompany(
      user.token,
      client === "unregister" ? orderInfo.orderDetails.symbol : orderInfo.symbol
    );
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setCompany(res.data);
    }
  };
  const gettingOrderValidate = async () => {
    let res = await ConfirmOrderApi(
      user.token,
      orderInfo.orderRequestId,
      orderInfo.version
    );
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setConfirmOrderDetail(res.data);
    }
  };
  const gettingUnRegisterShare = async () => {
    let res = await unRegisterShare(orderId);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setOrderInfo(res.data);
    }
  };

  const onSucc = async (digioRes) => {
    let res = await orderSignAccept(user.token, digioRes, "SUCCESS");
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPageLoading(null);
      setOpenSlide({
        open: true,
        div: "fail",
      });
      setResError(res.error);
    } else {
      history.push({
        pathname: "/what-now",
        state: {
          share: share,
          company: company,
          status: "Order Confirmed",
          data: res.data,
        },
      });
    }
  };
  const onError = () => {
    history.go({
      pathname: "/confirm-order",
      search: `?orderId=${orderInfo.orderRequestId}&orderVersion=${orderInfo.version}`,
    });
  };
  const close = () => {
    // history.push(`/dashboard/order-entry?symbol=${company.symbol}`)
    history.push(`/order/${orderInfo.orderRequestId}?v=${orderInfo.version}`);
  };

  const FailOrder = () => {
    return (
      <>
        <div className="fail_container">
          <div className="fail_heading">
            Your <span> order </span> <br />
            could not be <span> confirmed </span>
          </div>
          <div className="fail_detail">
            <div className="fail_orderid">
              <span>Order ID: </span> {orderInfo.orderRequestId}
            </div>
            <div className="fail_orderversion">
              <span>Order Version: </span> {orderInfo.version}
            </div>
            <div className="fail_reason">
              <span>Reason: </span> {resError && resError}
            </div>
          </div>
          <div className="fail_disc">
            Do not worry <br />
            Any blocked amount would be reverted to your free funds within a few
            hours. <br />
            In case of any queries, <br />
            Reach out us at support@fundsmap.com
          </div>
          <Button text="CLOSE" click={close} />
        </div>
      </>
    );
  };

  const modifyOrder = async () => {
    setPageLoading(true);
    let res = await confirmOrder(
      user.token,
      confirmOrderDetail.orderId.orderRequestId,
      confirmOrderDetail.orderId.version,
      otp
    );
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPageLoading(null);
    } else {
      history.push({
        pathname: "/success",
        state: { company, orderInfo, path: "succ" },
      });
    }
  };

  useEffect(() => {
    if (openSlide.open) {
      setLoadingBackDrop(false);
    }
  }, [openSlide.open]);

  const confirmTheOrder = async () => {
    setLoadingBackDrop(true);
    console.log(confirmOrderDetail);
    if (by === "partner") {
      let res = await notify(
        user.token,
        client === "unregister" ? orderInfo.orderId : orderInfo.orderRequestId,
        orderInfo.version,
        client
      );
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        history.push({
          pathname: "call-client",
          state: {
            orderId:
              client === "unregister"
                ? orderInfo.orderId
                : orderInfo.orderRequestId,
            company: company,
            user: client,
          },
        });
      }
    } else {
      if (orderInfo.orderStatus === "SIGNING") {
        setPageLoading(true);
        let res = await getOrderSign(
          user.token,
          orderInfo.orderRequestId,
          orderInfo.version
        );
        if (res.error != null) {
          toast.error(res.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          let data = await get_resources();
          if (data.error != null) {
            toast.error(data.error, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            run_digio(
              {
                id: res.data.requestId,
                customerIdentifier: res.data.identifier,
                token: res.data.accessTokenId,
                imageUrl: data.data.data.avatar,
              },
              onSucc,
              onError
            );
          }
        }
      } else {
        if (confirmOrderDetail.failure === "MARGIN") {
          setOpenSlide({
            open: true,
            div: "addfunds",
          });
        } else if (confirmOrderDetail.failure === "PROFILE") {
          setOpenSlide({
            open: true,
            div: "profile",
          });
        } else if (confirmOrderDetail.failure === "COMPANY") {
          toast.error("Invalid company name please place order again", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.go("/dashboard");
          }, 2000);
        } else if (
          confirmOrderDetail.failure === "OTHER" &&
          confirmOrderDetail.reason === "DEFAULT_DEPOSITORY_NOT_COMPATIBLE"
        ) {
          setOpenSlide({
            open: true,
            div: "oneMoreThing",
          });
        } else if (confirmOrderDetail.failure === null) {
          if (confirmOrderDetail.contract.orderContractRequired === true) {
            let res = await preSign(
              user.token,
              confirmOrderDetail.orderId.orderRequestId
            );
            if (res.error != null) {
              toast.error(res.error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              if (res.data.length < 1) {
                let res = await genOtp(
                  user.token,
                  `${confirmOrderDetail.orderId.orderRequestId}${confirmOrderDetail.orderId.version}`
                );
                if (res.error != null) {
                  toast.error(res.error, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                } else {
                  setOpenSlide({
                    open: true,
                    div: "otp",
                  });
                }
              } else {
                history.push({
                  pathname: "/deal-details",
                  state: { company, share, confirmOrderDetail, data: res.data },
                });
              }
            }
          } else if (
            confirmOrderDetail.contract.orderContractRequired === false &&
            confirmOrderDetail.command === "UPDATE_ORDER"
          ) {
            let res = await genOtp(
              user.token,
              `${confirmOrderDetail.orderId.orderRequestId}${confirmOrderDetail.orderId.version}`
            );
            if (res.error != null) {
              toast.error(res.error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              setOpenSlide({
                open: true,
                div: "modifyOtp",
              });
            }
          } else {
            toast.error("OrderContractRequired === False", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else if (
          confirmOrderDetail?.failure === "STALENESS" &&
          confirmOrderDetail?.staleness?.remedy === "DISCARD" &&
          confirmOrderDetail.reason === "MARGIN_VALUE_STALE"
        ) {
          setOpenSlide({
            open: true,
            div: "marginError",
          });
        } else {
          alert("No Condition MET");
        }
      }
    }
  };

  const addBankSucc = () => {
    toast.success("Bank added Success", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setOpenSlide((preValue) => {
      return {
        ...preValue,
        open: true,
      };
    });
  };

  const gotToProfile = () => {
    localStorage.setItem("confirmOrderData", JSON.stringify(location.state));
    history.push({
      pathname: "/profile",
      search: `?redirectPath=confirm-order&orderId=${orderInfo.orderRequestId}&orderVersion=${orderInfo.version}`,
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const OneMoreThingComponent = () => {
    return (
      <>
        <div className="onemorething_container">
          <div className="box">
            <div className="heading">
              <p> Just need to fix one </p>
              <p style={{ color: "#002bff" }}> One more thing </p>
            </div>
            <div className="detail">{confirmOrderDetail.message}</div>
            <div></div>
          </div>
          <Button text={"Go to profile"} click={gotToProfile} />
        </div>
      </>
    );
  };

  const MarginError = () => (
    <div className="margin-error-container">
      <p>
        This order cannot be confirmed as margin values are not up-to-date.
        Please place a new order.
      </p>
      <Button text="Close" click={() => setOpenSlide(false)} />
    </div>
  );

  const FundsAddedStep = () => {
    const [vpaData, setVpaData] = useState(null);
    let processingData = {
      ammount: confirmOrderDetail.margin.shortfall,
      bank: addFundsDetail.bank,
    };

    useEffect(() => {
      const getData = async () => {
        if (processingData.bank != null && processingData.ammount != null) {
          let data = await getVpa(user.token, processingData.bank);
          if (data.error != null) {
            toast.error(data.error, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoadingBackDrop(false);
            setOpenSlide(false);
          } else {
            setVpaData(data.data);
          }
        }
      };

      getData();
    }, []);

    return (
      <>
        <div className="funds_added_slide">
          <div className="heading">
            <p> Add Funds </p>
            <div className="detail">
              Please remit funds to the below mentioned account
            </div>
          </div>

          {vpaData === null ? (
            <>
              <div className="progress">
                <Progress className="bar" />
              </div>
            </>
          ) : (
            <>
              <div className="transection_detail">
                <div className="one_div">
                  <div className="beneficary">
                    <p className="title"> Beneficiary </p>
                    <div> {vpaData.accountHolderName} </div>
                  </div>
                  <div className="ifsc">
                    <p className="title"> IFSC </p>
                    <div> {vpaData.bankAccountId.ifsc} </div>
                  </div>
                  <div className="account">
                    <p className="title"> Account </p>
                    <div> {vpaData.bankAccountId.bankAccountNumber} </div>
                  </div>
                </div>
                <div className="two_div" style={{ opacity: "-10" }}>
                  <div className="bank">
                    <p className="title"> Bank </p>
                    <div> Static American Express </div>
                  </div>
                  <div className="branch" style={{ opacity: "-10" }}>
                    <p className="title"> Branch </p>
                    <div> Horniman Circle </div>
                  </div>
                  <div className="important">
                    <p className="important_title">
                      <p> IMPORTANT </p>
                    </p>
                    <div className="important_data">
                      <p className="detail">
                        This account number is specifically for you, it will be
                        different for other users.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="note_box">
                Once you’ve remitted the funds, please wait until the funds
                reach our bank account. We will intimate youvia sms / email as
                soon as we receive the funds & the same shall be added to
                available funds in the Funds Tab
              </div>
              <div className="notis">
                <p className="important_title">
                  <p> IMPORTANT </p>
                </p>
                <div className="important_data">
                  <p className="detail">
                    Please note that the remittance to the above account should
                    only be made from the below mentioned account that you’ve
                    selected:
                  </p>
                  <div className="bank_container">
                    <div className="name">{processingData.bank.bankName}</div>
                    <div className="accnumber">
                      xxxx - xxxx - xxxx -
                      {processingData.bank.bankAccountId.bankAccountNumber.substr(
                        processingData.bank.bankAccountId.bankAccountNumber
                          .length - 4,
                        4
                      )}
                    </div>
                    <div className="address">
                      {processingData.bank.branchName}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const sendClientOrder = async () => {
    // setOtpLoading(true)
    let counter = 1;
    const interval = setInterval(() => {
      counter += 1;
      console.log(loadingMSGs[counter % loadingMSGs.length]);
      setLoadingText(loadingMSGs[counter % loadingMSGs.length]);
    }, 5000);
    setPageLoading(true);
    let res = await orderSign(
      user.token,
      confirmOrderDetail.orderId.orderRequestId,
      confirmOrderDetail.orderId.version,
      [],
      otpValue
    );
    if (res.error != null) {
      clearInterval(interval);
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPageLoading(null);
    } else {
      let data = await get_resources();
      if (data.error != null) {
        toast.error(data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        clearInterval(interval);
        setLoadingText("");
        setPageLoading(null);
      } else {
        setLoadingText("");
        setPageLoading(null);
        clearInterval(interval);

        setDigioData({
          id: res.data.requestId,
          customerIdentifier: res.data.identifier,
          token: res.data.accessTokenId,
          imageUrl: data.data.data.avatar,
        });
        setOpenSlide({
          open: true,
          div: "digio",
        });
      }
    }
  };

  const DigioGo = () => {
    const go = () => {
      setPageLoading(true);

      run_digio(digioData, onSucc, onError);
    };
    return (
      <>
        <div className="success_container">
          <img src={Check} alt="ERROR" />
          <div className="message">
            <div
              className="center"
              style={{ textAlign: "center", fontSize: ".6rem" }}
            >
              <p> OTP is valid. </p> <p> Please validate T&C with signature </p>
            </div>
          </div>
          <Button text="PROVIDE SIGNATURE" click={go}>
            Digio
          </Button>
        </div>
      </>
    );
  };

  const [processingData, setProcessingData] = useState({
    ammount: null,
    transferType: null,
    bank: null,
  });

  const nextStep = (data) => {
    setOpenSlide({
      open: true,
      div: data,
    });
  };

  return (
    <>
      {loadingBackDrop && (
        <div className="backdrop">
          <ScreenLoader />
        </div>
      )}
      {/* <MenuComponent /> */}
      {confirmOrderDetail === null ? (
        <div className="progress" style={{ minHeight: "100vh" }}>
          <Progress className="bar" />
        </div>
      ) : (
        <>
          <div
            className="pageLoading"
            style={{ display: pageLoading === null ? "none" : null }}
          >
            <Progress style={{ color: "#aaa" }} />
            <p>{loadingText}</p>
          </div>
          <div
            id="paymentDOM"
            style={{
              position: "absolute",
              width: "40%",
              zIndex: " ",
              display: "none",
              background: "#fff",
              borderRadius: "8px",
            }}
          >
            <div className="bottom__slide_close">
              <CloseIcon
                className="bsh__icon"
                onClick={() => {
                  history.go("/funds");
                }}
              />
            </div>
          </div>
          <div className="co__container">
            <div className="co__upper">
              <BuyerInfo
                data={company}
                path={by === "partner" ? "entry" : "confirmorder"}
                quote={share}
                detail={orderInfo}
              />

              <div className="co__bottom__header" onClick={goBack}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={backarrow}
                  alt="Error"
                />
                <p className="co__para">Back</p>
              </div>

              <p className="co__heading">
                Confirm <span>Order</span>
              </p>

              {confirmOrderDetail.command &&
              confirmOrderDetail.command === "UPDATE_ORDER" ? (
                <>
                  <p
                    className="co__upper__para1"
                    style={{
                      backgroundColor: extra && "rgba(241, 241, 241, .6)",
                      color: extra && "gray",
                    }}
                  >
                    Total of Rs.
                    <span style={{ fontWeight: "bolder", color: "black" }}>
                      {confirmOrderDetail.margin.orderMargin}
                    </span>
                    is required to keep the order active. Rs.
                    <span style={{ fontWeight: "bolder", color: "black" }}>
                      {confirmOrderDetail.margin.marginAlreadyPaid}
                    </span>
                    has already been paid for this order. Rs.
                    <span style={{ fontWeight: "bolder", color: "black" }}>
                      {confirmOrderDetail.margin.thisVersionMarginRequired}
                    </span>
                    is required right now as additional margin to complete this
                    order. These margins are 100% refundable if transaction is
                    not complete.
                    {extra && (
                      <>
                        <div className="extra_box">
                          <p>
                            Required Funds :
                            {confirmOrderDetail.margin.orderMargin}
                          </p>
                          {confirmOrderDetail.margin.previousPendingAmount !=
                            0 && (
                            <p>
                              Pending Obligation Amount :
                              {confirmOrderDetail.margin.previousPendingAmount}
                            </p>
                          )}
                          <p>
                            Available Funds :
                            {confirmOrderDetail.margin.freeFund != null
                              ? confirmOrderDetail.margin.freeFund
                              : "0"}
                          </p>
                          <p style={{ color: "red" }}>
                            Shortfall :
                            {confirmOrderDetail.margin.shortfall != null
                              ? confirmOrderDetail.margin.shortfall
                              : "0"}
                          </p>
                        </div>
                      </>
                    )}
                  </p>
                </>
              ) : (
                <>
                  <p
                    className="co__upper__para1"
                    style={{
                      backgroundColor: extra && "rgba(241, 241, 241, .6)",
                      color: extra && "gray",
                    }}
                  >
                    Rs.
                    <span style={{ fontWeight: "bolder", color: "black" }}>
                      {client === "unregister"
                        ? orderInfo.orderDetails.marginAmount
                        : confirmOrderDetail.margin.orderMargin}
                    </span>
                    is required as margins to complete this order. <br /> These
                    margins are 100% refundable if transaction is not complete.
                    {extra && (
                      <>
                        <div className="extra_box">
                          <p>
                            Required Funds :
                            {confirmOrderDetail.margin.orderMargin}
                          </p>
                          {confirmOrderDetail.margin.previousPendingAmount !=
                            0 && (
                            <p>
                              Pending Obligation Amount :
                              {confirmOrderDetail.margin.previousPendingAmount}
                            </p>
                          )}
                          <p>
                            Available Funds :
                            {confirmOrderDetail.margin.freeFund != null
                              ? confirmOrderDetail.margin.freeFund
                              : "0"}
                          </p>
                          <p style={{ color: "red" }}>
                            Shortfall :
                            {confirmOrderDetail.margin.shortfall != null
                              ? confirmOrderDetail.margin.shortfall
                              : "0"}
                          </p>
                        </div>
                      </>
                    )}
                  </p>
                </>
              )}

              {extra && orderInfo.action === "ASK" ? (
                <div className="extra_box2">
                  <p>
                    Minimum fillable <span> Quantity </span>
                  </p>
                  <p style={{ color: "gray", fontSize: "1rem" }}>
                    {orderInfo?.minimumFulfillmentQty}
                  </p>
                  <p> Minimum qty per transaction </p>
                </div>
              ) : null}

              <p className="co__watch" style={{ marginTop: "1rem" }}>
                Need clarity on the transaction process?
                <span>
                  <a
                    href="https://youtu.be/GQl3f-e_goI"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp; Watch Video
                  </a>
                </span>
              </p>

              <div className="co__checkbox__component">
                <CheckboxComponent />
              </div>
            </div>
            <div className="upper bot_btn">
              <Button
                text={
                  by === "partner"
                    ? "SEND LINK"
                    : orderInfo.orderStatus === "SIGNING"
                    ? "RETRY SIGNING"
                    : "CONFIRM"
                }
                click={confirmTheOrder}
              />
            </div>

            {openSlide.open && (
              <BottomSlide bottomSlide={() => setOpenSlide(false)}>
                {openSlide.div === "addfunds" ? (
                  <Reqfund
                    token={user.token}
                    data={confirmOrderDetail}
                    setOpenSlide={setOpenSlide}
                    setAddFundsDetails={setAddFundsDetails}
                  />
                ) : openSlide.div === "selectBank" ? (
                  <ChoseBank
                    setBankIndex={setBankIndex}
                    bankIndex={bankIndex}
                    type={addFundsDetail.type}
                    data={confirmOrderDetail}
                    setOpenSlide={setOpenSlide}
                    addFundsDetail={addFundsDetail}
                    setAddFundsDetails={setAddFundsDetails}
                    shortfall={confirmOrderDetail?.margin?.shortfall}
                    margin={confirmOrderDetail?.margin?.orderMargin}
                    setOpenAddBank={setOpenAddBank}
                  />
                ) : openSlide.div === "marginError" ? (
                  <MarginError />
                ) : openSlide.div === "enterupi" ? (
                  <EnterUPIID
                    bankIndex={bankIndex}
                    type={addFundsDetail.type}
                    setLoadingBackDrop={setLoadingBackDrop}
                    confirmOrderDetail={confirmOrderDetail}
                    selectedBankData={addFundsDetail}
                    closeBottom={() => {
                      setLoadingBackDrop(false);
                      setOpenSlide(false);
                    }}
                    next={nextStep}
                    user={user}
                    shortfall={confirmOrderDetail?.margin?.shortfall}
                    margin={confirmOrderDetail?.margin?.orderMargin}
                    data={setProcessingData}
                    net={processingData}
                  />
                ) : openSlide.div === "profile" ? (
                  <Userprofile next={gotToProfile} />
                ) : openSlide.div === "oneMoreThing" ? (
                  <OneMoreThingComponent />
                ) : openSlide.div === "succ" ? (
                  <Success setReload={setReload} setOpenSlide={setOpenSlide} />
                ) : openSlide.div === "otp" ? (
                  // <ConfirmOrderOtp orderId={orderInfo.orderRequestId} version={orderInfo.version} />
                  <ConfirmOrderOtp
                    heading="Order"
                    btnText="Continue"
                    otpValue={otpValue}
                    setOtpValue={setOtpValue}
                    otpLoader={otpLoading}
                    sendOrder={() => sendClientOrder()}
                    orderId={confirmOrderDetail.orderId.orderRequestId}
                    version={confirmOrderDetail.orderId.version}
                  />
                ) : openSlide.div === "modifyOtp" ? (
                  <>
                    <Cordermodi
                      heading="order modification"
                      btnText="MODIFY ORDER"
                      otpValue={otp}
                      setOtpValue={setOtp}
                      sendOrder={modifyOrder}
                      orderId={orderInfo.orderRequestId}
                      version={orderInfo.version}
                    />
                  </>
                ) : openSlide.div === "offlineNet" ? (
                  <>
                    <FundsAddedStep />
                  </>
                ) : openSlide.div === "digio" ? (
                  <>
                    <DigioGo />
                  </>
                ) : null}
              </BottomSlide>
            )}
            {openSlide.open && openSlide.div === "fail" && (
              <BottomSlide bottomSlide={() => close()}>
                {openSlide.div === "fail" ? (
                  <>
                    <FailOrder />
                  </>
                ) : null}
              </BottomSlide>
            )}
            {openAddBank && (
              <AddBank
                bank={openAddBank}
                setBank={setOpenAddBank}
                step={addBankstep}
                setStep={setAddBankStep}
                get_banks1={addBankSucc}
                profileData={user}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ConfirmOrder;
