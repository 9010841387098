import ls from "localstorage-slim";
import { get_resources } from "../api/dashboard";

/* returns 
  -1 (negative) in case partName is not valid
  +1(positive) in case partnerName is valid
  2(positive) in case partnerName is valid and we don't change localstorage
*/
export function processLocalStorageWrite(
  uid,
  isPersonalised,
  token,
  partnerName = undefined
) {
  console.log(
    "processLocalStorageWrite",
    uid,
    isPersonalised,
    token,
    partnerName
  );
  let getData = ls.get(uid);
  // removing null partner data from localstorage
  if (getData?.startTime !== null && getData?.partnerName == null) {
    console.log("removeing data from ls..");
    ls.remove(uid);
  }
  //data not found in local storage when partner name is defined
  if (getData == null && partnerName !== undefined) {
    console.log("im here 001");
    return verifyPartnerAndSetData(isPersonalised, partnerName, token, uid)
      ? 1
      : -1;
  } else {
    console.log("im here 002");
    //data found in local storage when partner name is defined
    if (
      getData?.partnerName !== partnerName &&
      getData?.partnerName !== undefined
    ) {
      console.log("im here 003");
      //data is old and needs to be updated
      return verifyPartnerAndSetData(isPersonalised, partnerName, token, uid)
        ? 1
        : -1;
    } else {
      return 2;
    }
  }
}

export function writePartnerNameToLS(
  uid,
  partnerName,
  currentTime = new Date(),
  expiryTime = 1296000
) {
  console.log("im here 006", uid, partnerName, currentTime, expiryTime);
  if (partnerName != null) {
    ls.set(
      uid,
      { partnerName: partnerName, startTime: currentTime },
      { ttl: expiryTime }
    );
  }
}

export function readPartnerNameFromLS(uid) {
  if (uid == null) {
    return null;
  }
  const partnerName = ls.get(uid);
  if (partnerName != null) {
    return partnerName?.partnerName;
  } else {
    return null;
  }
}

export function getDefaultUserId() {
  return 0;
}

async function verifyPartnerAndSetData(
  isPersonalised,
  partnerName,
  token,
  uid
) {
  let response = await get_resources(
    isPersonalised,
    partnerName !== null ? partnerName : false,
    token
  );
  if (response.error) {
    console.log(response.error);
    return false;
  } else {
    const partner = response?.data?.data?.partnerName;
    // don't allow partner to set their partner as someone else.
    if (partner === partnerName) {
      if (partner != null) {
        writePartnerNameToLS(uid, partner);
        return true;
      }
      return false;
    } else {
      return false;
    }
  }
}

// function ifConvertedCopyData(uid=0) {
//   const nonLoggedUserData = ls.get(0);
//   if(nonLoggedUserData !== undefined && uid !== 0){
//     ls.set(uid, nonLoggedUserData, { ttl: 20 })
//   }
// }
