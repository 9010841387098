import { useState } from "react";
import Button from "../../../../components/button/Button";
import "./ModifyInfo.css";

const ModifyInfo = () => {
  // ! why state?
  const [cancel, setCancel] = useState(false);
  return (
    <>
      <div className="mi__container">
        <div className="mi__uper">
          <div className="mi__upper__heading">
            Send order for{" "}
            <span>{cancel === true ? "modificaiton" : "cancellation"}</span>
          </div>
          <div className="mi__paras">
            <p className="mi__para1">
              We will send a link to your client, via email on email@domail.com
              and via sms on 999999999
            </p>
            <p style={{ margin: "17px 0" }} className="mi__para2">
              They will need to confirm the{" "}
              {cancel === true ? "modificaiton" : "cancellation"} by clicking
              the link sent to them.
            </p>
          </div>
        </div>

        <div className="mi__lower">
          {cancel === true ? (
            <Button text="SEND MODIFICATION REQUEST TO CLIENT" />
          ) : (
            <Button text="SEND CANCELLATION REQUEST TO CLIENT" />
          )}
        </div>
      </div>
    </>
  );
};

export default ModifyInfo;
