import React from 'react'
import { useHistory , useLocation } from "react-router-dom"

import backarrow from "../../../../Assets/imgs/backarrow.svg";

import "./BackIcon.css";


const BackIcon = () => {
    const history = useHistory();
    const location = useLocation();

    const goBack =()=>{
        history.goBack();
    }
    return (
        <>
            <div className="bi__bottom__header" onClick={goBack}>
                <img
                    style={{ width: "20px", height: "20px" }}
                    src={backarrow}
                    alt="Error"
                />
                <p className="bi__para">Back</p>
            </div>

        </>
    )
}

export default BackIcon
