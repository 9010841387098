import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Lock from "../../Assets/imgs/lock.svg";
import Button from "../../components/button/Button";

import { toast } from "react-toastify";

import {
  loginOtp,
  registerOtp,
  resendRegisterOtp,
  resendUnRegisterOtp,
} from "../../api/register";
import { profile_details } from "../../api/profile";
import { useDispatch, useSelector } from "react-redux";
import { set_profile, signup_user } from "../../store/User";
import ls from "localstorage-slim";

import "./SignOtp.scss";

const SignOtp = () => {
  const user = useSelector((state) => state.user);
  let history = useHistory();
  let location = useLocation();
  let dispatch = useDispatch();

  const redirectPath = new URLSearchParams(location.search).get("redirectPath");

  let email = location.state.email;
  let data = location.state.data;

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(null);

  const [time, settime] = useState(15);
  const [re, setRe] = useState(true);

  const countDown = () => {
    const run = () => {
      if (time <= 0) {
        clearInterval(id);
      } else {
        settime((preVal) => {
          if (preVal === 0) {
            clearInterval(id);
            return 0;
          } else {
            return preVal - 1;
          }
        });
      }
    };

    var id = setInterval(run, 1000);
  };

  useEffect(() => {
    countDown();
    console.log("thisisii..", user?.profile?.userType);
    if (user?.profile?.userType === "INDIVIDUAL_CLIENT") {
      const nonLoggedUserData = ls.get(0);
      const uid = user?.profile === null ? 0 : user.profile.userId;
      console.log("nonLoggedUserData", nonLoggedUserData);
      if (nonLoggedUserData != null && uid !== 0) {
        console.log("setting data from non-logedin user");
        ls.set(uid, nonLoggedUserData, { ttl: 86400 });
        ls.remove(0);
      }
    }
  }, [user.profile]);

  const login = async () => {
    setLoading(true);
    if (data.newUser === false) {
      let res = await loginOtp(email, otp);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      } else {
        dispatch(signup_user(res.data));
        localStorage.setItem("token", res.data);
        const details = await profile_details(res.data);
        dispatch(set_profile(details.data.data));
        localStorage.setItem("profile", JSON.stringify(details.data.data));
        if (redirectPath) {
          history.push({ pathname: redirectPath, search: "?loggedIn=true" });
        } else {
          history.push("/dashboard");
        }
      }
    } else {
      let res = await registerOtp(data.token, otp);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      } else {
        dispatch(signup_user(data.token));
        localStorage.setItem("token", data.token);
        const details = await profile_details(data.token);
        dispatch(set_profile(details.data.data));
        localStorage.setItem("profile", JSON.stringify(details.data.data));
        if (redirectPath) {
          history.push({ pathname: redirectPath, search: "?loggedIn=true" });
        } else {
          history.push("/dashboard");
        }
      }
    }
  };
  const reCall = async () => {
    settime(15);
    setRe((preValue) => {
      return preValue ? false : true;
    });
    if (data.newUser === false) {
      let res = await resendRegisterOtp(email);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("OTP Send success !", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      let res = await resendUnRegisterOtp(data.token, email);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("OTP Send success !", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      <div className="signotp_container">
        <div className="box">
          <div className="data">
            <div className="lock">
              <img src={Lock} alt="ERROR" />
              <div className="title">
                Enter <span> OTP </span>
              </div>
            </div>
            <div className="input">
              <p> Enter OTP sent to you via email on your ID {email} </p>
              <div className="otp-input">
                <input
                  type="number"
                  placeholder="Enter your OTP ( 6 digits )"
                  value={otp}
                  autoFocus
                  onChange={(e) => setOtp(e.target.value.slice(0, 6))}
                  disabled={loading}
                />
                <button
                  className="paste-btn"
                  onClick={login}
                  disabled={otp.length < 6 || loading}
                >
                  <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                </button>
              </div>
            </div>
            <div className="otpdetail">
              <p> Didn't receive OTP ? </p>
              <p>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={time <= 0 ? reCall : null}
                >
                  Resend OTP {time > 0 && <span> (After {time}s) </span>}
                </span>
                <span style={{ color: "black" }}>
                  |
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => history.goBack()}
                  >
                    Change Email ID
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div className="btn">
            <Button
              loading={loading}
              click={login}
              text="DONE"
              disabled={otp.length < 6}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignOtp;
