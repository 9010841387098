import { useState, useEffect } from "react";
import "./Gstart.css";
import { useHistory } from "react-router-dom";
import Alert from "../../../components/Alert/Alert";
import VideoPlayer from "../../dashboard/components/media-box/MediaBox";

const Gstart = () => {
  const [time, settime] = useState(30);
  const history = useHistory();
  useEffect(() => {
    setInterval(
      () =>
        settime((time) => {
          if (time > 0) {
            return time - 1;
          } else {
            return 0;
          }
        }),
      1000
    );
  }, []);
  console.log(time);
  const next_page = () => {
    history.push("/customweb");
  };

  return (
    <>
      <Alert text="Your mobile was verified successfully" />
      <div className="gstart__container">
        <div className="gstart__header">
          <div className="gstart__heading">
            Let's get <span>Started</span>
          </div>
          <p className="header__para">
            Watch this short video to get upto pace
          </p>
          <div className="getstart__media">
            <VideoPlayer
              video={"https://www.youtube.com/watch?v=q8xWDuDlyyM"}
              autoplay
            />
          </div>
          <div className="getstart__next">
            {time <= 0 ? (
              <span onClick={next_page}>Next</span>
            ) : (
              `Next(${time}s)`
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gstart;
