import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { whoami } from '../../../../api/auth';
import { BASE_URL } from '../../../../axiosInstance';
import "./Weblink.css"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
const Weblink = () => {
    const profile = JSON.parse(localStorage.getItem("user"));
    const location = useLocation();

    return (
        <>
            <div className="Weblink__component">
                <div className="Weblink__left"></div>
                <div className="Weblink__right"><Link className="Weblink__component__link" to={`#`}>{`${window.origin}/dashboard?partnerName=${profile.username}`}</Link></div>
            </div>

        </>
    )
}

export default Weblink
