import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Radio } from "@material-ui/core";

import Button from "../../../components/button/Button";
import Slide from "../../dashboard/components/slide/Slide";
import BackIcon from "../../OrderModification/Components/BackIcon/BackIcon";
import BuyerInfo from "../../OrderModification/Components/BuyerInfo/BuyerInfo";
import BottomSlide from "../../../components/bottom-slide/BottomSlide";
import Registration from "../../PartnerInitiated/PI-Registration/PI_Registration";

import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { CreateOrder, getCompany, defaultMargin } from "../../../api/order";

import "./PlaceOrder.scss";
import ScreenLoader from "../../../components/screen-loader/ScreenLoader";

const PlaceOrder = () => {
  const history = useHistory();
  const location = useLocation();
  let user = useSelector((state) => state.user);

  let companyId = new URLSearchParams(location.search).get("symbol");
  let quoteDataString = new URLSearchParams(location.search).get("quotedata");
  let type = new URLSearchParams(location.search).get("type");
  let minTrade = new URLSearchParams(location.search).get("mintrade");

  let quoteData = JSON.parse(quoteDataString);
  const [company, setCompany] = useState(null);

  const [margin, setMargin] = useState({
    defaultSpread: null,
    floorQuotedSpread: null,
  });
  const [netLimit, setNetLimit] = useState(null);
  const [check, setCheck] = useState(0);
  const [loadingBackDrop, setLoadingBackDrop] = useState(false);

  const [openSlide, setOpenSlide] = useState({
    open: false,
    div: null,
  });

  useEffect(() => {
    const getData = async () => {
      let res = await getCompany(user.token, companyId, true);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setCompany(res.data);
      }
    };

    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      let res = await defaultMargin(user);
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setMargin({
          defaultSpread: res.data.defaultSpread,
          floorQuotedSpread: res.data.floorQuotedSpread,
        });
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (check === "1") {
      setNetLimit(quoteData.price);
      if (type === "ASK") {
        if (!margin || !margin.floorQuotedSpread) {
          setNetLimit(parseFloat(quoteData.price));
        } else {
          let n =
            parseFloat(quoteData.price) +
            (parseFloat(quoteData.price) *
              parseFloat(margin.floorQuotedSpread)) /
              100;
          setNetLimit(n.toFixed(3));
        }
      } else {
        if (!margin || !margin.floorQuotedSpread) {
          setNetLimit(parseFloat(quoteData.price));
        } else {
          let n =
            parseFloat(quoteData.price) -
            (parseFloat(quoteData.price) *
              parseFloat(margin.floorQuotedSpread)) /
              100;
          setNetLimit(n.toFixed(3));
        }
      }
    } else {
      if (type === "ASK") {
        if (!margin || !margin.defaultSpread) {
          setNetLimit(parseFloat(quoteData.price));
        } else {
          let n =
            parseFloat(quoteData.price) +
            (parseFloat(quoteData.price) * parseFloat(margin.defaultSpread)) /
              100;
          setNetLimit(n.toFixed(3));
        }
      } else {
        if (!margin || !margin.defaultSpread) {
          setNetLimit(parseFloat(quoteData.price));
        } else {
          let n =
            parseFloat(quoteData.price) -
            (parseFloat(quoteData.price) * parseFloat(margin.defaultSpread)) /
              100;
          setNetLimit(n.toFixed(3));
        }
      }
    }
  }, [margin, check]);

  const enteringMargin = (event) => {
    setMargin((preValue) => {
      return {
        ...preValue,
        defaultSpread: event.target.value,
      };
    });
  };
  const enteringCheck = (event) => {
    setCheck(event.target.id);
  };

  useEffect(() => {
    if (openSlide.open) {
      setLoadingBackDrop(false);
    }
  }, [openSlide.open]);

  const place = async () => {
    setLoadingBackDrop(true);
    if (check === "0") {
      if (margin > 50 || margin < margin.floorQuotedSpread) {
        toast.error("Invalid commision", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setOpenSlide({
          open: true,
          div: "client",
        });
      }
    } else {
      let res = await CreateOrder(
        user.token,
        user.profile.userName,
        company,
        quoteData,
        quoteData,
        minTrade,
        type,
        user.profile.userName,
        null,
        margin.floorQuotedSpread,
        "selfPartner"
      );
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        history.push({
          pathname: "/confirm-order",
          search: `?orderId=${res.data.orderRequestId}&orderVersion=${res.data.version}`,
        });
      }
    }
  };
  return (
    <>
      {loadingBackDrop && (
        <div className="backdrop">
          <ScreenLoader />
        </div>
      )}
      {/* <MenuComponent/> */}
      {company === null ? (
        <></>
      ) : (
        <div className="po__container">
          <div className="po__upper">
            <div className="po__userProfile__component">
              <BuyerInfo path="entry" data={company} />
            </div>
            <div className="po__slide__component">
              <Slide
                items={[
                  "funding",
                  "resources",
                  "invest/divest",
                  "modify",
                  "order",
                  "create",
                ]}
              />
            </div>

            <div className="po__backIcon__component">
              <BackIcon />
            </div>
            <div className="po__heading">
              Place <span>Order</span>
            </div>
            <div className="po__paras">
              {type === "ASK" ? "Sell" : "Buy"} Qty :{" "}
              <span>{quoteData.shares} Shares</span>
            </div>
            <div className="po__paras">
              Price for Client(incl. commission):{" "}
              <span>Rs. {quoteData.price}</span>
            </div>
            <div className="po__paras">
              Your commision in % :{" "}
              <span className="per">
                {check === "0" ? (
                  <>
                    <input
                      value={margin?.defaultSpread}
                      onChange={enteringMargin}
                      type="number"
                    />{" "}
                    %
                  </>
                ) : (
                  margin?.floorQuotedSpread
                )}
              </span>
              {check === "0" && margin.defaultSpread > 50 ? (
                <>
                  <span className="pererr" style={{ color: "red" }}>
                    {" "}
                    Quoted Spread Cannot be greater than 50%{" "}
                  </span>
                </>
              ) : check === "0" &&
                margin.defaultSpread < margin.floorQuotedSpread ? (
                <>
                  <span className="pererr" style={{ color: "red" }}>
                    {" "}
                    Spread should be greater than floor spread{" "}
                    {margin.floorQuotedSpread}{" "}
                  </span>
                </>
              ) : null}
            </div>
            <div className="po__paras">
              Net Limit Price (Rs.) : <span>Rs. {netLimit}</span>
            </div>
            {type === "ASK" && (
              <>
                <div className="po__paras">
                  Minimum trade quantity: <span>{minTrade}</span>
                </div>
              </>
            )}
            <div className="modo__paras">
              Your commission will not be revealed to the client
            </div>
            <div className="po__checkbox__component">
              <Radio
                checked={check === "0" ? true : false}
                id="0"
                onChange={enteringCheck}
                style={{ color: "green" }}
              />
              <div className="po__checkbox__para">
                <p> Transact in my client's account </p>
                Funds/Securities shall be debited/credited to your client's
                bank/demat account
              </div>
            </div>
            <div className="po__checkbox__component">
              <Radio
                checked={check === "1" ? true : false}
                id="1"
                onChange={enteringCheck}
                style={{ color: "green" }}
              />
              <div className="po__checkbox__para">
                <p> Transact in my account </p>
                Funds/Securities shall be debited/credited to your bank/demat
                account
              </div>
            </div>

            <div className="po__footer">
              <p className="po__footer__para1">
                Need more clarity on the transaction process?{" "}
                <span>
                  <a
                    href="https://youtu.be/GQl3f-e_goI"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Watch video.
                  </a>
                </span>
              </p>
              <p className="po__footer__para2">
                Confirm to transact on behalf of your client
              </p>
            </div>
          </div>
          <div className="po__lower">
            <Button
              text="NEXT"
              click={place}
              disabled={
                margin.defaultSpread < margin.floorQuotedSpread ||
                (check === "0" && margin.defaultSpread > 50)
              }
            />
          </div>

          {openSlide.open && (
            <BottomSlide bottomSlide={() => setOpenSlide(false)}>
              {openSlide.div === "client" ? (
                <>
                  <Registration
                    company={company}
                    quoteData={quoteData}
                    type={type}
                    minTrade={minTrade}
                    margin={margin.defaultSpread}
                  />
                </>
              ) : null}
            </BottomSlide>
          )}
        </div>
      )}
    </>
  );
};

export default PlaceOrder;
