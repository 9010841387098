import axios, { BASE_URL } from "../axiosInstance";
import axiosCacheInstance from "../axiosCacheInteceptorInstance";

export const dashboard_data = async (
  personalised,
  partnerName,
  token,
  pageNo
) => {
  const resolved = {
    data: null,
    error: null,
  };
  console.log("personalised:", personalised);

  try {
    resolved.data = await axios({
      url: `/api/v1/companies/dashboard?personalised=${
        personalised ? personalised : "false"
      }${`&pageNo=${pageNo - 1}`}${
        partnerName ? `&partnerName=${partnerName}` : ""
      }`,
      method: "get",
      headers: personalised
        ? {
            authorization: "Bearer " + token,
          }
        : null,
    });
  } catch (error) {
    if (error.response) {
      console.log(error.response);
      resolved.error = error.response.data.message;
    } else {
      resolved.error = "Something went wrong";
    }
  }
  return resolved;
};
export const dashboard_filtered_data = async (
  listingType,
  personalised,
  partnerName,
  token,
  pageNo
) => {
  const resolved = {
    data: null,
    error: null,
  };

  try {
    resolved.data = await axios({
      url: `/api/v1/companies/dashboard?personalised=${
        personalised ? `${personalised}` : "false"
      }${`&pageNo=${pageNo - 1}`}&listingType=${listingType}${
        partnerName ? `&partnerName=${partnerName}` : ""
      }`,
      method: "get",
      headers: personalised
        ? {
            authorization: "Bearer " + token,
          }
        : null,
    });
  } catch (error) {
    if (error.response) {
      resolved.error = error.response.data.message;
    } else {
      resolved.error = "Something went wrong";
    }
  }
  return resolved;
};

export const dashboard_company_data = async (
  company,
  personalised,
  partnerName,
  token
) => {
  const resolved = {
    data: null,
    error: null,
  };

  try {
    resolved.data = await axios({
      url: `/api/v1/companies/${company.toLowerCase()}${`?personalised=${
        personalised ? personalised : "false"
      }`}${partnerName ? `&partnerName=${partnerName}` : ""}`,
      method: "get",
    });
  } catch (error) {
    if (error.response) {
      resolved.error = error.response.data.message;
    } else {
      resolved.error = "Something went wrong";
    }
  }
  return resolved;
};

export const get_resources = async (personalised, partnerName, token) => {
  const resolved = {
    data: null,
    error: null,
  };
  try {
    resolved.data = await axiosCacheInstance({
      url: `/api/v1/users/resources?personalised=${
        personalised ? personalised : "false"
      }${partnerName ? `&partnerName=${partnerName}` : ""}`,
      method: "get",
      headers: personalised
        ? {
            authorization: "Bearer " + token,
          }
        : null,
    });
  } catch (error) {
    if (error.response) {
      resolved.error = error.response.data.message;
    } else {
      resolved.error = "Something went wrong";
    }
  }
  return resolved;
};

export const bookmark = async (symbol, token) => {
  const resolved = {
    data: null,
    error: null,
  };
  try {
    resolved.data = await axios({
      url: `/api/v1/companies/bookmark?symbol=${symbol.toLowerCase()}`,
      method: "post",
      headers: {
        authorization: "Bearer " + token,
      },
    });
  } catch (error) {
    if (error.response) {
      resolved.error = error.response.data.message;
    } else {
      resolved.error = "Something went wrong";
    }
  }
  return resolved;
};
export const delete_bookmark = async (symbol, token) => {
  const resolved = {
    data: null,
    error: null,
  };
  try {
    resolved.data = await axios({
      url: `/api/v1/companies/bookmark?symbol=${symbol.toLowerCase()}`,
      method: "delete",
      headers: {
        authorization: "Bearer " + token,
      },
    });
  } catch (error) {
    if (error.response) {
      resolved.error = error.response.data.message;
    } else {
      resolved.error = "Something went wrong";
    }
  }
  return resolved;
};
export const dashboard_search = async (text, token) => {
  const resolved = {
    data: null,
    error: null,
  };
  try {
    resolved.data = await axios({
      url: `/api/v1/companies/search?text=${text}`,
      method: "get",
    });
  } catch (error) {
    if (error.response) {
      resolved.error = error.response.data.message;
    } else {
      resolved.error = "Something went wrong";
    }
  }
  return resolved;
};
export const whatsapp_lead = async (token, countryCode, number) => {
  const resolved = {
    data: null,
    error: null,
  };
  try {
    resolved.data = await axios({
      url: `/api/v1/users/whatsapp?countryCode=${countryCode}&mobile=${number}`,
      method: "post",
      headers: {
        authorization: "Bearer " + token,
      },
    });
  } catch (error) {
    if (error.response) {
      resolved.error = error.response.data.message;
    } else {
      resolved.error = "Something went wrong";
    }
  }
  return resolved;
};
