import React from "react";
import "./ScreenLoader.css";
import CircularProgress from "@material-ui/core/CircularProgress";

const ScreenLoader = ({ text }) => {
  return (
    <div className="screen__loader">
      {text ? (
        <p>{text}</p>
      ) : (
        <CircularProgress className="screen__loader_bar" />
      )}
    </div>
  );
};

export default ScreenLoader;
