import { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Progress from "@material-ui/core/CircularProgress";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@material-ui/core";

import ConfirmOrderModiOtp from "../../../OrderModification/CnfrmOrderModi/Cordermodi";
import Button from "../../../../components/button/Button";
import BottomSlide from "../../../../components/bottom-slide/BottomSlide";
import Check from "../../../../Assets/imgs/check.svg";

import MenuComponent from "../../../../components/client-partner-menu/ClientPartnerMenu";

import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { genOtp, orderSign, orderSignAccept } from "../../../../api/order";
import { get_resources } from "../../../../api/dashboard";
import run_digio from "./DigioIntegration";

import "./DealDetails.scss";

const DealsDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  const share = location.state ? location.state.share : null;
  const company = location.state ? location.state.company : null;
  const confirmOrderDetail = location.state
    ? location.state.confirmOrderDetail
    : null;
  const [questions, setQuestions] = useState(
    location.state ? location.state.data : null
  );

  const [textValue, setTextValue] = useState({});
  const [selectedRadioValue, setSelectedRadioValue] = useState({});
  const [checkBoxValue, setCheckBoxValue] = useState({});
  const [fields, setFields] = useState([]);

  const [openSlide, setOpenSlide] = useState({
    open: false,
    div: null,
  });
  const [otpValue, setOtpValue] = useState();
  const [digioData, setDigioData] = useState(null);

  const [pageLoading, setPageLoading] = useState(null);
  // !  what's the use of it?
  const [otpLoading, setOtpLoading] = useState(null);
  const [resError, setResError] = useState(null);

  const enteringText = (event, id) => {
    setTextValue((preValue) => {
      return {
        ...preValue,
        [id]: event.target.value,
      };
    });
  };
  const selectRadio = (event, id) => {
    setSelectedRadioValue((preValue) => {
      return {
        ...preValue,
        [id]: event.target.value,
      };
    });
  };
  const selectCheckBox = (event, id) => {
    setCheckBoxValue((preValue) => {
      return {
        ...preValue,
        [id]: event.target.name,
      };
    });
  };

  const open = async () => {
    let allFields = { ...textValue, ...selectedRadioValue, ...checkBoxValue };
    Object.keys(allFields).map((key, index) =>
      setFields((preValue) => {
        return [
          ...preValue,
          {
            id: key,
            value: allFields[key],
          },
        ];
      })
    );

    let res = await genOtp(
      user.token,
      `${confirmOrderDetail.orderId.orderRequestId}${confirmOrderDetail.orderId.version}`
    );
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setOpenSlide({
        open: true,
        div: "otp",
      });
    }
  };

  const onSucc = async (digioRes) => {
    let res = await orderSignAccept(user.token, digioRes, "SUCCESS");
    console.log("RES FROM ESIGN", res);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPageLoading(null);
      setOpenSlide({
        open: true,
        div: "fail",
      });
      setResError(res.error);
    } else {
      history.push({
        pathname: "/what-now",
        state: {
          share: share,
          company: company,
          confirmOrderDetail: confirmOrderDetail,
          status: "Order Confirmed",
          data: res.data,
        },
      });
    }
  };

  const onError = () => {
    history.push({
      pathname: "/confirm-order",
      search: `?orderId=${confirmOrderDetail.orderId.orderRequestId}&orderVersion=${confirmOrderDetail.orderId.version}`,
    });
  };

  const sendClientOrder = async () => {
    // setOtpLoading(true)
    setPageLoading(true);
    if (confirmOrderDetail.contract.signatureType === "AADHAAR") {
      let res = await orderSign(
        user.token,
        confirmOrderDetail.orderId.orderRequestId,
        confirmOrderDetail.orderId.version,
        fields,
        otpValue
      );
      if (res.error != null) {
        toast.error(res.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPageLoading(null);
      } else {
        let data = await get_resources();
        if (data.error != null) {
          toast.error(data.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPageLoading(null);
          setDigioData({
            id: res.data.requestId,
            customerIdentifier: res.data.identifier,
            token: res.data.accessTokenId,
            imageUrl: data.data.data.avatar,
          });
          setOpenSlide({
            open: true,
            div: "digio",
          });
        }
      }
    }
  };

  const close = () => {
    // history.push(`/dashboard/ordder-entry?symbol=${company.symbol}`)
    history.push(
      `/order/${confirmOrderDetail.orderId.orderRequestId}?v=${confirmOrderDetail.orderId?.version}`
    );
  };
  const FailOrder = () => {
    return (
      <>
        <MenuComponent />
        <div className="fail_container">
          <div className="fail_heading">
            Your <span> Order </span> <br />
            Could Not be <span> Confirmed </span>
          </div>
          <div className="fail_detail">
            <div className="fail_orderid">
              <span>Order ID : </span>{" "}
              {confirmOrderDetail.orderId?.orderRequestId}
            </div>
            <div className="fail_orderversion">
              <span>Order Version : </span>{" "}
              {confirmOrderDetail.orderId?.version}
            </div>
            <div className="fail_reason">
              <span>Reason : </span> {resError && resError}
            </div>
          </div>

          <div className="fail_disc">
            Do not worry <br />
            Any blocked amount would be reverted to your free funds within a few
            hours. <br />
            In case there is still some problem , <br />
            Reach out us at support@fundsmap.com
          </div>
          <Button text="CLOSE" click={close} />
        </div>
      </>
    );
  };

  const DigioGo = () => {
    const go = () => {
      setPageLoading(true);

      run_digio(digioData, onSucc, onError);
    };
    return (
      <>
        <div className="success_container">
          <img src={Check} alt="ERROR" />
          <div className="message">
            <div
              className="center"
              style={{ textAlign: "center", fontSize: ".6rem" }}
            >
              <p> OTP is valid. </p> <p> Please validate T&C with signature </p>
            </div>
          </div>
          <Button text="PROVIDE SIGNATURE" click={go}>
            Digio
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="pageLoading"
        style={{ display: pageLoading === null ? "none" : null }}
      >
        <Progress style={{ color: "#aaa" }} />
      </div>

      <div className="dd__container">
        <div className="dd__upper">
          <div className="dd__heading">
            Deal <span>details</span>
          </div>
          <p className="dd__heading__para">
            We require some information from you to confirm your intent to
            transact.
          </p>

          <div className="dd__body">
            <div>
              {questions.map((qus, index) => {
                return qus.type === "TEXT" ? (
                  <>
                    <div className="dd__textfield1">
                      <p className="dd__tf__hading">{qus.question}</p>
                      <TextField
                        id="outlined-basic"
                        name={qus.id}
                        value={textValue[qus.id]}
                        placeholder="Text field"
                        variant="standard"
                        onChange={(e) => enteringText(e, qus.id)}
                      />
                    </div>
                  </>
                ) : null;
              })}
            </div>
            <div>
              {questions.map((qus, index) => {
                return qus.type === "RADIO" ? (
                  <>
                    <div className="dd__textfield1">
                      <p className="dd__tf__hading"> {qus.question} </p>
                      <RadioGroup
                        value={selectedRadioValue[qus.id]}
                        onChange={(e) => selectRadio(e, qus.id)}
                      >
                        {qus.options.map((opt, index) => {
                          return (
                            <>
                              <FormControlLabel
                                value={opt}
                                control={<Radio />}
                                label={opt}
                              />
                            </>
                          );
                        })}
                      </RadioGroup>
                    </div>
                  </>
                ) : null;
              })}
            </div>
            <div>
              {questions.map((qus, index) => {
                return qus.type === "CHECK_BOX" ? (
                  <>
                    <div className="dd__textfield1">
                      <p
                        style={{ marginBottom: "3px" }}
                        className="dd__tf__hading"
                      >
                        {qus.question}
                      </p>
                      <div
                        className="dd__checkbox__component"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {qus.options.map((opt, index) => {
                          return (
                            <FormControlLabel
                              style={{ border: "none" }}
                              control={
                                <Checkbox
                                  color={"primary"}
                                  checked={
                                    checkBoxValue[qus.id] === opt ? true : false
                                  }
                                  name={opt}
                                  onChange={(e) => selectCheckBox(e, qus.id)}
                                />
                              }
                              label={opt}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </>
                ) : null;
              })}
            </div>
          </div>
        </div>
        <div className="dd__lower">
          <Button text="DONE" click={open} />
        </div>

        {openSlide.open && (
          <BottomSlide bottomSlide={() => setOpenSlide(false)}>
            {openSlide.div === "otp" ? (
              <>
                <ConfirmOrderModiOtp
                  heading="Order"
                  btnText="Continue"
                  otpValue={otpValue}
                  setOtpValue={setOtpValue}
                  otpLoader={otpLoading}
                  sendOrder={() => sendClientOrder()}
                  orderId={confirmOrderDetail.orderId.orderRequestId}
                  version={confirmOrderDetail.orderId.version}
                />
                {/* <ConfirmOtp /> */}
              </>
            ) : openSlide.div === "digio" ? (
              <>
                <DigioGo />
              </>
            ) : null}
          </BottomSlide>
        )}
        {openSlide.open && openSlide.div === "fail" && (
          <BottomSlide bottomSlide={() => close()}>
            {openSlide.div === "fail" ? (
              <>
                <FailOrder />
              </>
            ) : null}
          </BottomSlide>
        )}
      </div>
    </>
  );
};

export default DealsDetails;
