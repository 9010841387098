import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Progress from "@material-ui/core/CircularProgress";

import BuyerInfo from "../Components/BuyerInfo/BuyerInfo";
import BottomSlide from "../../../components/bottom-slide/BottomSlide";
import Cordermodi from "../CnfrmOrderModi/Cordermodi";
import SendLink from "../sendLink/SendLink";

import {
  getConfirmedOrder,
  getCompany,
  whoIam,
  notify,
} from "../../../api/order";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import "./OrderCancellation.scss";
import { cancelOrder, opentheSlide } from "../../../constants";
import Confirm from "../../../constants/Confirm";

const OrderCancellation = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  let orderId = new URLSearchParams(location.search).get("orderId");

  const [orderData, setOrderData] = useState(null);
  const [expireTime, setExpireTime] = useState(null);
  const [company, setCompany] = useState(null);
  const [shareDetail, setShareDetail] = useState(null);

  const [otp, setOtp] = useState(null);
  const [partnerName, setPartnerName] = useState(null);

  const [otpLoader, setOtpLoader] = useState(false);
  const [openslide, setOpenslide] = useState({
    open: false,
    div: null,
  });
  useEffect(() => {
    gettingConfirmOrder();
    gettingWhoIam();
  }, []);

  useEffect(() => {
    if (orderData != null) {
      gettingCompany();
    }
  }, [orderData]);

  const gettingConfirmOrder = async () => {
    let res = await getConfirmedOrder(user.token, orderId);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setOrderData(res.data);
      var time = new Date(res.data.expiryTimestamp * 1000);
      setExpireTime(time);
    }
  };
  const gettingCompany = async () => {
    let res = await getCompany(user.token, orderData.symbol);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setCompany(res.data);
    }
  };

  const gettingWhoIam = async () => {
    let res = await whoIam(user.token);
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setPartnerName(
        res.data.userType !== "INDIVIDUAL_CLIENT" ? res.data.username : null
      );
    }
  };

  const goToShareLink = async () => {
    let res = await notify(
      user.token,
      orderData.orderRequestId,
      orderData.version,
      "cancel"
    );
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      history.push({
        pathname: "/call-client",
        state: {
          company,
          orderId: orderData.orderRequestId,
          user: "cancel",
          orderInfo: orderData,
          expireTime,
        },
      });
    }
  };

  return (
    <>
      {!orderData || !company ? (
        <>
          <div className="progress">
            <Progress className="bar" />
          </div>
        </>
      ) : (
        <>
          <div className="ordermod__container">
            <div className="ordermod__heading">
              Order <span>Cancellation</span>
            </div>
            <BuyerInfo
              data={company}
              detail={orderData}
              path="order-modification"
              time={expireTime}
            />
          </div>

          <div className="ordermod__btns">
            <button
              onClick={() => opentheSlide("confirm", setOpenslide)}
              className="order_cancellation_btn"
            >
              CANCEL ORDER
            </button>
          </div>
          {openslide.open && (
            <BottomSlide
              bottomSlide={() => {
                setOpenslide(false);
              }}
            >
              {openslide.div === "confirm" ? (
                <>
                  <Confirm
                    orderData={orderData}
                    user={user}
                    partnerName={partnerName}
                    setShareDetail={setShareDetail}
                    setOpenslide={setOpenslide}
                    setOtpLoader={setOtpLoader}
                  />
                </>
              ) : openslide.div === "otp" ? (
                <>
                  <Cordermodi
                    heading="order cancellation"
                    btnText="CANCEL ORDER"
                    otpValue={otp}
                    setOtpValue={setOtp}
                    otpLoader={otpLoader}
                    sendOrder={() =>
                      cancelOrder({
                        setOtpLoader,
                        user,
                        orderData,
                        otp,
                        history,
                        company,
                      })
                    }
                    orderId={orderData.orderRequestId}
                    version={orderData.version}
                  />
                </>
              ) : openslide.div === "sendlink" ? (
                <>
                  <SendLink data={shareDetail} action={goToShareLink} />
                </>
              ) : null}
            </BottomSlide>
          )}
        </>
      )}
    </>
  );
};

export default OrderCancellation;
