import { useState } from "react";
import { delete_bank } from "../../../api/profile";
import "./AddedBank.css";

import { toast } from "react-toastify";
import ConfirmBox from "../../../components/confirm-box/ConfirmBox";

const AddedBank = ({ banks, getBanks }) => {
  const [dialog, setDialog] = useState(false);

  const [deleting, setDeleting] = useState(false);
  const [delIndex, setDelIndex] = useState();

  const handle_delete = (index) => {
    setDialog(true);
    setDelIndex(index);
  };

  const deleteBank = async (val) => {
    setDeleting(true);
    const delete_res = await delete_bank(
      val.bankAccountId.bankAccountNumber,
      val.bankAccountId.ifsc
    );
    if (delete_res.error) {
      toast.error(delete_res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDeleting(false);
    } else {
      getBanks();
      setDeleting(false);
    }
  };
  return (
    <>
      {banks?.map((val, index) => (
        <>
          {dialog && (
            <ConfirmBox
              text="Are you sure you wish to un-link this account?"
              action={() => deleteBank(banks[delIndex])}
              closeFunc={() => setDialog(false)}
            />
          )}

          <div className="addedbank__container">
            <div className="addedbank__header">
              <div className="addedbank__left">{index + 1}</div>
              <div className="addedbank__right">
                <div className="user__info">
                  <div className="user__w-name">
                    <p className="ab__w-name">Name</p>
                    {deleting && index == delIndex ? (
                      <p className="ad__delbtn_disabled">Deleting...</p>
                    ) : (
                      <p
                        className="ad__delbtn"
                        onClick={() => handle_delete(index)}
                      >
                        Delete
                      </p>
                    )}
                  </div>
                  <div className="ab__name">{val.accountHolderName}</div>
                  <div className="user__w-name">
                    <p className="ab__w-name">Bank</p>
                  </div>
                  <div className="ab__name">{val.bankName}</div>
                  <div className="user__w-name">
                    <p className="ab__w-name">Branch</p>
                  </div>
                  <div className="ab__name">{val.branchName}</div>
                  <div className="user__w-name">
                    <p className="ab__w-name">Account Number</p>
                  </div>
                  <div className="ab__name">
                    XXXX - XXXX - XXXX -{" "}
                    {val.bankAccountId.bankAccountNumber.substr(
                      val.bankAccountId?.bankAccountNumber?.length - 4,
                      4
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default AddedBank;
