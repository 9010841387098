import { toast } from "react-toastify";
import { cashFreeAccept } from "../../api/FundsAPI";

async function successTrigger(data, token, history) {
  console.log(data);
  if (data.order && data.order.status === "PAID") {
    let cashfreeA = await cashFreeAccept(token, data);
    if (cashfreeA.error != null) {
      toast.error(data.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        history.go("/funds");
      }, 2000);
    } else {
      toast.success("Funds addition successful", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        history.go("/funds");
      }, 2000);
    }
    //order is paid
    //verify order status by making an API call using your server to cashfree's server
    // using data.order.order_id
  }
}

function paymentFailureTrigger(data, history) {
  console.log(data);

  toast.error(data?.order?.errorText || "Funds not added", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  setTimeout(() => {
    history.go("/funds");
  }, 2000);
}

function errorTrigger(data, history) {
  console.log(data);
  toast.error(data?.message || "Error", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  setTimeout(() => {
    history.go("/funds");
  }, 2000);
}

export const cashfreeGateway = async (token, data, history, type) => {
  //always show order details while showing cashfree screen
  const options = {
    onPaymentSuccess: (data) => successTrigger(data, token, history),
    onPaymentFailure: (data) => paymentFailureTrigger(data, history),
    onError: (data) => errorTrigger(data, history),
  };
  const cashfree = new window.Cashfree(data?.data?.cashfree?.orderToken);
  const cfCheckout = cashfree.elements(options);
  if (type === "net") {
    cfCheckout.element({
      pay: document.getElementById("pay-nb"),
      type: "netbanking",
    });
    await cfCheckout.pay("netbanking");
  }
  if (type === "upi") {
    cfCheckout.elements([
      {
        pay: document.getElementById("pay-collect"),
        type: "upi-collect",
      },
    ]);

    await cfCheckout.pay("upi-collect");
  }
};
