import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import "./Accordian.scss";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import toogle from "../../../../Assets/imgs/toogle.svg";
import longarrow from "../../../../Assets/imgs/longarrow.svg";
import varifiedIcon from "../../../../Assets/imgs/varified.svg";
import notvarified from "../../../../Assets/imgs/notvarified.svg";
import failed from "../../../../Assets/imgs/faild.svg";
import Pencilicom from "../../../../Assets/imgs/pencil.svg";
import { useSelector } from "react-redux";
import {
  get_trades,
  order_details,
  download_signed_document,
} from "../../../../api/orderDetails";
import { toast } from "react-toastify";
import { whoami } from "../../../../api/auth";
import ScreenLoader from "../../../../components/screen-loader/ScreenLoader";
import Trade from "../../Components/Trade/Trade";
import ObligationBox from "../../Components/obligation-box/ObligationBox";
import ClosureBox from "../../Components/closure-box/ClosureBox";
import Button from "../../../../components/button/Button";
import {
  PopUp1,
  PopUp2,
  PopUp3,
  PopUp4,
  PopUp5,
  PopUp6,
  PopUp7,
  PopUp8,
  PopUp42,
  PopUp52,
  PopUp62,
  PopUp72,
  PopUp82,
  Popup9,
} from "../../Components/PopUps/PopUps";
import AccordionRead from "./AccordionRead";
import Cordermodi from "../../../OrderModification/CnfrmOrderModi/Cordermodi";
import BottomSlide from "../../../../components/bottom-slide/BottomSlide";

import { notify } from "../../../../api/order";
import SendLink from "../../../OrderModification/sendLink/SendLink";
import Confirm from "../../../../constants/Confirm";
import { cancelOrder } from "../../../../constants";
import Notfound from "../../../../components/NotFound/Notfound";
import AccordingButton from "./AccordingButton";

const orderStatus = {
  AWAITING_FUNDS: "Awaiting Funds",
  AWAITING_CONFIRMATION: "Awaiting Confirmation",
  ORDER_CONFIRMED: "Order Confirmed",
  PARTIALLY_FILLED: "Partially Filled",
  FULLY_FILLED: "Fully Filled",
  CANCELLED: "Cancelled",
  INTEREST_VALIDATED: "Interest Validated",
  SIGNING: "Signing",
  ORDER_EXPIRED: "Order Expired",
  SIGNATURE_EXPIRED: "Signature Expired",
  IN_ERROR: "In Error",
};

const badgeColors = {
  AWAITING_FUNDS: { color: "#F8DA6A", backgroundColor: "#FFF8EC" },
  AWAITING_CONFIRMATION: { color: "#F8DA6A", backgroundColor: "#FFF8EC" },
  ORDER_CONFIRMED: { color: "#7AD581", backgroundColor: "#DEFEDF" },
  PARTIALLY_FILLED: { color: "#F752F4", backgroundColor: "#FEE1FD" },
  FULLY_FILLED: { color: "#5188FF", backgroundColor: "#AEC7FF" },
  CANCELLED: { color: "#FF5B59", backgroundColor: "#FFE5E5" },
  INTEREST_VALIDATED: { color: "#848485", backgroundColor: "#E6E6E6" },
  SIGNING: { color: "#F8DA6A", backgroundColor: "#FFF8EC" },
  ORDER_EXPIRED: { color: "#7AD581", backgroundColor: "#DEFEDF" },
  SIGNATURE_EXPIRED: { color: "#F8DA6A", backgroundColor: "#FFF8EC" },
  IN_ERROR: { color: "#FF5B59", backgroundColor: "#FFE5E5" },
};

const Accor = () => {
  const history = useHistory();
  const [orderVersion, setOrderVersion] = useState("1");
  const orderId = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
  const urlFillId = new URLSearchParams(history.location.search).get("fill");
  const urlTab = new URLSearchParams(history.location.search).get("tab");
  const [confirmedIsOpen, setconfirmedIsOpen] = useState(false);
  const [placedIsOpen, setplacedIsOpen] = useState(false);
  const [fillId, setFillId] = useState("");
  const [detailPanel, setDetailPanel] = useState(true);
  const [trades, setTrades] = useState([]);
  const [disableClosure, setDisableClosure] = useState(false);
  const [obligationBadge, setObligationBadge] = useState(null);
  const [closureBadge, setClosureBadge] = useState(null);
  const [count, setCount] = useState(0);
  const [detail, setDetail] = useState({
    popup1: { open: false, data: {}, placementType: "" },
    popup2: { open: false, data: {}, placementType: "" },
    popup3: { open: false, data: {}, placementType: "" },
    popup4: { open: false, data: {}, placementType: "" },
    popup5: { open: false, data: {}, placementType: "" },
    popup6: { open: false, data: {}, placementType: "" },
    popup7: { open: false, data: {}, placementType: "" },
    popup8: { open: false, data: {}, placementType: "" },
    popup42: { open: false, data: {}, placementType: "" },
    popup52: { open: false, data: {}, placementType: "" },
    popup62: { open: false, data: {}, placementType: "" },
    popup72: { open: false, data: {}, placementType: "" },
    popup82: { open: false, data: {}, placementType: "" },
  });
  const [resError, setResError] = useState("");
  const [openSlide, setOpenSlide] = useState({
    open: true,
    div: "trade",
  });

  const percentageFormatter = (data) => {
    if (data % 1 === 0) {
      return data;
    } else {
      return data.toFixed(2);
    }
  };

  useEffect(() => {
    if (count > 0) {
      history.replace({
        pathname: history.location.pathname,
        search: fillId
          ? `?v=${orderVersion}&fill=${fillId}&tab=${openSlide.div}`
          : `?v=${orderVersion}&tab=${openSlide.div}`,
      });
    } else {
      setCount(count + 1);
    }
  }, [openSlide.div, urlFillId]);

  const changeDiv = (check, tradeData) => {
    switch (check) {
      case "trade":
        return <Trade id={tradeData.fillId} orderQty={order.orderDetail.qty} />;
      case "obligation":
        return (
          <ObligationBox
            userData={myData}
            trade={tradeData}
            order={order}
            detail={detail}
            setDetail={setDetail}
          />
        );
      case "closure":
        return (
          <ClosureBox
            userData={myData}
            trade={tradeData}
            order={order}
            detail={detail}
            setDetail={setDetail}
          />
        );
      default:
        return <Trade />;
    }
  };

  const CruntDiv = (data) => {
    setOpenSlide({
      open: true,
      div: data,
    });
  };

  const handleChange = () => {
    setDetailPanel(!detailPanel);
  };
  const handleChange2 = (fillId, trade) => (event, isExpanded) => {
    console.clear();
    console.log(trade);
    openBox(trade);
    const newFillID = trade.fillId === fillId ? null : trade.fillId;
    setFillId(newFillID);
    history.replace({
      pathname: history.location.pathname,
      search: newFillID
        ? `?v=${orderVersion}&fill=${newFillID}&tab=${openSlide.div}`
        : `?v=${orderVersion}&tab=${openSlide.div}`,
    });
  };
  const [myData, setMyData] = useState({});
  const [screenLoading, setScreenLoading] = useState(false);
  const [order, setOrder] = useState();
  const {
    user,
    user: { token },
  } = useSelector((state) => state);

  const formatDate = (timestamp) => {
    let d = new Date(timestamp * 1000);
    let ye = new Intl.DateTimeFormat("en", { year: "2-digit" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    let formatted = da + "/" + mo + "/" + ye;
    return formatted;
  };

  const formatDateTime = (timestamp) => {
    let d = new Date(timestamp * 1000);
    var options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return d.toLocaleDateString("en-UK", options);
  };
  const tradeFilter = (trade) => {
    // console.log("trade:", trade);
    const { fillLifeCycleStatus, obligationStatus, closureType } = trade;
    if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "PENDING" &&
      closureType === null
    ) {
      return {
        currentTradeStage: "Obligation pending",
        tradeStatus: "Action required",
        color: "blue",
      };
    } else if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "NEW" &&
      closureType === null
    ) {
      return {
        currentTradeStage: "Obligation pending",
        tradeStatus: "Prepare for share transfer",
        color: "blue",
      };
    } else if (
      fillLifeCycleStatus === "OBLIGATION_FULFILLED" &&
      obligationStatus === "FULFILLED" &&
      closureType === null
    ) {
      return {
        currentTradeStage: "Obligation pending",
        tradeStatus: "No action required",
        color: "blue",
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      obligationStatus === "FULFILLED" &&
      closureType === "SUCCESS"
    ) {
      return {
        currentTradeStage: "Open Closure",
        tradeStatus: "No action required",
        color: "blue",
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      (obligationStatus === "FULFILLED" ||
        obligationStatus === "CLOSED_BEFORE_READY") &&
      closureType === "FAILURE"
    ) {
      return {
        currentTradeStage: "Failed closure",
        tradeStatus: "Trade failed",
        color: "red",
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      obligationStatus === "FULFILLED" &&
      closureType === "SUCCESS"
    ) {
      return {
        currentTradeStage: "Successful closure",
        tradeStatus: "Trade completed",
        color: "green",
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      (obligationStatus === "FULFILLED" ||
        obligationStatus === "CLOSED_BEFORE_READY") &&
      closureType === "FAILURE"
    ) {
      return {
        currentTradeStage: "Failed closure",
        tradeStatus: "Trade failed",
        color: "red",
      };
    } else if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "PENDING_TIME_EXPIRED" &&
      closureType === null
    ) {
      return {
        currentTradeStage: "Failed obligation",
        tradeStatus: "Trade failed",
        color: "red",
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      (obligationStatus === "PENDING" ||
        obligationStatus === "PENDING_TIME_EXPIRED") &&
      closureType === "FAILURE"
    ) {
      return {
        currentTradeStage: "Failed closure",
        tradeStatus: "Trade failed",
        color: "red",
      };
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      (obligationStatus === "PENDING" ||
        obligationStatus === "PENDING_TIME_EXPIRED") &&
      closureType === "FAILURE"
    ) {
      return {
        currentTradeStage: "Failed closure",
        tradeStatus: "Trade failed",
        color: "red",
      };
    } else {
      return {};
    }
  };
  const openBox = useCallback((trade) => {
    console.log({ trade });
    const { fillLifeCycleStatus, obligationStatus, closureType } = trade;
    if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "PENDING" &&
      closureType === null
    ) {
      CruntDiv("obligation");
      setDisableClosure(true);
      setObligationBadge(null);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "NEW" &&
      closureType === null
    ) {
      CruntDiv("obligation");
      setDisableClosure(true);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "OBLIGATION_FULFILLED" &&
      obligationStatus === "FULFILLED" &&
      closureType === null
    ) {
      CruntDiv("obligation");
      setDisableClosure(true);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      obligationStatus === "FULFILLED" &&
      closureType === "SUCCESS"
    ) {
      CruntDiv("closure");
      setDisableClosure(false);
      setObligationBadge(true);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      obligationStatus === "FULFILLED" &&
      closureType === "FAILURE"
    ) {
      CruntDiv("closure");
      setDisableClosure(false);
      setObligationBadge(true);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      obligationStatus === "CLOSED_BEFORE_READY" &&
      closureType === "FAILURE"
    ) {
      CruntDiv("closure");
      setDisableClosure(false);
      setObligationBadge(false);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      obligationStatus === "FULFILLED" &&
      closureType === "SUCCESS"
    ) {
      CruntDiv("closure");
      setDisableClosure(false);
      setObligationBadge(true);
      setClosureBadge(true);
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      obligationStatus === "FULFILLED" &&
      closureType === "FAILURE"
    ) {
      CruntDiv("closure");
      setDisableClosure(false);
      setObligationBadge(true);
      setClosureBadge(false);
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      obligationStatus === "CLOSED_BEFORE_READY" &&
      closureType === "FAILURE"
    ) {
      CruntDiv("closure");
      setDisableClosure(false);
      setObligationBadge(false);
      setClosureBadge(false);
    } else if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "PENDING_TIME_EXPIRED" &&
      closureType === null
    ) {
      CruntDiv("obligation");
      setDisableClosure(true);
      setObligationBadge(false);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      (obligationStatus === "PENDING" ||
        obligationStatus === "PENDING_TIME_EXPIRED" ||
        obligationStatus === "CLOSED_BEFORE_READY") &&
      closureType === "FAILURE"
    ) {
      CruntDiv("closure");
      setDisableClosure(false);
      setObligationBadge(false);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      (obligationStatus === "PENDING" ||
        obligationStatus === "PENDING_TIME_EXPIRED" ||
        obligationStatus === "CLOSED_BEFORE_READY") &&
      closureType === "FAILURE"
    ) {
      CruntDiv("closure");
      setDisableClosure(false);
      setObligationBadge(false);
      setClosureBadge(false);
    }
  }, []);

  const setBadges = (trade) => {
    const { closureId, fillLifeCycleStatus, obligationStatus, closureType } =
      trade;
    if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "PENDING" &&
      closureType === null
    ) {
      setObligationBadge(null);
      setClosureBadge(null);
      setDisableClosure(true);
    } else if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "NEW" &&
      closureType === null
    ) {
      setDisableClosure(true);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "OBLIGATION_FULFILLED" &&
      obligationStatus === "FULFILLED" &&
      closureType === null
    ) {
      setObligationBadge(true);
      setClosureBadge(null);
      setDisableClosure(true);
    } else if (
      fillLifeCycleStatus === "OBLIGATION_FULFILLED" &&
      obligationStatus === "FULFILLED" &&
      closureId === null
    ) {
      setObligationBadge(true);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      obligationStatus === "FULFILLED" &&
      closureType === "SUCCESS"
    ) {
      setObligationBadge(true);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      obligationStatus === "FULFILLED" &&
      closureType === "FAILURE"
    ) {
      setObligationBadge(true);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      obligationStatus === "FULFILLED" &&
      closureType === "SUCCESS"
    ) {
      setObligationBadge(true);
      setClosureBadge(true);
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      (obligationStatus === "FULFILLED" ||
        obligationStatus === "CLOSED_BEFORE_READY") &&
      closureType === "FAILURE"
    ) {
      setObligationBadge(true);
      setClosureBadge(false);
    } else if (
      fillLifeCycleStatus === "OBLIGATION_CREATED" &&
      obligationStatus === "PENDING_TIME_EXPIRED" &&
      closureType === null
    ) {
      setObligationBadge(false);
      setClosureBadge(null);
      setDisableClosure(true);
    } else if (
      fillLifeCycleStatus === "CLOSURE_CREATED" &&
      (obligationStatus === "PENDING" ||
        obligationStatus === "PENDING_TIME_EXPIRED") &&
      closureType === "FAILURE"
    ) {
      setObligationBadge(false);
      setClosureBadge(null);
    } else if (
      fillLifeCycleStatus === "CLOSURE_COMPLETE" &&
      (obligationStatus === "PENDING" ||
        obligationStatus === "PENDING_TIME_EXPIRED") &&
      closureType === "FAILURE"
    ) {
      setObligationBadge(false);
      setClosureBadge(false);
    }
  };

  const get_order = async () => {
    setScreenLoading(true);

    let response = await order_details(token, orderId);
    if (response.error) {
      setScreenLoading(false);
      console.log(response.error);
      setResError(response.error.data.error);
    } else {
      setOrder(response.data.data);
      setOrderVersion(response?.data?.data?.orderDetail?.version);
    }
  };

  const get_download_signed_document = async () => {
    let response = await download_signed_document(token, orderId);
    if (response.error) {
      toast.error(response.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      //TODO download blob

      // set the blog type to final pdf
      const file = new Blob([response.data.data], { type: "application/pdf" });

      // process to auto download it
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = orderId + "_signed.pdf";
      link.click();
    }
  };

  const get_all_trades = async () => {
    setScreenLoading(true);
    let response = await get_trades(token, orderId);
    if (response.error) {
      setScreenLoading(false);
      toast.error(response.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setTrades(response.data.data);
    }
  };

  const myself = async () => {
    if (token) {
      let response = await whoami(token);
      if (response.error) {
        toast.error(response.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setMyData(response.data.data);
      }
    }
  };
  useEffect(() => {
    get_order();
    myself();
    get_all_trades();
  }, []);

  useEffect(() => {
    if (trades && order) {
      setScreenLoading(false);
    }
  }, [trades, order]);

  useEffect(() => {
    if (urlTab && urlFillId) {
      if (urlFillId !== null) {
        setOpenSlide({
          open: true,
          div: urlTab,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (
      urlFillId &&
      urlFillId?.length > 0 &&
      trades?.length > 0 &&
      urlFillId !== "false"
    ) {
      var index = trades.findIndex(function (trade) {
        return trade.fillId === urlFillId;
      });
      //if fill id is not in trades array, make index as 0
      if (index === -1) {
        index = 0;
      }
      setBadges(trades[index]);
    }
  }, [trades, urlFillId]);

  const [otp, setOtp] = useState("");

  const [openslide, setOpenslide] = useState({
    open: false,
    div: null,
  });
  const [otpLoader, setOtpLoader] = useState(false);
  const [shareDetail, setShareDetail] = useState(null);

  const opentheSlide = (prop) => {
    setOpenslide({
      open: true,
      div: prop,
    });
  };

  const goToShareLink = async () => {
    let res = await notify(
      token,
      order?.orderDetail?.orderRequestId,
      order?.orderDetail?.version,
      "cancel"
    );
    if (res.error != null) {
      toast.error(res.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      history.push({
        pathname: "/call-client",
        state: {
          company: order?.companyDetail,
          path: "cancel",
          orderInfo: order?.orderDetail,
          expireTime: res?.data?.data?.expireTime,
        },
      });
    }
  };

  useEffect(() => {
    if (!urlTab && urlFillId && trades.length > 0) {
      const tradeToOpen = trades.find((trade) => trade.fillId === urlFillId);
      console.log({
        tradeToOpen,
      });
      if (tradeToOpen) openBox(tradeToOpen);
    }
    if (urlFillId) {
      setFillId(urlFillId);
    }
  }, [openBox, trades, urlFillId, urlTab]);

  return (
    <>
      {screenLoading && <ScreenLoader />}
      <div className="accor__container">
        {resError.length > 0 && (
          <Notfound
            resError={resError}
            link="/dashboard"
            text="Go back to dashboard"
          />
        )}
        {detail.popup1.open && (
          <PopUp1
            order={order}
            data={detail.popup1.data}
            placementType={detail.popup1.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup2.open && (
          <PopUp2
            order={order}
            data={detail.popup2.data}
            placementType={detail.popup2.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup3.open && (
          <PopUp3
            order={order}
            data={detail.popup3.data}
            placementType={detail.popup3.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup4.open && (
          <PopUp4
            order={order}
            data={detail.popup4.data}
            placementType={detail.popup4.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup5.open && (
          <PopUp5
            order={order}
            data={detail.popup5.data}
            placementType={detail.popup5.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup6.open && (
          <PopUp6
            order={order}
            data={detail.popup6.data}
            placementType={detail.popup6.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup7.open && (
          <PopUp7
            order={order}
            data={detail.popup7.data}
            placementType={detail.popup7.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup8.open && (
          <PopUp8
            order={order}
            data={detail.popup8.data}
            placementType={detail.popup8.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup42.open && (
          <PopUp42
            order={order}
            data={detail.popup42.data}
            placementType={detail.popup42.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup52.open && (
          <PopUp52
            order={order}
            data={detail.popup52.data}
            placementType={detail.popup52.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup62.open && (
          <PopUp62
            order={order}
            data={detail.popup62.data}
            placementType={detail.popup62.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup72.open && (
          <PopUp72
            order={order}
            data={detail.popup72.data}
            placementType={detail.popup72.placementType}
            setDetail={setDetail}
          />
        )}
        {detail.popup82.open && (
          <PopUp82
            order={order}
            data={detail.popup82.data}
            placementType={detail.popup82.placementType}
            setDetail={setDetail}
          />
        )}
        {order && (
          <div className="buyerInfo__container">
            <div className="accor__header">
              <div className="accor__first">
                <div className="accor__background">
                  <img
                    className="accor__img"
                    src={order.companyDetail.logo}
                    alt=""
                  />
                </div>

                <div className="accor__second">
                  <div className="accor__second__heading">
                    {order.companyDetail.aka}
                  </div>
                  <div className="accor__second__body">
                    <div className="accor__body__one">
                      {order.orderDetail.action === "ASK" ? (
                        <span className="orderCardSellAction">SELL</span>
                      ) : (
                        <span className="orderCardBuyAction">BUY</span>
                      )}
                      &nbsp;| {order.orderDetail.qty} @{" "}
                      {order.orderDetail.price}
                    </div>
                    <div className="accor__body__one">
                      ISIN: {order.companyDetail.isin}
                    </div>
                  </div>
                </div>
              </div>

              <div className="accor__third__box">
                <p
                  className="accor__box1"
                  style={
                    badgeColors[[order.orderDetail.consolidatedOrderStatus]]
                  }
                >
                  {orderStatus[order.orderDetail.consolidatedOrderStatus]}
                </p>
                <p
                  className="accor__box2"
                  style={
                    order.orderDetail.listingType === "FRESH"
                      ? {
                          backgroundColor: "rgba(211, 211, 211, 0.486)",
                          color: "grey",
                        }
                      : null
                  }
                >
                  {" "}
                  {order.orderDetail.listingType === "FRESH"
                    ? "Fresh Round"
                    : "2 Way Quote"}
                </p>
                {(order?.orderDetail?.consolidatedOrderStatus ===
                  "AWAITING_CONFIRMATION" ||
                  order?.orderDetail?.consolidatedOrderStatus ===
                    "ORDER_CONFIRMED" ||
                  order?.orderDetail?.consolidatedOrderStatus ===
                    "PARTIALLY_FILLED") &&
                  (order?.orderDetail.placementType === "SELF_PLACED" ||
                    order?.orderDetail.placementType === "PARTNER_PLACED") && (
                    <img
                      src={Pencilicom}
                      alt="Error"
                      className="accor__box3__img"
                      onClick={() => {
                        history.push(
                          `/order-modification?orderId=${orderId}&type=modify`
                        );
                      }}
                    />
                  )}
              </div>
            </div>
          </div>
        )}
        {order && (
          <Accordion expanded={detailPanel} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="accor__heading">Order Details</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accor__expanded__view">
                <div className="ev__body1">
                  <p className="ev__body1__para1">
                    {order.orderDetail.action === "ASK" ? (
                      <span style={{ color: "gray" }}>SELL:&nbsp;</span>
                    ) : (
                      <span style={{ color: "gray" }}>BUY:&nbsp;</span>
                    )}
                    {order.orderDetail.qty} Shares @ Rs.
                    {order.orderDetail.price}
                    {myData.userType !== "INDIVIDUAL_CLIENT" &&
                      `each (Net) [Spread (Rs): ${order.orderDetail.netSpreadAmount}]`}
                  </p>
                  <p className="ev__body1__para2">
                    <span style={{ color: "gray" }}>Valid till:&nbsp;</span>
                    {formatDate(order.orderDetail.expiryTimestamp)}
                  </p>
                  <p className="ev__body1__para2">
                    <span style={{ color: "gray" }}>Min trade qty:&nbsp;</span>
                    {/* {Math.trunc(
                      Math.ceil(
                        order.orderDetail.minimumFulfillmentPercent *
                          order.orderDetail.qty
                      )
                    )} */}
                    {order?.orderDetail?.minimumFulfillmentQty || 0}
                  </p>
                  <p className="ev__body1__para2">
                    <span style={{ color: "gray" }}>Order ID:&nbsp;</span>
                    {order.orderDetail.orderRequestId}
                  </p>
                  <p className="ev__body1__para2">
                    <span style={{ color: "gray" }}>Order Version:&nbsp;</span>
                    {order.orderDetail.version}
                  </p>
                </div>
                <div
                  className="ev__body2"
                  style={{ margin: ".5rem 0", position: "relative" }}
                >
                  <p className="ev__body2__para1" style={{ color: "grey" }}>
                    Placed by:
                  </p>
                  <p className="ev__body2__para2">
                    {order.placedByContactInformation.fullName !== null
                      ? order.placedByContactInformation.fullName
                      : order.placedByContactInformation.emailId}{" "}
                    (ClientID:&nbsp;
                    <button
                      onClick={() => {
                        setplacedIsOpen(true);
                      }}
                      className="btn"
                    >
                      {order.placedByContactInformation.userId}
                    </button>{" "}
                    )
                    <br /> on&nbsp;
                    {formatDate(order.orderDetail.requestReceivedTimestamp)}
                  </p>
                  {/* <Link className="ev__body2__link">Modification History</Link> */}
                </div>
                <div className="ev__body3" style={{ margin: ".5rem 0" }}>
                  <p className="ev__body3__para1" style={{ color: "grey" }}>
                    Confirmed by:
                  </p>
                  <p className="ev__body3__para2">
                    {order.placedForContactInformation.fullName !== null
                      ? order.placedForContactInformation.fullName
                      : order.placedForContactInformation.emailId}{" "}
                    (ClientID:&nbsp;
                    <button
                      onClick={() => {
                        setconfirmedIsOpen(true);
                      }}
                      className="btn"
                    >
                      {order.placedForContactInformation.userId}
                    </button>{" "}
                    )
                    <br /> on&nbsp;
                    {order.orderDetail.requestConfirmationTimeStamp === 0
                      ? "Not yet confirmed"
                      : formatDate(
                          order.orderDetail.requestConfirmationTimeStamp
                        )}
                  </p>
                </div>
                {[
                  "ORDER_CONFIRMED",
                  "ORDER_EXPIRED",
                  "PARTIALLY_FILLED",
                  "FULLY_FILLED",
                  "CANCELLED",
                ].includes(order.orderDetail.consolidatedOrderStatus) && (
                  <div className="ev__body3">
                    <p className="ev__body3__para1" style={{ color: "grey" }}>
                      Download:
                    </p>
                    <p className="ev__body3__para2">
                      <button
                        onClick={() => {
                          get_download_signed_document();
                        }}
                        className="btn"
                      >
                        Signed Expression Of Intent
                      </button>
                    </p>
                  </div>
                )}
              </div>
              {confirmedIsOpen && (
                <Popup9
                  orderData={order.placedForContactInformation}
                  setClientIdIsOpen={setconfirmedIsOpen}
                  heading={"Confirmed By"}
                />
              )}
              {placedIsOpen && (
                <Popup9
                  orderData={order.placedByContactInformation}
                  setClientIdIsOpen={setplacedIsOpen}
                  heading={"Placed By"}
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}

        {trades.length > 0 && (
          <div className="sa__header">
            Trades
            <div className="sa__icon">
              <img className="sa__icon" src={toogle} alt="" />
            </div>
          </div>
        )}
        {order?.orderDetail?.consolidatedOrderStatus === "SIGNING" &&
          order?.orderDetail?.placedForUid === myData.id && (
            <Button
              text="Retry Signing"
              click={() => {
                history.push(
                  `/confirm-order?orderId=${orderId}&orderVersion=${orderVersion}`
                );
              }}
            />
          )}
        {order?.orderDetail?.consolidatedOrderStatus === "SIGNING" &&
          order?.orderDetail?.placedForUid === myData.id && (
            <button
              onClick={() => opentheSlide("confirm")}
              className="cancel-btn"
            >
              Cancel Order
            </button>
          )}
        {order?.orderDetail?.consolidatedOrderStatus ===
          "AWAITING_CONFIRMATION" &&
          order?.placedForContactInformation?.userId === myData.id && (
            <Button
              text="Confirm Order"
              click={() => {
                history.push(
                  `/confirm-order?orderId=${orderId}&orderVersion=${orderVersion}`
                );
              }}
            />
          )}
        {order?.orderDetail?.consolidatedOrderStatus === "ORDER_CONFIRMED" &&
          trades?.length === 0 && (
            <div className="obligation__container">
              <AccordionRead />
            </div>
          )}
        {trades && (
          <>
            {order &&
              trades.length > 0 &&
              trades.map((val, index) => {
                // console.log(val);
                // var fillId = val.fillId;
                return (
                  <div className="obligation__container" key={index}>
                    <Accordion
                      // expanded={expanded === `${fillId}`}
                      expanded={fillId === val?.fillId}
                      onChange={handleChange2(fillId, val)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{
                          borderLeft: ` 8px solid ${tradeFilter(val).color}`,
                        }}
                      >
                        <div className="sa__box">
                          <p className="sa__detail">
                            {val.qty}(
                            {percentageFormatter(
                              (val.qty / order.orderDetail.qty) * 100
                            )}
                            %) @ Rs. {val.price} |&nbsp;
                            {formatDateTime(val.fillCreationTime)}
                          </p>
                          <p className="sa__status">
                            Status: {tradeFilter(val).currentTradeStage}
                          </p>
                          <p
                            className="sa__action"
                            style={{
                              color:
                                tradeFilter(val).tradeStatus ===
                                "No Action Required"
                                  ? "blue"
                                  : tradeFilter(val).color,
                            }}
                          >
                            {tradeFilter(val).tradeStatus}
                          </p>
                          {tradeFilter(val).tradeStatus ===
                            "Action Required" && (
                            <div className="sa__btn"> Fulfill Obligation </div>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accor__expanded__view">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              width: "100%",
                            }}
                          >
                            <AccordingButton
                              active={openSlide.div === "trade"}
                              iconSrc={varifiedIcon}
                              onClick={() => CruntDiv("trade")}
                            >
                              Trade
                            </AccordingButton>
                            <img
                              className="ac__steps__arrow"
                              src={longarrow}
                              alt="Error"
                            />
                            <AccordingButton
                              disabled={false}
                              onClick={() => CruntDiv("obligation")}
                              active={openSlide.div === "obligation"}
                              iconSrc={
                                obligationBadge === null
                                  ? notvarified
                                  : obligationBadge
                                  ? varifiedIcon
                                  : failed
                              }
                            >
                              Obligation
                            </AccordingButton>
                            <img
                              className="ac__steps__arrow"
                              src={longarrow}
                              alt="Error"
                            />
                            <AccordingButton
                              disabled={disableClosure}
                              onClick={() => CruntDiv("closure")}
                              active={openSlide.div === "closure"}
                              iconSrc={
                                closureBadge === null
                                  ? notvarified
                                  : closureBadge
                                  ? varifiedIcon
                                  : failed
                              }
                            >
                              Closure
                            </AccordingButton>
                          </div>
                          {openSlide.open
                            ? changeDiv(openSlide.div, val)
                            : null}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
          </>
        )}
      </div>
      {openslide.open && (
        <BottomSlide
          bottomSlide={() => {
            setOpenslide(false);
          }}
        >
          {openslide.div === "confirm" ? (
            <>
              <Confirm
                orderData={order?.orderDetail}
                user={user}
                // partnerName={partnerName}
                setShareDetail={setShareDetail}
                setOpenslide={setOpenslide}
                setOtpLoader={setOtpLoader}
              />
            </>
          ) : openslide.div === "otp" ? (
            <>
              <Cordermodi
                heading="order cancellation"
                btnText="CANCEL ORDER"
                otpValue={otp}
                setOtpValue={setOtp}
                otpLoader={otpLoader}
                sendOrder={() => {
                  cancelOrder({
                    setOtpLoader,
                    user,
                    orderData: order?.orderDetail,
                    otp,
                    history,
                    company: order?.companyDetail,
                  });
                }}
                orderId={order?.orderDetail?.orderId}
                version={order?.orderDetail?.version}
              />
            </>
          ) : openslide.div === "sendlink" ? (
            <>
              <SendLink data={shareDetail} action={goToShareLink} />
            </>
          ) : null}
        </BottomSlide>
      )}
    </>
  );
};

export default Accor;
