import React from 'react'
import "./SearchNotFound.css"

const SearchNotFound = () => {
    return (
        <div className="snf__wrapper">
            <div className="search__not_found">
                <p className="snf__title">Can't find what you're looking for?</p>
                <a href="https://fundsmap.freshdesk.com/support/tickets/new" className="snf__btn">RAISE A REQUEST</a>
                <span className="snf__desc1">Raise a request to add a company that you would wish to invest in.</span>
                <span className="snf__desc2">We are commited to find oppotunities for you.</span>
            </div>
        </div>
    )
}

export default SearchNotFound
